import React, { memo, useCallback } from 'react';
import { floatkeys, roundkeys, FILTER_COLUMNS, DISPLAY_TOTAL } from './TableBack';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import OpenNotification from '../common/CommonButton/OpenNotification';
import UtilService from '../../services/util';
import { LIMIT } from './TableBack';
import {
  BASE_DOWNLOAD_URL,
  BASE_URL,
  trackTypeObj,
  LOCAL_STORAGE_VAR,
  DIAMOND_WEB_STATUS,
  DIAMOND_BID,
  OFFICESTATUS,
} from '../../constants/Common';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import {
  SEARCH_DIAMOND_LIST,
  FETCH_CONFIRM_STONE,
  FETCH_DIAMOND_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  PRINT_PDF,
  EXPORT_EXCEL,
  CONFIRM_STONE,
  DELETE_TRACK,
  CREATE_BID,
  SEND_EMAIL_XRAY,
  FEATURE_STONE,
  CREATE_MATCH_PAIR,
  BID_PAGINATE,
  BID_DELETE,
  UPDATE_BID,
  SEARCH_LIST,
  DOWNLOAD_ZIP,
  DOWNLOAD_FILE,
  NOTE_UPSERT,
  FETCH_NOTES,
  NOTE_DELETE,
  PROJECT_SETTING,
  FETCH_OFFICE_DIAMOND,
  DELETE_OFFICE_DIAMOND,
  QUOTE_DAY_LIST,
  GET_BLOCK_DIAMONDS,
  BLOCK_MASTERS,
  ADD_HOLD,
  COUNTER_OFFER,
  BID_CART_PAGINATE,
  BLOCK_REALESE,
  CREATE_BID_CART,
  DIAMOND_TRACK_STATUS_UPDATE,
} from '../../constants/apiConstant';
import { Tooltip } from 'antd';
import { groupBy, uniq } from 'lodash';
import { parse } from 'query-string';
import { history } from 'util/history';
import { BidService } from 'services/BidService';
import { formatDate, formatDateTime } from 'util/utils';
import { Appointment } from 'components/common/DiamondListing/OfficeSidebar';
// import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from "@ant-design/icons";
const _ = require('lodash');

export const FLAGICON = {
  INDIA: require('../../assets/img/Flags/india.svg'),
  HONGKONG: require('../../assets/img/Flags/hongkong.png'),
  NEWYORK: require('../../assets/img/Flags/usa.jpg'),
  DUBAI: require('../../assets/img/Flags/dubai.png'),
  BELGIUM: require('../../assets/img/Flags/belgium.png')
}
const LOCATION = {
  india: 'IND',
  beligium: 'BEL',
  newyork: 'NY',
  hongkong: 'HK',
  dubai: 'DXB',
};
const NOTUPCOMING = [];

export const LISTINGPAGES = {
  DNA: 'dna',
  SHOW: 'show',
  LIST: 'diamond-list',
  UPCOMING: 'upcoming',
  DASHBOARD: 'dashboard',
  SEARCH: 'diamond-search',
  DEALTHEOFDAY: 'deal-of-the-day',
  FANCY_SEARCH: 'fancy-search',
  CART: 'cart',
  WATCHLIST: 'watchlist',
  REMINDER: 'reminder',
  CONFIRM: 'confirm-list',
  BID: 'new-arrival-bid-it',
  QUOTE: 'quote-list',
  STONE_OF_DAY: 'stone-of-the-day',
  NEWARR: 'new-arrivals',
  MATCHPAIR: 'match-pair',
  SEARCHMATCHPAIR: 'search-match-pair',
  MYBID: 'my-bid',
  SPECIAL: 'special-stone',
  EXCLUSIVE: 'exclusive',
  NOTE: 'notes',
  OFFICE: 'office-list',
  FORMAL_MEET: 'formal-meet',
  DETAIL: 'diamond-detail',
  DETAILS: 'diamond-details',
  QUOTEDAY: 'quote-day',
  SUGGESTED_STOCK: 'suggested-stock',
  ENQUIRY: 'enquiry',
  HOLD: 'hold',
  SEARCH_DIAMOND: 'search-bid-diamond',
  COMPARE: 'compare',
  MYDEALOFDAYLIST: 'my-deal-of-the-day',
  DEALOFDAYLIST: 'deal-of-the-day',
};

export const TITLE = {
  [LISTINGPAGES.LIST]: 'Diamond List',
  [LISTINGPAGES.SHOW]: `JCK | LAS VEGAS`,
  [LISTINGPAGES.UPCOMING]: 'New Arrival',
  BID: 'UBS',
  [LISTINGPAGES.STONE_OF_DAY]: 'Feature Stone',
  [LISTINGPAGES.NEWARR]: 'New Arrivals',
  [LISTINGPAGES.MATCHPAIR]: 'Couple Stone',
  [LISTINGPAGES.MYBID]: 'My Bid',
  [LISTINGPAGES.SPECIAL]: 'Exclusive Stone',
  [LISTINGPAGES.EXCLUSIVE]: 'Exclusive Collection',
  [LISTINGPAGES.CART]: 'My Cart',
  [LISTINGPAGES.WATCHLIST]: 'My Watchlist',
  [LISTINGPAGES.REMINDER]: 'My Reminder',
  [LISTINGPAGES.CONFIRM]: 'My Confirm List',
  [LISTINGPAGES.QUOTE]: 'My Quote List',
  [LISTINGPAGES.NOTE]: 'My Notes',
  [LISTINGPAGES.OFFICE]: 'My Office List',
  [LISTINGPAGES.FORMAL_MEET]: 'My Formal Meet',
  [LISTINGPAGES.QUOTEDAY]: 'Quote Day',
  [LISTINGPAGES.SUGGESTED_STOCK]: 'Suggested Stock',
  [LISTINGPAGES.ENQUIRY]: 'My Enquiry',
  [LISTINGPAGES.HOLD]: 'My Hold List',
};

export const getPath = () => window?.location?.pathname?.split?.('?')?.[0]?.split?.('/')?.pop?.();

const ColumnFilter = memo((props) => {
  const {
    column: { filterValue = [], setFilter, preFilteredRows, id, isSorted },
  } = props;
  const availableOptions = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if (!filterValue.includes(row.values[id])) {
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows, filterValue]);

  const toggleFilter = useCallback(
    (id) => {
      const selectedFilters = [...filterValue];
      if (selectedFilters.includes(id)) {
        selectedFilters.splice(
          selectedFilters.findIndex((x) => x == id),
          1,
        );
      } else {
        selectedFilters.push(id);
      }
      setFilter(selectedFilters);
    },
    [setFilter, filterValue],
  );

  return (
    <div className="llfilterMenuDropdown" style={isSorted ? { borderTop: '1px solid #e9ebf0' } : {}}>
      {filterValue && filterValue.length > 0 && (
        <ul className="llfilterSelect">
          <div className="llSortingTitle">Selected Filter</div>
          {filterValue.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`}>
                {x}
                <div className="closeFilter" onClick={() => toggleFilter(x)}>
                  <img src={require('../../assets/svg/DiamondList/close-black.svg')} alt="icon" />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {availableOptions.length ? (
        <ul>
          <div className="llSortingTitle">Select Filter</div>
          {availableOptions.map((x) => {
            return (
              <li key={`columnFilter_${id}${x}`} onClick={() => toggleFilter(x)}>
                {x}
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
});

// Define a custom filter filter function!
function filterArrayIncludes(rows, id, filterValue = []) {
  if (filterValue.length === 0) return rows;
  return rows.filter((row) => filterValue.includes(row.values[id]));
}
filterArrayIncludes.autoRemove = (val) => !val || !val.length;
export function isMobile() {
  return window.innerWidth <= 767 ? true : false;
}
export function isIpade() {
  return window.innerWidth <= 991 ? true : false;
}

export function getNewStatus(row) {
  const diff = moment() - moment(row?.mkDt);

  const newInvent = diff > 15 * 24 * 60 * 60 * 1000 || row.wSts === 'B' || row.wSts === 'U' ? false : true;
  return newInvent;
}

export function getColumn(page = 1) {
  const columnList = null;
  const Columns = [];
  const master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`) || columnList) {
    const getColumnList = columnList ? columnList : JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-column`));

    getColumnList.map((dataa) => {
      const data = {};
      Object.keys(dataa).map((x) => (data[x] = dataa[x]));
      if (data.isActive && data.field) {
        data.field = data.field.trim();
        data.title = data.title ? data.title.trim() : data.title;
        data.key = data.key ? data.key.trim() : data.key;
        if (_.find(Columns, { id: data.field })) return;
        if (data.field === 'reminderDate' && getPath() !== LISTINGPAGES.REMINDER) return false;
        const remarksList = [LISTINGPAGES.ENQUIRY, LISTINGPAGES.NOTE];
        if (data.field === 'remarks' && !remarksList.includes(getPath())) return false;
        if (data.field === 'remarks' && remarksList.includes(getPath()))
          data.title = getPath() === LISTINGPAGES.NOTE ? 'Notes' : 'Enquiry';
        // if (data.field === "remarks" && getPath() !== LISTINGPAGES.NOTE)
        //   return false;
        // if (data.field === "remarks" && getPath() === LISTINGPAGES.NOTE)
        //   data.title = "Notes";
        if (NOTUPCOMING.includes(data.field) && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'back' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'rap' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'amt' && getPath() === LISTINGPAGES.UPCOMING) return false;
        if (data.field === 'status' && getPath() !== LISTINGPAGES.OFFICE) return false;
        if (data.field === 'purpose' && getPath() !== LISTINGPAGES.OFFICE) return false;
        if (data.field === 'purpose' && getPath() === LISTINGPAGES.OFFICE) data.title = 'Comments';
        if (data.field === 'ctPr' && getPath() === LISTINGPAGES.UPCOMING) return false;

        const canFilter = FILTER_COLUMNS.includes(data.field);
        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCHLIST) data.title = "Today's Disc%";

        Columns.push({
          Header: data.title,
          accessor: data.field,
          id: data.field,
          desc: data.desc,
          link: data.link,
          sort: data.sort,
          width: data.width,
          cellClass: data.cellClass,
          Cell: ({ row, cell }) => {
            // className certificateNo redColor greenColor

            if (data.field === 'dna') cell.value = row.original.vStnId;
            const newInvent = getNewStatus(row.original);
            if (data.key === 'TO') data.key = 'OPEN_INCLUSION_TABLE';
            else if (data.key === 'CO') data.key = 'OPEN_INCLUSION_CROWN';
            else if (data.key === 'PO') data.key = 'OPEN_INCLUSION_PAVILION';
            else if (data.key === 'CN') data.key = 'BLACK_INCLUSION_TABLE';
            else if (data.key === 'SN') data.key = 'BLACK_INCLUSION_SIDE';
            else if (data.key === 'CW') data.key = 'WHITE_INCLUSION_TABLE';
            else if (data.key === 'SW') data.key = 'WHITE_INCLUSION_SIDE';
            const fullName =
              cell.value &&
                master &&
                data.key &&
                master[data.key] &&
                _.find(master[data.key], { code: cell.value }) &&
                _.find(master[data.key], { code: cell.value }).description
                ? _.find(master[data.key], { code: cell.value }).description
                : cell.value &&
                  master &&
                  data.key &&
                  master[data.key] &&
                  _.find(master[data.key], { name: cell.value }) &&
                  _.find(master[data.key], { name: cell.value }).description
                  ? _.find(master[data.key], { name: cell.value }).description
                  : null;

            return (
              <div
                className={
                  data.field === 'dna' // data.field === 'stoneId' || data.field === 'vStnId'
                    ? 'underline'
                    : floatkeys.includes(data.field) || roundkeys.includes(data.field)
                      ? ''
                      : null
                }
              >
                {data.isFlag && cell.value ? (
                  typeof data.sLabel === 'string' ? (
                    data.sLabel
                  ) : (
                    ' Yes'
                  )
                ) : data.isFlag && !cell.value ? (
                  typeof data.nLabel === 'string' ? (
                    data.nLabel
                  ) : (
                    '-'
                  )
                ) : cell.value === true ? (
                  'Y'
                ) : !cell.value ? (
                  '-'
                ) : data.field === 'vStnId' ? (
                  <div>
                    {cell.value}
                    {row?.original?.mkDt ? newInvent && <span className="newdiamond">New</span> : ''}
                  </div>
                ) : fullName ? (
                  // <Tooltip key={cell.row.id + cell.column.id} title={fullName}>
                  cell.value
                ) : // </Tooltip>
                  data.field === 'reminderDate' ? (
                    moment(row.original.reminderDate).format('LLL')
                  ) : row.original.isFcCol && data.field === 'colNm' ? (
                    <div className="normalTableDropdown">
                      <Tooltip
                        title={`${row.original.fcColNm ? row.original.fcColNm : '-'}`}
                        placement="bottomLeft"
                        className="normalTableDropdown "
                      >
                        {`${row.original.fcColNm ? row.original.fcColNm : '-'}`}
                      </Tooltip>
                      {/* <div className="tableDropdown">
                      {[
                        // row.original.intenNm,
                        // row.original.ovrtnNm,
                        row.original.fcColNm,
                      ]
                        .filter(function (el) {
                          return el && el;
                        })
                        .map((d, i) => {
                          return <span key={i}>{d}</span>;
                        })}
                    </div> */}
                    </div>
                  ) : data.field === 'locNm' ? (
                    <>
                      {/* <Tooltip title={cell.value.toUpperCase()}>
                                          {FLAGICON[cell.value.toUpperCase()] ? (
                                              <img
                                                  width='30px'
                                                  src={FLAGICON[cell.value.toUpperCase()]}
                                                  alt={row.original[data.field]}
                                              />
                                          ) : (
                                              cell.value
                                          )}
                                      </Tooltip> */}
                      {LOCATION[cell.value.trim().toLowerCase()] || cell.value}
                    </>
                  ) : data.field === 'status' && getPath() === LISTINGPAGES.OFFICE ? (
                    <div className={`officeStatus ${OFFICESTATUS[cell.value].toLowerCase()}`}>
                      <span>{OFFICESTATUS[cell.value]}</span>
                      {/* {LOCATION[cell.value.trim().toLowerCase()] || cell.value} */}
                    </div>
                  ) : floatkeys.includes(data.field) ? (
                    formatNumber(parseFloat(cell.value).toFixed(2))
                  ) : roundkeys.includes(data.field) ? (
                    formatNumber(Math.round(cell.value))
                  ) : (
                    cell.value
                  )}
              </div>
            );
          },
          Filter: canFilter ? ColumnFilter : <></>,
          filter: filterArrayIncludes,
        });
        if (data.field === 'Details' && getPath() === LISTINGPAGES.OFFICE) {
          Columns.unshift({
            Header: 'Status',
            accessor: 'status',
            id: 'status',
            Cell: ({ cell }) => {
              return (
                <div className={`officeStatus ${OFFICESTATUS[cell.value].toLowerCase()}`}>
                  <span>{OFFICESTATUS[cell.value]}</span>
                </div>
              );
            },
          });
        }
        // if (data.field === 'Details' && getPath() === LISTINGPAGES.SHOW) {
        //   Columns.push({
        //     Header: 'Tray No',
        //     accessor: 'showTrayNo',
        //     id: 'showTrayNo',
        //     minWidth: '100px',
        //     width: data.width,
        //     Cell: ({ cell }) => {
        //       const generateSerialNumber = (serialNumber) => {
        //         var desiredLength = 3; // Adjust the length as needed
        //         var paddedSerialNumber = String(serialNumber).padStart(desiredLength);

        //         return paddedSerialNumber;
        //       };
        //       return generateSerialNumber(cell?.value || '-');
        //     },
        //   });
        // }
        if (data.field === 'back' && getPath() === LISTINGPAGES.WATCHLIST) {
          Columns.push({
            Header: 'Comment',
            accessor: 'remarks',
            id: 'remarks',
            Cell: ({ cell }) => (cell.row.original.wSts !== 'U' ? cell.value : '-'),
          });

          // const rapColumnIndex = Columns.findIndex((column) => column.accessor === 'rap');
          // const rapColumn = Columns?.[rapColumnIndex];
          // rapColumn.Cell = function Cell(s) {
          //   return s.row.original.wSts === 'U' ? '-' : s.row.original.rap;
          // };
          // Columns.push({
          //   Header: 'Watchlist Disc%',
          //   accessor: 'trackDiscount',
          //   id: 'trackDiscount',
          //   Cell: ({ cell }) => (cell.value ? parseFloat(cell.value).toFixed(2) : '-'),
          // });
          // Columns.push({
          //   Header: 'Expected Disc%',
          //   accessor: 'newDiscount',
          //   id: 'newDiscount',
          //   Cell: ({ cell }) => {
          //     return cell.value ? parseFloat(cell.value).toFixed(2) : '-';
          //   },
          // });
          // Columns.push({
          //   accessor: '**',
          //   id: '**',
          //   Header: (
          //     <>
          //       <img width="12px" height="12px" src={require('../../assets/svg/up-arrow-dis.svg')} alt="Up Arrow" />
          //       /
          //       <img width="12px" height="12px" src={require('../../assets/svg/downa-rrow-dis.svg')} alt="Down Arrow" />
          //     </>
          //   ),
          //   Cell: ({ row }) => {
          //     let discUpDown = '-';
          //     if (row.original.trackDiscount !== 0 && row.original.trackDiscount < row.original.back) {
          //       discUpDown = (
          //         <img width="12px" height="12px" src={require('../../assets/svg/up-arrow-dis.svg')} alt="Up Arrow" />
          //       );
          //     } else if (row.original.trackDiscount !== 0 && row.original.trackDiscount > row.original.back) {
          //       discUpDown = (
          //         <img
          //           width="12px"
          //           height="12px"
          //           src={require('../../assets/svg/downa-rrow-dis.svg')}
          //           alt="Down Arrow"
          //         />
          //       );
          //     }
          //     return discUpDown;
          //   },
          // });
        }
      }
    });
  }

  return Columns;
}

export function handleAlter(array, item, key) {
  let checked = _.cloneDeep(array) || [];
  if (_.find(checked, { [key]: item[key] })) checked = checked.filter((el) => el[key] !== item[key]);
  else checked.push(item);
  return checked;
}

export function getParams(props_row) {
  const params = [
    // inside ? null : props_row.shpNm,
    // inside ? null : props_row.lbNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
    // inside ? null : props_row.fluNm
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneInfo(props_row) {
  const params = [
    props_row.vStnId || props_row.stoneId,
    props_row.shpNm,
    parseFloat(props_row.crt).toFixed(2),
    props_row.isFcCol ? props_row.fcColNm : props_row.colNm,
    props_row.clrNm,
    props_row.cutNm,
    props_row.polNm,
    props_row.symNm,
    props_row.fluNm,
  ].filter(function (el) {
    return el && el;
  });
  return params;
}

export function getStoneTitle(obj) {
  const params = obj.map((p) => ({
    shp: p.shpNm,
    crt: parseFloat(p.crt).toFixed(2),
    col: p.isFcCol ? p.fcColNm : p.colNm,
    clr: p.clrNm,
    cut: p.cutNm,
    pol: p.polNm,
    sym: p.symNm,
  }));
  let display = [];
  const keyArr = ['shp', 'crt', 'col', 'clr', 'cut', 'pol', 'sym'];
  keyArr.map((key) => {
    const word = _.groupBy(params, key);
    if (_.size(word) === 1) display.push(params[0][key]);
  });
  display = display.filter((el) => el && el).join(' ');
  return display;
}

export function compareStones(list, currentType = null) {
  if (list.length === 0) {
    OpenNotification({
      type: 'error',
      title: 'Please select stone(s) to compare.',
    });
    return;
  }
  if (list.length > 15) {
    OpenNotification({
      type: 'error',
      title: 'Maximum 15 stones can be compared.',
    });
    return;
  }
  let data =
    //localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
    //? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
    // :
    [];
  list.map((x) => data.push(window.location.pathname === '/account/confirm-list' ? x.diamondId : x.id));
  data = _.uniq(data);
  if (data.length === 1) {
    OpenNotification({
      type: 'error',
      title: 'Please select minimum two stones to compare.',
    });
    return;
  }
  if (data.length > 15) {
    data = data.slice(data.length - 15, data.length);
  }
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-compare-diamond`, JSON.stringify(data));
  localStorage.setItem(`${LOCAL_STORAGE_VAR}-now`, JSON.stringify(window.location.pathname + window.location.search));
  ['MyDealOfTheDay'].includes(currentType) ? history.push('/compare', { currentType }) : window.open('/compare');
}

export function fetchDiamondList(state, filters, cb) {
  const sort = state.sort;
  const obj = SEARCH_DIAMOND_LIST;
  obj.request = {
    filters,
    isAppendMasters: true,
    isSkipSave: true,
    page: state.page,
    limit: state.limit,
    sort: sort?.map((x) => (x.hasOwnProperty('clrNm') ? { clrSq: x.clrNm } : x)),
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      seachFilterTag = data.data[0]?.filter;
      rows = data.data[0]?.diamonds;
      sum = data.data[0]?.total && data.data[0]?.total[0];
      count = data.data[0]?.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows?.map((r) => r[x]));
      });
      inTrackDiamonds = data.data[0]?.inTrackDiamonds;
      inBlockDiamonds = data.data[0]?.inBlockDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
}

export function fetchStoneOfDay(state, filters, cb) {
  // let obj = FEATURE_STONE
  const obj = {
    ...SEARCH_DIAMOND_LIST,
  };

  obj.request = {
    filters,
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    isAppendMasters: true,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      //seachFilterTag = data.data[0].filter
      // Old code has list
      // rows = data.data.list //_.flatten(data.data.list.map(x => x.featuredPair))
      sum = data.data[0]?.total && data.data[0]?.total[0];
      rows = data?.data[0]?.diamonds;
      count = data?.data[0]?.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows?.map((r) => r[x]));
      });
      inTrackDiamonds = data?.data[0]?.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function handleExclusiveSearchListId(cb) {
  const searchAPI = SEARCH_DIAMOND_LIST;
  const searchAPIObj = {
    filters: [
      {
        or: [
          {
            crt: { '>=': '5.00', '<=': '5.99' },
          },
          {
            crt: { '>=': '6.00', '<=': '9.99' },
          },
          {
            crt: { '>=': '10.00', '<=': '19.99' },
          },
          {
            crt: { '>=': '20.000', '<=': '100' },
          },
        ],
      },
    ],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };
  const objData = {
    ...searchAPI,
    request: searchAPIObj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (data && data.code === 'OK') {
      const obj = SEARCH_LIST;
      obj.request = {
        id: data.data[0].filter.id,
        isAppendMasters: true,
      };
      UtilService.callApi(obj, function (err, data) {
        cb(data && data.code === 'OK' ? data.data.list[0].searchData : null);
      });
    } else cb(null);
  });
}

export function fetchOffer(state, cb) {
  const obj = {
    method: FETCH_DIAMOND_TRACK.method,
    url: FETCH_DIAMOND_TRACK.url,
    request: {
      page: state.page,
      limit: state.limit,
      trackType: trackTypeObj.QUOTE,
      sort: state.sort,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        otherRemarks: x.otherRemarks,
        bargainTrack: x.bargainTrack,
        remarks: x.remarks,
        newDiscount: x.newDiscount,
        newAmount: x.newAmount,
        newPricePerCarat: x.newPricePerCarat,
        offerStatus: x.offerStatus,
        updatedAt: x.updatedAt,
        trackId: x.id,
        offerValidDate: x.offerValidDate,
        grpBy: x.updatedAt.split('T')[0],
        usernotes: x?.usernotes
      }));
      const grp = groupBy(rows, 'grpBy');
      rows = [];
      Object.keys(grp).forEach((key) => {
        grp[key][0].isOfferHeader = true;
        rows = [...rows, ...grp[key]];
      });
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.forEach((x) => {
        filterArray[x] = uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchBid(state, configId, subTypeCode, status, cb, historyTab) {
  const obj = BID_PAGINATE;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    status: status,
    from: null,
    to: null,
    from: state.date.from ? state.date.from.toISOString() : null,
    to: state.date.to ? state.date.to.toISOString() : null,
    bidConfigurationType: getPath() === LISTINGPAGES.MYDEALOFDAYLIST ? 2 : undefined,
  };
  if (!historyTab) {
    obj.request = {
      ...obj.request,
      bidConfigurationId: configId,
      subTypeCode,
    };
  }
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        trackId: x.id,
        notes: x?.notes,
        bidAmount: x.bidAmount,
        bidDiscount: x.bidDiscount,
        bidPricePerCarat: x.bidPricePerCarat,
        bidType: x.bidType,
        status: x.status,
        updatedAt: x.updatedAt,
        grpBy: x.updatedAt.split('T')[0],
        isLowerBid: x.isLowerBid,
        usernotes: x?.usernotes,
      }));
      // if (type.length === 2) {
      const grp = _.groupBy(rows, 'grpBy');
      rows = [];
      Object.keys(grp).map((grpNo) => {
        const val = grp[grpNo];
        val[0].isBidHeader = true;
        val[0].groupingTitle = moment(val[0].updatedAt).format('DD/MM/YYYY');
        rows = [...rows, ...val];
      });
      // }
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchBidCart(cb) {
  return new Promise((resolve, reject) => {
    const obj = BID_CART_PAGINATE;
    obj.request = {
      trackType: trackTypeObj.BIDCART,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) reject(err);
      if (data && data.code === 'OK') return resolve(data.data);
      reject(err);
    });
  });
}

export function fetchMatchPair(state, diamondSearchId, cb) {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: state.page,
    limit: state.limit,
    sort: state.sort,
    //isPredefinedPair: false,
    isSkipSave: true,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    let inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      const grp = _.groupBy(data.data.list, 'groupNo');
      Object.keys(grp).map((grpNo) => {
        const val = grp[grpNo];
        val[0].isMatchHeader = true;
        val[0].groupingTitle = getStoneTitle(val);
        val[0].totalDiamonds = val.length;
        val[val.length - 1].groupData = {};
        val[val.length - 1].isMatchFooter = true;
        DISPLAY_TOTAL.map((i) => {
          val[val.length - 1].groupData[i] = [];
          val.map((x) => {
            if (x[i]) val[val.length - 1].groupData[i].push(x[i]);
            return true;
          });
          return true;
        });
        rows = [...rows, ...val];
      });
      sum = data.data.total[0];
      count = data.data.count;
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchConfirmStone(state, cb) {
  const obj = FETCH_CONFIRM_STONE;
  obj.request = { page: state.page, limit: state.limit };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    const sum = {};
    let seachFilterTag = {};
    const inTrackDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      const grp = data.data.list.filter((x) => x.memoDetails && x.memoDetails.length);
      _.each(grp, (x) => {
        const val = x.memoDetails;
        const obj = _.omit(x, 'memoDetails');
        val[0] = {
          ...obj,
          ...val[0],
          isConfirmHeader: true,
          totalDiamonds: val.length,
        };
        rows = [...rows, ...val];
      });
      // rows = _.uniqBy(rows, "vStnId");
      count = data.data.count;
      seachFilterTag = data.data.filter;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchCart(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.CART,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data?.data?.list.map((x) => ({ ...x?.diamond, usernotes: x?.usernotes } ?? {}));
      sum = data?.data.total[0];
      count = data?.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchFormalMeetList(state, cb) {
  const obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: 'ASC' }],
    //type: 1,
    appointmentFor: 1,
    filter: { date: { '>=': moment().startOf('day').toISOString() } },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];

    if (data && data.code === 'OK') {
      const formalMeet = data?.data?.list.map((formalMeet, index) => ({
        ...formalMeet,
        index: index + 1,
        cabinSlot: `${formatDate(formalMeet.date)} ${' (' +
          moment(formalMeet?.cabinSlot[0].start).format('hh:mm A') +
          '-' +
          moment(formalMeet?.cabinSlot[0].end).format('hh:mm A') +
          ')'
          }`,
        date: formatDateTime(formalMeet?.createdAt),
        comment: isEmpty(formalMeet?.purpose) ? '-' : formalMeet?.purpose,
        meetingType: Appointment.virtualType?.filter?.((x) => x?.id === formalMeet?.meetingType)[0]?.name,
      }));
      rows = formalMeet;
    }

    cb({
      data: rows,
    });
  });
}
export function fetchOfficeList(state, cb) {
  const obj = FETCH_OFFICE_DIAMOND;
  obj.request = {
    page: state.page,
    limit: state.limit,
    sort: [{ date: 'ASC' }],
    //type: 1,
    appointmentFor: 2,
    filter: { date: { '>=': moment().startOf('day').toISOString() } },
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      if (state?.status == 1) {
        const formalMeet = data?.data?.list.map((formalMeet, index) => ({
          ...formalMeet,
          index: index + 1,
          cabinSlot: `${formatDate(formalMeet.date)} ${' (' +
            moment(formalMeet?.cabinSlot[0].start).format('hh:mm A') +
            '-' +
            moment(formalMeet?.cabinSlot[0].end).format('hh:mm A') +
            ')'
            }`,
          date: formatDateTime(formalMeet?.createdAt),
          comment: isEmpty(formalMeet?.purpose) ? '-' : formalMeet?.purpose,
          meetingType: Appointment.virtualType?.filter?.((x) => x?.id === formalMeet?.meetingType)[0]?.name,
          usernotes: formalMeet?.usernotes
        }));
        rows = formalMeet;
      } else if (!isEmpty(data.data)) {
        const grp = data.data.list.filter((x) => x.diamonds && x.diamonds.length);
        _.each(grp, (x) => {
          const val = x.diamonds;
          val[0] = {
            ...val[0],
            isOfficeHeader: true,
            cabinSlot: x.cabinSlot,
            date: x.date,
            // meetingType: x.meetingType,
            // status: x.status,
            // purpose: x.purpose,
            // slottId: x.id,
          };
          _.each(val, (all) => {
            all.slottId = x.id;
            all.meetingType = x.meetingType;
            all.status = x.status;
            all.purpose = x.purpose;
          });
          rows = [...rows, ...val];
        });

        // rows = _.uniqBy(rows, "vStnId");
        count = data.data.count;
        sum = data.data.total;
        FILTER_COLUMNS.map((x) => {
          filterArray[x] = _.uniq(rows.map((r) => r[x]));
        });
        inTrackDiamonds = data.data.inTrackDiamonds;
      }
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchWatch(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.WATCHLIST,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        newDiscount: x.newDiscount,
        trackDiscount: x.trackDiscount,
        remarks: x.remarks,
        usernotes: x?.usernotes,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const releaseBlockedDiamonds = (ids, cb) => {
  const obj = {
    ...BLOCK_REALESE,
    request: { diamondBlocks: ids, status: 4 },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export function fetchNotes(state, cb) {
  const obj = FETCH_NOTES;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        remarks: x.remarks,
        trackId: x.id,
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchEnquiries(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    isAppendDiamond: 1,
    sort: state.sort,
    trackType: trackTypeObj.ENQUIRY,
  };

  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        remarks: x.remarks,
        ...x.diamond,
        trackId: x.id,
        createdAt: x.createdAt ? moment(x.createdAt).format('DD/MM/YYYY hh:mm A') : '-',
        usernotes: x?.usernotes
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      const grp = _.groupBy(rows, 'remarks');
      rows = [];
      Object.keys(grp).forEach((x) => {
        grp[x][0].isEnquiryRemarks = true;
        rows = [...rows, ...grp[x]];
      });

      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchReminder(state, cb) {
  const obj = FETCH_DIAMOND_TRACK;
  obj.request = {
    page: state.page,
    limit: state.limit,
    trackType: trackTypeObj.REMINDER,
    sort: state.sort,
  };
  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    let sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = data.data.list.map((x) => ({
        ...x.diamond,
        reminderDate: x.reminderDate,
        usernotes: x?.usernotes
      }));
      sum = data.data.total[0];
      count = data.data.count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export function fetchDiamondPaginate(state, type, cb, filtr = {}) {
  const obj = SEARCH_DIAMOND_LIST;
  const sort = getPath() === LISTINGPAGES.SPECIAL;

  obj.request = {
    page: state.page,
    limit: state.limit,
    filters: !isEmpty(filtr) ? [filtr] : [type.viewType ? {} : { wSts: type, ...filtr }],
    sort: sort ? [{ crt: 'DESC' }] : state.sort,
    viewType: type.viewType,
  };
  if (type === DIAMOND_WEB_STATUS.UPCOMING) {
    obj.request.isUpcoming = true;
  }
  return UtilService.callApi(obj, (err, data) => {
    let rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    const filterArray = {};
    let inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      seachFilterTag = data.data[0].filter;
      rows = data.data[0].diamonds;
      if (type === DIAMOND_WEB_STATUS.UPCOMING) {
        const grp = _.groupBy(rows, 'inDt');
        rows = [];
        Object.keys(grp).map((x) => {
          grp[x][0].isUpcomingHeader = true;
          grp[x][0].totalDiamonds = grp[x].length;
          rows = [...rows, ...grp[x]];
        });
      }
      sum = data.data[0].total[0] || {};
      count = data.data[0].count;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
      inTrackDiamonds = data.data.inTrackDiamonds;
    }
    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
}

export const handleInsertTrack = (trackType, selected, cb, date, remarks) => {
  const diamonds = [];
  selected.map((s) =>
    diamonds.push({
      diamond: s.id,
      trackPricePerCarat: s.ctPr,
      trackAmount: s.amt,
      newDiscount: trackType === trackTypeObj.WATCHLIST ? Number(s.expBack) : null,
      trackDiscount: trackType === trackTypeObj.WATCHLIST ? s.back : null,
    }),
  );
  const obj = {
    reminderDate: date,
    trackType,
    diamonds,
    remarks,
  };
  const objData = {
    ...CREATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    const flag =
      trackType !== trackTypeObj.REMINDER
        ? true
        : data &&
          data.data &&
          data.data.created &&
          data.data.created.length === 0 &&
          data.data.exists &&
          data.data.exists.length !== 0
          ? false
          : true;
    if (data && data.code === 'OK' && flag) {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export function handleNoteUpsert(selected, cb) {
  const obj = NOTE_UPSERT;
  obj.request = {
    diamonds: selected.map((d) => ({ diamond: d.id, remarks: d.note.trim() })),
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export const handleUpsertTrack = (trackType, selected, inTrackDiamonds, cb, date) => {
  const ids = selected.map((x) => x.id);
  const id = [];
  const diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    _.each(ids, (diamondId) => {
      const trackId = _.find(diamonds, { id: diamondId });
      if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
    });
  }
  const obj = {
    reminderDate: date,
    trackType,
    id,
  };
  const objData = {
    ...UPDATE_TRACK,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
};

export const handleMemoPrint = (filter, orderDiamond, cb) => {
  let obj = {
    orderDiamond,
  };
  if (_.isEmpty(orderDiamond)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handlePrint = (filter, id, cb) => {
  let obj = {
    id,
  };
  if (_.isEmpty(id)) {
    obj = { filter };
  }
  const objData = {
    ...PRINT_PDF,
    request: obj,
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    }
  });
};

export const handleDownloadExcel = (filter = {}, id = [], cb) => {
  const objData = {
    ...EXPORT_EXCEL,
    request: _.isEmpty(filter) ? { id } : { filter },
  };
  UtilService.callApi(objData, async function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
      cb(true);
    }
  });
};
export function shareExcel(checked, cb) {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      filter: {
        vStnId: checked,
      },
      isExcel: true,
    },
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(data.data.data);
    } else {
      OpenNotification({ type: 'error', title: err.data.message || data.message });
    }
  });
}

export function handleConfirmStone(ids, comment, date, company, pBack, sBack, cb) {
  const termDis = localStorage.getItem('TermsDis');
  const termObj = JSON.parse(localStorage.getItem('termObj'));
  termObj.value = termDis;
  const { id, minAmt, resetDis = '', ...reset } = termObj;
  const obj = {
    ...CONFIRM_STONE,
    request: {
      diamonds: ids,
      comment,
      company,
      date,
      pBack,
      termsDis: reset,
    },
  };
  if (getPath() === 'show') {
    obj['request']['sBack'] = sBack;
  } else {
    obj['request']['sBack'] = 0;
  }
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export function handleNoteDelete(id, cb) {
  const obj = NOTE_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err.data.message,
      });
      cb(null);
    }
  });
}

export function handleTrackDelete(trackType, ids, inTrackDiamonds, cb) {
  const id = [];
  const diamonds = _.find(inTrackDiamonds, { _id: trackType })
    ? _.find(inTrackDiamonds, { _id: trackType }).diamonds
    : [];
  if (diamonds) {
    _.each(ids, (diamondId) => {
      const trackId = _.find(diamonds, { id: diamondId });
      if (trackId) id.push(_.find(diamonds, { id: diamondId }).trackId);
    });
  }
  //const path = window.location.pathname === '/account/hold'
  const obj = {
    ...DELETE_TRACK,
    request: {
      trackType,
      id,
    },
  };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export function handleBidDelete(id, cb) {
  const obj = BID_DELETE;
  obj.request = { id };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: data ? data.message : err && err.data ? err.data.message : '',
      });
      cb(false);
    }
  });
}

export const handleSendEmail = (apiObj, cb) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: apiObj,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: data.message,
      });
    }
  });
};

export function handleBidCartCreate(rows, id, code, result, cb) {
  const obj = CREATE_BID_CART;
  const subType = BidService.bid?.sub_types;
  obj.request = {
    trackType: trackTypeObj.BIDCART,
    bidResultDateTime: subType.currentBidEndDate,
    diamonds: rows.map((x) => ({
      diamond: x.id,
      trackPricePerCarat: parseFloat(x.ctPr).toFixed(2),
      trackAmount: x.amt,
      trackDiscount: x.back,
      newDiscount: null,
    })),
  };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export function handleMoveToBidCart(rows, fetch) {
  const obj = CREATE_BID_CART;
  const subType = BidService.bid?.sub_types;
  obj.request = {
    trackType: trackTypeObj.BIDCART,
    bidResultDateTime: subType.currentBidEndDate,
    moveToBidCart: true,
    diamonds: rows.map((x) => ({
      diamond: x?.id,
      trackPricePerCarat: parseFloat(x?.ctPr)?.toFixed(2),
      trackAmount: x?.amt,
      trackDiscount: x?.back,
      newDiscount: null,
    })),
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data?.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data?.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: err && err?.data ? err?.data?.message : data?.message,
      });
    }
    fetch();
  });
}

export function handleBidUpsert(rows, id, code, result, cb) {
  const obj = getPath() === LISTINGPAGES.MYDEALOFDAYLIST || getPath() === LISTINGPAGES.MYBID ? UPDATE_BID : CREATE_BID;
  const isDealOfTheDay = getPath() === LISTINGPAGES.MYDEALOFDAYLIST || getPath() === LISTINGPAGES.DEALTHEOFDAY;
  obj.request =
    getPath() === LISTINGPAGES.MYDEALOFDAYLIST || getPath() === LISTINGPAGES.MYBID
      ? {
        // bidTermsDiscount: termObj,
        id,
        bidConfigurationType: isDealOfTheDay ? 2 : undefined,
        result_type: parseFloat(result),
        diamonds: rows.map((x) => ({
          id: x.trackId,
          bidPricePerCarat: x.bidPricePerCarat,
          bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
          bidAmount: x.bidAmount,
          trackPricePerCarat: x.ctPr,
          trackAmount: x.amt,
          trackDiscount: x.back,
          deviceType: 1,
          status: x.status,
        })),
      }
      : {
        //bidType: type,
        bidConfigurationId: id,
        subTypeCode: code,
        bidConfigurationType: isDealOfTheDay ? 2 : undefined,
        // bidTermsDiscount: termObj,
        result_type: parseFloat(result),
        diamonds: rows.map((x) => ({
          diamond: x.id,
          bidPricePerCarat: x.bidPricePerCarat,
          bidAmount: x.bidAmount,
          bidDiscount: x.isFcCol ? 0 : x.bidDiscount,
          trackPricePerCarat: x.ctPr,
          trackAmount: x.amt,
          trackDiscount: x.back,
          vnd: x.vnd,
          vStnId: x.vStnId,
        })),
      };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export function handleCartBidUpsert(rows, id, code, result, cb) {
  const obj = CREATE_BID;
  obj.request = {
    //bidType: type,
    bidConfigurationId: id,
    subTypeCode: code,
    // bidTermsDiscount: termObj,
    result_type: parseFloat(result),
    diamonds: rows.map((x) => ({
      diamond: x.id,
      bidPricePerCarat: parseFloat(x.bidPricePerCarat ?? x.ctPr),
      bidAmount: x.bidAmount ?? x.amt ?? null,
      bidDiscount: x.isFcCol ? 0 : x.bidDiscount ?? x.back ?? null,
      trackPricePerCarat: x.ctPr,
      trackAmount: x.amt,
      trackDiscount: x.back,
      vnd: x.vnd,
      vStnId: x.vStnId,
    })),
  };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

function offerObject(item, comment) {
  if (!item.isFcCol) {
    // if (item.calcDiscount >= item.back - 2) {
    return {
      tracks: [
        {
          remarks: comment,
          id: item.trackId,
          diamond: item.id,
          newPricePerCarat: item.calcPricePerCarat,
          newAmount: item.calcAmount,
          newDiscount: item.calcDiscount,
          offerValidDate: moment().add(item.hours, 'hours'),
        },
      ],
    };
    // } else {
    //   OpenNotification({
    //     type: 'error',
    //     title: 'Final quote must be less than discount',
    //   });
    //   return false;
    // }
  } else {
    return {
      tracks: [
        {
          remarks: comment,
          id: item.trackId,
          diamond: item.id,
          newPricePerCarat: item.calcPricePerCarat,
          newAmount: item.calcAmount,
          newDiscount: item.calcDiscount,
          offerValidDate: moment().add(item.hours, 'hours'),
        },
      ],
    };
  }
}

function offerCrateObject(item) {
  if (!item.isFcCol) {
    // if (item.calcDiscount >= item.back - 2) {
    return {
      diamond: item.trackId,
      trackPricePerCarat: item.ctPr,
      trackAmount: item.amt,
      trackDiscount: item.back,
      vStnId: item.vStnId,
      newAmount: item.calcAmount,
      newPricePerCarat: item.calcPricePerCarat,
      newDiscount: item.calcDiscount,
      offerValidDate: moment().add(item.hours, 'hours'),
    };
    // } else {
    //   OpenNotification({
    //     type: 'error',
    //     title: 'Final quote must be less than discount',
    //   });
    //   return false;
    // }
  } else {
    return {
      diamond: item.trackId,
      trackPricePerCarat: item.ctPr,
      trackAmount: item.amt,
      trackDiscount: item.back,
      vStnId: item.vStnId,
      newAmount: item.calcAmount,
      newPricePerCarat: item.calcPricePerCarat,
      newDiscount: item.calcDiscount,
      offerValidDate: moment().add(item.hours, 'hours'),
    };
  }
}

export function handleSaveQuote(selectedItems, comment, company, cb, update) {
  const obj = update ? COUNTER_OFFER : CREATE_TRACK;
  const item = selectedItems[0];
  const offer = offerObject(item);
  if (!offer) return false;
  obj.request = update
    ? {
      // trackType: trackTypeObj.QUOTE,
      // remarks: comment,
      // company,

      ...offerObject(item, comment),
    }
    : {
      trackType: trackTypeObj.QUOTE,
      remarks: comment,
      company,
      diamonds: selectedItems.map((x) => ({
        ...offerCrateObject(x),
        diamond: x.id,
      })),
    };

  // let isValid = true;

  // selectedItems.forEach((x) => {
  //   if (!(x.calcDiscount >= x.back - 2) && !item.isFcCol) isValid = false;
  // });

  // if (!isValid) return false;

  update && delete obj.request.offerValidDate;
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      if (update) {
        OpenNotification({
          type: 'success',
          title: data.message,
        });
        cb(true);
        return;
      } else {
        OpenNotification({
          type: 'success',
          title: data.message,
        });
        cb(true);
        // if (data.data.created.length > 0) cb(true);
        // else cb(false);
      }
    } else {
      OpenNotification({
        type: 'error',
        title: err && err.data ? err.data.message : data.message,
      });
      cb(false);
    }
  });
}

export const handleSendXRayEmail = (apiObj, path, cb) => {
  apiObj.message = `${apiObj.message} <a href=${path}> View File</a>`;
  const obj = {
    ...SEND_EMAIL_XRAY,
    request: apiObj,
  };
  UtilService.callApi(obj, (err, data) => {
    if (data && data.code === 'OK') {
      cb(true);
      return OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      cb(false);
      return OpenNotification({
        type: 'error',
        title: data.message,
      });
    }
  });
};

export function allSortObject(columns) {
  const sort = {};
  _.each(columns, (col) => {
    // if (col.id !== 'Details') sort[col.id] = col.Header
    if (col.sort) sort[col.sort] = col.Header;
  });
  return sort;
}

export function getProjectSetting(type, cb) {
  const obj = PROJECT_SETTING;
  obj.request = {
    filter: {
      type,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') cb(data.data);
    else cb([]);
  });
}

export const downloadZip = (dt, cb) => {
  const obj = {
    ...DOWNLOAD_ZIP,
    request: { ...dt },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_URL;
      const file_path = server + '/' + data.data;
      window.open(file_path, 'Download');
    } else if (data && data.code === 'E_NOT_FOUND') {
      OpenNotification({
        type: 'error',
        title: data.message,
      });
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    cb(true);
  });
};

export const downloadFile = (apiObj) => {
  const obj = {
    ...DOWNLOAD_FILE,
    request: apiObj,
  };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.path;
      // window.open(file_path,"download")
      const xhr = new XMLHttpRequest();
      xhr.open('GET', file_path, true);
      xhr.responseType = 'blob';

      xhr.onload = function () {
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(this.response);
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = apiObj.fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      };

      xhr.onerror = (err) => {
        return OpenNotification({
          type: 'error',
          title: 'This file is not available !',
        });
      };

      setTimeout(function () {
        xhr.send();
      }, 5000);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const downloadVideo = (apiObj) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', apiObj.path, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.target = '_blank';
    tag.download = apiObj.fileName + apiObj.ext;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.onerror = (err) => {
    return OpenNotification({
      type: 'error',
      title: 'This file is not available !',
    });
  };
  setTimeout(function () {
    xhr.send();
  }, 5000);
};

export const handleDeleteOffice = (filter, cb) => {
  const self = this;

  const objData = {
    ...DELETE_OFFICE_DIAMOND,
    request: filter,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(true);
    }
  });
};

export const fetchQuotedayDiamonds = (cb) => {
  const obj = QUOTE_DAY_LIST;
  obj.request = {
    type: 2,
    isDeleted: false,
    isActive: true,
  };

  UtilService.callApi(obj, function (err, data) {
    let rows = [];
    let count = 0;
    const sum = {};
    const seachFilterTag = {};
    const filterArray = {};
    const inTrackDiamonds = [];
    if (data && data.code === 'OK') {
      rows = _.map(data.data.list, 'diamond');
      count = rows.length;
      FILTER_COLUMNS.map((x) => {
        filterArray[x] = _.uniq(rows.map((r) => r[x]));
      });
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
    });
  });
};

export const myAccountExcelTrack = (trackType) => {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myHoldExcelTrack = (blockType) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { isExcel: true, page: 1, limit: 100000, blockType, sort: {} },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const featureStoneExcelTrack = (sectionType) => {
  const obj = {
    ...EXPORT_EXCEL,
    request: {
      page: 1,
      limit: 100000,
      filter: { sectionType },
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const matchPairExcelTrack = (diamondSearchId) => {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: 1,
    limit: 100000,
    sort: {},
    isExcel: true,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountExcelMemo = (filter) => {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountExcelOffice = () => {
  const obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isExcel: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
      filter: { date: { '>=': moment().startOf('day').toISOString() } },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export function myAccountNotesExcel() {
  const obj = {
    ...FETCH_NOTES,
    request: {
      isExcel: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myBidExcel() {
  const obj = {
    ...BID_PAGINATE,
    request: {
      isExcel: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function upcomingExcel(filter) {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isExcel: true,
      page: 1,
      filter,
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

///new pdf

export const myAccountPDFTrack = (trackType) => {
  const obj = {
    ...FETCH_DIAMOND_TRACK,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      trackType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const featureStonePDFTrack = (sectionType) => {
  const obj = {
    ...PRINT_PDF,
    request: {
      page: 1,
      limit: 100000,
      filter: { sectionType },
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myHoldPDFTrack = (blockType) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      blockType,
      sort: {},
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const matchPairPDFTrack = (diamondSearchId) => {
  const obj = CREATE_MATCH_PAIR;
  obj.request = {
    filter: { diamondSearchId },
    page: 1,
    limit: 100000,
    sort: {},
    isPdf: true,
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const myAccountPDFMemo = (filter) => {
  const obj = {
    ...FETCH_CONFIRM_STONE,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      filter,
      sort: [{ memoNo: 'DESC' }],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};
export const myAccountPDFOffice = () => {
  const obj = {
    ...FETCH_OFFICE_DIAMOND,
    request: {
      isPdf: true,
      page: 1,
      limit: 100000,
      sort: [{ date: 'ASC' }],
      filter: { date: { '>=': moment().startOf('day').toISOString() } },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export function myAccountNotesPDF() {
  const obj = {
    ...FETCH_NOTES,
    request: {
      isPdf: true,
      page: 1,
      isAppendDiamond: 1,
      limit: 100000,
      sort: [],
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export function myBidPDF() {
  const obj = {
    ...BID_PAGINATE,
    request: {
      isPdf: true,
      page: 1,
      status: [1],
      bidType: [1],
      limit: 100000,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
}

export const upcomingPDF = (filter) => {
  const obj = {
    ...SEARCH_DIAMOND_LIST,
    request: {
      isPdf: true,
      isUpcoming: true,
      page: 1,
      limit: 100000,
      sort: {},
      filter,
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      const server = BASE_DOWNLOAD_URL;
      const file_path = server + data.data;
      window.open(file_path, 'Download');
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
    //cb(true);
  });
};

export const getDiamondBlockMaster = (code, cb) => {
  const obj = {
    ...BLOCK_MASTERS,
    request: {
      filter: { blockCode: code },
    },
  };
  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(data.data[0]);
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const handleAddBlock = (block, checked, time, comment, cb) => {
  const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  const termDis = localStorage.getItem('TermsDis');
  const termObj = termDis && JSON.parse(localStorage.getItem('termObj'));
  if (termObj) termObj.value = termDis;
  const { id, minAmt, resetDis = '', ...reset } = termObj;
  const obj = {
    ...ADD_HOLD,
    request: {
      blockType: block,
      diamonds: checked,
      user: user?.id,
      userAccount: user.account?.id,
      seller: user.seller,
      remarks: comment,
      termsDis: reset,
    },
  };
  if (block === 1) obj.request = { ...obj.request, validTillDate: time };
  if (block === 2)
    obj.request = {
      ...obj.request,
      assistantSeller: user.seller,
      shippingCountry: user.country,
      shippingCity: user.city,
      stage: checked[0].blockSetting,
      broker: user.account.broker,
      shipMode: 'Ship To Direct',
    };

  UtilService.callApi(obj, function (err, data) {
    if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: data.message,
      });
    } else {
      return OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
    }
  });
};

export const fetchBlockDiamonds = (blockType, cb) => {
  const obj = {
    ...GET_BLOCK_DIAMONDS,
    request: { blockType },
  };
  UtilService.callApi(obj, function (err, data) {
    const rows = [];
    let count = 0;
    let sum = {};
    let seachFilterTag = {};
    let inTrackDiamonds = [];
    let inBlockDiamonds = [];
    const filterArray = {};
    if (data && data.code === 'OK') {
      seachFilterTag = data.data.filter ? data.data.filter : {};
      data.data.list &&
        data.data.list.map((l) => {
          rows.push({ ...l.diamond, ids: l.id, usernotes: l?.usernotes });
        });
      sum = data.data.total ? data.data.total[0] : {};
      count = data.data.count;
      // FILTER_COLUMNS.map((x) => {
      //   filterArray[x] = _.uniq(rows.map((r) => r[x]));
      // });
      inTrackDiamonds = data.data.inTrackDiamonds ? data.data.inTrackDiamonds : [];
      inBlockDiamonds = data.data.inBlockDiamonds ? data.data.inBlockDiamonds : [];
    }

    cb({
      data: rows,
      seachFilterTag,
      sum,
      count,
      filterArray,
      selectedFilterArray: {},
      inTrackDiamonds,
      inBlockDiamonds,
    });
  });
};

export const DiamondTrackStatusUpdate = (id, offerStatus, cb) => {
  const objData = {
    ...DIAMOND_TRACK_STATUS_UPDATE,
    request: { id, ...offerStatus },
  };
  UtilService.callApi(objData, async function (err, data) {
    if (data && data.code === 'OK') {
      OpenNotification({
        type: 'success',
        title: data.data.message || data.message,
      });
      cb(true);
    } else {
      OpenNotification({
        type: 'error',
        title: err.data.message || data.message,
      });
      cb(false);
    }
  });
};