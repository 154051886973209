import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import UtilService from '../../services/util';
import moment from 'moment';
import _, { get } from 'lodash';
import { diamondParameters, LOCAL_STORAGE_VAR } from '../../constants/Common';
import { LISTINGPAGES } from '../DiamondList/DiamondListFunctions';
import DeleteConfirmation from '../../components/common/DeleteConfirmation';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
import Pagination from '../common/Pagination';
import Heading from '../common/Heading';
import { SEARCH_LIST, DELETE_SEARCH } from '../../constants/apiConstant';
import { getGridHeight } from '../DiamondList';

class SavedSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      total: 0,
      page: 1,
      limit: 10,
      showModal: false,
      id: '',
    };
  }

  componentDidMount() {
    let self = this;
    window.addEventListener('resize', function () {
      self.forceUpdate();
    });
    this.getData();
  }
  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }
  getData() {
    let self = this;
    let obj = {
      type: 2,
      page: self.state.page,
      limit: self.state.limit,
      isAppendMasters: true,
    };

    let api = SEARCH_LIST.url;
    let objData = {
      method: SEARCH_LIST.method,
      url: api,
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) {
        if (err.data && err.data.code === 'E_NOT_FOUND') {
          return self.setState({ rows: [], total: 0 });
        } else {
          throw err;
        }
      }
      if (data && data.code === 'OK') {
        self.setState({ rows: data.data.list, total: data.data.count });
      }
    });
  }

  handleDelete() {
    let self = this;
    let obj = {
      id: this.state.id,
    };
    let api = DELETE_SEARCH.url;
    let objData = {
      method: DELETE_SEARCH.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        if ((self.state.total - 1) % self.state.limit === 0) {
          if (self.state.page > 1) await self.setState({ page: self.state.page - 1 });
        }
        self.setState({ showModal: false, id: '' });
        self.getData();
        OpenNotification({
          type: 'success',
          title: 'Your saved search is deleted successfully.',
        });
      }
    });
  }

  render() {
    const isMobile = window.innerWidth <= 767;
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    let gridHeight = getGridHeight(
      [
        'headerTopMenu',
        'accountTopBlock',
        'accountTabBox',
        'botoomStickyBar',
        'covidStrip',
        'HeaderStripBlock',
        'popupInnerTitle',
      ],
      10,
    );
    return (
      <>
        <div className="savedSearchMainWrapper">
          {isMobile && <Heading title="Saved Search " className="popupInnerTitle p-lr-15"></Heading>}
          <div className="savedSearchWrapper" style={{ height: gridHeight, overflow: 'auto' }}>
            {this.state.rows && this.state.rows.length > 0 ? (
              this.state.rows.map((data, index) => (
                <div className="savedSearchItem">
                  <div className="savedSearchTop">
                    <span className="savedTitle">
                      {data.name}
                      <span className="noOfDiamondTitle">(No. of count : {data.searchCount || 0})</span>
                    </span>
                    <span className="savedSearchAction">
                      {get(USER_PERMIT, 'SAVE_SEARCH.update', false) && (
                        <div className="savedSearchActionItem">
                          <img
                            src={require('../../assets/svg/savedSearch/edit.svg')}
                            alt="icon"
                            onClick={() => window.location.assign(`/${LISTINGPAGES.SEARCH}/${data.id}`)}
                          />
                        </div>
                      )}
                      {get(USER_PERMIT, 'SAVE_SEARCH.delete', false) && (
                        <div className="savedSearchActionItem">
                          <img
                            onClick={() =>
                              this.setState({
                                showModal: !this.state.showModal,
                                id: data.id,
                              })
                            }
                            src={require('../../assets/svg/savedSearch/delete.svg')}
                            alt="icon"
                          />
                        </div>
                      )}
                      {data.searchCount !== 0 && (
                        <div className="savedSearchActionItem">
                          <img
                            src={require('../../assets/svg/savedSearch/search.svg')}
                            alt="icon"
                            onClick={() => window.open('/diamond-list?' + data.id)}
                          />
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="savedSearchBottom">
                    {!data?.displayData? (
                      <div className="savedSearchValue">
                        <span>All criteria has been selected.</span>
                      </div>
                    ) : (
                      Object.keys(data?.displayData)?.map((filter, ind) => {
                        let param = _.find(diamondParameters, { key: filter });
                        if (param) {
                          if (param.key === 'or') {
                            if (_.has(data.displayData['or'][0], 'crt')) {
                              let arr = data.displayData['or'].map((carat) => {
                                return carat['crt']['>='] + ' to ' + carat['crt']['<='];
                              });
                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>Carat : </span>
                                  <span>{_.join(arr, ', ')}</span>
                                </div>
                              );
                            } else if (
                              //Stone ID/ Report Number/ Seller Stone Number
                              _.has(data.displayData['or'][0], 'stoneId') ||
                              _.has(data.displayData['or'][0], 'vStnId') ||
                              _.has(data.displayData['or'][0], 'pairStkNo')
                            ) {
                              return data.displayData['or'].map((id) => {
                                if (_.keys(id)[0] === 'stoneId') {
                                  return (
                                    <div className="savedSearchValue">
                                      <span>Stone ID/ Report Number : </span>
                                      <span>{_.join(_.values(id), ', ')}</span>
                                    </div>
                                  );
                                }
                                if (_.keys(id)[0] === 'vStnId') {
                                  return (
                                    <div className="savedSearchValue" key={ind}>
                                      <span>Seller Stone Number : </span>
                                      <span>{_.join(_.values(id), ', ')}</span>
                                    </div>
                                  );
                                }
                                if (_.keys(id)[0] === 'pairStkNo') {
                                  return (
                                    <div className="savedSearchValue" key={ind}>
                                      <span>Pair Number : </span>
                                      <span>{_.join(_.values(id), ', ')}</span>
                                    </div>
                                  );
                                }
                                return null;
                              });
                            }
                          } else if (param.key === 'vnd') {
                            let arr = data.displayData['vnd'].map((v) => {
                              return v.companyName;
                            });
                            return (
                              <div className="savedSearchValue" key={ind}>
                                <span>{param.name} : </span>
                                <span>{_.join(arr, ', ')}</span>
                              </div>
                            );
                          } else {
                            if (_.isObject(data.displayData[filter]) && !_.isArray(data.displayData[filter])) {
                              if (filter === 'kToSArr') {
                                let arr = Object.values(data.displayData['kToSArr'])[0];
                                let key = Object.keys(data.displayData['kToSArr'])[0];

                                return (
                                  <div className="savedSearchValue" key={ind}>
                                    <span>
                                      {param.name}&nbsp;:&nbsp;
                                      <span>{key === 'in' ? 'Contains' : 'Not contains'}</span>
                                    </span>
                                    <span>{_.join(arr, ', ')}</span>
                                  </div>
                                );
                              } else if (filter === 'type2') {
                                return (
                                  <div className="savedSearchValue" key={ind}>
                                    <span>{param.name}&nbsp;:&nbsp;</span>
                                    <span>YES</span>
                                  </div>
                                );
                              } else {
                                return (
                                  <div className="savedSearchValue" key={ind}>
                                    <span>{param.name} : </span>
                                    <span>
                                      {(filter === 'marketingIssueDate'
                                        ? moment(data.displayData[filter]['>=']).format('LL')
                                        : data.displayData[filter]['>=']) +
                                        '  to  ' +
                                        (filter === 'marketingIssueDate'
                                          ? moment(data.displayData[filter]['<=']).format('LL')
                                          : data.displayData[filter]['<='])}
                                    </span>
                                  </div>
                                );
                              }
                            } else if (typeof data.displayData[param.key] === 'boolean') {
                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>{param.name} : </span>
                                  <span>{data.displayData[param.key] === true ? 'YES' : 'NO'}</span>
                                </div>
                              );
                            } else {
                              let disp = data.displayData[param.key];
                              if (param.key === 'showSrNo') {
                                if (disp.includes(',')) disp = disp.split(',');
                                else disp = disp.split(' ');
                              }
                              return (
                                <div className="savedSearchValue" key={ind}>
                                  <span>{param.name} : </span>
                                  <span>{_.join(disp, ', ')}</span>
                                </div>
                              );
                            }
                          }
                        }
                      })
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="empty-data">
                <h3>
                  <span>Your saved search is empty.</span>
                </h3>
              </div>
            )}
          </div>

          <DeleteConfirmation
            showModal={this.state.showModal}
            handleCancel={() => this.setState({ showModal: !this.state.showModal })}
            handleDelete={() => this.handleDelete()}
          />
        </div>
        <Tooltip title="Add New Search">
          <a className="addOption" href="/diamond-search">
            <img src={require('../../assets/svg/plus.svg')} alt="" />
          </a>
        </Tooltip>
        <div className="botoomStickyBar bottom-0">
          <div></div>
          <Pagination
            count={this.state.total}
            limit={this.state.limit}
            page={this.state.page}
            onPaginationChange={(page, pageSize) => {
              this.setState({ page: page }, () => {
                this.getData();
              });
            }}
            data={this.state.rows}
            noLimitChange
          />
        </div>
      </>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(SavedSearch);
