const initialState = {
  loading: 0,
  data: {},
  error: {},
};

export const BID_ACTION_TYPES = {
  SET_BID_CONFIG: '@bid-config/set',
  RESET: '@bid-config/reset',
  START_LOADING: '@bid-config/start-loading',
  STOP_LOADING: '@bid-config/stop-loading',
};

export const BID_ACTIONS = {
  setBidConfig: (payload) => ({ type: BID_ACTION_TYPES.SET_BID_CONFIG, payload }),
  startLoading: () => ({ type: BID_ACTION_TYPES.START_LOADING }),
  stopLoading: () => ({ type: BID_ACTION_TYPES.STOP_LOADING }),
  reset: () => ({ type: BID_ACTION_TYPES.RESET }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case BID_ACTION_TYPES.SET_BID_CONFIG:
      return { ...state, data: { ...payload?.data }, error: { ...payload?.error } };

    case BID_ACTION_TYPES.START_LOADING:
      return { ...state, loading: state.loading + 1 };

    case BID_ACTION_TYPES.STOP_LOADING:
      return { ...state, loading: state.loading - 1 };

    case BID_ACTION_TYPES.RESET:
      return { ...initialState };

    default:
      return state;
  }
};
