import React, { Component } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import { Select, Input, Drawer, Tooltip } from 'antd';
import DiamondDetail from '../DiamondDetail';
import DiamondListingHead from '../common/DiamondListing/DiamondListingHead';
import SortingFilterDropdown from '../common/DiamondListing/SortingFilterDropdown';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import SelectStone from '../common/DiamondListing/SelectStone';
import Status from '../common/DiamondListing/Status';
import { data } from '../../constants/tableData';
import CheckBox from '../common/CheckBox';

const ColorMenuConst = <SortingFilterDropdown />;
let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 72;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.pageHeadHeight = document.getElementsByClassName('accountTopBlock')[0];
  allContainers.pinHeight = document.getElementsByClassName('accountTabBox')[0];
  allContainers.actionBtnHeight = document.getElementsByClassName('diamondListingAction')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
class Enquiry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      visible: false,
      stoneblock: false,
      checked: false,
      gridHeight: 0,
    };
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  toggleChecked = () => {
    this.setState({ checked: !this.state.checked });
  };
  onChange = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };
  render() {
    let self = this;
    gridHeight = setGridHeightFn();
    if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    window.addEventListener('resize', function () {
      gridHeight = setGridHeightFn();
      if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    });
    const columns = [
      {
        Header: '',
        // width:"50px",
        accessor: 'action',
        totalDropdown: true,
        Cell: ({ row, cell }) => (
          <div className="selectActionIcon">
            <Status status={row.original.status} statusname={row.original.statusname} />
            <div className="selectActionIconWrapper">
              <CheckBox checked={this.state.checked} onChange={this.onChange} />
            </div>
          </div>
        ),
      },
      {
        Header: 'Lab',
        accessor: 'lab',
        totalDropdown: true,
      },
      {
        Header: 'Packet No',
        accessor: 'packetno',
        shortingSelect: true,
        shortingCount: '1',
        totalDropdown: true,
        Cell: ({ row, cell }) => (
          <a className="tableLink" onClick={this.showDrawer}>
            {row.original.packetno}
          </a>
        ),
      },
      {
        Header: 'Report No',
        accessor: 'reportno',
        totalDropdown: true,
        // Cell:({row,cell})=>(
        //   <a className="tableLink" onClick={this.showDrawer}>{row.original.packetno}</a>
        // )
      },
      {
        Header: 'Location',
        accessor: 'location',
        totalDropdown: true,
      },
      {
        Header: 'DOR',
        accessor: 'dor',
        totalDropdown: true,
      },
      {
        Header: 'Xray',
        accessor: 'Xray',
        totalDropdown: true,
      },
      {
        Header: 'FTC',
        accessor: 'ftc',
        totalDropdown: true,
      },
      {
        Header: 'CM No',
        accessor: 'cmno',
        totalDropdown: true,
      },
      {
        Header: 'certificate No',
        accessor: 'certificateno',
        totalDropdown: true,
        Cell: ({ row, cell }) => (
          <a className="certificateNo" target="_balnk" href="http://google.com/">
            {row.original.certificateno}
          </a>
        ),
      },
      {
        Header: 'Shape',
        accessor: 'shape',
        shortingSelect: true,
        totalDropdown: true,
        shortingCount: '2',
      },
      {
        Header: 'Carat',
        accessor: 'carat',
        shortingSelect: true,
        totalDropdown: true,
        shortingCount: '3',
        Cell: ({ row, cell }) => <span className="">{row.original.carat}</span>,
      },
      {
        Header: 'Color',
        accessor: 'color',
        shortingSelect: true,
        totalDropdown: true,
        shortingCount: '5',
        colorDropdown: true,
        Cell: ({ row, cell }) => (
          <div className="normalTableDropdown">
            <span className="tableDropdownTitle">{row.original.color}</span>
            {row.original.colormenu && (
              <div className="tableDropdown">
                {row &&
                  row.original &&
                  row.original.colormenu &&
                  row.original.colormenu.map((d, index) => {
                    return <span>{d}</span>;
                  })}
              </div>
            )}
          </div>
        ),
      },
      {
        Header: 'Clarity',
        accessor: 'clarity',
        totalDropdown: true,
        shortingSelect: true,
        shortingCount: '4',
      },
      {
        Header: 'Shade',
        accessor: 'shade',
        totalDropdown: true,
      },
      {
        Header: 'BRI.',
        accessor: 'bri',
        totalDropdown: true,
      },
      {
        Header: 'Rap',
        accessor: 'rap',
        rapDropdown: true,
        totalDropdown: true,
        Cell: ({ row, cell }) => (
          <span
            className={` ${row.original.rapRed === true ? 'redColor' : ''} ${
              row.original.rapgreen === true ? 'greenColor' : ''
            }`}
          >
            {row.original.rap}
          </span>
        ),
      },
      {
        Header: 'Disc %',
        accessor: 'discount',
        totalDropdown: true,
      },
      {
        Header: 'Price/Ct',
        accessor: 'pricect',
        totalDropdown: true,
      },
      {
        Header: 'Cut',
        accessor: 'cut',
        totalDropdown: true,
      },
      {
        Header: 'Pol',
        accessor: 'pol',
        totalDropdown: true,
      },
      {
        Header: 'Sym',
        accessor: 'sym',
        totalDropdown: true,
      },
      {
        Header: 'Fol',
        totalDropdown: true,
        accessor: 'fol',
      },
      {
        Header: 'CN',
        totalDropdown: true,
        accessor: 'cn',
      },
      {
        Header: 'SN',
        totalDropdown: true,
        accessor: 'sn',
      },
      {
        Header: 'CW',
        totalDropdown: true,
        accessor: 'cw',
      },
      {
        Header: 'SW',
        totalDropdown: true,
        accessor: 'sw',
      },
      {
        Header: 'TO',
        totalDropdown: true,
        accessor: 'to',
      },
      {
        Header: 'CO',
        totalDropdown: true,
        accessor: 'co',
      },
      {
        Header: 'PO',
        totalDropdown: true,
        accessor: 'po',
      },
      {
        Header: 'EC',
        totalDropdown: true,
        accessor: 'ec',
      },
      {
        Header: 'TB%',
        accessor: 'tb',
        totalDropdown: true,
        // Cell:({row,cell})=>(
        //   <span className="">{row.original.tb}</span>
        // )
      },
      {
        Header: 'DP%',
        accessor: 'dpper',
        totalDropdown: true,
        // Cell:({row,cell})=>(
        //   <span className="">{row.original.dp}</span>
        // )
      },
      {
        Header: 'Length',
        accessor: 'length',
        totalDropdown: true,
      },
      {
        Header: 'Width',
        accessor: 'width',
        totalDropdown: true,
      },
      {
        Header: 'DP',
        accessor: 'dp',
        totalDropdown: true,
      },
      {
        Header: 'Ratio',
        accessor: 'ratio',
        totalDropdown: true,
        // Cell:({row,cell})=>(
        //   <span className="">{row.original.ratio}</span>
        // )
      },
      {
        Header: 'C/A',
        accessor: 'ca',
        totalDropdown: true,
      },
      {
        Header: 'C/H',
        accessor: 'ch',
        totalDropdown: true,
      },
      {
        Header: 'P/A',
        accessor: 'pa',
        totalDropdown: true,
      },
      {
        Header: 'P/H',
        accessor: 'ph',
        totalDropdown: true,
      },
      {
        Header: 'Girdle',
        accessor: 'gridle',
        totalDropdown: true,
      },
      {
        Header: 'Culet',
        accessor: 'culet',
        totalDropdown: true,
      },
      {
        Header: 'Ins.',
        accessor: 'ins',
        totalDropdown: true,
      },
      {
        Header: 'H&A',
        accessor: 'ha',
        totalDropdown: true,
      },
      {
        Header: 'Report Comments',
        accessor: 'reportcomment',
        totalDropdown: true,
      },
      {
        Header: 'Key to Symbols',
        accessor: 'keysym',
        totalDropdown: true,
      },
      {
        Header: 'Lower',
        accessor: 'lower',
        totalDropdown: true,
      },
      {
        Header: 'Star',
        accessor: 'star',
        totalDropdown: true,
      },
      {
        Header: 'Gridle%',
        accessor: 'gridleper',
        totalDropdown: true,
      },
      {
        Header: 'Seal',
        accessor: 'seal',
        totalDropdown: true,
      },
      {
        Header: 'Rough Mine',
        accessor: 'roughmin',
        totalDropdown: true,
      },
      {
        Header: 'Country of Origin',
        accessor: 'contyryorigin',
        totalDropdown: true,
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });

      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <DiamondListingHead
                      header={column.render('Header')}
                      ColorMenuConst={ColorMenuConst}
                      shortingSelect={column.shortingSelect}
                      shortingCount={column.shortingCount}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td width="100px" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        <div className="searchResultListWrapper profilePage">
          <div className="searchInnerResult ">
            {this.state.checked && <SelectStone onClick={this.onChange} profilListing="profileStonSelect" />}
            <div
              className="searchResultTable profilePagewithoutFilter"
              style={{ height: gridHeight, overflow: 'auto' }}
            >
              <Table columns={columns} data={data} />
            </div>
            <DiamondListingAction />
          </div>
        </div>
        {this.state.visible ? (
          <Drawer title={false} onClose={this.onClose} visible={this.state.visible} wrapClassName="diamondDetailPopup">
            <DiamondDetail />
          </Drawer>
        ) : null}
      </>
    );
  }
}

export default Enquiry;
