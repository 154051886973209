import React, { Component } from 'react';
import { connect } from 'react-redux';
import _, { groupBy, isEqual, isUndefined, omit, pullAt, trim, uniqWith } from 'lodash';
import DiamondSearchComponent from '../components/DiamondSearch';
import { resetDiamondSearch, modifyDiamondSearch } from '../appRedux/actions/Common';
import { LOCAL_STORAGE_VAR, DIAMOND_WEB_STATUS } from '../constants/Common';
import {
  handleSearch,
  getSearchList,
  handleSavedSearch,
  handleCoupleStone,
  handleSelectSearch,
  getCount,
  handleMultiSearch,
  fetchUpcoming,
} from './APIFunctions';

import { LISTINGPAGES } from '../components/DiamondList/DiamondListFunctions';
import { each, map, find, filter, concat, split, last as _last, includes, uniq, head, join, uniqBy } from 'lodash';
import OpenNotification from 'components/common/CommonButton/OpenNotification';

import { isArray, isEmpty, isObject } from 'util/utils';
import { SearchService } from 'services/SearchService';
import { BidService } from 'services/BidService';

import { notification } from 'antd';
import { history } from 'util/history';

class DiamondSearch extends Component {
  state = this.initialState;

  get initialState() {
    return {
      diamParams: {},
      selectedMaster: { isFcCol: false, wSts: history.location?.state?.fromShow ? ['SW'] : [] },
      or: [],
      threeEX: false,
      threeEXNon: false,
      selectedClrGrps: [],
      selectedClarGrps: [],
      clrSwitch: true,
      claritySwitch: true,
      showSavedSearch: false,
      searchTitle: undefined,
      noBGM: false,
      searchList: [],
      stoneIds: '',
      showDemand: false,
      demandTitle: '',
      expiryDate: '',
      isSeal: '',
      searchId: '',
      packetNo: false,
      newArr: false,
      upcoming: false,
      canadamark: false,
      DORInv: false,
      DORElig: false,
      FMElig: false,
      FMInv: false,
      xRay: false,
      eyeCln: false,
      wSts: [],
      pairNo: '',
      dorKeys: [],
      fmKeys: [],
      type2: false,
      opTbl: '',
      opCrwn: '',
      opPav: '',
      noBlack: false,
      twoVG: false,
      threeVG: false,
      multipleSearch: [],
      masterIndex: {},
      excluFilter: [],
      bidDiamond: '',
    };
  }

  get searchFilter() {
    return SearchService.getSearchFilter(this.state.selectedMaster);
  }

  get searchFilterLength() {
    return SearchService.getSearchFilterLength(this.searchFilter);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location) {
      return this.componentDidMount();
    }

    if (prevState.selectedMaster !== this.state.selectedMaster) {
      getCount(this.state.selectedMaster, (count) => this.setState({ count }));
    }
  }

  async componentDidMount() {
    this.setState({ bidDiamond: '' });
    const searchId = _last(split(window.location.pathname, '/'));
    // if (history.location?.state?.fromShow) this.setState({ ...this.state.selectedMaster, wSts: ['SW'] });
    this.getData();
    getSearchList((list) => {
      if (!isEmpty(list)) this.setState({ searchList: list });
    });

    if (
      searchId !== LISTINGPAGES.SEARCH &&
      searchId !== LISTINGPAGES.SEARCH_DIAMOND &&
      searchId !== LISTINGPAGES.SEARCHMATCHPAIR
    ) {
      handleSelectSearch(searchId, (searchData, title, type) => {
        if (!isEmpty(searchData)) {
          type === 2 && this.setState({ searchTitle: title, searchId });
          this.handleAfterSelectSearch(searchData);
        }
      });
    } else if (this.props.history.location.state && this.props.history.location.state.newArr) {
      this.handleReverseState('newArr');
      this.handleNewArrival();
    } else {
      this.setState({ bidDiamond: '' });
      const selectedMaster = { ...this.state.selectedMaster };
      getCount(selectedMaster, (count) => this.setState({ count }));
      if (searchId === LISTINGPAGES.SEARCH_DIAMOND) this.setState({ bidDiamond: BidService.bid });
    }
  }

  getData = () => {
    const masterData = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    const cutData = masterData.CUT && masterData.CUT.filter((x) => x.name !== 'NON');
    const lusterData = masterData.LUSTER && masterData.LUSTER.filter((x) => x.name !== 'NON');
    const { diamParams } = this.state;
    const caratGroupData = uniqBy(masterData.SIZE, 'group').map((d) => {
      const groupData = masterData.SIZE.filter(({ group }) => group === d.group);
      d.fromCarat = Math.min(...groupData.map(({ fromCarat }) => fromCarat));
      d.toCarat = Math.max(...groupData.map(({ toCarat }) => toCarat));
      return {
        ...d,
        caratGroup: `${d.fromCarat}-${d.toCarat}`,
      };
    });
    if (masterData) {
      diamParams.shp = masterData.SHAPE;
      diamParams.carat = caratGroupData;
      diamParams.col = masterData.COLOR;
      diamParams.fcCol = masterData.FANCY_COLOR;
      diamParams.clr = masterData.CLARITY;
      diamParams.flu = masterData.FLUORESCENCE;
      diamParams.shd = masterData.SHADE;
      diamParams.lb = masterData.LAB;
      diamParams.cut = cutData;
      diamParams.pol = masterData.POLISH;
      diamParams.sym = masterData.SYMMETRY;
      diamParams.hA = masterData.H_AND_A;
      diamParams.cult = masterData.CULET;
      diamParams.opTbl = masterData.OPEN_INCLUSION_TABLE;
      diamParams.opPav = masterData.OPEN_INCLUSION_PAVILION;
      diamParams.eFact = masterData.EXTRA_FACET_PAVILION;
      diamParams.opCrwn = masterData.EXTRA_FACET_CROWN;
      diamParams.grdl = masterData.GIRDLE;
      diamParams.keyToSymbol = masterData.KEY_TO_SYMBOLS;
      diamParams.loc = masterData.LOCATION;
      diamParams.wTbl = masterData.WHITE_INCLUSION_TABLE;
      diamParams.blkTbl = masterData.BLACK_INCLUSION_TABLE;
      diamParams.blkSd = masterData.BLACK_INCLUSION_SIDE;
      diamParams.wSd = masterData.WHITE_INCLUSION_SIDE;
      diamParams.brlncy = masterData.BRILLIANCY;
      diamParams.eCln = masterData.EYECLEAN;
      diamParams.inten = masterData.INTENSITY;
      diamParams.ovrtn = masterData.OVERTONE;
      diamParams.blkCrn = masterData.BLACK_INCLUSION_CROWN;
      // diamParams.blkTbl = masterData.BLACK_INCLUSION_TABLE;
      diamParams.lstr = lusterData;
      diamParams.grning = masterData.GRAINING;
      diamParams.clrGrp = [
        { grp: 'DEF', codes: ['D', 'E', 'F'] },
        { grp: 'GH', codes: ['G', 'H'] },
        { grp: 'IJK', codes: ['I', 'J', 'K'] },
        { grp: 'LMN-', codes: ['L', 'M', 'N', 'N-'] },
      ];
      diamParams.clarGrp = [
        { grp: 'FL-IF', codes: ['FL', 'IF'] },
        { grp: 'VVS', codes: ['VVS1', 'VVS2'] },
        { grp: 'VS', codes: ['VS1', 'VS2'] },
        { grp: 'SI', codes: ['SI1', 'SI2'] },
        { grp: 'I', codes: ['I1', 'I2'] },
      ];
    }
    this.setState({ diamParams });
  };

  handleFancySelection = async (selValues, checked, keyToSend, selectAll = false) => {
    const { diamParams, selectedClrGrps, selectedClarGrps } = this.state;
    const selectedMaster = { ...this.state.selectedMaster };

    if (selectAll) {
      selectedMaster[keyToSend] = [];
    }

    if (selValues === []) {
      each(diamParams[keyToSend], function (data) {
        selectedMaster[keyToSend] = uniq(concat(selectedMaster[keyToSend], data.id));
      });
    } else {
      selectedMaster[keyToSend] = this.state.selectedMaster[keyToSend] || [];
      selectedMaster[keyToSend] = uniq(concat(selectedMaster[keyToSend], selValues));
    }

    if (!checked) {
      selectedMaster[keyToSend] = filter(this.state.selectedMaster[keyToSend], function (val) {
        return selValues.indexOf(val) === -1;
      });
    }

    if (keyToSend === 'col' || keyToSend === 'clr') {
      // To select from groups.
      const cds = this.getCodeFromIds(keyToSend);
      if (keyToSend === 'col') {
        diamParams['clrGrp'].map((cg) => {
          if (cg.codes.every((v) => cds.includes(v))) {
            selectedClrGrps.push(cg.grp);
          }
        });
      }
      if (keyToSend === 'clr') {
        diamParams['clarGrp'].map((cg) => {
          if (cg.codes.every((v) => cds.includes(v))) {
            selectedClarGrps.push(cg.grp);
          }
        });
      }
    }
    await getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster, selectedClrGrps, selectedClarGrps });
  };

  selectedCarat = (carat, removed = null) => {
    let first;
    let last;
    let or = [];
    const selectedMaster = { ...this.state.selectedMaster };
    const wholeData = groupBy(this.state.diamParams.carat, 'group');

    if (isObject(carat) && !isArray(carat)) {
      if (removed && removed.checked === false) {
        or = this.state.or;
        if (removed.range === '') {
          const currG = wholeData[removed.group];
          or = or.filter((x) => x.crt['>='] !== head(currG)['fromCarat'] || x.crt['<='] !== _last(currG)['toCarat']);
        } else if (Array.isArray(removed.range)) {
          removed.range.forEach((rr) => {
            const first = rr.from;
            const last = rr.to;
            or = or.filter((x) => x.crt['>='] !== first || x.crt['<='] !== last);
          });
        } else {
          const curr = wholeData[removed.range];
          const first = head(curr)['fromCarat'];
          const last = _last(curr)['toCarat'];
          or = or.filter((x) => x.crt['>='] !== first || x.crt['<='] !== last);
        }
      } else {
        Object.keys(carat).forEach((key) => {
          carat[key].forEach((val) => {
            first = head(wholeData[key])['fromCarat'];
            last = _last(wholeData[key])['toCarat'];

            or.push({ crt: { '>=': first, '<=': last } });
          });
        });
        or = uniqWith(or.concat(this.state.or), isEqual);
      }
      this.setState({ or: or });
    }

    selectedMaster.or = or;
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  selectedKeyToSymbol = (selected, key) => {
    const selectedMaster = { ...this.state.selectedMaster };
    const kToSArr = { [key]: selected };
    selectedMaster['kToSArr'] = kToSArr;
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  selectedRange = (From, To, master) => {
    const selectedMaster = { ...this.state.selectedMaster };
    if (From === '' && To === '') {
      delete selectedMaster[master];
    } else {
      Object.assign(selectedMaster, {
        [master]: { '>=': From, '<=': To },
      });
    }
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  handleClrGrpSearch = (grp) => {
    let ids = [];
    let { selectedClrGrps, diamParams, selectedMaster } = this.state;
    if (!includes(this.state.selectedClrGrps, grp)) {
      selectedClrGrps.push(grp);
    } else {
      selectedClrGrps = filter(this.state.selectedClrGrps, function (val) {
        return val !== grp;
      });
    }
    selectedClrGrps.map((sg) => {
      const { codes } = find(diamParams.clrGrp, { grp: sg });
      diamParams.col.map((c) => {
        if (includes(codes, c.code)) {
          ids = concat(ids, c.id);
        }
        return true;
      });
      selectedMaster['col'] = ids;
      return true;
    });

    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedClrGrps, selectedMaster });
  };

  handleClarityGrpSearch = (grp) => {
    let ids = [];
    let { selectedClarGrps, diamParams, selectedMaster } = this.state;
    if (!includes(this.state.selectedClarGrps, grp)) {
      selectedClarGrps.push(grp);
    } else {
      selectedClarGrps = filter(this.state.selectedClarGrps, function (val) {
        return val !== grp;
      });
    }
    selectedClarGrps.map((sg) => {
      const { codes } = find(diamParams.clarGrp, { grp: sg });
      diamParams.clr.map((c) => {
        if (includes(codes, c.code)) {
          ids = concat(ids, c.id);
        }
        return true;
      });
      selectedMaster['clr'] = ids;
      return true;
    });
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedClarGrps, selectedMaster });
  };

  handleClrSwitch = () => {
    this.setState({ loader: true });

    const selectedMaster = { ...this.state.selectedMaster };
    selectedMaster['col'] = [];
    selectedMaster['inten'] = [];
    selectedMaster['ovrtn'] = [];
    selectedMaster['fcCol'] = [];
    if (this.state.clrSwitch) {
      selectedMaster.isFcCol = true;
    } else selectedMaster.isFcCol = false;

    getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
    this.setState({
      selectedMaster,
      selectedClrGrps: [],
      clrSwitch: !this.state.clrSwitch,
    });
  };

  handleClarSwitch = () => {
    const selectedMaster = { ...this.state.selectedMaster };
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({
      selectedMaster,
      claritySwitch: !this.state.claritySwitch,
    });
  };

  clearSelection = async (type) => {
    if (type === 'or') {
      const selectedMaster = {
        ...this.state.selectedMaster,
        isFcCol: false,
        or: [],
      };
      return (
        this.setState({ selectedMaster, masterIndex: {}, or: [] }),
        getCount(selectedMaster, (count) => this.setState({ count }))
      );
    }
    if (type == 'col') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false, col: [], fcCol: [], inten: [], ovrtn: [] };
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type === 'finishing') {
      const selectedMaster = {
        ...this.state.selectedMaster,
        isFcCol: false,
        cut: [],
        excludeFilter: {},
        pol: [],
        sym: [],
      };
      return (
        this.setState({ selectedMaster, threeEX: false, threeVG: false, twoVG: false, excludeFilter: {} }),
        getCount(selectedMaster, (count) => this.setState({ count }))
      );
    }

    if (type == 'range') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false };
      delete selectedMaster?.ctPr;
      delete selectedMaster?.amt;
      delete selectedMaster?.back;
      this.props.resetDiamondSearch({ searchReset: true });
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type == 'parameter') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false };
      delete selectedMaster?.tblPer;
      delete selectedMaster?.depPer;
      delete selectedMaster?.ratio;
      delete selectedMaster?.length;
      delete selectedMaster?.width;
      delete selectedMaster?.height;
      delete selectedMaster?.cAng;
      delete selectedMaster?.cHgt;
      delete selectedMaster?.grdlPer;
      delete selectedMaster?.pAng;
      delete selectedMaster?.pHgt;
      this.props.resetDiamondSearch({ searchReset: true });
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type == 'whiteInclusion') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false, wTbl: [], wSd: [] };
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type == 'blackInclusion') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false, blkTbl: [], blkSd: [] };
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type == 'openInc') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false, opTbl: [], opPav: [] };
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }
    if (type == 'extra') {
      const selectedMaster = { ...this.state.selectedMaster, isFcCol: false, eFact: [], opCrwn: [] };
      return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
    }

    const selectedMaster = { ...this.state.selectedMaster, [type]: [] };
    this.setState({ selectedMaster });
    return this.setState({ selectedMaster }), getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleRefresh = async () => {
    this.props.resetDiamondSearch({ searchReset: true });
    this.setState({
      selectedMaster: { isFcCol: false, wSts: history.location?.state?.fromShow ? ['SW'] : [] },
      or: [],
      threeEX: false,
      threeEXNon: false,
      noBGM: false,
      isSeal: '',
      searchId: '',
      newArr: false,
      upcoming: false,
      canadamark: false,
      DORInv: false,
      DORElig: false,
      FMElig: false,
      FMInv: false,
      xRay: false,
      eyeCln: false,
      wSts: [],
      pairNo: '',
      dorKeys: [],
      fmKeys: [],
      type2: false,
      selectedClarGrps: [],
      selectedClrGrps: [],
      searchTitle: undefined,
      demandTitle: '',
      expiryDate: '',
      noBlack: false,
      twoVG: false,
      threeVG: false,
      clrSwitch: true,
      masterIndex: {},
      multipleSearch: [],
      stoneIds: '',
    });
    const selectedMaster = { isFcCol: false };
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleSetStoneIds = async () => {
    const { selectedMaster } = this.state;
    const or = [];
    let arr = this.state.stoneIds.includes(',')
      ? split(this.state.stoneIds, ',')
      : this.state.stoneIds.includes(' ')
        ? split(this.state.stoneIds, ' ')
        : this.state.stoneIds;

    arr = isArray(arr) ? arr.map((a) => trim(a)) : [this.state.stoneIds];

    map(arr, (a, i) => {
      if (a === '' || isEmpty(a) || a === ' ') {
        pullAt(arr, [i]);
      }
    });
    arr.length && or.push({ vStnId: arr });
    arr.length && or.push({ rptNo: arr });

    selectedMaster.or = or;

    if (selectedMaster.or && selectedMaster.or[0] && selectedMaster.or[0].vStnId) {
      await getCount(selectedMaster, (count) => {
        this.setState({ count });
        if (selectedMaster.or && selectedMaster.or[0] && selectedMaster.or[0].vStnId && count === 0) {
          notification.destroy();
          OpenNotification({
            type: 'error',
            title: 'Sorry, the stone id(s) you have entered is not found. Please try again.',
          });
          this.setState({ showDemand: true });
        } else {
          this.setState({ packetNo: true });
        }
      });
    } else {
      notification.destroy();
      OpenNotification({
        type: 'error',
        title: 'Please enter stone ID or report number.',
      });
    }
    this.setState({ selectedMaster });
  };

  handleSetPairs = () => {
    const selectedMaster = { ...this.state.selectedMaster };
    const or = [];
    let arr = this.state.pairNo.includes(',')
      ? split(this.state.pairNo, ',')
      : this.state.pairNo.includes(' ')
        ? split(this.state.pairNo, ' ')
        : this.state.pairNo;

    arr = isArray(arr) ? arr.map((a) => trim(a)) : [this.state.pairNo];

    map(arr, (a, i) => {
      if (a === '' || isEmpty(a) || a === ' ') {
        pullAt(arr, [i]);
      }
      return true;
    });
    arr.length && or.push({ pairStkNo: arr });

    selectedMaster.or = or;
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleAfterSelectSearch = (searchData) => {
    const { diamParams, masterIndex } = this.state;
    const selectedMaster = { ...this.state.selectedMaster };
    const caratSizeGroup = groupBy(diamParams.carat, 'group');

    const wSts = searchData.wSts || [];
    const eyeCln = searchData.eCln ? true : false;
    const upcoming = searchData.wSts && includes(searchData.wSts, DIAMOND_WEB_STATUS.UPCOMING);
    const newArr = searchData.wSts && includes(searchData.wSts, DIAMOND_WEB_STATUS.BID);
    const canadamark = searchData.isCm ? true : false;
    const type2 = searchData.type2 ? true : false;
    const xRay = searchData.isXray;
    const dorKeys = searchData.isDor || [];
    const fmKeys = searchData.isFm || [];

    const opTbl = searchData.opTbl && find(diamParams['opTbl'], { id: searchData.opTbl }).code;
    const opCrwn = searchData.opCrwn && find(diamParams['opCrwn'], { id: searchData.opCrwn }).code;
    const opPav = searchData.opPav && find(diamParams['opPav'], { id: searchData.opPav }).code;
    const isSeal = searchData.isSeal === true ? 'YES' : searchData.isSeal === false ? 'NO' : '';
    const threeEX = searchData.excludeFilter && searchData.excludeFilter.threeEX;
    const threeEXNon = searchData.excludeFilter && searchData.excludeFilter.threeEXNon;
    const noBGM = searchData.excludeFilter && searchData.excludeFilter.noBGM;

    let caratRange = [];
    let customeCarat = [];

    searchData.or &&
      searchData.or.map((sel) => {
        if (sel && sel.vStnId && sel.vStnId.length !== 0) {
          this.setState({ stoneIds: sel.vStnId });
        }
      });
    this.setState({ selectedMaster: searchData });
    getCount(searchData, (count) => this.setState({ count }));
    Object.entries(caratSizeGroup).map((carat, index) => {
      searchData.or &&
        searchData.or.length &&
        searchData.or.map((s) => {
          if (s.crt['>='] === carat[1][0]['fromCarat'] && s.crt['<='] === carat[1][0]['toCarat']) {
            caratRange = caratRange.concat(s);
            masterIndex.crt = masterIndex.crt ? masterIndex.crt.concat(index) : [index];
          }
        });
    });
    customeCarat =
      searchData.or &&
      searchData.or.length &&
      searchData.or.filter((condition) => {
        if (condition && condition.crt) {
          return !diamParams.carat.find(
            (carat) => carat.fromCarat == condition.crt['>='] && carat.toCarat == condition.crt['<='],
          );
        }
      });

    getCount(searchData, (count) => this.setState({ count }));
    this.props.modifyDiamondSearch({ modifyData: searchData });
    this.setState({
      masterIndex,
      selectedMaster: searchData,
      or: { carat: caratRange, customeCarat },
      wSts,
      eyeCln,
      upcoming,
      canadamark,
      type2,
      xRay,
      dorKeys,
      fmKeys,
      newArr,
      opTbl,
      opPav,
      opCrwn,
      isSeal,
      noBGM,
      threeEXNon,
      threeEX,
      clrSwitch: !searchData.isFcCol,
    });
  };

  handleReverseState = (state) => {
    let updateState = {};
    //clear demand popup value on submit
    if (state === 'showDemand') {
      updateState = { demandTitle: '', expiryDate: '' };
    }

    this.setState({ [state]: !this.state[[state]], ...updateState });
  };

  handleCombineSearch = (opt, checked) => {
    const { selectedMaster, diamParams } = this.state;
    let { excludeFilter = {} } = selectedMaster;
    const filter = {
      threeEX: {
        cut: ['EX'],
        pol: ['EX'],
        sym: ['EX'],
        flu: [],
      },
      threeEXNon: {
        cut: ['I', 'EX', 'NONE'],
        pol: ['I', 'EX', 'NONE'],
        sym: ['I', 'EX', 'NONE'],
        flu: ['I', 'EX', 'VG', 'NON'],
      },
      noBGM: {
        shd: ['WHT', 'YEL', 'NON', 'OWH', 'LYL'],
        wTbl: ['NON', 'PP1', 'PP2', 'CRL1', 'CRL2', 'SPR1', 'SPR2', 'FR1', 'FR2', 'CLD1'],
        wSd: ['NON', 'PP1', 'PP2', 'CRL1', 'CRL2', 'SPR1', 'SPR2', 'FR1', 'FR2', 'CLD1'],
      },
      milky: {
        mlk: ['ML1', 'ML2', 'ML3'],
      },
      eyeCln: {
        eCln: ['Y'],
      },
      noBlack: {
        blkTbl: ['NON'],
        blkSd: ['NON'],
      },
      twoVG: {
        cut: [],
        pol: ['VG', 'EX'],
        sym: ['VG', 'EX'],
      },
      threeVG: {
        cut: ['VG', 'EX'],
        pol: ['VG', 'EX'],
        sym: ['VG', 'EX'],
      },
    };
    const clr = ['FL', 'IF', 'VVS1', 'VVS2', 'VS1']
    excludeFilter[opt] = checked;
    if (checked) {
      if (opt === 'noBGM') {
        if (diamParams.clr.length > 0) {
          diamParams.clr.forEach((e) => {
            if (selectedMaster.clr && includes(selectedMaster.clr, e.id[0])) {
              if (includes(clr, e.code)) {
                filter['noBGM'].wSd.push('CLD2')
                filter['noBGM'].wTbl.push('CLD2')
              } else {
                Object.keys(filter[opt]).forEach((k) => {
                  let ids = [];
                  diamParams[k].forEach((d2) => {
                    if (includes(filter[opt][k], d2.code)) ids = concat(ids, d2.id);
                  });
                  selectedMaster[k] = ids;
                });
              }
            }
          })
        }
        if (!selectedMaster?.clr || selectedMaster?.clr?.length == 0 || selectedMaster?.clr?.length == 12) {
          filter['noBGM'].wSd.push('CLD2')
          filter['noBGM'].wTbl.push('CLD2')
        }
      }
      Object.keys(filter[opt]).forEach((k) => {
        let ids = [];
        diamParams[k].forEach((d2) => {
          if (includes(filter[opt][k], d2.code)) ids = concat(ids, d2.id);
        });
        selectedMaster[k] = ids;
      });
    } else {
      Object.keys(filter[opt]).forEach((d1) => {
        selectedMaster[d1] = [];
      });
      excludeFilter = omit(excludeFilter, opt);
    }

    selectedMaster.excludeFilter = excludeFilter;

    this.setState({
      selectedMaster,
      [opt]: !this.state[[opt]],
    });
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleUpcoming = () => {
    let selectedMaster = { ...this.state.selectedMaster };
    selectedMaster = { ...selectedMaster, wSts: 'U' };
    fetchUpcoming(selectedMaster, (cb) => {
      this.props.history.push(`/${LISTINGPAGES.UPCOMING}?` + cb.id);
    });
  };

  //For External-Show function
  handleShow = () => {
    const selectedMaster = { ...this.state.selectedMaster, wSts: ['SW'] };

    handleSearch(selectedMaster, (cb) => {
      this.props.history.push(`/${LISTINGPAGES.SHOW}?` + cb.id, { id: cb.id });
    });
  };

  handleCanadamark = () => {
    const selectedMaster = { ...this.state.selectedMaster };
    if (!this.state.canadamark) selectedMaster.isCm = ['CERT', 'ELIG'];
    else delete selectedMaster.isCm;
    this.setState({ selectedMaster });
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleXray = () => {
    const selectedMaster = { ...this.state.selectedMaster };
    if (!this.state.xRay) selectedMaster.isXray = true;
    else delete selectedMaster.isXray;
    this.setState({ selectedMaster });
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleType2 = () => {
    const selectedMaster = { ...this.state.selectedMaster };
    if (!this.state.type2) selectedMaster.type2 = { nin: [''] };
    else delete selectedMaster.type2;
    this.setState({ selectedMaster });
    getCount(selectedMaster, (count) => this.setState({ count }));
  };

  handleNewArrival = () => {
    let { selectedMaster, wSts } = this.state;

    if (!this.state.newArr) {
      wSts = concat(wSts, [DIAMOND_WEB_STATUS.BID]);
      wSts = filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.UPCOMING; // Upcoming false when new arrival selected.
      });
    } else if (this.state.newArr)
      wSts = filter(wSts, function (val) {
        return val !== DIAMOND_WEB_STATUS.BID;
      });
    selectedMaster.wSts = wSts;

    this.setState({ upcoming: false, selectedMaster, wSts });
    getCount(this.state.selectedMaster, (count) => this.setState({ count }));
  };

  handleLab = (status, key, inKey) => {
    let { selectedMaster, fmKeys, dorKeys } = this.state;
    if (status) {
      if (key === 'isDor') {
        dorKeys = concat(dorKeys, inKey);
        selectedMaster[key] = dorKeys;
      }
      if (key === 'isFm') {
        fmKeys = concat(fmKeys, inKey);
        selectedMaster[key] = fmKeys;
      }
    } else {
      selectedMaster[key] = filter(selectedMaster[key], function (val) {
        return val !== inKey;
      });
      if (key === 'isDor') {
        dorKeys = selectedMaster[key];
      }
      if (key === 'isFm') {
        fmKeys = selectedMaster[key];
      }
    }
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster, dorKeys, fmKeys });
  };

  handleWebStatus = (value) => {
    let { wSts, selectedMaster } = this.state;
    if (!includes(wSts, value)) {
      wSts = concat(wSts, value);
    } else {
      wSts = filter(wSts, (val) => {
        return val !== value;
      });
    }

    selectedMaster['wSts'] = wSts;
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ wSts });
  };

  getCodeFromIds = (master) => {
    const { selectedMaster, diamParams } = this.state;
    const ids = selectedMaster[master];
    let cds = [];
    diamParams[master].map((ms) => {
      if (includes(ids, ms.id[0])) {
        cds = concat(cds, ms.code);
      }
      return true;
    });
    return cds;
  };

  handleToggle = (key, checked) => {
    const { selectedMaster, diamParams } = this.state;
    if (key === 'isSeal') {
      let seal = false;
      seal = checked === 'YES' ? true : false;
      selectedMaster[key] = seal;
    } else {
      const id = find(diamParams[key], { code: checked }).id;
      selectedMaster[key] = id;
    }

    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };

  handleFancy = (checked, key) => {
    const selectedMaster = { ...this.state.selectedMaster };
    selectedMaster[`${key}`] = checked;
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster });
  };
  handleSelection = (selValues, checked, keyToSend, certi, rptrue, allSelect) => {
    const { masterIndex } = this.state;
    const selectedMaster = { ...this.state.selectedMaster };
    this.setState({ loader: true });
    if (rptrue) {
      if (certi && certi.length !== 0) {
        if (this.state.or && this.state.or.length) {
          this.state.or.map((ab, ind) => {
            if (ab.vStnId || ab.rptNo || ab.stoneId) {
              this.state.or.splice(ind, 1);
            }
          });
          selectedMaster.or = [...this.state.or];
        } else {
          selectedMaster.or = [...[{ stoneId: certi }, { rptNo: certi }, { vStnId: certi }]];
        }
      } else {
        delete selectedMaster.or;
      }
    } else {
      if (selValues === '') {
        each(this.state.diamParams[keyToSend], function (data) {
          selectedMaster[keyToSend] = concat(selectedMaster[keyToSend], data.id);
        });
      } else {
        if (allSelect) {
          selectedMaster[keyToSend] = this.state.selectedMaster[keyToSend] || [];
          selectedMaster[keyToSend] = concat(selectedMaster[keyToSend], selValues);
        } else {
          selectedMaster[keyToSend] = this.state.selectedMaster[keyToSend] || [];
          selectedMaster[keyToSend] = concat(selectedMaster[keyToSend], selValues);
        }
      }
    }

    if (!checked) {
      selectedMaster[keyToSend] = filter(this.state.selectedMaster[keyToSend], function (val) {
        return selValues.indexOf(val) === -1;
      });
    }
    getCount(selectedMaster, (count) => this.setState({ count, loader: false }));
    this.setState({ selectedMaster, masterIndex });
  };

  handleMutipleSearch = (callApi) => {
    const { multipleSearch, selectedMaster } = this.state;
    multipleSearch.push(selectedMaster);
    if (!callApi) {
      this.handleRefresh();
    }
    this.setState({ multipleSearch }, () => {
      if (callApi) {
        this.handleMultipleSearchApiCall();
      }
    });
  };

  handleMatchPair = () => {
    if (Object.keys(this.searchFilter).includes('shp' && 'or')) {
      handleCoupleStone(
        (id) => id && this.props.history.push(`/${LISTINGPAGES.MATCHPAIR}?` + id),
        this.state.selectedMaster,
      );
    } else {
      OpenNotification({
        type: 'error',
        title: 'Select at-least Shape and one Carat range to search the pair diamonds.',
      });
    }
  };

  handleMultipleSearchApiCall = () => {
    const { multipleSearch } = this.state;
    handleMultiSearch(multipleSearch, (success) => {
      this.props.history.push(`/${LISTINGPAGES.LIST}?${join(success.ids, ',')}`);
    });
  };

  handleAddCustomCarat = (arr) => {
    const { selectedMaster, or } = this.state;
    const newArr = [];
    Object.keys(or).map((o) => {
      if (!isUndefined(or[o]) && or[o].length) {
        or[o].map((i) => o !== 'customeCarat' && newArr.push(i));
      }
    });

    selectedMaster.or = newArr;
    selectedMaster.or = selectedMaster.or.concat(...arr);
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({
      selectedMaster,
      or: { ...this.state.or, customeCarat: arr },
    });
  };

  handleCaratSelection = (index) => {
    const { diamParams, or, masterIndex = {} } = this.state;
    const caratData = groupBy(diamParams.carat, 'group');

    const selectedMaster = { ...this.state.selectedMaster };
    masterIndex.crt = masterIndex.crt ? masterIndex.crt : [];

    if (includes(masterIndex.crt, index)) masterIndex.crt = masterIndex.crt.filter((f) => f !== index);
    else masterIndex.crt.push(index);

    const newArr = [];
    Object.keys(or).forEach((o) => {
      if (!isUndefined(or[o]) && or[o].length) {
        or[o] &&
          or[o].forEach((i) => {
            if (o !== 'carat') newArr.push(i);
          });
      }
    });
    selectedMaster.or = newArr;

    const caratRange = masterIndex.crt.map((i) => {
      const fromCarat = caratData[Object.keys(caratData)[i]][0]['fromCarat'];
      const toCarat = caratData[Object.keys(caratData)[i]][0]['toCarat'];
      return {
        crt: { '>=': fromCarat, '<=': toCarat },
      };
    });

    selectedMaster.or = selectedMaster.or.concat(...caratRange);
    getCount(selectedMaster, (count) => this.setState({ count }));
    this.setState({ selectedMaster, or: { ...this.state.or, carat: caratRange }, masterIndex });
  };

  render() {
    const fromShow = history.location?.state?.fromShow;
    return (
      <DiamondSearchComponent
        {...this.state}
        clearSelection={this.clearSelection}
        handleFancy={this.handleFancy}
        handleSelection={this.handleSelection}
        handleFancySelection={this.handleFancySelection}
        selectedCaratFn={this.selectedCarat}
        fromShow={fromShow}
        handleSearch={() => {
          const selectedMaster = { ...this.state.selectedMaster };
          const searchId = _last(split(window.location.pathname, '/'));
          const path = `${split(window.location.pathname, '/')[1]}`;
          handleSearch(selectedMaster, (cb) => {
            if (cb.NOSTONE === 'NOSTONE') {
              this.setState({ showDemand: false });
            } else if (this.state.newArr && searchId === LISTINGPAGES.LIST) {
              this.props.history.push(`/${LISTINGPAGES.LIST}?${cb.id}`);
              return;
            } else if (fromShow || path === LISTINGPAGES.SHOW) {
              return this.props.history.push(`/${LISTINGPAGES.SHOW}?${cb.id}`, { fromShow });
            } else if (this.state.upcoming) {
              this.props.history.push(`/${LISTINGPAGES.UPCOMING}`);
              return;
            } else if (LISTINGPAGES.SEARCH_DIAMOND === path && this.state.newArr) {
              this.props.history.push(`/${LISTINGPAGES.BID}?${cb.id}`);
              return;
            } else if (LISTINGPAGES.SEARCH_DIAMOND === path) {
              this.props.history.push(`/${LISTINGPAGES.BID}?${cb.id}`);
              return;
            } else {
              this.props.history.push(`/${LISTINGPAGES.LIST}?${cb.id}`);
            }
          });
        }}
        handleSavedSearch={() =>
          handleSavedSearch(this.state.selectedMaster, this.state.searchTitle, this.state.searchId, (id) => {
            if (id) {
              this.setState({ showSavedSearch: false, searchId: '' });
              this.props.history.push(`/${LISTINGPAGES.LIST}?` + id);
            }
          })
        }
        handleMatchPair={this.handleMatchPair}
        // handleMatchPair={() => {
        //   // handleCoupleStone(
        //   //   (id) => id && this.props.history.push(`/${LISTINGPAGES.MATCHPAIR}?` + id),
        //   //   this.state.selectedMaster,
        //   // )
        //   // let filter = this.searchFilter;
        //   // const search = 'shp' in filter && 'or' in filter;
        //   let length = this.searchFilterLength;
        //   if (length >= 2) {
        //     handleCoupleStone(
        //       (id) => id && this.props.history.push(`/${LISTINGPAGES.MATCHPAIR}?` + id),
        //       this.state.selectedMaster,
        //     );
        //   } else {
        //     if (Object.keys(this.searchFilter).includes('shp' || 'or')) {
        //       // if (this.searchFilter.or && this.searchFilter.or.length !== 0) {
        //       //   this.searchFilter.or.forEach((m) => {
        //       //     if (m.vStnId) stoneId = true;
        //       //   });
        //       handleCoupleStone(
        //         (id) => id && this.props.history.push(`/${LISTINGPAGES.MATCHPAIR}?` + id),
        //         this.state.selectedMaster,
        //       );
        //     } else {
        //       OpenNotification({
        //         type: 'error',
        //         title: 'Select at-least Shape and one Carat range to search the pair diamonds.',
        //       });
        //     }
        //     // } else {
        //     //   OpenNotification({
        //     //     type: 'error',
        //     //     title: 'Select at-least Shape and one carat range to search the pair diamonds.',
        //     //   });
        //     // }
        //     // } else {
        //     //   OpenNotification({
        //     //     type: 'error',
        //     //     title: 'Select at-least Shape and one carat range to search the pair diamonds.',
        //     //   });
        //   }
        // }}
        selectedKeyToSymbol={this.selectedKeyToSymbol}
        selectedRange={this.selectedRange}
        handleClrGrpSearch={this.handleClrGrpSearch}
        handleClarityGrpSearch={this.handleClarityGrpSearch}
        handleClrSwitch={this.handleClrSwitch}
        handleClarSwitch={this.handleClarSwitch}
        handleRefresh={this.handleRefresh}
        handleChange={this.handleChange}
        handleSetStoneIds={this.handleSetStoneIds}
        handleAfterSelectSearch={this.handleAfterSelectSearch}
        isFancy={split(window.location.pathname, '/')[1] === LISTINGPAGES.FANCY_SEARCH ? true : false}
        handleReverseState={this.handleReverseState}
        handleCombineSearch={this.handleCombineSearch}
        handleSetPairs={this.handleSetPairs}
        handleWebStatus={this.handleWebStatus}
        handleLab={this.handleLab}
        handleUpcoming={this.handleUpcoming}
        handleShow={this.handleShow}
        handleType2={this.handleType2}
        handleXray={this.handleXray}
        handleCanadamark={this.handleCanadamark}
        handleNewArrival={this.handleNewArrival}
        handleToggle={this.handleToggle}
        handleMutipleSearch={this.handleMutipleSearch}
        handleMultipleSearchApiCall={this.handleMultipleSearchApiCall}
        handleAddCustomCarat={this.handleAddCustomCarat}
        handleCaratSelection={this.handleCaratSelection}
      />
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};

export default connect(mapStateToProps, {
  resetDiamondSearch,
  modifyDiamondSearch,
})(DiamondSearch);
