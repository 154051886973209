import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './pageNotFound.less';

class PageNotFound extends Component {
  render() {
    return (
      <div className="thankYouWrapper">
        <div className="thankYouInnerBox">
          <div className="thankYouMainBox">
            <div className="thankYouImgBlock">
              <img src={require('../../assets/svg/ThankYou/1.svg')} alt="" />
            </div>
            <div className="thankYouPageContent">
              <h2>Oops, Page not found!</h2>

              <Link to="/dashboard">
                <button className="commonButton">Back To Dashboard</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
