import React, { useState } from 'react';
import img from './../../assets/img/no_img.jpeg'

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import IntlMessages from '../../util/IntlMessages';

import { FILE_URLS } from '../../constants/Common';
import MagnifierImage from '../DiamondDetail/MagnifierImage';

import { downloadFile, downloadVideo, isMobile } from '../../components/DiamondList/DiamondListFunctions';

import { ImageWithView } from 'components/DiamondDetail/ImageWithView';

const ImageSlider = (props) => {
  const { dnaData } = props;

  const [blackBackgroundFile, setBlackBackgroundFile] = useState(false)
  const [fluorescenceFile, setFluorescenceFile] = useState(false)
  const [assetFile, setAssetFile] = useState(false)
  const [arrowFile, setArrowFile] = useState(false)
  const [hAFile, setHAFile] = useState(false)
  const [certImg, setCertImg] = useState(false)
  const [image, setImage] = useState(false)
  const [video, setVideo] = useState(false)

  const setting = [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 3,
      },
    },
  ];

  // const actions = (link, ext) => {
  //   return (
  //     <div className="downlaod_data">
  //       {/* <p>Single Stone</p> */}
  //       <div className="dwn_liks">
  //         <a target="_blank" href={link}>
  //           <img src={require('../../assets/svg/DNA/photo.svg')} />
  //         </a>
  //         <a
  //           onClick={() => {
  //             if (ext === '.mp4')
  //               downloadVideo({
  //                 path: link,
  //                 fileName: last(split(link, '/')),
  //                 ext,
  //               });
  //             else
  //               downloadFile({
  //                 path: link,
  //                 fileName: last(split(link, '/')),
  //                 ext,
  //               });
  //           }}
  //         >
  //           <img src={require('../../assets/svg/DNA/download-black.svg')} />
  //         </a>
  //       </div>
  //     </div>
  //   );
  // };
  const [activeTab, setActiveTab] = useState(
    dnaData.img ? '1' : dnaData.videoFile ? '2' : dnaData.certFile ? '3' : '1',
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const link = () => {
    return (
      <Nav tabs className=" DNAdiamondDetailTopTab">
        {dnaData.img && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { toggle('1'); }}  >
              <div className='DNAdiamondDetailTabText' >
                <div className='DNAdiamondDetailTabImage' >
                  {image ? <img src={img} /> : <img src={FILE_URLS.img.replace('***', dnaData.vStnId)} onError={() => setImage(true)} />}
                </div>
                <IntlMessages id="app.Image" />
              </div>
            </NavLink>
          </NavItem>
        )}
        {dnaData.videoFile && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { toggle('2'); }}  >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage'>
                  {(video || image) ? <img src={img} /> : <img src={FILE_URLS.img.replace('***', dnaData.vStnId)} />}
                </div>
                <IntlMessages id="app.Video" />
              </div>
            </NavLink>
          </NavItem>
        )}

        {dnaData.certFile && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { toggle('3'); }}  >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage' >
                  {certImg ? <img src={img} /> : <img src={FILE_URLS.certImg.replace('***', dnaData.rptNo)} onError={() => setCertImg(true)} />}
                </div>
                <IntlMessages id="app.Certificate" />
              </div>
            </NavLink>
          </NavItem>
        )
        }

        {dnaData.hAFile && dnaData.shpNm === 'ROUND' && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { toggle('4'); }} >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage'>
                  {hAFile ? <img src={img} /> : <img src={FILE_URLS.hAFile.replace('***', dnaData.vStnId)} onError={() => setHAFile(true)} />}
                </div>
                Heart
              </div>
            </NavLink>
          </NavItem>
        )
        }
        {dnaData.arrowFile && dnaData.shpNm === 'ROUND' && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '5' })} onClick={() => { toggle('5'); }} >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage'>
                  {arrowFile ? <img src={img} /> : <img src={FILE_URLS.arrowFile.replace('***', dnaData.vStnId)} onError={() => setArrowFile(true)} />}
                </div>
                <IntlMessages id="app.Arrow" />
              </div>
            </NavLink>
          </NavItem>)}
        {dnaData.fluorescenceFile && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '7' })} onClick={() => { toggle('7'); }}  >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage' >
                  {fluorescenceFile ? <img src={img} /> : <img src={FILE_URLS.fluorescenceFile.replace('***', dnaData.vStnId)} onError={() => { setFluorescenceFile(true) }} />}
                </div>
                <IntlMessages id="app.compare.flo" />
              </div>
            </NavLink>
          </NavItem>)}
        {dnaData.blackBackgroundFile && (
          <NavItem>
            <NavLink className={classnames({ active: activeTab === '8' })} onClick={() => { toggle('8'); }} >
              <div className='DNAdiamondDetailTabText'>
                <div className='DNAdiamondDetailTabImage' >
                  {blackBackgroundFile ? <img src={img} /> : <img src={FILE_URLS.blackBackgroundFile.replace('***', dnaData.vStnId)} onError={() => { setBlackBackgroundFile(true) }} />}
                </div>
                <div className='DNAdiamondDetailTabTextBlackBackground'>
                  <IntlMessages id="app.blackBackground" />
                </div>
              </div>
            </NavLink>
          </NavItem>)}
      </Nav >
    );
  };
  return (
    <>
      <div className="dnaLeftBox">
        <div className="dnaLeftBoxImage">
          {/* {!isMobile() && link()} */}
          <TabContent activeTab={activeTab}>
            {activeTab === '1' && dnaData.img && (
              <TabPane className="after-logout" tabId={activeTab}>
                <>
                  <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.img.replace('***', dnaData.vStnId)} title="" height="900px" />
                    ) : (
                      <MagnifierImage image={FILE_URLS.img.replace('***', dnaData.vStnId)} />
                    )}
                  </div>
                </>
              </TabPane>
            )}

            {activeTab === '2' && dnaData.videoFile && (
              <TabPane tabId={activeTab}>
                <>
                  <div className="video-set imageSliderFullBlock dnaImageSliderFullBlock width-100">
                    <iframe
                      height="315"
                      allowfullscreen
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      src={FILE_URLS.videoFile.replace('***', dnaData.vStnId)}
                    />
                  </div>
                </>
              </TabPane>
            )}

            {activeTab === '3' && dnaData.certFile && (
              <TabPane tabId={activeTab}>
                <>
                  <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.certImg.replace('***', dnaData.rptNo)} title="" />
                    ) : (
                      <MagnifierImage image={FILE_URLS.certImg.replace('***', dnaData.rptNo)} />
                    )}
                  </div>
                </>
              </TabPane>
            )}
          </TabContent>
          {activeTab === '4' && dnaData.hAFile && dnaData.shpNm === 'ROUND' && (
            <TabPane className="after-logout" tabId={activeTab}>
              <>
                <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                  {isMobile() ? (
                    <ImageWithView src={FILE_URLS.hAFile.replace('***', dnaData.vStnId)} title="" height="900px" />
                  ) : (
                    <MagnifierImage image={FILE_URLS.hAFile.replace('***', dnaData.vStnId)} />
                  )}
                </div>
              </>
            </TabPane>
          )}
          {activeTab === '5' && dnaData.arrowFile && dnaData.shpNm === 'ROUND' && (
            <TabPane className="after-logout" tabId={activeTab}>
              <>
                <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                  {isMobile() ? (
                    <ImageWithView src={FILE_URLS.arrowFile.replace('***', dnaData.vStnId)} title="" height="900px" />
                  ) : (
                    <MagnifierImage image={FILE_URLS.arrowFile.replace('***', dnaData.vStnId)} />
                  )}
                </div>
              </>
            </TabPane>
          )}
          {activeTab === '7' && dnaData.fluorescenceFile && (
            <TabPane className="after-logout" tabId={activeTab}>
              <>
                <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                  {isMobile() ? (
                    <ImageWithView src={FILE_URLS.fluorescenceFile.replace('***', dnaData.vStnId)} title="" height="900px" />
                  ) : (
                    <MagnifierImage image={FILE_URLS.fluorescenceFile.replace('***', dnaData.vStnId)} />
                  )}
                </div>
              </>
            </TabPane>
          )}
          {activeTab === '8' && dnaData.blackBackgroundFile && (
            <TabPane className="after-logout" tabId={activeTab}>
              <>
                <div className="imageSliderFullBlock dnaImageSliderFullBlock">
                  {isMobile() ? (
                    <ImageWithView src={FILE_URLS.blackBackgroundFile.replace('***', dnaData.vStnId)} title="" height="900px" />
                  ) : (
                    <MagnifierImage image={FILE_URLS.blackBackgroundFile.replace('***', dnaData.vStnId)} />
                  )}
                </div>
              </>
            </TabPane>
          )}
          {/* {isMobile() && link()} */}
          {link()}

        </div>
      </div>
    </>
  );
};

export default ImageSlider;
