import React from 'react';
import './downloadBlock.less';

const DownloadBlock = () => {
  return (
    <div className="downloadBlockWrapper">
      {/* <div className="downloadBlockLabel">Click here to download App </div>
            <div className="downloadBlock d-flex d-flex-wrap">
                <div className="downloadBlockItem">
                    <img src={require("../../../assets/svg/Download/androidapp.svg")} alt=""/>
                </div>
                <div className="downloadBlockItem">
                    <img src={require("../../../assets/svg/Download/ipadeapp.svg")} alt=""/>
                </div>
                <div className="downloadBlockItem">
                    <img src={require("../../../assets/svg/Download/iosapp.svg")} alt=""/>
                </div>
            </div> */}
    </div>
  );
};
export default DownloadBlock;
