import React from 'react';
import './input.less';
import { Tooltip } from 'antd';

const InputBlock = (props) => {
  let groupClass = `from-group inputBlock ${props.className ? props.className : ''}`;
  if (props.hasError) {
    groupClass = groupClass + ' error-message';
  }
  // const displayTitle = () => {

  //   // return
  //   return (
  //     <div>
  //       <span> Password Must Contains At Least 1 Upper Case Alphabet, 1 Lower Case Alphabet, 1 Digit And 1 Special Character.</span>
  //       <br />
  //       <span>Special Characters includes : !@#$%^&*()_+</span>
  //     </div>

  //   )
  // }
  return (
    <div className={groupClass}>
      {props.label ? (
        <label>
          {props.label}

          {/* {props.info &&
          <Tooltip overlayClassName='Tool' placement="topLeft" title={displayTitle}>
            <span>
              <img alt=''
                style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '5px', width: '14px', height: '12px' }}
                src={require('../../../assets/svg/info.svg')} />


            </span>

          </Tooltip>

        } */}
        </label>
      ) : null}
      <div className="inputWrapper">
        <input
          placeholder={props.placeholder}
          value={props.value ? props.value : ''}
          type={props.type ? props.type : 'text'}
          onChange={props.onChange ? props.onChange : null}
          disabled={props.disabled ? props.disabled : false}
          {...props}
          onKeyDown={(e) => {
            if (e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
              props.type === 'number' && e.preventDefault();
            }
            if (e.keyCode === 13) {
              props.loginKeyDown && props.loginKeyDown();
            }
          }}
        />
        {/* {props.InputAdd ?
                <div className="plusInputRemove">
                    <div className="plusInputItem">
                        <img src={require("../../assets/svg/plus.svg")} alt="" className="plusInput"/>
                    </div>
                    <div className="closeInoutItem">
                        <img src={require("../../assets/svg/close.svg")} alt="" className="closeInput"/>
                    </div>
                </div>
                : null } */}
      </div>
      <span style={{ color: 'red' }}>{props.hasError ? props.hasError : ''}</span>
    </div>
  );
};
export default InputBlock;
