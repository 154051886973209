import React from 'react';
import { BASE_URL_ATTACH, LOCAL_STORAGE_VAR, BASE_URL } from '../../../constants/Common';
import FileUploaded from '../../common/FileUploaded';
import DefaultUser from '../../common/UserProfileDefault';
import find from 'lodash/find';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

const DetailItem = (props) => {
  return (
    <div className="detail">
      <span>
        <img src={props.image} alt="" />
      </span>
      <span>{props.value}</span>
    </div>
  );
};

const CardView = (props) => {
  const pd = (props && props.pDetails) || {};
  if (pd && pd.account && pd.account.kyc && !isEmpty(pd.account.kyc)) {
    const doc = pd && pd.account && pd.account.kyc && pd.account.kyc.find((item) => item.type == 'personal');
    pd.photoId = doc && doc.path;
  }
  const cd = (props && props.cDetails) || {};
  if (cd && cd.account && cd.account.kyc && !isEmpty(cd.account.kyc)) {
    const accountData = cd.kyc ?? cd?.account?.kyc;
    const doc = accountData.find((item) => item.type == 'business');
    cd.businessId = doc && doc.path;
  }
  const { BUSINESS_TYPE } = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));

  return (
    <>
      <div className="KycCardBlock">
        <div className="kycDetailBox d-flex flex-wrap">
          <div className="kycUploadDoc">
            {props.companyDetail &&
              (!isEmpty(cd?.vendorLogo || cd?.account?.vendorLogo) ? (
                (cd?.vendorLogo && <img src={`${BASE_URL_ATTACH}/${cd.vendorLogo}`} alt="" />) ??
                (cd.account.vendorLogo && <img src={`${BASE_URL_ATTACH}/${cd.account.vendorLogo}`} alt="" />)
              ) : (
                <DefaultUser size="md-size" />
              ))}
            {props.personalDetails &&
              (pd.profileImage ? (
                <img src={`${BASE_URL_ATTACH}/${pd.profileImage}`} alt="" />
              ) : (
                <DefaultUser size="md-size" />
              ))}
            {/* {props.companyDetail ? (
            <img src={`${BASE_URL_ATTACH}/${cd.account.vendorLogo}`} alt="" />
          ) : (
            <img src={`${BASE_URL_ATTACH}/${pd.profileImage}`} alt="" />
          )} */}
          </div>
          <div className="kycDetailItem">
            {props.companyDetail && (
              <>
                <div className="">
                  <div className="companyNameCode">
                    {<span>{cd.name ?? cd.account.name}</span>}
                    {<span>({cd?.vendorCode ?? cd.account.vendorCode})</span>}
                  </div>
                  {props.companyDetail && cd?.businessType && (
                    <div className="detail mt-5">
                      <span>{cd?.businessType ?? 'Business Type:'}</span>
                      <span>{cd.businessType && find(BUSINESS_TYPE, { id: [cd.businessType] }).name}</span>
                    </div>
                  )}
                  {cd && (
                    <div className="detail mt-5">
                      <span>
                        {(cd?.account?.companyName && 'Company Name:') ?? (cd.companyName && 'Company Name:')}{' '}
                      </span>
                      <span>{cd?.account?.companyName ?? cd?.companyName}</span>
                    </div>
                  )}
                </div>
                {cd && (
                  <>
                    <div className="detail mt-5">
                      <span>
                        {(cd?.address && 'Company Address: ') ?? (cd.account.address && 'Company Address: ')}{' '}
                      </span>
                      {cd?.address ?? <p>{cd?.address ?? cd.account.address}</p>}
                      <p>{cd?.landMark ?? cd.account.landMark}</p>
                    </div>
                  </>
                )}
                {cd && (
                  <>
                    <div className="detail mt-5">
                      <span>{(cd.zipCode && 'ZipCode: ') ?? (cd.account.zipCode && 'ZipCode: ')} </span>
                      {cd?.zipCode ?? <p>{cd.account.zipCode}</p>}
                    </div>
                  </>
                )}
                {/* {cd.account.vendorCode && (
                <>
                  <div className="detail mt-5">
                    <span>Company Code: </span>
                    {cd.account.vendorCode && <p>{cd.account.vendorCode}</p>}
                  </div>
                </>
              )} */}
              </>
            )}
            {props.companyDetail ? null : (
              <>
                <DetailItem
                  image={require('../../../assets/svg/Profile/user.svg')}
                  value={`${pd.firstName} ${pd.middleName} ${pd.lastName}`}
                />
                <div className="d-flex">
                  {(pd.mobile || pd.whatsapp) && (
                    <div className="mr-20">
                      {pd.mobile && (
                        <DetailItem
                          image={require('../../../assets/svg/Profile/call.svg')}
                          value={`+${pd.countryCode} ${pd.mobile}`}
                        />
                      )}
                      {pd.whatsapp && (
                        <DetailItem image={require('../../../assets/svg/Profile/whatsapp.svg')} value={pd.whatsapp} />
                      )}
                    </div>
                  )}
                  {(pd.email || pd.skype) && (
                    <div>
                      {pd.email && (
                        <DetailItem image={require('../../../assets/svg/Profile/email.svg')} value={pd.email} />
                      )}
                      {pd.skype && (
                        <DetailItem image={require('../../../assets/svg/Profile/skype.svg')} value={pd.skype} />
                      )}
                    </div>
                  )}
                </div>
                <div className="d-flex mt-10">
                  {pd.createdAt && (
                    <div className="mr-20">
                      <div className="detail">
                        <span>Register Date:</span>
                        <span>{moment(pd.createdAt).format('DD-MM-YYYY')}</span>
                      </div>
                    </div>
                  )}
                  {pd.account && (
                    <div>
                      <div className="detail">
                        <span>Activation Date:</span>
                        <span>{moment(pd?.updatedAt ?? pd.account.verifyDate).format('DD-MM-YYYY')}</span>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="detailEditBloxk" onClick={props.onClick}>
            <img src={require('../../../assets/svg/Profile/edit.svg')} alt="" />
          </div>
        </div>
        {props.companyDetail ? null : (
          <>
            {pd.companyAddress && (
              <div className="kycDetailBox mt-10 addressBlock">
                <div className="kycDetailItem">
                  <div className="detail">
                    <span>
                      <img src={require('../../../assets/svg/Profile/location.svg')} alt="" />
                    </span>
                    <span>
                      <p>{pd.companyAddress}</p>
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {(cd.businessId || pd.businessId || pd.photoId || cd.photoId) && (
          <FileUploaded
            title={props.companyDetail ? `Business Proof` : `Personal ID`}
            file={
              props.companyDetail
                ? `${BASE_URL_ATTACH}/${cd?.businessId || cd.account?.businessId || pd?.businessId}`
                : `${BASE_URL_ATTACH}/${pd.photoId}`
            }
            className="mt-20"
          />
        )}
      </div>
      {props.accountProfile ? (
        <div className="KycCardBlock">
          <img className="full_banner" src={BASE_URL + props.accountProfile} />
        </div>
      ) : (
        ''
      )}
    </>
  );
};
export default CardView;
