import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { store } from './appRedux/store';
import Root from './config/Root';
import { ConnectedProgress } from 'components/ConnectedProgress';

import 'styles/App.less';
import 'styles/DiamondListMobile.less';
import 'components/auth.less';

import 'styles/Header.less';
import 'styles/DiamondList.less';
import 'styles/DiamondDetail.less';
import 'styles/Account.less';
import 'styles/QuickSearch.less';
import 'styles/Compare.less';
import 'styles/DiamondListingSidePopup.less';
import 'styles/OfficePopup.less';
import 'styles/savedSeach.less';
import 'styles/DiamondSearch.less';
import 'styles/mobileHeader.less';
import 'styles/MadeInBotswana.less';
import 'styles/Dashboard.less';
import 'styles/Preview.less';

import 'styles/DiamondDetailMobile.less';
import 'styles/DiamondSearchMobile.less';
import 'styles/mobileAccount.less';
import 'styles/notificationSetting.less';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/print/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedProgress />
    <AppContainer>
      <Root />
    </AppContainer>
  </Provider>,
  document.getElementById('root'),
);
