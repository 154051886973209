import React, { useReducer, useRef, useState, useEffect, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Drawer } from 'antd';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import SelectStone from '../common/DiamondListing/SelectStone';
import Heading from '../common/Heading';
import BidTermsConditions from './BidTermsConditions';
import Countdown from '../Offer/Timer';
import { getState, isArrayEqual } from '../DiamondList';
import Table from '../DiamondList/TableBack';
import { store } from 'appRedux/store';
import { getColumn, fetchDiamondPaginate, isMobile } from '../DiamondList/DiamondListFunctions';
import NoDataShow from '../common/NoDataShow';
import { DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from '../../constants/Common';
import IntlMessages from '../../util/IntlMessages';
import NewArrivalBidMobile from './NewArrivalBidMobile';
import { getCurrentTime, getNextBidData } from '../../services/util';
import UtilService from '../../services/util';
import { isEmpty } from 'util/utils';
import { getBidType } from 'components/NewArrivalBidIt';
import { useCompositeState } from 'util/hooks';

const DealOfDay = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useCompositeState(getState());
  const sortRef = useRef([]);
  const [terms, showTerm] = useState(false);
  const [currTime, setCurrTime] = useState('');
  const [nextBidData, setNextBidData] = useState({});
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const clearSelection = React.useCallback(() => {
    store.dispatch({ type: 'RESET_SELECTED_ROWS' });
    if (document.getElementsByTagName('table')[0]) {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) row.classList.remove('selectTr');
    }
  }, []);

  const checked = useSelector((state) => state.diamondData.selectedRows[location.pathname]) || [];
  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? 'listingSelected diamondListStone bid_it' : ''}
        currentType={currentType.current}
        hideClearAll
      />
    );
  };

  const setResponse = React.useCallback(
    (res) => {
      const columns = getColumn(state.page);

      if (res) {
        const diamondData = res.data || [];
        let defaultChecked = [];
        if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
            ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
            : [];
          if (defaultChecked && defaultChecked.length > 0) {
            diamondData.forEach((d) => {
              if (defaultChecked.includes(d.id)) {
                d['isDefaultChecked'] = true;
              }
            });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }
        setState({
          ...res,
          data: diamondData,
          defaultChecked,
          columns,
          loading: false,
        });
        window.history.pushState(`${window.location.pathname}`, '', `?${res.seachFilterTag.id}`);
      } else {
        setState({ loading: false, defaultChecked: [] });
      }
    },
    [setState, state.page],
  );

  const fetch = useCallback(() => {
    setState({ loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current };
    fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse, {
      wSts: 'DD',
      diamondSearchId: window.location.search.split('?')[1],
    });
  }, [setState, state, setResponse]);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const clearAllSelectedRows = useCallback(() => {
    if (isMobile()) {
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    } else {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) {
        row.classList.remove('selectTr');
      }
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ page, limit });
    },
    [setState],
  );

  useEffect(() => {
    const imageDrawer = localStorage.getItem('imageDrawer') ? localStorage.getItem('imageDrawer') === 'true' : true;
    localStorage.setItem('imageDrawer', imageDrawer);
  }, []);

  const setTimers = async () => {
    const time = await getCurrentTime();
    const nextBid = await getNextBidData()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') setState({ loading: false, data: [] });
      });

    const data = nextBid?.sub_types?.ruleFile;
    showTerm(Boolean(data));
    setNextBidData(nextBid ? nextBid : []);

    setCurrTime(time.iso);
    clearSelection();

    if (nextBid?.sub_types) fetch();
  };

  useEffect(() => {
    setTimers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTitle = () => {
    return JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length
      ? 'Deal of the Day' + ' (' + state.count + ')'
      : 'Deal of the Day' + ' (0)';
  };

  const getBidBlock = () => {
    if (isEmpty(state.data)) return null;

    return (
      Boolean(getBidType()) && (
        <div className={'bidEndBlock' + (!isMobile() ? ' d-flex align-items-center' : '')}>
          {!isEmpty(nextBidData) && !isEmpty(currTime) && (
            <span className="bidEndLabel">
              <IntlMessages id="app.BidEndIn" /> :
            </span>
          )}
          {!isEmpty(nextBidData?.sub_types?.currentBidEndDate) && !isEmpty(currTime) && (
            <Countdown
              currTime={currTime}
              date={nextBidData?.sub_types?.currentBidEndDate && moment(nextBidData.sub_types.currentBidEndDate)}
              fetch={nextBidData?.sub_types?.currentBidEndDate && fetch}
              bidEndTime={setTimers}
            />
          )}
        </div>
      )
    );
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        {...props}
        //update={true}
        nowatch={false}
        nooffice={false}
        nocompare={false}
        noshare={false}
        nodownload={false}
        noprint={false}
        currentType={currentType.current}
        onPaginationChange={onPaginationChange}
        // nopaginate
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        bidStone={get(USER_PERMIT, 'BID.view', true)}
        // myBid
        myBidDealList
        nocart
        // nowatch
        noreminder
        noquote
        noconfirm
        nonote
        nohold
        nofinalcalc
        noenquiry
        // nooffice
        // nodownload
        // noprint
        // nonocompare
        // noshare
      />
    );
  };

  const getDrawer = () => {
    const imageDrawer = localStorage.getItem('imageDrawer');
    if (imageDrawer !== 'true') return null;

    return (
      <Drawer
        onClose={() => {
          showTerm(false);
          localStorage.setItem('imageDrawer', false);
        }}
        visible={terms}
        wrapClassName="diamondListinSidebar sm-size"
        destroyOnClose
      >
        {Boolean(terms) && <BidTermsConditions nextBidData={nextBidData} />}
      </Drawer>
    );
  };

  if (isMobile()) {
    return (
      <NewArrivalBidMobile
        {...props}
        {...state}
        currentType={currentType.current}
        parent={{ getTitle, getSelection, getBidBlock, getAction, getDrawer }}
        areAllChecked={true}
      />
    );
  }


  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
          <Heading title={getTitle()} />
          <div className="d-flex align-items-center">{getBidBlock()}</div>
          {!isEmpty(nextBidData) ? getSelection() : ''}
        </div>
      </div>
      <div className="searchInnerResult">
        <div
          className="searchResultTable"
          style={{ height: `${checked}` ? `calc(100vh - 217px)` : `calc(100vh - 160px)`, overflow: 'auto' }}
        >
          {!isEmpty(nextBidData) && (
            <Table
              data={state.data}
              loading={state.loading}
              ref={tableRef}
              columns={state.columns}
              handleSort={handleSort}
              currentType={currentType.current}
              defaultChecked={state.defaultChecked}
              // bidStatus={"Khushali"}
              noCheckBox
              areAllChecked={true}
              bidStatus={nextBidData && nextBidData.sub_types && nextBidData.sub_types.subTypeCode}
              bidStatusTooltip={nextBidData && nextBidData.sub_types && nextBidData.sub_types.tooltip}
            />
          )}
          {isEmpty(nextBidData) && (
            <NoDataShow
              message={'Currently UBS is Inactive, Kindly visit again. You will be notified when UBS is Active.'}
            />
          )}
        </div>
        {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? getAction() : ''}
      </div>
      {getDrawer()}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(DealOfDay));
