import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Heading from '../../common/Heading';
import InputBlock from '../../common/InputBlock';
import IntlMessages from '../../../util/IntlMessages';

/* eslint jsx-a11y/anchor-is-valid: 0 */

class ResetPassword extends Component {
  render() {
    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessages id="app.resetpassword" />} className="popupInnerTitle"></Heading>
        </div>
        <div className="profileDetailSpace">
          <InputBlock
            placeholder={<IntlMessages id="app.NewPassword" />}
            label={<IntlMessages id="app.NewPassword" />}
          />
          <InputBlock
            placeholder={<IntlMessages id="app.ConfirmPassword" />}
            label={<IntlMessages id="app.ConfirmPassword" />}
          />
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton">
            <IntlMessages id="app.signup.Submit" />
          </a>
          <a className="commonButton">
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}
export default withRouter(ResetPassword);
