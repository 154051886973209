import React from 'react';
import ReactDOM from 'react-dom';
import PinchZoom from 'pinch-zoom-js';
import img from './../../assets/img/no_img.jpeg'


const PreviewPortal = React.memo((props) => {
  const container = React.useMemo(() => {
    const el = document.createElement('div');
    el.classList.add('image-view-container');
    el.setAttribute('id', `image-view-container-${Math.round(Math.random() * 1000000)}`);
    el.classList.add();
    return el;
  }, []);

  React.useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return ReactDOM.createPortal(props.children, container);
});

export const ImageWithView = React.memo((props) => {
  const { className, onClick, ...rest } = props;

  const previewRef = React.useRef();
  const previewImgRef = React.useRef();
  const pinchZoomRef = React.useRef();
  const [image, setImage] = React.useState(false)

  const addPinchZoom = React.useCallback(() => {
    try {
      const pz = (() => {
        if (pinchZoomRef.current) return pinchZoomRef.current;
        return new PinchZoom(previewImgRef.current, {
          draggableUnzoomed: false,
        });
      })();
      pinchZoomRef.current = pz;
      pz.enable();
    } catch (error) {
      console.info(error);
    }
  }, []);

  const disablePinchZoom = React.useCallback(() => {
    try {
      if (!pinchZoomRef.current) addPinchZoom();
      const pz = pinchZoomRef.current;
      pz.resetOffset();
      pz.disable();
    } catch (error) {
      console.info(error);
    }
  }, []);

  const showPreview = React.useCallback(() => {
    try {
      const preview = previewRef.current;
      preview.classList.remove('image-view-hidden');
      preview.classList.remove('image-view-visible');
      preview.classList.remove('image-view-default');
      preview.classList.add('image-view-visible');
      addPinchZoom();
    } catch (error) {
      console.info(error);
    }
  }, [addPinchZoom]);

  const hidePreview = React.useCallback(() => {
    try {
      disablePinchZoom();
      const preview = previewRef.current;
      preview.classList.remove('image-view-hidden');
      preview.classList.remove('image-view-visible');
      preview.classList.remove('image-view-default');
      preview.classList.add('image-view-hidden');
    } catch (error) {
      console.info(error);
    }
  }, [disablePinchZoom]);

  const handleKeyDown = React.useCallback(
    (e) => {
      if ([e.key, e.code].includes('Escape') || [e.keyCode, e.which].includes(27)) {
        e.preventDefault();
        hidePreview();
      }
    },
    [hidePreview],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <React.Fragment>
      {image ? <img style={{ width: '100%', height: '100%' }} src={img} /> :
        <img className={`image-fluid image-view-thumbnail ${className ?? ''}`} onError={() => { setImage(true) }} onClick={showPreview} {...rest} />}
      <PreviewPortal>
        <div ref={previewRef} className="image-view image-view-hidden">
          <span className="image-view-close" onClick={hidePreview}>
            &times;
          </span>
          <img
            ref={previewImgRef}
            className="image-fluid image-view-image"
            style={{ transform: 'scale(0)' }}
            {...props}
          />
        </div>
      </PreviewPortal>
    </React.Fragment>
  );
});
ImageWithView.displayName = 'ImageWithView';
