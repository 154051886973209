import React, { Component } from 'react';
import { Drawer } from 'antd';
import ManageUserList from './ManageUserList';
import UserPopup from './UserPopup';
class ManageUser extends Component {
  state = { visible: false };

  showDrawer1 = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <>
        <ManageUserList onClick={this.showDrawer1} />
        <Drawer title={false} onClose={this.onClose} visible={this.state.visible} wrapClassName="diamondListinSidebar">
          <UserPopup />
        </Drawer>
      </>
    );
  }
}

export default ManageUser;
