import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import MagnifierImage from './MagnifierImage';
import Slider from 'react-slick';
import { FILE_URLS } from '../../constants/Common';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import IntlMessages from '../../util/IntlMessages';
import { withRouter } from 'react-router-dom';
import { isMobile } from '../../components/DiamondList/DiamondListFunctions';
import copy from 'copy-to-clipboard';
import img from './../../assets/img/no_img.jpeg'
import { Icon, Tooltip } from 'antd';
import {
  InclusionDetailTwo,
} from '../../constants/DiamondDetail';
import { relativeTimeRounding } from 'moment';
import { ImageWithView } from './ImageWithView';
import groupBy from 'lodash/groupBy';

const DiamondDetailLeft = (props) => {
  const [copyText, setCopyText] = useState('Copy URL');
  const [blackBackgroundFile, setBlackBackgroundFile] = useState(false)
  const [fluorescenceFile, setFluorescenceFile] = useState(false)
  const [assetFile, setAssetFile] = useState(false)
  const [arrowFile, setArrowFile] = useState(false)
  const [hAFile, setHAFile] = useState(false)
  const [certImg, setCertImg] = useState(false)
  const [image, setImage] = useState(false)
  const [video, setVideo] = useState(false)

  const [activeTab, setActiveTab] = useState(
    // props.shared.find((c) => c === "isVideo=true")
    //   ? "1"
    //   :
    props.shared.find((c) => c === 'isImg=true')
      ? '1'
      : props.shared.find((c) => c === 'isVideo=true')
        ? '2'
        : props.shared.find((c) => c === 'isHa=true')
          ? '3'
          : props.shared.find((c) => c === 'isCert=true')
            ? '4'
            : props.shared.find((c) => c === 'isArrow=true')
              ? '5'
              : props.shared.find((c) => c === 'isAsset=true')
                ? '6'
                : // : props.item.videoFile
                // ? "1"
                // :
                props.item.img
                  ? '1'
                  : props.item.videoFile
                    ? '2'
                    : props.item.hAFile
                      ? '3'
                      : props.item.certFile
                        ? '4'
                        : props.item.arrowFile
                          ? '5'
                          : props.item.assetFile
                            ? '6'
                            : '',
  );
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    // if(tab === '1') {
    //   window.history.pushState(null, '', '?isImg=true');
    // }else if(tab === '2'){
    //   window.history.pushState(null, '', `?isVideo=true`);
    // }else if(tab === '3'){
    //   window.history.pushState(null, '', "?isHa=true");
    // }else if(tab === '4'){
    //   window.history.pushState(null, '', "?isCert=true");
    // }else if(tab === '5'){
    //   window.history.pushState(null, '', "?isArrow=true");
    // }else if(tab === '6'){
    //  window.history.pushState(null, '', "?isAsset=true");
    // }
  };

  const diamondDetail = (data, key) => {
    const grouped = groupBy(data, key);
    let first = grouped[1] || [];
    const second = grouped[2] || [];
    const third = grouped[3] || [];
    const fourth = grouped[4] || [];
    if (
      (!first || !first.length) &&
      (!second || !second.length) &&
      (!third || !third.length) &&
      (!fourth || !fourth.length)
    ) {
      first = Object.values(grouped)[0];
    }
    const maxIndex = Math.max(first.length, second.length, third.length, fourth.length),
      arr = [];

    for (let i = 0; i < maxIndex; i++) {
      first[i] && arr.push(first[i]);
      second[i] && arr.push(second[i]);
      third[i] && arr.push(third[i]);
      fourth[i] && arr.push(fourth[i]);
    }
    return arr;
  };
  if (!activeTab) return <></>;

  const copyURL = (url) => {
    setCopyText('Copied!!');
    copy(url);
  };

  return (
    <>

      {
        localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.length > 1 ? (
          <TabContent activeTab={activeTab}>
            {props.item.img &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isImg=true')) && (
                <TabPane className="hv-600" tabId="1">
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.img.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.img.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                  {/* <Slider
                  //  asNavFor={nav4} ref={slider3 => setNav3(slider3)}
                  arrows={false}
                >
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.img.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.img.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                </Slider> */}
                </TabPane>
              )}

            {props.item.videoFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isVideo=true')) && (
                <TabPane className="mb-17" tabId="2" forceRender={true}>
                  {/* <Slider
                  asNavFor={nav2}
                  infinite={false}
                  arrows={false}
                  ref={(slider1) => setNav1(slider1)}
                > */}
                  <div className="diamondDetailImageBox htmlViewImage dna-mobile">
                    <iframe
                      // width="100%"
                      // width="560"
                      allowfullscreen
                      // height="315"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      key={Math.random()}
                      src={FILE_URLS.videoFile.replace('***', props.item.vStnId)}
                      title="vFile"
                      onError={() => setVideo(true)}
                    />
                  </div>
                  {/* </Slider> */}
                </TabPane>
              )}
            {props.item.hAFile && props.item.shpNm === 'ROUND' &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isHa=true')) && (
                <TabPane tabId="3">
                  {/* <Slider
                    // asNavFor={nav6} infinite={false}
                    arrows={false}
                  //  ref={slider5 => setNav5(slider5)}
                  > */}
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.hAFile.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.hAFile.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                  {/* </Slider> */}
                  {/* <Slider
                        asNavFor={nav5}
                        ref={slider6 => setNav6(slider6)}
                        slidesToShow={7}
                        swipeToSlide={true}
                        centerMode={true}
                        focusOnSelect={true}
                        infinite={false}
                        className='smallSliderBlock'
                    >
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/HeartImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                        <div className='smallSliderImage'>
                            <img src={`${MEDIA_URL}/ArrowImages/${props.pktNo}.jpg`} alt='' />
                        </div>
                    </Slider> */}
                </TabPane>
              )}
            {props.item.certFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isCert=true')) && (
                <TabPane tabId="4">
                  <div className="diamondDetailImageBox">
                    <div className="diamondDetailImageBox cert-image">
                      {isMobile() ? (
                        <ImageWithView src={FILE_URLS.certImg.replace('***', props.item.rptNo)} />
                      ) : (
                        <MagnifierImage image={FILE_URLS.certImg.replace('***', props.item.rptNo)} />
                      )}
                    </div>
                    {/* {props.item.img &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isImg=true')) && (
              <TabPane tabId="1">
                <Slider
                  //  asNavFor={nav4} ref={slider3 => setNav3(slider3)}
                  arrows={false}
                >
                  <div className="diamondDetailImageBox">
                    <MagnifierImage image={FILE_URLS.img.replace('***', props.item.vStnId)} />
                  </div>
                </Slider>
              </TabPane>
            )} */}
                    {/* <iframe
                    key={Math.random()}
                    src={`${FILE_URLS.certFile.replace('***', props.item.rptNo)}`}
                    height="100%"
                    width="100%"
                    title="certi"
                    scrolling="no"
                    // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    // allowfullscreen
                    frameBorder="0"
                  /> */}
                  </div>
                </TabPane>
              )}
            {props.item.arrowFile && props.item.shpNm === 'ROUND' &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)) && (
                <TabPane tabId="5">
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.arrowFile.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.arrowFile.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                </TabPane>
              )}
            {props.item.assetFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)) && (
                <TabPane tabId="6">
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.assetFile.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.assetFile.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                </TabPane>
              )}
            {props.item.fluorescenceFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)) && (
                < TabPane tabId="7">
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.fluorescenceFile.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.fluorescenceFile.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                </TabPane>
              )}
            {props.item.blackBackgroundFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)) && (
                <TabPane tabId="8">
                  <div className="diamondDetailImageBox">
                    {isMobile() ? (
                      <ImageWithView src={FILE_URLS.blackBackgroundFile.replace('***', props.item.vStnId)} />
                    ) : (
                      <MagnifierImage image={FILE_URLS.blackBackgroundFile.replace('***', props.item.vStnId)} />
                    )}
                  </div>
                </TabPane>
              )}
          </TabContent >
        ) : (
          ''
        )
      }
      <hr />
      {localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.length > 1 ? (
        <>
          <Nav tabs className="DNAdiamondDetailTopTab">
            {props.item.img &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isImg=true')) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => {
                      toggle('1');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {image ? <img style={{
                          width: '100%', height: '100%', border: '1px solid #999', borderRadius: '4px'
                        }} src={img} /> :

                          <img src={FILE_URLS.img.replace('***', props.item.vStnId)} onError={() => setImage(true)} />
                        }
                      </div>
                      <IntlMessages id="app.Image" />
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.videoFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
                props.shared.find((c) => c === 'isVideo=true')) && (
                <NavItem >
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => {
                      toggle('2');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {(video || image) ? <img src={img} /> :
                          <img src={FILE_URLS.img.replace('***', props.item.vStnId)} />
                        }
                      </div>
                      <IntlMessages id="app.Video" />
                    </div>
                  </NavLink>
                  {activeTab === '2' &&
                    props.item.videoFile &&
                    (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
                      props.shared.find((c) => c === 'isVideo=true')) && (
                      <div className="copy-icon">
                        <Tooltip placement="bottom" title={copyText}>

                          <Icon
                            type="copy"
                            onClick={() => copyURL(FILE_URLS.videoFile.replace('***', props.item.vStnId))}
                          />
                        </Tooltip>
                      </div>
                    )}
                </NavItem>
              )}
            {/* {props.item.img &&
            (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
              props.shared.find((c) => c === "isImg=true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  <IntlMessages id="app.Image" />
                </NavLink>
              </NavItem>
            )} */}

            {props.item.certFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isCert=true')) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => {
                      toggle('4');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {certImg ? <img src={img} /> :

                          <img src={FILE_URLS.certImg.replace('***', props.item.rptNo)} onError={() => setCertImg(true)} />
                        }
                      </div>
                      <IntlMessages id="app.Certificate" />
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.hAFile && props.item.shpNm === 'ROUND' &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'isHa=true')) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => {
                      toggle('3');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {hAFile ? <img src={img} /> :
                          <img src={FILE_URLS.hAFile.replace('***', props.item.vStnId)} onError={() => setHAFile(true)} />
                        }
                      </div>
                      Heart
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.arrowFile && props.item.shpNm === 'ROUND' &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
                props.shared.find((c) => c === 'isArrow=true')) && (
                < NavItem >
                  <NavLink
                    className={classnames({ active: activeTab === '5' })}
                    onClick={() => {
                      toggle('5');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {arrowFile ? <img src={img} /> :

                          <img src={FILE_URLS.arrowFile.replace('***', props.item.vStnId)} onError={() => setArrowFile(true)} />
                        }
                      </div>
                      <IntlMessages id="app.Arrow" />
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.assetFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) ||
                props.shared.find((c) => c === 'isAsset=true')) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => {
                      toggle('6');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {assetFile ? <img src={img} /> :

                          <img src={FILE_URLS.assetFile.replace('***', props.item.vStnId)} onError={() => setAssetFile(true)} />
                        }
                      </div>
                      <IntlMessages id="app.Asset" />
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.fluorescenceFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) || props.shared.find((c) => c === 'flsImage=true')) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => {
                      toggle('7');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage'>
                        {fluorescenceFile ? <img src={img} /> :

                          <img src={FILE_URLS.fluorescenceFile.replace('***', props.item.vStnId)} onError={() => { setFluorescenceFile(true) }} />
                        }
                      </div>
                      <IntlMessages id="app.compare.flo" />
                    </div>
                  </NavLink>
                </NavItem>
              )}
            {props.item.blackBackgroundFile &&
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`)) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '8' })}
                    onClick={() => {
                      toggle('8');
                    }}
                  >
                    <div className='DNAdiamondDetailTabText'>
                      <div className='DNAdiamondDetailTabImage' >
                        {blackBackgroundFile ? <img src={img} /> :
                          <img src={FILE_URLS.blackBackgroundFile.replace('***', props.item.vStnId)} onError={() => { setBlackBackgroundFile(true) }} />}


                      </div>
                      <div className='DNAdiamondDetailTabTextBlackBackground'>
                        <IntlMessages id="app.blackBackground" />
                      </div>
                    </div>
                  </NavLink>
                </NavItem>
              )}
          </Nav>
        </>
      ) : (
        ''
      )
      }
      <div style={{ minHight: '10px', hight: '10px' }}></div>
    </>
  );
};

export default withRouter(DiamondDetailLeft);
