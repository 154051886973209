import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useTable } from 'react-table';
import { Drawer, Tooltip } from 'antd';
import DiamondDetail from '../DiamondDetail';
import DiamondListingHead from './ListTableHeaderBack';
import useRowSelect from './../../util/useRowSelect';
import useFilters from './../../util/useFilters';
import useSortBy from './../../util/useSortBy';
import CheckBox from '../common/CheckBox';
import Status from '../common/DiamondListing/Status';
import { getPath, LISTINGPAGES, getColumn } from './DiamondListFunctions';
import TableGrouping from '../common/DiamondListing/TableGrouping';
import { LAB_LINKS, FILE_URLS, LOCAL_STORAGE_VAR } from '../../constants/Common';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import { groupBy } from 'lodash';
import { isArray, objectToQueryString } from 'util/utils';
import { isEmpty } from 'util/utils';
import { useFixStickyLayout } from './useStickyFixLayout';
import { useSticky } from 'react-table-sticky';

import imageSource from 'assets/svg/camera.svg';
import videoSource from 'assets/svg/video.svg';
import certiSource from 'assets/svg/certi.svg';
import { RowSelectService } from 'services/RowSelectService';

// const Actions = React.memo((props) => {
//   const {
//     areAllChecked,
//     bidStatus,
//     bidStatusTooltip,
//     currentType,
//     disabled,
//     displayedRows,
//     isHeader,
//     noCheckBox,
//     nostatus,
//     row = {},
//   } = props;

//   console.log(props);

//   const { isRowChecked, isHeaderChecked } = useRowSelect(currentType);

//   useEffect(() => {
//     if (areAllChecked || row.isDefaultChecked) {
//       RowSelectService.selectRows(currentType, row);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [props.currentType === "ConfirmStonePopup"]);

//   const isChecked = isHeader ? isHeaderChecked(displayedRows) : isRowChecked(row.selectionKey ?? row?.id);

//   const handleChange = React.useCallback(() => {
//     const list = isHeader ? displayedRows : row;
//     isChecked
//       ? RowSelectService.unSelectRows(currentType, list, isHeader)
//       : RowSelectService.selectRows(currentType, list, isHeader);
//   }, [currentType, displayedRows, isChecked, isHeader, row]);

//   return (
//     <div className="selectActionIcon">
//       {nostatus && (
//         <Status
//           status={isHeader ? 'all' : row.wSts}
//           bidStatus={getPath() === LISTINGPAGES.BID ? bidStatus : null}
//           winloss={getPath() === LISTINGPAGES.MYBID ? row.status : null}
//           bidStatusTooltip={getPath() === LISTINGPAGES.BID ? bidStatusTooltip : null}
//         />
//       )}
//       {!noCheckBox && !disabled && (
//         <div className="selectActionIconWrapper">
//           <CheckBox
//             key={`selection_${isHeader ? 'header' : row.id}`}
//             checked={isChecked}
//             onChange={handleChange}
//             disabled={disabled}
//           />
//         </div>
//       )}
//     </div>
//   );
// });

const Actions = React.memo((props) => {
  const {
    areAllChecked,
    currentType,
    isHeader,
    displayedRows,
    row = {},
    noCheckBox,
    disabled,
    nostatus,
    bidStatus,
    bidStatusTooltip,
  } = props;

  const { isRowChecked, isHeaderChecked } = useRowSelect(currentType);

  const [isCheckedFirstTime, setIsCheckedFirstTime] = useState(false); // Local state to track the first time selection

  const isChecked = isHeader ? isHeaderChecked(displayedRows) : isRowChecked(row.selectionKey ?? row?.id);

  const handleChange = useCallback(() => {
    const counterKey = "confirmStonePopupCounter";
  
    // Read the current counter value from localStorage
    let currentCounter = parseInt(localStorage.getItem(counterKey)) || 0;
  
    // Increment the counter
    currentCounter++;
  
    // Store the updated counter value in localStorage
    localStorage.setItem("confirmStonePopupCounter", currentCounter.toString());
    const list = isHeader ? displayedRows : [row]; // Pass the clicked row as an array
    if (isChecked) {
      RowSelectService.unSelectRows(currentType, list, isHeader);
    } else {
      RowSelectService.selectRows(currentType, list, isHeader);
    }
  }, [currentType, displayedRows, isChecked, isHeader, row]);

  useEffect(() => {
    if (areAllChecked || row.isDefaultChecked) {
      RowSelectService.selectRows(currentType, row);
    }
  }, [isHeader]);

  return (
    <div className="selectActionIcon">
      {!nostatus && (
        <Status
          status={isHeader ? 'all' : row.wSts}
          bidStatus={getPath() === LISTINGPAGES.BID ? bidStatus : null}
          winloss={getPath() === LISTINGPAGES.MYBID ? row.status : null}
          bidStatusTooltip={getPath() === LISTINGPAGES.BID ? bidStatusTooltip : null}
        />
      )}
      {!noCheckBox && !disabled && (
        <div className="selectActionIconWrapper">
          <CheckBox
            key={`selection_${isHeader ? 'header' : row.id}`}
            checked={isChecked}
            onChange={handleChange}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
});
Actions.displayName = 'Actions';

export const canSortCommon = {
  stoneId: 'Stone No',
  shpNm: 'Shape',
  crt: 'Carat',
  colNm: 'Color',
  clrNm: 'Clarity',
};
export const LIMIT = 250;
export const FILTER_COLUMNS = [
  'colNm',
  'fluNm',
  'shpNm',
  'lbNm',
  'clrNm',
  'locNm',
  'shdNm',
  'cutNm',
  'polNm',
  'symNm',
  'brlncyNm',
  'blkTblNm',
  'blkSdNm',
  'wSdNm',
  'wTblNm',
  'opCrwnNm',
  'opPavNm',
  'opTblNm',
  'eClnNm',
  'girdleStr',
  'cultNm',
  'hANm',
];
export const upcomingkeys = ['rap', 'ctPr', 'back', 'amt'];
export const floatkeys = [
  'depPer',
  'ratio',
  'crt',
  'rapAvg',
  'pAng',
  'pHgt',
  'cHgt',
  'back',
  'cAng',
  'fnDis',
  'height',
  'width',
  'length',
  'grdlPer',
  'strLn',
  'tblPer',
  'lwrHal',
  'ctPr',
  'amt',
];
const NOCHECKIDS = [
  'Details',
  'Action',
  'quote',
  'expBack',
  'hours',
  'bidPricePerCarat',
  'note',
  'finalquote',
  'offerDis',
  'bidDiscount',
  'bidAmount',
];
export const roundkeys = ['amt', 'rap'];
export const DISPLAY_TOTAL = ['ctPr', 'amt', 'rap', 'crt', 'back', 'fnCtpr', 'fnAmt'];
const ALT_KEYS = ['lbNm', 'crt', 'colNm', 'clrNm', 'cutNm', 'polNm', 'symNm', 'back', 'rap', 'fluNm'];

const getStyles = (props, item, type) => {
  return [
    props,
    {
      style: {
        textAlign: item.cellClass ? item.cellClass.replace('text-', '') : 'center',
        width: (item.width || '100') + 'px',
        // fontWeight:
        //   type === 'cell' &&
        //   (item.id === 'stoneId' ||
        //     item.id === 'vStnId' ||
        //     item.id === 'colNm' ||
        //     item.id === 'shpNm' ||
        //     item.id === 'crt' ||
        //     item.id === 'amt' ||
        //     item.id === 'clrNm' ||
        //     item.id === 'shdNm' ||
        //     item.id === 'rap' ||
        //     item.id === 'back' ||
        //     item.id === 'ctPr' ||
        //     item.id === 'cutNm' ||
        //     item.id === 'polNm' ||
        //     item.id === 'symNm' ||
        //     item.id === 'brlncyNm' ||
        //     item.id === 'fluNm')
        //     ? '60'
        //     : '',
        color: type === 'cell' && (item.id === 'rptNo' || item.id === 'dna' || item.link) ? '#008cba' : '',
        // title: type === 'cell' && (item.id === 'rptNo' || item.id === 'dna' || item.link) ? '#008cba' : '',
      },
    },
  ];
};

const headerProps = (props, { column }) => getStyles(props, column, 'header');
const cellProps = (props, { cell }) => getStyles(props, cell.column, 'cell');

const Table = (props) => {
  const {
    data = [],
    columns = false,
    handleSort = () => {},
    //statuses
    nocheck = false,
    nostatus = false,
    noCheckBox = false,
    loading = false,
    nodots = false,
    noGrp,
    currentType,
    areAllChecked = false,
    FilterOption = true,
    canSort = true,
    bidStatus = '',
    bidStatusTooltip = '',
    groupKey = 'groupNo',
    inputBox = false,
  } = props;

  const [visible, setVisible] = useState(false);
  const [detail, setDetail] = useState(null);
  const [expanded] = useState([]);

  const finalColumns = useMemo(() => {
    const freezColumns = [
      'showSeqNo',
      'status',
      'Details',
      'vStnId',
      'shpNm',
      'crt',
      'colNm',
      'clrNm',
      'cutNm',
      'polNm',
      'symNm',
      'fluNm',
    ];

    const primaryColumns = getColumn(props.page) || [];
    if (getPath() !== LISTINGPAGES.MATCHPAIR) primaryColumns.map((col) => ({ ...col, canSort: !!col.sort }));
    let allColumns = columns || primaryColumns;
    if (isEmpty(allColumns)) return [];
    allColumns = allColumns.map((col) => ({
      ...col,
      sticky: freezColumns.includes(col.accessor) ? 'left' : undefined,
    }));
    return nocheck
      ? allColumns
      : [
          {
            id: 'selection',
            Header: ({ rows }) => {
              const originalRows = rows.map((row) => row.original);
              return (
                <Actions
                  displayedRows={originalRows}
                  nostatus={nostatus}
                  currentType={currentType}
                  areAllChecked={areAllChecked}
                  bidStatus={bidStatus}
                  bidStatusTooltip={bidStatusTooltip}
                  noCheckBox={noCheckBox}
                  isHeader
                />
              );
            },
            width: 50,
            Cell: ({ row }) => (
              <Actions
                row={row.original}
                disabled={getPath() === LISTINGPAGES.MATCHPAIR}
                nostatus={nostatus}
                currentType={currentType}
                areAllChecked={areAllChecked}
                bidStatus={bidStatus}
                bidStatusTooltip={bidStatusTooltip}
                noCheckBox={noCheckBox}
              />
            ),
          },
          ...allColumns,
        ];
  }, [props.page, columns, nocheck, nostatus, currentType, areAllChecked, bidStatus, bidStatusTooltip, noCheckBox]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state: { sortBy, filters },
    headerGroups,
    rows,
    setSortBy,
    toggleSortBy,
  } = useTable(
    {
      columns: finalColumns,
      data,
      manualSortBy: true,
      isMultiSortEvent: () => {},
      disableSortBy: !canSort,
    },
    useFilters,
    useSortBy,
    useSticky,
    useFixStickyLayout,
  );

  useEffect(() => {
    const sortArr = sortBy.map((sort) => ({
      [sort.id]: sort.desc ? 'DESC' : 'ASC',
    }));
    handleSort(sortArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  //common functions
  const clickHandler = useCallback(
    (e, row, cell) => {
      if (cell.column.id === 'selection') return;
      if (cell.column.link && cell.value) {
        let field = cell.column.link.slice(cell.column.link.indexOf('$') + 1, cell.column.link.length);
        field = field.slice(0, field.indexOf('$'));
        const link = cell.column.link.replace('$' + field + '$', row.original[field]);
        window.open(link);
      } else if (cell.column.id === 'dna') {
        window.open(`/${LISTINGPAGES.DETAIL}/${row.original.id}`);
      } else if ((cell.column.id === 'lbNm' || cell.column.id === 'rptNo') && row.original.lbNm && row.original.rptNo) {
        window.open(LAB_LINKS[row.original.lbNm.toUpperCase()].replace('***', row.original.rptNo));
      } else if (!NOCHECKIDS.includes(cell.column.id)) {
        if (props.nocheck || props.noCheckBox) return;
        const parentNode = e.currentTarget.parentNode;
        if (parentNode) {
          const checkboxRef = parentNode.getElementsByTagName('input');
          if (checkboxRef && checkboxRef.length > 0) {
            checkboxRef[0].click();
          }
        }
      }
    },
    [props.noCheckBox, props.nocheck],
  );

  const grouppedRows = React.useMemo(() => {
    return groupBy(
      rows.map((row) => row.original),
      groupKey,
    );
  }, [groupKey, rows]);

  // React.useEffect(() => {
  //   RowSelectService.resetSelectedRows(currentType);
  // }, [rows, currentType]);

  if (loading) return <div className={loading ? 'loading-indicator' : ''} />;
  
  return (
    <table {...getTableProps({ className: `${currentType}-table sticky` })}>
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr key={index} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} {...column.getHeaderProps(headerProps)}>
                {column.id === 'selection'
                  ? column.render('Header')
                  : column.id !== 'action' && (
                      <DiamondListingHead
                        toggleSortBy={toggleSortBy}
                        setSortBy={setSortBy}
                        sortBy={sortBy}
                        column={column}
                        filters={filters}
                        nodots={nodots}
                        FilterOption={FilterOption}
                      />
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {isArray(rows) &&
          rows.map((row) => {
            prepareRow(row);

            return (
              <>
                {!noGrp && (
                  <>
                    {row.original.isMatchHeader && (
                      <TableGrouping
                        displayTotal={DISPLAY_TOTAL.filter((el) => finalColumns.find((c) => c.id == el))}
                        multipleCheckBox
                        row={row.original}
                        col={row}
                      >
                        <Actions displayedRows={grouppedRows?.[row.original.groupNo]} nostatus isHeader />
                      </TableGrouping>
                    )}
                    {(row.original.isConfirmHeader || row.original.isBidHeader || row.original.isOfferHeader) && (
                      <TableGrouping row={row.original} />
                    )}
                    {row.original.isOfficeHeader && <TableGrouping row={row.original} />}
                    {row.original.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <TableGrouping row={row.original} />}
                  </>
                )}
                <tr
                  className={row.original.lbNm !== 'GIA' ? row.original.lbNm : ''}
                  {...row.getRowProps({ 'table-row': currentType + row.original.id })}
                >
                  {row.cells.map((cell) => (
                    <MemoizedCell
                      key={cell.column.id + '_cell'}
                      cell={cell}
                      clickHandler={clickHandler}
                      row={row}
                      setDetail={setDetail}
                      grouppedRows={grouppedRows}
                      expandedList={expanded}
                      data={data}
                      inputBox={inputBox}
                    />
                  ))}
                </tr>
                {!noGrp && row.original.isMatchFooter && (
                  <TableGrouping
                    displayTotal={DISPLAY_TOTAL.filter((el) => finalColumns.find((c) => c.id == el))}
                    columns={finalColumns}
                    row={row.original}
                    col={row}
                  />
                )}
              </>
            );
          })}
      </tbody>
      <Drawer onClose={() => setVisible(false)} visible={visible} wrapClassName="diamondDetailPopup" destroyOnClose>
        {visible && <DiamondDetail diamondPopup={true} data={visible} onClose={() => setVisible(false)} />}
      </Drawer>
      {detail && (
        <Drawer onClose={() => setDetail(null)} visible={detail} wrapClassName="diamondDetailPopup" destroyOnClose>
          {detail.detail.i && <img src={FILE_URLS.img.replace('***', detail.detail.i.vStnId)} />}
          {detail.detail.v && (
            <iframe src={FILE_URLS.videoFile.replace('***', detail.detail.v.vStnId)} height="100%" width="100%" />
          )}
          {detail.detail.c && (
            <iframe src={FILE_URLS.certFile.replace('***', detail.detail.c.rptNo)} height="100%" width="100%" />
          )}
        </Drawer>
      )}
    </table>
  );
};

function getMap(x, key) {
  return !x
    ? '-'
    : floatkeys.includes(key)
    ? formatNumber(parseFloat(x).toFixed(2))
    : roundkeys.includes(key)
    ? formatNumber(Math.round(x))
    : x;
}

const MemoizedCell = React.memo(({ cell, clickHandler, row, setDetail, grouppedRows, expandedList = [] }) => {
  const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
  const rowSpan = React.useMemo(() => {
    if (window.location?.pathname.includes('match-pair')) {
      const { id, groupNo } = { ...row?.original };
      const rows = grouppedRows?.[groupNo];
      if (rows?.length === 2) {
        const expList = rows.filter(
          (row) => row?.id !== rows?.[rows.length - 1]?.id && expandedList && expandedList.includes(row?.id),
        );
        if (isEmpty(expList)) return 1;
        if (rows?.[0]?.id === id) return 2;
        return 0;
      }
    }
    return 1;
  }, [expandedList, grouppedRows, row?.original]);

  if (rowSpan === 0) return null;

  if (cell.column.id === 'cutNm' && cell.value === 'NON' && cell.value !== 'ROUND') {
    return (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        -
      </td>
    );
  }
  if (cell.column.id === 'back' || cell.column.id === 'trackDiscount') {
    localStorage.setItem('fancyDiamond', row.original.isFcCol);
    return !row.original.isFcCol && !isGuest && cell.value ? (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {row.original.ctPr < row.original.rap
          ? `${cell.row.original.wSts !== 'U' ? '-' + parseFloat(Math.abs(cell.value)).toFixed(2) : '-'}`
          : cell.value === 0
          ? Math.abs(cell.value)
          : `${cell.row.original.wSts !== 'U' ? '+' + parseFloat(Math.abs(cell.value)).toFixed(2) : '-'}`}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        -
      </td>
    );
  }
  if (cell.column.id === 'rap') {
    return !row.original.isFcCol && !isGuest ? (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {cell.row.original.wSts !== 'U' ? cell.value : '-'}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        -
      </td>
    );
  }
  if (cell.column.id === 'ctPr') {
    return !isGuest ? (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {cell.row.original.wSts !== 'U' ? parseFloat(cell.value).toFixed(2) : '-'}
        {/* {inputBox && (
            <div className="editCell">
              <input type="text" />
            </div>
          )} */}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        -
      </td>
    );
  }
  if (cell.column.id === 'amt') {
    return !isGuest ? (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {cell.row.original.wSts !== 'U' ? parseFloat(cell.value).toFixed(2) : '-'}
        {/* {inputBox && (
            <div className="editCell">
              <input type="text" />
            </div>
          )} */}
      </td>
    ) : (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        -
      </td>
    );
  }
  if (cell.column.id === 'newDiscount') {
    return (
      <td {...cell.getCellProps(cellProps)} onClick={(e) => clickHandler(e, row, cell)}>
        {cell.value < 0
          ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
          : cell.value === 0
          ? Math.abs(cell.value)
          : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`}
      </td>
    );
  }
  if (cell.column.id === 'lbNm') {
    return (
      <td
        {...cell.getCellProps(cellProps)}
        className={cell.value === 'GIA' ? 'gia-cer-color' : cell.value === 'IGI' ? 'ige-cer-color' : 'other-cer-color'}
        onClick={(e) => clickHandler(e, row, cell)}
      >
        {cell.value}
      </td>
    );
  }

  return (
    <td
      className={`${cell.row.original.wSts === 'U' && 'upcoming-row-data'}`}
      {...cell.getCellProps(cellProps)}
      // title={cell.column.id !== 'colNm' && cell.value}
      rowSpan={rowSpan}
      onClick={(e) => clickHandler(e, row, cell)}
    >
      {cell.column.id !== 'Details' && (
        <Tooltip title={cell.column.id !== 'colNm' && cell.column.id !== 'notes' && cell.value}>
          <div>
            {row.original.altCert && ALT_KEYS.includes(cell.column.id) ? (
              <span style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                <span style={{ width: '100%' }}>{cell.render('Cell')}</span>
                <span
                  style={{ width: '100%' }}
                  className={floatkeys.includes(cell.column.id) || roundkeys.includes(cell.column.id) ? '' : null}
                >
                  {getMap(row.original.altCert[cell.column.id], cell.column.id)}
                </span>
              </span>
            ) : (
              cell.render('Cell')
            )}
          </div>
        </Tooltip>
      )}
      {cell.column.id === 'Details' && (
        <Resource original={row.original} setDetail={setDetail} grouppedRows={grouppedRows} />
      )}
    </td>
  );
});

const Resource = React.memo(({ original, setDetail }) => {
  const { img } = original;
  const detailKey = '';

  const requestParams = {
    ...([LISTINGPAGES.MYDEALOFDAYLIST, LISTINGPAGES.DEALOFDAYLIST].includes(getPath()) ? { isDealOfTheDay: true } : {}),
  };

  return (
    <>
      <img
        style={{ marginRight: '5px' }}
        src={imageSource}
        width="15px"
        alt=""
        onClick={() => {
          getPath() === LISTINGPAGES.CONFIRM
            ? window.open(
                `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
                  ...requestParams,
                  isImg: true,
                  isConfirm: true,
                })}`,
              )
            : window.open(
                `/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({ ...requestParams, isImg: true })}`,
              );
        }}
      />
      <img
        style={{ marginRight: '5px' }}
        src={videoSource}
        width="15px"
        alt=""
        onClick={() => {
          getPath() === LISTINGPAGES.CONFIRM
            ? window.open(
                `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
                  ...requestParams,
                  isVideo: true,
                  isConfirm: true,
                })}`,
              )
            : window.open(
                `/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({
                  ...requestParams,
                  isVideo: true,
                })}`,
              );
        }}
      />
      <img
        style={{ marginRight: '5px' }}
        src={certiSource}
        width="15px"
        alt=""
        onClick={() => {
          getPath() === LISTINGPAGES.CONFIRM
            ? window.open(
                `/${LISTINGPAGES.DETAIL}/${original.diamondId}${objectToQueryString({
                  ...requestParams,
                  isCert: true,
                  isConfirm: true,
                })}`,
              )
            : window.open(
                `/${LISTINGPAGES.DETAIL}/${original.id}${objectToQueryString({
                  ...requestParams,
                  isCert: true,
                })}`,
              );
        }}
      />
    </>
  );
});

export default React.memo(Table);
