/**
 * NOTE: DO NOT CHANGE THIS FILE WITHOUT PERMISSION
 */

import { DateTime, Duration } from 'luxon';
import { forOwn, cloneDeep } from 'lodash';

import { LOCALE, CURRENCY } from 'constants/Common';
import { REGEX, FRACTION_LENGTH } from 'constants/Common';
import { TIMEZONE_IANA, LUXON_FORMAT } from 'constants/Common';

export const typeOf = (input, type) => input?.constructor?.name === type ?? null;

export const isArray = (input) => typeOf(input, 'Array');

export const isObject = (input) => typeOf(input, 'Object');

export const isBoolean = (input) => typeOf(input, 'Boolean');

export const isString = (input) => typeOf(input, 'String');

export const isNumber = (input) => typeOf(input, 'Number') && !Number.isNaN(input) && Number.isFinite(input);

export const isNumeric = (input, strict = false) =>
  new RegExp(strict ? REGEX.NUMERIC.STRICT : REGEX.NUMERIC.LOOSE).test(input);

export const isAlphaNumeric = (input) => new RegExp(REGEX.ALPHA_NUMERIC).test(input);

export const isFunction = (input) => typeOf(input, 'Function');

export const callFunction = (func, ...args) => isFunction(func) && func(...args);

export const forEach = (instance, callback) => Array.prototype.forEach.call(instance, callback);

export const isHTMLElement = (input) => input instanceof HTMLElement;

export const isEmpty = (input, except = []) => {
  if (except?.includes?.(input)) return false;
  const type = input?.constructor?.name;
  if ([undefined, null].includes(input)) return true;
  if (type === 'Array') return !input.length;
  if (type === 'Number') return Number.isNaN(input);
  if (type === 'Object') return !Object.keys(input).length;
  if (type === 'String') return !input.trim().length;
  return false;
};

export const isNotEmpty = (...args) => !isEmpty(...args);

export const pruneEmpty = (obj, keep = []) => {
  const prune = (current) => {
    forOwn(current, (value, key) => {
      if (isEmpty(value, keep) || ((isObject(value) || isArray(value)) && isEmpty(prune(value), keep)))
        delete current[key];
    });
    if (isArray(current)) current = current.filter(isNotEmpty);
    return current;
  };
  return prune(cloneDeep(obj));
};

export const returnIfNotEmpty = (value, replaceWith) => (isEmpty(value) ? replaceWith : value);

export const hasKey = (object, key) => isObject(object) && !isEmpty(object) && Object.keys(object).includes(key);

export const withDefaults = (object, defaults) => ({ ...defaults, ...object });

export const runInDevelopment = (callback) =>
  (isEmpty(process.env.REACT_APP_ENV) || process.env.REACT_APP_ENV === 'development') && callback();

export const logInfo = (...args) => runInDevelopment(() => console.info(...args)); // eslint-disable-line no-console
export const logWarn = (...args) => runInDevelopment(() => console.warn(...args)); // eslint-disable-line no-console
export const logTable = (...args) => runInDevelopment(() => console.table(...args)); // eslint-disable-line no-console

export const getCurrentTime = () => DateTime.local().setZone(TIMEZONE_IANA);

export const formatDateTime = (isoDate, format = LUXON_FORMAT.DATE_TIME) => {
  const dateTime = DateTime.fromISO(isoDate);
  return dateTime.isValid ? dateTime.toFormat(format) : undefined;
};

export const formatDate = (isoDate, format = LUXON_FORMAT.DATE) => formatDateTime(isoDate, format);

export const formatTime = (isoDate, format = LUXON_FORMAT.TIME) => formatDateTime(isoDate, format);

export const formatDuration = (duration, format = LUXON_FORMAT.DURATION) =>
  Duration.isDuration(duration) && duration?.isValid ? duration.toFormat(format) : undefined;

export const getDateTimeDiff = (startISO, endISO) => DateTime.fromISO(endISO).diff(DateTime.fromISO(startISO));

export const getFormattedDateTimeDiff = (startISO, endISO, format = LUXON_FORMAT.DURATION) =>
  formatDuration(getDateTimeDiff(startISO, endISO), format);

export const formatNumber = (input, options = {}) => {
  if (!isNumber(Number(input))) return input;
  const { locale, trimFractions, ...rest } = { locale: LOCALE, trimFractions: false, ...options };
  const fractionLength = trimFractions ? rest?.fractionLength : `${input}`.split('.')?.[1]?.length;
  const defaults = { maximumFractionDigits: fractionLength, minimumFractionDigits: fractionLength };
  return new Intl.NumberFormat(locale, { ...defaults, ...rest }).format(input);
};

export const formatCurrency = (number, options = {}) => {
  const { locale, ...rest } = { locale: LOCALE, currency: CURRENCY, ...options };
  return new Intl.NumberFormat(locale, { style: 'currency', ...rest }).format(number);
};

export const formatDecimal = (input, fractionLength = FRACTION_LENGTH) =>
  formatNumber(input, { trimFractions: true, fractionLength });

export const parseDecimal = (input, fractionLength = FRACTION_LENGTH) => {
  if (!isNumber(input)) return 0;
  return Number(parseFloat(input).toFixed(fractionLength));
};

export const percentDiff = (originalNumber, newNumber, fractionLength = FRACTION_LENGTH) => {
  return parseDecimal(100 * ((newNumber - originalNumber) / originalNumber), fractionLength);
};

export const formatFloat = (input, fractionLength = FRACTION_LENGTH) => parseFloat(input).toFixed(fractionLength);

export const formatFloatWithFiveDigits = (input, fractionLength = FRACTION_LENGTH) => parseFloat(input).toFixed(5);

export const formatInlineList = (value, options = {}) => {
  if (!isString(value)) return value;
  const { separator, allowAppend } = { separator: ',', allowAppend: false, ...options };
  const valueList = `${value}`.replace(/[\s,]+/gm, separator).split(separator);
  return valueList
    .filter((value, index) => !isEmpty(value) || (allowAppend && index && valueList?.length === index + 1))
    .join(separator);
};

export const sortEntriesByKey = (desc = false) => {
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[0] < next?.[0] ? n.less : curr?.[0] > next?.[0] ? n.more : 0);
};

export const sortArrayByKey = (key = 'id', desc = false) => {
  if (!isString(key)) return undefined;
  const n = { less: desc ? 1 : -1, more: desc ? -1 : 1 };
  return (curr, next) => (curr?.[key] < next?.[key] ? n.less : curr?.[key] > next?.[key] ? n.more : 0);
};

export const padArray = (list, length, fillWith) => {
  return list.concat(Array(length).fill(fillWith)).slice(0, length);
};

export const reduceTotal = (list, key) => {
  if (!isArray(list) || isEmpty(list)) return 0;
  const numList = key === undefined ? list.map(Number) : list.map((item) => Number(item?.[key]));
  return numList.filter(isNumber).reduce((pv, cv) => (pv += cv), 0);
};

export const classNames = (list) => list.filter(isString).join(' ');

export const upperFirst = (input, locale = LOCALE) =>
  isString(input) ? input.replace(/(^[a-z])/, (match) => match.toLocaleUpperCase(locale)) : input;

export const lowerFirst = (input, locale = LOCALE) =>
  isString(input) ? input.replace(/(^[a-z])/, (match) => match.toLocaleLowerCase(locale)) : input;

export const upperCase = (input, locale = LOCALE) => (isString(input) ? input.toLocaleUpperCase(locale) : input);

export const lowerCase = (input, locale = LOCALE) => (isString(input) ? input.toLocaleLowerCase(locale) : input);

export const titleCase = (input, locale = LOCALE) =>
  isString(input)
    ? input
        .split(/([ :–—-])/)
        .map((current, index, list) => {
          return (
            // Check for small words
            current.search(/^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i) > -1 &&
              // Skip first and last word
              index !== 0 &&
              index !== list.length - 1 &&
              // Ignore title end and subtitle start
              list[index - 3] !== ':' &&
              list[index + 1] !== ':' &&
              // Ignore small words that start a hyphenated phrase
              (list[index + 1] !== '-' || (list[index - 1] === '-' && list[index + 1] === '-'))
              ? current.toLocaleLowerCase(locale)
              : current.substr(1).search(/[A-Z]|\../) > -1 // Ignore intentional capitalization
              ? current
              : list[index + 1] === ':' && list[index + 2] !== '' // Ignore URLs
              ? current
              : current.replace(/([A-Za-z0-9\u00C0-\u00FF])/, (match) => match.toLocaleUpperCase(locale)) // Capitalize the first letter
          );
        })
        .join('')
    : input;

export const objectToQueryString = (object) => {
  try {
    return `?${Object.keys(object)
      .map((key) => `${key}=${object?.[key]?.toString ? object[key].toString() : ''}`)
      .join('&')}`;
  } catch (error) {
    return '';
  }
};

export const queryStringToObject = (location = window.location) => {
  try {
    return !isEmpty(location.search)
      ? JSON.parse(
          `{"${decodeURI(location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        )
      : {};
  } catch (error) {
    return {};
  }
};

export const comparePathname = (pathnames) => {
  pathnames = isArray(pathnames) ? pathnames : [pathnames];
  return pathnames.includes(window?.location?.pathname?.split?.('/')?.pop?.());
};
