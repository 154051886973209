import { Action } from 'services/Action';
import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { hasKey, isArray, isString, logWarn, runInDevelopment } from 'util/utils';

export class Storage {
  static prefix = `${LOCAL_STORAGE_VAR}-`;
  static action = new Action('storage');

  /**
   * Store
   * @param {String} key
   * @param {*} value
   */
  static set(key, value) {
    key = `${this.prefix}${key}`;
    try {
      const output = localStorage.setItem(key, btoa(JSON.stringify(value))) === undefined;
      this.action.emit({ [key]: value });
      return output;
    } catch (error) {
      logWarn(error);
      return null;
    }
  }

  /**
   * Retrieve
   * @param {String} key
   */
  static get(key) {
    key = `${this.prefix}${key}`;
    try {
      return JSON.parse(atob(localStorage.getItem(key)));
    } catch (error) {
      logWarn(error);
      return null;
    }
  }

  /**
   * Remove values by key
   * @param {String|Array} keys String or Array of string
   */
  static delete(keys) {
    keys = isArray(keys) ? keys : isString(keys) ? [keys] : [];
    keys.forEach((key) => {
      key = `${this.prefix}${key}`;

      try {
        const deleted = localStorage.removeItem(key);
        this.action.emit({ [key]: deleted });
        return deleted === undefined;
      } catch (error) {
        logWarn(error);
        return false;
      }
    });
  }

  static listen(key, callback) {
    const unlisten = this.action.listen((event) => {
      if (!hasKey(event?.detail, key)) return;
      callback({ [key]: event?.detail?.[key] });
    });
    return unlisten;
  }
}

runInDevelopment(() => void (window.__Storage = Storage));
