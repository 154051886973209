import React from 'react';
import 'components/auth.less';

export const Captcha = React.memo(({ data = '' }) => {
  return (
    <div className="Captcha">
      <div className="randomChars">
        {data.split('').map((number, index) => (
          <div key={`${number}${index}`}>{number}</div>
        ))}
      </div>
    </div>
  );
});

Captcha.displayName = 'Captcha';
Captcha.generateCaptcha = () => Math.random().toString(10).substr(2, 4);
