import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import IntlMessages from '../../util/IntlMessages';
import Heading from '../common/Heading';
import { DOWNLOAD_CERTIFICATE, UPLOAD_FILE } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { BASE_DOWNLOAD_URL, BASE_URL, MIME_TYPES } from '../../constants/Common';
import _ from 'lodash';
import OpenNotification from '../common/CommonButton/OpenNotification';
import ProfileUpload from '../common/ProfileUpload';
const DownloadOption = (props) => {
  const [certType, setCertType] = useState(1);
  const [defaulttab, setDefaulttab] = useState(props.defaulttab);
  const [uploadPath, setUploadPath] = useState();
  const ShareOption = (props) => {
    return (
      <li className={props.activeClass}>
        <div className="dropdownBottomItem">
          <span onClick={(e) => handleOnClick(props.value)} className="shareOptionLabel">
            {props.title}
          </span>
        </div>
      </li>
    );
  };

  const downloadCertificate = () => {
    try {
      let obj = {
        ...DOWNLOAD_CERTIFICATE,
        request: {
          vStnId: _.last(_.split(window.location.pathname, '/')),
          certType: defaulttab + 1,
          logoUrl: certType === 3 ? uploadPath : '',
          withLogo: certType === 2 ? false : true,
        },
      };
      UtilService.callApi(obj, function (err, data) {
        if (err) {
        } else if (data && data.code === 'OK') {
          if (data.data) {
            let file_path = `${BASE_DOWNLOAD_URL}/${data.data}`;
            let xhr = new XMLHttpRequest();
            xhr.open('GET', file_path, true);
            xhr.responseType = 'blob';
            xhr.onload = function () {
              let urlCreator = window.URL || window.webkitURL;
              let pdfURL = urlCreator.createObjectURL(this.response);
              let tag = document.createElement('a');
              tag.href = pdfURL;
              tag.download = obj.request.vStnId;
              document.body.appendChild(tag);
              tag.click();
              document.body.removeChild(tag);
            };
            xhr.send();
          }
        }
      });
    } catch (e) {}
  };
  const handleDownload = () => {
    downloadCertificate();
  };
  const handleOnClick = (value) => {
    setCertType(value);
  };
  const handlesecondtab = () => {
    setDefaulttab(1);
  };
  const handlefirsttab = () => {
    setDefaulttab(0);
  };

  const uploadDocument = (ee, type) => {
    const e = _.cloneDeep(ee);
    let self = this;
    let formData = '';

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'user');
        data.append('file', e.target.files[0]);

        let objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === 'OK') {
            formData = data.data.files[0].absolutePath;
            //self.setState({ data: formData, errors });
            setUploadPath(formData);
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };
  const handleInputChange = (key, value) => {
    setUploadPath(value);
  };
  useEffect(() => {
    setDefaulttab(props.defaulttab);
  }, [props.defaulttab]);
  return (
    <>
      <div>
        <div className="searchPopupCommon">
          <div className="shareOptionSet ftcDownloadBtn d-flex justify-content-center">
            {/* <Heading title="Basic Certificate" className="text-center"/> */}
            <Tabs selectedIndex={defaulttab}>
              <TabList className="customerMaintab">
                <Tab onClick={handlefirsttab}>Advance Certificate</Tab>
                <Tab onClick={handlesecondtab}>Basic Certificate</Tab>
              </TabList>
              <TabPanel>
                <div className="shareOptionSetItem">
                  <ul>
                    <ShareOption value={1} activeClass={certType === 1 ? 'active' : ''} title="With Logo" />
                    <ShareOption value={2} activeClass={certType === 2 ? 'active' : ''} title="Without Logo" />
                    <ShareOption value={3} activeClass={certType === 3 ? 'active' : ''} title="Upload Your Logo" />
                  </ul>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="shareOptionSetItem">
                  <ul>
                    <ShareOption value={1} activeClass={certType === 1 ? 'active' : ''} title="With Logo" />
                    <ShareOption value={2} activeClass={certType === 2 ? 'active' : ''} title="Without Logo" />
                    <ShareOption value={3} activeClass={certType === 3 ? 'active' : ''} title="Upload Your Logo" />
                  </ul>
                </div>
              </TabPanel>
            </Tabs>
            {/* <Heading title="Advance Certificate" className="text-center mt-20"/> */}
          </div>
          {certType === 3 && (
            <div className="personalProfileWrapper j-content-center">
              <div className="editProfileLogo">
                <img alt="" src={_.isEmpty(uploadPath) ? '' : BASE_URL + '/' + uploadPath} />
              </div>
              <div className="profileAction">
                <ProfileUpload
                  label="Select Logo"
                  accept="jpg, png,jpeg,PNG,JPEG,JPG"
                  onChange={(e) => uploadDocument(e, 'certificateLogoImage')}
                />
                <a onClick={() => handleInputChange('certificateLogoImage', '')}>Remove Logo</a>
              </div>
            </div>
          )}
          <div className="commonModalButton mt-50">
            <button onClick={handleDownload} className="fillButton">
              <IntlMessages id="app.Download" />
            </button>
            <button onClick={props.handleCancelDownload} className="outLineButton ml-5">
              <IntlMessages id="app.Cancel" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default DownloadOption;
