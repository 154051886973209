import React, { Component } from 'react';
import Heading from '../Heading';
import { HeadingCalc } from './ConfirmStonePopup';
import { ListingCards } from './MobileComfirmStone';
import IntlMessages from '../../../util/IntlMessages';
import { getPath } from '../../../components/DiamondList/DiamondListFunctions';
import CommonModal from '../CommonModal';
import plusIcon from '../../../assets/img/plus.jpg';
import minusIcon from '../../../assets/img/minus.jpg';

class BIDStoneMobile extends Component {
  state = {
    isModalVisible: false,
  };

  handleModalVisibility = (visible) => {
    this.setState({ isModalVisible: visible });
  };
  render() {
    const dataa = this?.props?.checked
      ?.filter((data) =>
        ['HONGKONG', 'Hong Kong', 'Hongkong', 'hongkong', 'hong kong', 'HONG KONG'].includes(data?.countryNm),
      )
      .map(({ id, stoneId, countryNm, vStnId }) => ({
        id,
        stoneId,
        countryNm,
        vStnId,
      }));
    const ids = dataa?.map((obj) => `${obj?.vStnId}`).join(',');
    return (
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.bidStone" />}></Heading>
          </div>
          {HeadingCalc({ display: 'flex', width: '48%' }, this.props.VALUES)}
        </div>
        {this.props.disDiffShow && (
          <div style={{ display: 'flex' }}>
            <div className="d-flex align-items-center" style={{ flexDirection: 'column', marginLeft: 'auto' }}>
              <label htmlFor="disDiff" style={{ fontSize: '12px' }}>
                Dis. Diff.
              </label>
              <div>
                <img
                  src={minusIcon}
                  alt="Minus"
                  onClick={() => this.props.parent.handleDisDiffAction('minus')}
                  style={{ width: '20px', height: '20px', margin: 'auto 10px' }}
                />
                <input
                  type="number"
                  name="disDiff"
                  value={this.props.parent.state.disDiff}
                  onChange={(e) => this.props.parent.setDisDiff(e?.target?.value)}
                  style={{ maxWidth: '4rem', textAlign: 'center' }}
                  onKeyPress={(evt) => {
                    evt.which == 13 &&
                      this.props.parent.setState(
                        {
                          disDiff: this.props.parent.state.disDiff
                            ? this.props.parent.state.disDiff?.toFixed(2)
                            : this.props.parent.state.disDiff,
                        },
                        () => this.props.parent.handleDisDiffOnEnter(),
                      );
                  }}
                  max={this.props.parent?.bid?.sub_types?.downwardInterval}
                  min={this.props.parent?.bid?.sub_types?.upwardInterval * -1}
                />
                <img
                  src={plusIcon}
                  alt="Plus"
                  style={{ width: '20px', height: '20px', margin: 'auto 10px' }}
                  onClick={() => this.props.parent.handleDisDiffAction('plus')}
                />
              </div>
            </div>
          </div>
        )}
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards
              bidPopup={
                getPath() === 'deal-of-the-day' || getPath() === 'my-deal-of-the-day' ? false : this.props.parent
              }
              checked={this.props.checked}
              data={this.props.data}
              currentType={this.props.currentType}
              areAllChecked={this.props.areAllChecked}
            />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>
        {(getPath() === 'deal-of-the-day' || getPath() === 'my-deal-of-the-day') && (
          <div style={{ display: 'flex' }}>
            <span>{this.props.parent.getAvgDisc()}</span>
            <span>{this.props.parent.getPrCt()}</span>
            <span>{this.props.parent.getAmount()}</span>
          </div>
        )}
        <div className="sideBarPopupButton">
          <a
            className="commonButton"
            onClick={() => {
              if (ids) {
                this.handleModalVisibility(true);
              } else {
                this.props.parent.submit();
              }
            }}
          >
            <IntlMessages id="app.bidStone" />
          </a>
          <a className="commonButton" onClick={this.props.onClose}>
            <IntlMessages id="app.CancelBid" />
          </a>
        </div>

        <CommonModal
          visible={this.state.isModalVisible}
          onCancel={() => this.handleModalVisibility(false)}
          handleCancel={() => this.handleModalVisibility(false)}
          handleOk={() => {
            this.props.parent.submit();
          }}
          // handleOk={() => this.props.parent.submit()}
          modalSize="xs-size"
          footerShow
          title="Bid Stone"
          submitTitle={<IntlMessages id="app.bidStone" />}
          cancelTitle={<IntlMessages id="app.Cancel" />}
        >
          <div style={{ textAlign: 'center' }}>
            {`Packet No. ${ids} from your bid selection belong to HONGKONG Inventory, So they will be available for
            HONGKONG Delivery only. If Bid win amount of HONGKONG inventory is greater than $15000 it can ship directly
            without any charge apart from INDIA.`}
          </div>
        </CommonModal>
      </div>
    );
  }
}

export default BIDStoneMobile;
