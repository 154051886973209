import React, { useEffect, useState } from 'react';
import { GET_BANNERS } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { BASE_URL_ATTACH } from '../../constants/Common';

const AuthPoster = (props) => {
  const [poster, setPoster] = useState('');
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let obj = {
      ...GET_BANNERS,
      request: { page: 1, limit: 100, filter: { type: 7 } },
    };

    UtilService.callApi(obj, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        setPoster(data.data && data.data.list && data.data.list[0].images[0]);
        setLoading(false);
      }
    });
  }, []);
  return (
    <div className={`loginLeftBlock ${isLoading ? 'loading-indicator' : ''}`}>
      {poster &&
        (['mp4', 'mkv', 'wmv'].includes(poster.split('.').pop().toLowerCase()) ? (
          <video autoPlay loop muted>
            <source src={`${BASE_URL_ATTACH}${poster}`}></source>
          </video>
        ) : (
          <img
            className="main-desktop-img-cover"
            src={`${BASE_URL_ATTACH}${poster}`}
            // src={require('../../assets/img/desktop-login-banner.jpg')}
            alt="banner"
            height="100%"
            width="100%"
          />
        ))}

      {!isLoading && !poster && (
        <div className="loginLeftBlockInner">
          <div className="login_desc">
            <div className="">
              <img src={require('../../assets/img/logo.svg')} alt="banner" />
            </div>
          </div>
        </div>
      )}
      {/* <div className="loginLeftBlockInner">
        <div className="login_desc">
          <div className="">
            <img src={require("../../assets/img/logo.svg")} alt="" />
          </div>
          <h1 className="main-title">Make Every Moment Precious</h1>
          <p className="desc">
            Lorem ipsum, or lipsum as it is sometimes known, is dummy text used
            in laying out print, graphic or web designs. The passage is
            attributed to an unknown typesetter in the 15th century who is
            thought to have scrambled parts of Cicero's De Finibus Bonorum et
            Malorum for use in a type specimen book.
          </p>

          <div className="post_banner">
            <img src={require("../../assets/img/login/bg.jpg")} alt="" />
          </div>
        </div> */}
      {/* {!((window.location.pathname).includes('/device/')) ? */}
      {/* <div className="loginLeftWrapper"> */}
      {/* <div className="loginLeftOverlay"></div> */}
      {/* <div className="loginLeftContent"> */}
      {/* <h2>Unique Diamax Pvt Ltd Jewellery & Diamonds Pvt. Ltd.</h2> */}
      {/* <ScrollspyNav
                    scrollTargetIds={["section_1", "section_2", "section_3","section_4"]}
                    activeNavClass="is-active"
                  >
                  <ul className="tabList">
                    <li>
                      <a href="#section_1"><span>1</span>Login Information</a>
                    </li>
                    <li>
                      <a href="#section_2"><span>2</span>Contact Information</a>
                    </li>
                    <li>
                      <a href="#section_3"><span>3</span>Business Information</a>
                    </li>
                    <li>
                      <a href="#section_4"><span>4</span>Broker/Sales Information</a>
                    </li>
                  </ul>
                  </ScrollspyNav> */}
      {/* </div> */}
      {/* <div className="loginBasicList">
            <ul>
              <li>
                <a href="news">News & Event</a>
              </li>
              <li>
                <a href="/bidding-procedure">Bidding Procedure</a>
              </li>
              <li>
                <a href="Unique Diamax Pvt Ltd KYC FORM.xls" download>
                  Download KYC Form
                </a>
              </li>
              <li>
                <a href="/book-appointment">Take Appointment</a>
              </li>
              <li>
                <a href="/terms-condition">Terms</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
        </div> */}
      {/* : ''} */}
      {/* </div> */}
    </div>
  );
};
export default AuthPoster;
