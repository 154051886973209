import React from 'react';
import './default.less';

const UserProfileDefault = (props) => {
  return (
    <div className={`UserProfileDefault ${props.size}`}>
      <span>
        <img src={require('../../../assets/svg/Header/user-white.svg')} alt="" />
      </span>
    </div>
  );
};
export default UserProfileDefault;
