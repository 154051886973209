import React from 'react';
import Heading from '../common/Heading';
import { ListingCards } from '../common/DiamondListing/MobileComfirmStone';
import { emptyData } from '../DiamondList';

const BidListMobile = (props) => {
  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={props.parent.getTitle()} />
          </div>
          {props.parent.getSelection()}
        </div>
      </div>
      <div className="bidEndBlock">
        {props.parent.getBidBlock}
        <div>
          <div className="liveBidOption d-flex j-content-center align-items-center">
            {props.parent.getRadio}
            {props.parent.goBack}
          </div>
        </div>
      </div>
      <div className="searchInnerResult">
        <div className="diamondListMobile">
          <ListingCards
            {...props}
            bid={props.displayStatus}
            data={props.data}
            currentType={props.currentType}
            bidCartPopup={props.bidCartPopup}
            myBidList={props.myBidList}
            areAllChecked={props.areAllChecked}
          />
          {emptyData(props.loading, props.data?.length)}
        </div>
        {props.parent.getAction()}
      </div>
    </div>
  );
};

export default BidListMobile;
