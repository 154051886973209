import React from 'react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { GET_EXCLUSIVE_COLLECTION } from 'constants/apiConstant';
import { IS_QUICK_SEARCH } from 'constants/Common';
import { isEmpty } from 'util/utils';
import { callApi } from 'util/CallAPI';
import { Storage } from 'services/Storage';

import { commonLogout } from 'constants/Common';
import IntlMessages from 'util/IntlMessages';

import './sidebarHeader.less';

const SidebarHeader = () => {
  const [exclusiveList, setExclusiveList] = React.useState([]);

  const location = useLocation();
  const P = Storage.get('userPermissions');
  const { data: bidConfig, loading: bidConfigLoading } = useSelector((store) => store?.bidConfig);
  const { showData } = useSelector((store) => store?.auth);
  const fetchExclusiveCollection = React.useCallback(async () => {
    const [exclusiveCollectionError, exclusiveCollectionResponse] = await callApi(GET_EXCLUSIVE_COLLECTION);
    if (!exclusiveCollectionError) setExclusiveList(exclusiveCollectionResponse?.data?.list ?? []);
  }, []);

  React.useEffect(() => {
    fetchExclusiveCollection();
  }, [location.pathname, fetchExclusiveCollection]);

  return (
    <div className="sidebarHeader sidebarFixed">
      <div>
        <Link to="/dashboard" className="sidebarLogo text-center">
          <img width="50px" src={require('assets/img/sidebar_logo.png')} alt="Unique Diamax Pvt Ltds" />
        </Link>
        <div className="sidebarMenuList">
          <ul>
            {get(P, 'DASHBOARD.view', false) && (
              <li className={`${location.pathname === `/dashboard` && `active`}`}>
                <Link to="/dashboard">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/dashboard.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/dashboard-active.svg')} />
                  <span className="menu_link">Dashboard</span>
                </Link>
              </li>
            )}
            {get(P, 'SEARCH_DIAMOND.view', false) && (
              <li className={`${location.pathname === `/diamond-search` && `active`}`}>
                <Link to="/diamond-search">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/search.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/search-active.svg')} />
                  <span className="menu_link">Search</span>
                </Link>
              </li>
            )}
            {IS_QUICK_SEARCH && get(P, 'QUICK_SEARCH.view', false) && (
              <li className={`${location.pathname === `/quick-search` && `active`}`}>
                <Link to="/quick-search">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/quick-search.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/quick-search-active.svg')} />
                  <span className="menu_link">Quick Search</span>
                </Link>
              </li>
            )}
            {get(P, 'SEARCH_MATCH_PAIR.view', false) && (
              <li className={`${location.pathname === `/search-match-pair` && `active`}`}>
                <Link to="/search-match-pair">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/couple-stone.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/couple-stone-active.svg')} />
                  <span className="menu_link">Couple Stone</span>
                </Link>
              </li>
            )}
            {get(P, 'BID.view', false) && (
              <li
                className={`${(location.pathname === `/new-arrival-bid-it` && `active`) ||
                  (location.pathname === `/search-bid-diamond` && `active`)
                  }`}
              >
                <Link to={bidConfig.sub_types?.subTypeCode === 'BIWI' ? '/search-bid-diamond' : '/new-arrival-bid-it'}>
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/ubs.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/ubs-active.svg')} />
                  <span className="menu_link">
                    UBS {bidConfig?.sub_types?.subTypeCode ? '(' + bidConfig?.sub_types?.subTypeCode + ')' : ''}
                  </span>
                  <div
                    className={bidConfigLoading ? 'ribbon' : !isEmpty(bidConfig) ? 'ribbon active' : 'ribbon inactive'}
                  >
                    <span>{bidConfigLoading ? 'Loading...' : !isEmpty(bidConfig) ? 'Active' : 'Inactive'}</span>
                  </div>
                </Link>
              </li>
            )}
            {get(P, 'EXCLUSIVE.view', false) && (
              <li className={`${location.pathname === `/special-stone` && `active`}`}>
                <Link to="/special-stone">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/special-stone.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/special-stone-active.svg')} />
                  <span className="menu_link">Exclusive Stone</span>
                </Link>
              </li>
            )}
            {get(P, 'COLLECTION.view', false)
              ? exclusiveList.map((menuItem) => (
                <li
                  key={menuItem.id}
                  className={`/exclusive${window.location.search}` === `/exclusive?${menuItem.id}` && `active`}
                >
                  <Link to={`/exclusive?${menuItem.id}`}>
                    <img className="defualtImg" src={require('assets/svg/sidebar/new/special-stone.svg')} />
                    <img className="activeImg" src={require('assets/svg/sidebar/new/special-stone-active.svg')} />
                    <span className="menu_link">{menuItem ? menuItem.name : 'Exclusive Stone'}</span>
                  </Link>
                </li>
              ))
              : ''}
            {/* //For expo use only : DO NOT DELETE THIS*/}

            {get(P, 'SEARCH_DIAMOND.view', false) && showData && showData.isShowEnable === true && (
              <li className={`${location.pathname === `/show` && `active`}`}>
                <Link to="/show">
                  <img alt="" className="defualtImg" src={require('../../assets/svg/sidebar/JCK/show_black.svg')} />
                  <img className="activeImg" alt="" src={require('../../assets/svg/sidebar/JCK/show_white.svg')} />
                  <span className="menu_link">{showData?.fullName}</span>
                  <div className="ribbon-jck-icon">
                    <span> {showData?.shortName} </span>
                  </div>
                </Link>
              </li>
            )}

            {get(P, 'STONE_OF_THE_DAY.view', false) && (
              <li className={`${location.pathname === `/stone-of-the-day` && `active`}`}>
                <Link to="/stone-of-the-day">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/featured-stone.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/featured-stone-active.svg')} />
                  <span className="menu_link">Feature Stone</span>
                </Link>
              </li>
            )}
            {get(P, 'UPCOMING.view', false) && (
              <li className={`${location.pathname === `/upcoming` && `active`}`}>
                <Link to="/upcoming">
                  <img className="defualtImg" src={require('assets/svg/sidebar/new/upcoming-stone.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/new/upcoming-stone-active.svg')} />
                  <span className="menu_link">New Arrival</span>
                </Link>
              </li>
            )}
            {get(P, 'CART.view', false) && (
              <li className={`${location.pathname === `/account/cart` && `active`}`}>
                <Link to="/account/cart">
                  <img className="defualtImg" src={require('assets/svg/sidebar/my-cart.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/my-cart-active.svg')} />
                  <span className="menu_link">My Cart</span>
                </Link>
              </li>
            )}
            {get(P, 'WATCHLIST.view', false) && (
              <li className={`${location.pathname === `/account/watchlist` && `active`}`}>
                <Link to="/account/watchlist">
                  <img className="defualtImg" src={require('assets/svg/sidebar/watchlist.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/watchlist-active.svg')} />
                  <span className="menu_link">My Watchlist</span>
                </Link>
              </li>
            )}
            {get(P, 'QUOTE.view', false) && (
              <li className={`${location.pathname === `/account/quote-list` && `active`}`}>
                <Link to="/account/quote-list">
                  <img className="defualtImg" src={require('assets/svg/sidebar/my-offer.svg')} />
                  <img className="activeImg" src={require('assets/svg/sidebar/my-offer-active.svg')} />
                  <span className="menu_link">My Quote</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
        <div className="sidebarMenuList sidebarListBottom">
          <ul>
            <li onClick={() => commonLogout()}>
              <Link>
                <img src={require('assets/svg/Header/logout.svg')} />
                <span className="menu_link">
                  <IntlMessages id="app.Logout" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default withRouter(SidebarHeader);
