import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Logo from './Header/Logo';
import { connect } from 'react-redux';
import { setLoader, covidStrip } from '../appRedux/actions/Common';
import _, { get, isUndefined } from 'lodash';
import UtilService from '../services/util';
import { trackTypeObj, commonLogout, LOCAL_STORAGE_VAR, BASE_URL_ATTACH } from '../constants/Common';
import Sidebar from './Sidebar';
import HeaderRightSidebar from './common/HeaderRightSidebar';
import HeaderAccountList from './common/HeaderAccountList';
import CartDropDown from './common/Header/CartDropdown';
import NotificationDropdown from './common/Header/NotificationDropdown';
import HeaderSearch from './common/Header/Search';
import HeaderStrip from './common/Header/HeaderStrip';
import CovidStrip from './common/Header/CovidStrip';
import UpDownBox from './common/UpDownBox';
import CountBox from './common/CountBox';
import IntlMessages from '../util/IntlMessages';
import { GET_DASHBOARD_DATA, GET_EVENTS, GET_NOTIFICATIONS, MARK_AS_READ } from '../constants/apiConstant';
import { getSearchList } from '../containers/APIFunctions';
import DefaultUser from './common/UserProfileDefault';
import { GuestUserPopup } from './GuestUserPopup';

class Header extends Component {
  // new Code
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      isHovered1: false,
      isHovered2: false,
      covid: true,
      search: false,
      details: {},
      HeaderStripList: true,
      counts: {},
      rates: {},
      notifications: 0,
      event: [],
      searchList: [],
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
      // notifyData: [],
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.data.deleteReminder.count !== prevProps.data.deleteReminder.count) {
      this.getSidebarCounts();
      this.getNotifications();
    }
  }
  componentDidMount() {
    this.getSidebarCounts();
    this.getEvents();
    this.getNotifications();
    this.props.covidStrip(this.state.covid);
    getSearchList((list) => this.setState({ searchList: list }));
  }
  closeStrip = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      covid: false,
    });
  };
  searchOption = () => {
    if (!this.state.isGuest) {
      this.setState({
        search: !this.state.search,
      });
    } else {
      this.setState({
        showGuest: true,
      });
    }
  };
  showGuest = () => {
    this.setState({ showGuest: true });
  };
  handleHover = () => {
    this.setState({
      isHovered: !this.state.isHovered,
      isHovered1: this.state.isHovered,
      isHovered2: this.state.isHovered,
    });
  };
  handleHover1 = () => {
    this.setState({
      isHovered1: !this.state.isHovered1,
      isHovered: this.state.isHovered1,
      isHovered2: this.state.isHovered1,
    });
  };
  handleHover2 = () => {
    this.setState({
      isHovered2: !this.state.isHovered2,
      isHovered: this.state.isHovered2,
      isHovered1: this.state.isHovered2,
    });
  };
  handleOut = () => {
    this.setState({
      isHovered: false,
    });
  };
  handleOut1 = () => {
    this.setState({
      isHovered1: false,
    });
  };
  handleOut2 = () => {
    this.setState({
      isHovered2: false,
    });
    // this.MarkAsRead();
  };

  HeaderStripCick = () => {
    this.props.covidStrip(!this.props.data.commonData.strip);
    this.setState({
      HeaderStripList: !this.state.HeaderStripList,
    });
  };
  getSidebarCounts = () => {
    const self = this;
    const obj = {
      savedSearch: true,
      recentSearch: true,
      track: true,
      orderData: true,
      dashboardCount: true,
      shippingCount: true,
      seller: true,
      account: true,
    };
    const api = GET_DASHBOARD_DATA.url;
    const objData = {
      method: GET_DASHBOARD_DATA.method,
      url: api,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        self.setState({
          details: data.data.tracks,
          rates: data.data.rates,
          counts: _.omit(data.data, 'tracks'),
        });
      }
    });
  };

  getEvents = () => {
    const self = this;
    const obj = {
      ...GET_EVENTS,
      request: {
        page: 1,
        limit: 10,
        type: 6,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === 'OK') {
        if (data.data) {
          const eventData = data.data.list[0];

          self.setState({
            event: eventData,
          });
        }
      } else {
        //console.log("event else");
      }
    });
  };

  getNotifications = () => {
    const self = this;
    try {
      const obj = {
        ...GET_NOTIFICATIONS,
      };
      UtilService.callApi(obj, function (err, data) {
        if (err) {
        } else if (data && data.code === 'OK') {
          if (data.data) {
            self.setState({
              notifications: data.data.unReadCount,
            });
          }
        }
      });
    } catch (e) {}
  };

  // MarkAsRead = () => {
  //   let self = this;
  //   let obj = {
  //     ...MARK_AS_READ,
  //     // request: {
  //     //   id: id,
  //     // },
  //   };
  //   UtilService.callApi(obj, function (err, data) {
  //     if (err) {
  //       //console.log("mark err", err);
  //     } else if (data && data.code === "OK") {
  //       if (data.data) {
  //         //setData(data.data.list);
  //         self.setState({
  //           notifications: 0,
  //         });
  //       }
  //     } else {
  //       //setData(undefined);
  //     }
  //   });
  // };

  render() {
    // new Code
    const btnClass = this.state.isHovered ? 'active' : '';
    const btnClass1 = this.state.isHovered1 ? 'active' : '';
    const btnClass2 = this.state.isHovered2 ? 'active' : '';
    // const name = JSON.parse(localStorage.getItem("fs-user")).name;
    const { name, profileImage } = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const HeaderLinks = [
      get(USER_PERMIT, 'CART.view', false) && {
        link: '/account/cart',
        title: <IntlMessages id="app.my-cart" />,
        details: this.state.details[trackTypeObj.CART],
      },
      get(USER_PERMIT, 'WATCHLIST.view', false) && {
        link: '/account/watchlist',
        title: <IntlMessages id="app.my-watchlist" />,
        details: this.state.details[trackTypeObj.WATCHLIST],
      },
      get(USER_PERMIT, 'OFFICE.view', false) && {
        link: '/account/office-list',
        title: <IntlMessages id="app.office-list" />,
        details: this.state.details[trackTypeObj.REQUEST],
      },
      get(USER_PERMIT, 'FORMAL_MEET.view', false) && {
        link: '/account/formal-meet',
        title: 'My Formal Meet',
        details: this.state.details[trackTypeObj.REQUEST],
      },
      get(USER_PERMIT, 'CONFIRM_STONE.view', false) && {
        link: '/account/confirm-list',
        title: <IntlMessages id="app.confirm-list" />,
        details:
          this.state.counts.orderData && this.state.counts.orderData.order ? this.state.counts.orderData.order : {},
      },
      get(USER_PERMIT, 'QUOTE.view', false) && {
        link: '/account/quote-list',
        title: <IntlMessages id="app.MyOfferList" />,
        details: this.state.details[trackTypeObj.QUOTE],
      },
      get(USER_PERMIT, 'NOTES.view', false) && {
        link: '/account/notes',
        title: <IntlMessages id="app.my-comment" />,
        details: this.state.details[trackTypeObj.COMMENT],
      },
      get(USER_PERMIT, 'MY_BID.view', false) && {
        link: '/my-bid',
        title: <IntlMessages id="app.myBid" />,
        basicDetailnull: true,
      },
      get(USER_PERMIT, 'MY_BID.view', false) && {
        link: '/my-bid',
        title: <IntlMessages id="app.MyBidCart" />,
        basicDetailnull: true,
      },
      get(USER_PERMIT, 'SAVE_SEARCH.view', false) && {
        link: '/account/saved-search',
        title: <IntlMessages id="app.saved-search" />,
        basicDetailnull: true,
      },
      get(USER_PERMIT, 'DEMAND.view', false) && {
        link: '/account/my-demand',
        title: <IntlMessages id="app.my-demand-tab" />,
        basicDetailnull: true,
      },

      {
        link: '/account/profile',
        title: <IntlMessages id="app.MyProfile" />,
        basicDetailnull: true,
      },

      {
        link: '',
        title: <IntlMessages id="app.SignOut" />,
        onClick: () => {
          commonLogout();
        },
        basicDetailnull: true,
      },
    ];

    return (
      <>
        <div className="HeaderSticky">
          {this.state.covid && <CovidStrip event={this.state.event} onClick={this.closeStrip} />}
          <div className={`headerTopMenu ${this.state.covid && 'active'}`}>
            {/* {this.state.HeaderStripList && (
              <UpDownBox onClick={this.HeaderStripCick} />
            )}
            {!this.state.HeaderStripList && (
              <UpDownBox downArrow onClick={this.HeaderStripCick} />
            )} */}

            <div className="headerWrapper">
              <div className="headerLeft">
                <div className="headerLogo">
                  <Link to="/dashboard" className="">
                    <Logo />
                  </Link>
                </div>
                <div className="headerMenuOption">
                  <Sidebar />
                </div>
                {/* <div className="headerSearchWrapper">
                <div className="headerSearch">
                  <input placeholder="Search " />
                  <div className="headerSearchIcon">
                    <img src={require("../assets/svg/Header/search.svg")} />
                  </div>
                  <div className="headerSearchIcon2">
                    <img
                      src={require("../assets/svg/Header/voice.svg")}
                      className="voiceSearchImg"
                    />
                  </div>
                </div>
              </div> */}
              </div>
              <div className="headerRightSide">
                <div className={`headerRightBlock ${btnClass || btnClass1 || btnClass2}`}>
                  <div className="headerIcon" onClick={this.searchOption}>
                    <img className="defulticon" src={require('../assets/svg/Header/search.svg')} alt="icon" />
                    <img className="hoverIcon" src={require('../assets/svg/Header/search-white.svg')} alt="icon" />
                  </div>
                  <HeaderSearch
                    className={this.state.search && 'active'}
                    onClick={this.searchOption}
                    searchList={this.state.searchList}
                  />
                  <div
                    className="headerRightOption"
                    onMouseEnter={() => this.handleHover()}
                    onClick={() => this.handleHover()}
                  >
                    <div className="headerIcon" onMouseEnter={this.handleHover2} onClick={this.handleHover2}>
                      <img className="defulticon" src={require('../assets/svg/Header/notification.svg')} alt="icon" />
                      <img
                        className="hoverIcon"
                        src={require('../assets/svg/Header/notification-white.svg')}
                        alt="icon"
                      />
                      {this.state.notifications > 0 && <CountBox count={this.state.notifications} />}
                    </div>
                    <Link to="/account/profile" className="headerIcon">
                      <img className="defulticon" src={require('../assets/svg/Header/user.svg')} alt="icon" />
                      <img className="hoverIcon" src={require('../assets/svg/Header/user-white.svg')} alt="icon" />
                      <span className="headerUserName">
                        <IntlMessages id="app.Welcome" />,{name}
                      </span>
                    </Link>
                    <HeaderRightSidebar activeClass={btnClass} handleOut={this.handleOut} onClick={this.handleOut}>
                      <div className="dropdownUserIcon">
                        <div className="userProfileLogo">
                          {profileImage ? (
                            <img src={`${BASE_URL_ATTACH}/${profileImage}`} alt="user" />
                          ) : (
                            <DefaultUser />
                            // <img src={require("../assets/img/user-default.png")} alt="user" />
                          )}
                        </div>
                        <div className="userProfileContent">
                          <h2>{name}</h2>
                          <p onClick={() => window.location.assign('/account/profile')}>
                            <IntlMessages id="app.SeeYourProfile" />
                          </p>
                        </div>
                      </div>
                      <div className="userDetailList">
                        <ul>
                          {HeaderLinks.map((x, i) => {
                            if (!isUndefined(x)) {
                              return (
                                <HeaderAccountList
                                  key={i}
                                  isGuest={this.state.isGuest}
                                  showGuest={this.showGuest}
                                  {...this.props}
                                  {...x}
                                />
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </HeaderRightSidebar>
                  </div>
                  {/* <div className="headerRightOption">
                    <div className="headerIcon" onMouseEnter={this.handleHover1}>
                      <img
                        className="defulticon"
                        src={require("../assets/svg/Header/cart.svg")}
                        alt="icon"
                      />
                      <img
                        className="hoverIcon"
                        src={require("../assets/svg/Header/cart-white.svg")}
                        alt="icon"
                      />
                    </div>
                    <HeaderRightSidebar activeClass={btnClass1} handleOut={this.handleOut1}>
                      <CartDropDown />
                      <div className="notFoundDataSidebar">
                            <div>
                              <h2>Welcome To Your Cart</h2>
                              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </p>
                            </div>
                        </div>
                    </HeaderRightSidebar>
                  </div> */}
                  {/* <div className="headerRightOption">
                    <div className="headerIcon">
                      <img
                        className="defulticon"
                        src={require("../assets/svg/Header/message.svg")}
                        alt="icon"
                      />
                      <img
                        className="hoverIcon"
                        src={require("../assets/svg/Header/message-white.svg")}
                        alt="icon"
                      />
                    </div>
                  </div> */}
                  <div className="headerRightOption">
                    <HeaderRightSidebar activeClass={btnClass2} handleOut={this.handleOut2} onClick={this.handleOut2}>
                      <NotificationDropdown count={this.state.notifications} updatedCount={this.getNotifications} />
                    </HeaderRightSidebar>
                  </div>
                  <HeaderStrip />
                </div>
              </div>
            </div>
          </div>
          <div className="headerStrip"></div>
          {/* {this.state.HeaderStripList && (
            <HeaderStrip
              // className={btnClass || btnClass1 || btnClass2}
              onClick={this.HeaderStripCick}
              rates={this.state.rates}
            />
          )} */}
          {this.state.showGuest && (
            <GuestUserPopup
              visible={this.state.showGuest}
              register={() => {
                window.open('/signup', '_self');
                window.localStorage.clear();
                window.sessionStorage.clear();
              }}
              handleCancel={() => {
                this.setState({ showGuest: false });
              }}
            />
          )}
        </div>
      </>
    );
  }
}

// export default Header;
function mapStateToProps(props) {
  return { data: props };
}

export default connect(mapStateToProps, { setLoader, covidStrip })(withRouter(Header));
