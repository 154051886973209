import React from 'react';
import CommonModal from '../common/CommonModal';
import InputBlock from '../common/InputBlock';
import IntlMessages from '../../util/IntlMessages';
import CheckBox from '../common/CheckBox';

const SavedSearchPopup = (props) => {
  return (
    <>
      <CommonModal
        visible={props.showSavedSearch}
        handleOk={() => props.handleSavedSearch()}
        handleCancel={() => props.handleReverseState('showSavedSearch')}
        title={props.searchId ? <IntlMessages id="app.update-search" /> : <IntlMessages id="app.AddSaveNSearch" />}
        footerShow
        submitTitle={<IntlMessages id="app.signup.Submit" />}
        cancelTitle={<IntlMessages id="app.Cancel" />}
      >
        <div className="searchPopupCommon">
          <div className="searchPopupTwoValue">
            <IntlMessages id="app.SearchTitle">
              {(placeholder) => (
                <InputBlock
                  label={placeholder}
                  placeholder={placeholder}
                  value={props.searchTitle}
                  onChange={(e) => props.handleChange('searchTitle', e.target.value)}
                />
              )}
            </IntlMessages>
            {props.searchId && (
              <div>
                <CheckBox
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.handleChange('searchTitle', '');
                      props.handleChange('searchId', '');
                    }
                  }}
                  label="New Search"
                />
              </div>
            )}
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default SavedSearchPopup;
