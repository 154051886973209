import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { useTable, useRowSelect } from 'react-table';
import { Checkbox, Dropdown } from 'antd';
import SortingFilterDropdown from '../../common/DiamondListing/SortingFilterDropdown';
import DiamondListingHead from '../../common/DiamondListing/DiamondListingHead';
import { sortingData } from '../../../constants/sorting';
import Heading from '../../common/Heading';
import _ from 'lodash';
import UtilService from '../../../services/util';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import OpenNotification from '../../common/CommonButton/OpenNotification';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import IntlMessages from '../../../util/IntlMessages';
import { USER_SETTING_LIST, USER_SETTING_UPSERT } from '../../../constants/apiConstant';

const ColorMenuConst = <SortingFilterDropdown OtherTable data={sortingData.colunmSetting} FilterOption={false} />;
let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 55;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.pageHeadHeight = document.getElementsByClassName('accountTopBlock')[0];
  allContainers.pinHeight = document.getElementsByClassName('accountTabBox')[0];
  allContainers.actionBtnHeight = document.getElementsByClassName('userManageTop')[0];
  allContainers.diamondListingAction = document.getElementsByClassName('diamondListingAction')[0];
  allContainers.covidStrip = document.getElementsByClassName('covidStrip')[0];
  allContainers.smallHeaderWrapper = document.getElementsByClassName('smallHeaderWrapper')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }

    return true;
  });
  return totalHeight;
}
class DiamondColumnListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: false,
      gridHeight: 0,
      checked: false,
      settings: [],
      allChecked: null,
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = () => {
    let self = this;
    let obj = {
      // url: "/admin/user-setting/paginate",
      // method: "post",
      ...USER_SETTING_LIST,
    };

    UtilService.callApi(obj, function (err, data) {
      if (err && err.data) {
      } else if (data && data.code === 'OK') {
        if (data.data.setting) {
          self.setState({ settings: data.data.setting });
        }
      }
    });
  };

  handleInputChange(key, value, index) {
    let settings = this.state.settings;
    settings[index][key] = value;
    this.setState({ settings: settings });
  }

  handleCheckChange = (key, checked) => {
    let { settings } = this.state;
    let newSetting = settings;
    newSetting.map((s) => {
      if (s.field === key) {
        s.isActive = checked;
      }
    });
    this.setState({ settings: newSetting, allChecked: null });
  };

  handleAllCheckChange = (checked) => {
    let newSetting = _.cloneDeep(this.state.settings);
    newSetting.map((s) => {
      s.isActive = checked;
    });
    this.setState({ allChecked: checked, settings: newSetting });
  };

  handleUpdateSetting = () => {
    let self = this;
    let obj = {
      setting: this.state.settings,
    };
    let api = '/admin/user-setting/upsert';
    let objData = {
      // method: "post",
      // url: api,
      ...USER_SETTING_UPSERT,
      request: obj,
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(self.state.settings));
        return OpenNotification({
          type: 'success',
          title: 'Your diamond sequence is updated successfully.',
        });
      }
    });
  };

  render() {
    let settings = this.state.settings;
    let self = this;
    gridHeight = setGridHeightFn();
    if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    const columns = [
      {
        Header: 'Sr. Number',
        width: '50px',
        accessor: 'srnu',
        totalDropdown: true,
        Cell: ({ row }) => <div>{row.index + 1}</div>,
      },
      {
        Header: 'Name',
        accessor: 'title',
        //shortingSelect: true,
        totalDropdown: true,
        //shortingCount: "2",
      },
      {
        Header: 'Sequence',
        accessor: 'sequence',
      },
      // {
      //   Header: "Width",
      //   accessor: "width",
      //   shortingSelect: true,
      //   totalDropdown: true,
      //   shortingCount: "1",
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="tableInput">
      //         <input
      //           type="number"
      //           defaultValue={row.original.width}
      //           onChange={(e) => {
      //             this.state.settings[row.index]["width"] = e.target.value;
      //           }}
      //         />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Align",
      //   Cell: ({ row, cell }) => (
      //     <div className="colunmAlign">
      //       <div
      //         onClick={() => self.handleInputChange("cellClass", "text-left", row.index)}
      //         className="colunmAlignItem colunmLeft"
      //       >
      //         {row.original.cellClass === "text-left" ? (
      //           <img src={require("../../../assets/svg/Account/align-left-color.svg")} alt="" />
      //         ) : (
      //           <img src={require("../../../assets/svg/Account/align-left.svg")} alt="" />
      //         )}
      //       </div>
      //       <div
      //         onClick={() => self.handleInputChange("cellClass", "text-center", row.index)}
      //         className="colunmAlignItem colunmCenter"
      //       >
      //         {row.original.cellClass === "text-center" ? (
      //           <img src={require("../../../assets/svg/Account/align-center-color.svg")} alt="" />
      //         ) : (
      //           <img src={require("../../../assets/svg/Account/align-center.svg")} alt="" />
      //         )}
      //       </div>
      //       <div
      //         onClick={() => self.handleInputChange("cellClass", "text-right", row.index)}
      //         className="colunmAlignItem colunmRight"
      //       >
      //         {row.original.cellClass === "text-right" ? (
      //           <img src={require("../../../assets/svg/Account/align-right-color.svg")} alt="" />
      //         ) : (
      //           <img src={require("../../../assets/svg/Account/align-right.svg")} alt="" />
      //         )}
      //       </div>
      //     </div>
      //   ),
      // },
    ];

    const Table = ({ columns, data }) => {
      const [records, setRecords] = React.useState(data);
      const getRowId = React.useCallback((row) => {
        return row.sequence + row.field;
      }, []);

      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
          data: records,
          columns,
          getRowId,
        },
        useRowSelect,
        (hooks) => {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              sortingFalse: true,
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="selectActionIcon">
                  <div className="selectActionIconWrapper">
                    <Checkbox
                      checked={this.state.allChecked}
                      onChange={(e) => this.handleAllCheckChange(e.target.checked)}
                    />
                  </div>
                </div>
              ),
              Cell: ({ row }) => {
                return (
                  <div className="selectActionIcon">
                    <div className="selectActionIconWrapper">
                      <Checkbox
                        checked={row.original.isActive}
                        onChange={(e) => this.handleCheckChange(row.original.field, e.target.checked)}
                      />
                    </div>
                  </div>
                );
              },
            },
            ...columns,
          ]);
        },
      );

      const moveRow = (dragIndex, hoverIndex) => {
        const dragRecord = records[dragIndex];

        var b = records[dragIndex].sequence;
        records[dragIndex].sequence = records[hoverIndex].sequence;
        records[hoverIndex].sequence = b;

        this.setState({ settings: records });

        this.setState(
          update(this.state, {
            settings: {
              $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragRecord],
              ],
            },
          }),
        );
      };

      return (
        <DndProvider backend={HTML5Backend}>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()} width="100px">
                      <DiamondListingHead
                        header={column.render('Header')}
                        sortingFalse={column.sortingFalse}
                        ColorMenuConst={ColorMenuConst}
                        shortingSelect={column.shortingSelect}
                        shortingCount={column.shortingCount}
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(
                (row, i) => prepareRow(row) || <Row index={i} row={row} moveRow={moveRow} {...row.getRowProps()} />,
              )}
            </tbody>
          </table>
        </DndProvider>
      );
    };

    const DND_ITEM_TYPE = 'row';

    const Row = ({ row, index, moveRow }) => {
      const dropRef = React.useRef(null);
      const dragRef = React.useRef(null);

      const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item, monitor) {
          if (!dropRef.current) {
            return;
          }
          const dragIndex = item.index;
          const hoverIndex = index;
          // Don't replace items with themselves
          if (dragIndex === hoverIndex) {
            return;
          }
          const hoverBoundingRect = dropRef.current.getBoundingClientRect();
          const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const clientOffset = monitor.getClientOffset();
          const hoverClientY = clientOffset.y - hoverBoundingRect.top;

          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }
          moveRow(dragIndex, hoverIndex);
          item.index = hoverIndex;
        },
      });

      const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, index },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });

      const opacity = isDragging ? 0 : 1;

      preview(drop(dropRef));
      drag(dragRef);

      return (
        <>
          <tr ref={dropRef} style={{ opacity }}>
            {row.cells.map((cell) => {
              return (
                <td width="100px" {...cell.getCellProps()}>
                  <div ref={dragRef} className="tableSortingTd">
                    <img src={require('../../../assets/svg/DiamondList/sorting-light.svg')} />
                  </div>
                  {cell.render('Cell')}
                </td>
              );
            })}
          </tr>
        </>
      );
    };

    const isMobile = window.innerWidth <= 767;
    return (
      <div className="configurationInnerSubTb">
        <div className="searchResultListWrapper">
          <div className="editProfilePage">
            {isMobile && (
              <div className="d-flex justify-content-between align-items-center userManageTop">
                <Heading
                  title={<IntlMessages id="app.ManageDiamondSequence" />}
                  className="mainHeadingTitle popupInnerTitle m-0 p-10"
                />
              </div>
            )}
            <div className="searchInnerResult">
              <div className="searchResultTable columnSettingPage" style={{ height: gridHeight }}>
                <Table columns={columns} data={settings} />
              </div>
            </div>
            <div className="botoomStickyBar diamondListingAction justify-content-center">
              <button className="bottomStickyButton" onClick={() => this.handleUpdateSetting()}>
                <IntlMessages id="app.updateButton" />
              </button>
            </div>
            {isMobile && (
              <div className="sideBarPopupButton">
                <button className="commonButton" onClick={() => this.handleUpdateSetting()}>
                  <IntlMessages id="app.updateButton" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DiamondColumnListing;
