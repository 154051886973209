import React, { useState, useEffect } from 'react';
import Heading from '../common/Heading';
import MagnifierImage from '../DiamondDetail/MagnifierImage';
import last from 'lodash/last';
import split from 'lodash/split';
import { VIEW_DIAMOND } from '../../constants/apiConstant';
import UtilService from '../../services/util';

const ViewImage = () => {
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const path = split(window.location.pathname, '/')[2];
  useEffect(() => {
    let id = last(split(window.location.pathname, '/'));
    let imgPath = split(window.location.pathname, '/');
    if (id !== 'dna') {
      let api = `${VIEW_DIAMOND.url}/${id}`;
      let objData = {
        method: VIEW_DIAMOND.method,
        url: api,
        showLoader: true,
      };
      UtilService.callApi(objData, async function (err, data) {
        if (err) throw err;
        if (data && data.code === 'OK') {
          let whichImage = imgPath[2];
          let imgs = [];
          if (whichImage === 'HeartImages' || whichImage === 'ArrowImages') {
            imgs[0] = `https://s3.ap-south-1.amazonaws.com/finestargroup/HeartImages/${data.data.vStnId}.jpg`;
            imgs[1] = `https://s3.ap-south-1.amazonaws.com/finestargroup/ArrowImages/${data.data.vStnId}.jpg`;
          } else if (whichImage === 'PlottingImages') {
            imgs[0] = `https://s3.ap-south-1.amazonaws.com/finestargroup/PlottingImages/${data.data.rptNo}.gif`;
          } else if (whichImage === 'RoughImages') {
            const roughImgId = data.data.vStnId && data.data.vStnId.slice(0, -1) + '0';
            imgs[0] = `https://s3.ap-south-1.amazonaws.com/finestargroup/MFG/RoughImages/${roughImgId}.jpg`;
          } else if (whichImage === 'PlanImages') {
            imgs[0] = `https://s3.ap-south-1.amazonaws.com/finestargroup/MFG/PlanImages/${data.data.vStnId}.png`;
          } else {
            imgs[0] = `https://s3.ap-south-1.amazonaws.com/finestargroup/${whichImage}/${data.data.vStnId}.jpg`;
          }
          setImages(imgs);
          setData(data.data);
        }
      });
    }
  }, []);
  const measurements = [
    { name: 'Packet No', key: 'vStnId' },
    { name: 'Report No', key: 'rptNo' },
    { name: 'Lab', key: 'lbNm' },
    { name: 'Shape', key: 'shpNm' },
    { name: 'Carat', key: 'crt' },
    { name: 'Clarity', key: 'clrNm' },
    { name: 'Color', key: 'colNm' },
    { name: 'Cut', key: 'cutNm' },
    { name: 'Polish', key: 'polNm' },
    { name: 'Symmetry', key: 'symNm' },
    { name: 'Fluorescence', key: 'fluNm' },
  ];
  return (
    <div>
      <div className={`d-flex viewImageBlock ${!images[1] && 'j-content-center centeBlock'}`}>
        <div className="viewImageBlockLeft">
          <div className="dnaWrapperBox">
            <div className="dnaImageTop">
              <span>Diamond Detail</span>
            </div>
            <div className="dnaDetailBlock">
              <div className=" d-flex flex-wrap align-items-normal">
                {measurements.map((m) => (
                  <div className="detail">
                    <span>{m.name} : </span>
                    <span>{data[m.key]}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <p>Rollover Mouse on Image For Zooming Effect</p>
        </div>
        {images[0] && (
          <div className={`viewImageBlockRight ${!images[1] && 'halfBlock'} ${path}`}>
            <MagnifierImage image={images[0]} />
          </div>
        )}
        {images[1] && (
          <div className={`viewImageBlockRight ${path}`}>
            <MagnifierImage image={images[1]} />
          </div>
        )}
      </div>
      {(path === 'HeartImages' || path === 'ArrowImages') && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            Heart & Arrow is a depiction of the diamond’s supreme symmetrical (due to its cut) features. It consists of
            eight hearts and eight arrows that can be seen in a diamond of superb cut quality, it's a ﬁrst in this
            segment. With this feature you don’t need to buy another system for taking H & A images of Diamond because
            it’s inbuilt feature in this system.
          </p>
        </div>
      )}
      {path === 'AssetScopeImages' && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            The ASET® Scope image works with the Angular Spectrum to display and you evaluate the optical performance of
            the diamonds. Only a diamond that reﬂects a lot of light back to you will be a diamond full of brilliance,
            ﬁre and scintillation.
          </p>
        </div>
      )}
      {path === 'Office_Light_Black_BG' && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            It is used to examine diamond’s “clarity characteristics” or ‘“inclusions” that either invisible in the
            exterior light, cannot be stained by standard methods, or are so distorted by staining that their
            characteristics then cannot be identiﬁed. This interior lighting arrangement makes it easier to see the
            stone’s interior & surface inclusions that can be very important for its characterisation of an inclusions.
          </p>
        </div>
      )}
      {path === 'Dark_Field_White_BG' && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            Face-up Image is the high-quality steady face-up photo of the diamond, which simulates to under ofﬁce light
            appearance of a diamond in which you can easily check diamond's overall cut symmetry, Inclusion location &
            light refraction in form of its brilliance.
          </p>
        </div>
      )}
      {path === 'IDEAL_White_BG' && (
        <div className="viewImageDetail">
          <span>Note : </span>
          <p>
            IDEAL scope photo can tell you a lot about where the light is leaking in a diamond and how much light a
            diamond will actually reﬂect back to you. Only a diamond that reﬂects a lot of light back to you will be a
            diamond full of brilliance, ﬁre and scintillation.
          </p>
        </div>
      )}
    </div>
  );
};
export default ViewImage;
