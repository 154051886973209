import React, { useState, useEffect } from 'react';
import Heading from '../../common/Heading';
import SendEmailPopup from './SendEmail';
import { Checkbox } from 'antd';
import { LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import map from 'lodash/map';
import OpenNotification from '../../../components/common/CommonButton/OpenNotification';

const ShareOption = (props) => {
  return (
    <li>
      <div className="dropdownBottomItem">
        <div className="dropdownIconCheckBox">
          <Checkbox onChange={props.onChange} checked={props.checked}></Checkbox>
        </div>
        <span
          className="shareOptionLabel"
        // onChange={(e) => {}}
        >
          {props.title}
        </span>
      </div>
    </li>
  );
};

const MobileShareOption = (props) => {
  const [visible, SetVisible] = useState(true);
  const [visible1, SetVisible1] = useState(true);
  const [visible2, SetVisible2] = useState(true);
  const [visible3, SetVisible3] = useState(true);
  const [visible4, SetVisible4] = useState(true);

  const [sendEmail, setSendEmail] = useState(false);

  const [isExcel, setIsExcel] = useState(false);
  const [isCert, setIsCert] = useState(false);
  const [typeIIA, setTypeIIA] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [isImg2, setIsImg2] = useState(false);
  const [isFaceUp, setIsFaceUp] = useState(false);
  const [DarkField, setDarkField] = useState(false);
  const [Ideal, setIdeal] = useState(false);
  const [flourescence, setFlourescence] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlt, setIsPlt] = useState(false);
  const [isHa, setIsHa] = useState(false);
  const [isAsset, setIsAsset] = useState(false);
  const [isArrow, setIsArrow] = useState(false);
  const [flag, setFlag] = useState(0);
  const [isVideo2, setIsVideo2] = useState(false);
  const [roughImage, setRoughimage] = useState(false);
  const [roughVideo, setRoughVideo] = useState(false);
  const [DImage, setDImage] = useState(false);
  const [images, setImages] = useState(false);
  const [videos, setVideos] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [roughs, setRoughs] = useState(false);
  const handleMenuClick = () => {
    SetVisible(!visible);
  };
  const handleMenuClick1 = () => {
    SetVisible1(!visible1);
  };
  const handleMenuClick2 = () => {
    SetVisible2(!visible2);
  };
  const handleMenuClick3 = () => {
    SetVisible3(!visible3);
  };
  const handleMenuClick4 = () => {
    SetVisible4(!visible4);
  };

  const WhatsAppShare = () => {
    if (props.checked.length === 1) {
      let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0].id}`;
      window.open(`https://web.whatsapp.com/send?text=${path}`);
    } else if (props.checked.length >= 2) {
      let msg = 'Unique Diamax Pvt Ltd : Diamonds Detail%0A%0A';

      props.checked.forEach((element) => {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element.id}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.whatsapp.com/send?text=${msg}`);
    }
    props.onClose();
  };

  const SkypeShare = () => {
    if (props.checked.length === 1) {
      let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${props.checked[0].id}`;
      window.open(`https://web.skype.com/share?url=${path}`);
    } else if (props.checked.length >= 2) {
      let msg = 'Unique Diamax Pvt Ltd : Diamonds Detail%0A%0A';

      props.checked.forEach((element) => {
        let path = `${window.location.origin}/${LISTINGPAGES.DETAILS}/${element.id}`;
        msg += path + '%0A%0A';
      });
      window.open(`https://web.skype.com/share?url=${msg}`);
    }
    props.onClose();
  };

  useEffect(() => {
    setIsImg(images);
    setIsImg2(images);
    setIsPlt(images);
    setIsHa(images);
    setIsAsset(images);
    setIsFaceUp(images);
    setDarkField(images);
    setIdeal(images);
    setFlourescence(images);
    setIsArrow(images);
  }, [images]);

  useEffect(() => {
    setIsVideo(videos);
    setIsVideo2(videos);
  }, [videos]);

  useEffect(() => {
    setIsCert(certificates);
    setTypeIIA(certificates);
  }, [certificates]);

  useEffect(() => {
    setRoughVideo(roughs);
    setRoughimage(roughs);
    setDImage(roughs);
  }, [roughs]);

  return (
    <div>
      <Heading title="Share Stone" className="p-0" />
      <div className="">
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={images} onChange={() => setImages(!images)}></Checkbox>
              </div>
              Images
            </span>
            <span onClick={handleMenuClick}>
              {visible ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible && (
            <div className="mobileShareOption">
              <ShareOption
                title="Real Image - 1"
                checked={isImg}
                //activeClass={isImg === true ? "active" : ""}
                onChange={() => {
                  setIsImg(!isImg);
                }}
              />
              <ShareOption
                title="Real Image - 2"
                checked={isImg2}
                onChange={() => {
                  setIsImg2(!isImg2);
                }}
              />
              <ShareOption title="Plotting" checked={isPlt} onChange={() => setIsPlt(!isPlt)} />
              <ShareOption title="Heart & Arrow" checked={isHa} onChange={() => setIsHa(!isHa)} />
              <ShareOption title="Asset Scope" checked={isAsset} onChange={() => setIsAsset(!isAsset)} />
              <ShareOption title="Arrow Image" checked={isArrow} onChange={() => setIsArrow(!isArrow)} />
              <ShareOption title="Face Up Image" checked={isFaceUp} onChange={() => setIsFaceUp(!isFaceUp)} />
              <ShareOption title="Dark - Field Image" checked={DarkField} onChange={() => setDarkField(!DarkField)} />
              <ShareOption title="Ideal Scope Image" checked={Ideal} onChange={() => setIdeal(!Ideal)} />
              <ShareOption
                title="Flouresence Image"
                checked={flourescence}
                onChange={() => setFlourescence(!flourescence)}
              />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={videos} onChange={() => setVideos(!videos)}></Checkbox>
              </div>
              Videos
            </span>
            <span onClick={handleMenuClick1}>
              {visible1 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible1 && (
            <div className="mobileShareOption">
              <ShareOption title="Video 1" checked={isVideo} onChange={() => setIsVideo(!isVideo)} />
              <ShareOption title="Video 2" checked={isVideo2} onChange={() => setIsVideo2(!isVideo2)} />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={certificates} onChange={() => setCertificates(!certificates)}></Checkbox>
              </div>
              Certificates
            </span>
            <span onClick={handleMenuClick2}>
              {visible2 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible2 && (
            <div className="mobileShareOption">
              <ShareOption title="Certificate" checked={isCert} onChange={() => setIsCert(!isCert)} />
              <ShareOption title="Type IIA" checked={typeIIA} onChange={() => setTypeIIA(!typeIIA)} />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">{/* <Checkbox></Checkbox> */}</div>
              Excel
            </span>
            <span onClick={handleMenuClick3}>
              {visible3 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible3 && (
            <div className="mobileShareOption">
              <ShareOption checked={isExcel} onChange={() => setIsExcel(!isExcel)} title="Excel" />
            </div>
          )}
        </div>
        <div className="shareStoneMobileItem">
          <div className="shareStoneMobileHead">
            <span className="shareDropdownMobileHead d-flex">
              <div className="dropdownIconCheckBox">
                <Checkbox checked={roughs} onChange={() => setRoughs(!roughs)}></Checkbox>
              </div>
              Roughs
            </span>
            <span onClick={handleMenuClick4}>
              {visible4 ? (
                <img src={require('../../../assets/svg/Search/down-arrow.svg')} alt="" />
              ) : (
                <img src={require('../../../assets/svg/Search/up-arrow.svg')} alt="" />
              )}
            </span>
          </div>
          {visible4 && (
            <div className="mobileShareOption">
              <ShareOption title="Rough Scope" checked={roughImage} onChange={() => setRoughimage(!roughImage)} />
              <ShareOption title="3D Image" checked={DImage} onChange={() => setDImage(!DImage)} />
              <ShareOption title="Rough Video" checked={roughVideo} onChange={() => setRoughVideo(!roughVideo)} />
            </div>
          )}
        </div>
      </div>
      <div className="sideBarPopupButton">
        <a onClick={() => WhatsAppShare()} className="commonButton">
          WhatsApp
        </a>
        <a onClick={() => SkypeShare()} className="commonButton">
          Skype
        </a>
        <a
          onClick={() => {
            if (
              isAsset ||
              isHa ||
              isCert ||
              isExcel ||
              isImg ||
              isPlt ||
              isVideo ||
              isArrow ||
              isImg2 ||
              typeIIA ||
              isFaceUp ||
              DarkField ||
              Ideal ||
              flourescence ||
              isVideo2 ||
              roughImage ||
              roughVideo ||
              DImage
            ) {
              setSendEmail(true);
            } else {
              OpenNotification({
                type: 'error',
                title: 'Please Select atleast 1 Option to Share.',
              });
            }
            //props.onCloseSharePopup();
          }}
          className="commonButton"
        >
          Email
        </a>
      </div>
      <SendEmailPopup
        sendEmail={sendEmail}
        onCancel={() => setSendEmail(!sendEmail)}
        ids={map(props.checked, 'id')}
        certFile={isCert}
        hAFile={isHa}
        img={isImg}
        isPlt={isPlt}
        isReal={isImg}
        assetFile={isAsset}
        videoFile={isVideo}
        isExcel={isExcel}
        arrowFile={isArrow}
        mp4Video={isVideo2}
        type2={typeIIA}
        roughVideo={roughVideo}
        darkFieldImage={DarkField}
        flsImage={flourescence}
        idealWhiteImage={Ideal}
        roughImage={roughImage}
        planImg={DImage}
        faceUp={isFaceUp}
        b2cRealImage={isImg2}
      />
    </div>
  );
};
export default MobileShareOption;
