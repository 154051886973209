import React from 'react';
import './fileUploaded.less';

const FileUploaded = (props) => {
  return (
    <div className={`fileUploadedBlockItem ${props.className}`}>
      <div className={`fileUplodImg ${props.sizeSmall && 'smallSize'}`}>
        <img src={props.file} alt="fileAdd" />
      </div>
      <div className="fileUplodContent">
        <p>{props.title}</p>
        {/* {props.closeFalse ? null : 
                    <div className="deleteIcon">
                        <img src={require("../../../assets/svg/DiamondList/close-theme.svg")} alt=""/>
                    </div>
                    } */}
      </div>
    </div>
  );
};
export default FileUploaded;
