import React, { useReducer, useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cloneDeep, findIndex, get } from 'lodash';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import Table from '../DiamondList/TableBack';
import { getState, isArrayEqual } from '../DiamondList';
import { fetchBid, getColumn, TITLE, LISTINGPAGES, fetchBidCart } from '../DiamondList/DiamondListFunctions';
import { getBidType } from '../NewArrivalBidIt';
import Heading from '../common/Heading';
import SelectStone from '../common/DiamondListing/SelectStone';
import { Radio } from 'antd';
import Countdown from '../Offer/Timer';
import IntlMessages from '../../util/IntlMessages';
import { DIAMOND_BID, LOCAL_STORAGE_VAR } from '../../constants/Common';
import { getCurrentTime, getNextBidData } from '../../services/util';
import UtilService from '../../services/util';
import NoDataShow from '../common/NoDataShow';
import BidCartList from './BidCartList';
import { BidService } from 'services/BidService';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
import { ADD_NOTES } from 'constants/apiConstant';
import { Tooltip } from 'antd';

const BIDTAB = {
  LIVE: '1',
  MYBIDCART: '2',
  HISTORY: '3',
};

const BidList = (props) => {
  const [data, setData] = useState([]);
  const dimaondDetailPage = window.location.pathname?.split('/')?.[1];
  const { location } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location?.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [tab, setTab] = useState(
    window.location.search === '?cart' || dimaondDetailPage === 'diamond-detail' || dimaondDetailPage === 'dna'
      ? BIDTAB.MYBIDCART
      : BIDTAB.LIVE,
  );
  const [showError, setShowError] = useState(false);
  const [dataList, setDataList] = useState();
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
  const [date, setDate] = useState({
    from: moment().startOf('day').add(-6, 'days'),
    to: moment(),
  });
  const [currTime, setCurrTime] = useState('');
  const [nextBidData, setNextBidData] = useState({});
  const selectedRows = useSelector((state) => state.diamondData.selectedRows[currentType.current]);
  const check = selectedRows;

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip, tab]);

  const clearAllSelectedRows = useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);
  const fetchCarts = () => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === 'E_NOT_FOUND') {
            setState({ ...state, loading: false, data: [] });
          }
        });
      setCurrTime(time.iso);
      setNextBidData(nextBid ? nextBid : []);
      nextBid && nextBid.sub_types && fetch();
      const row = await fetchBidCart().then();
      setDataList(row.list);
    }
    setTimers();
    check && tab === BIDTAB.LIVE && clearAllSelectedRows();
  };
  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === 'E_NOT_FOUND') {
            setState({ ...state, loading: false, data: [] });
          }
        });
      setCurrTime(time.iso);
      setNextBidData(nextBid ? nextBid : []);
      nextBid && nextBid.sub_types && fetch();
      const row = await fetchBidCart().then();
      setDataList(row.list);
    }
    setTimers();
    check && tab === BIDTAB.LIVE && clearAllSelectedRows();
  }, [state.page, state.limit, date, tab]);

  const setResponse = (res) => {
    setShowError(res?.count === 0);
    const columns = fetchcolumn();
    if (res) {
      let diamondData = res.data,
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData &&
            diamondData.map((d) => {
              if (defaultChecked.includes(d.id)) {
                d['isDefaultChecked'] = true;
              }
              return d;
            });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...state,
        ...res,
        columns,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, columns, defaultChecked: [] });
    }
  };

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [sortRef.current],
  );

  // useEffect(() => {
  //   fetch();
  // }, [date, tab, nextBidData]);

  const bidEndTime = async () => {
    const nextBid = await getNextBidData()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') {
          setState({ ...state, loading: false, data: [] });
        }
      });
    setNextBidData(nextBid ? nextBid : []);
    getSelection();
  };

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current, date };
    const bidData = BidService?.bid?.sub_types;
    const subTypeCode = BidService?.bid?.sub_types?.subTypeCode;
    if (tab === BIDTAB.LIVE) {
      fetchBid(
        payload,
        bidData.id ? bidData.id : '',
        subTypeCode ? subTypeCode : '',
        //tab === BIDTAB.LIVE ? [getBidType()] : [DIAMOND_BID.TYPE.OPEN, DIAMOND_BID.TYPE.BLIND],
        tab === BIDTAB.LIVE ? [DIAMOND_BID.STATUS.PENDING] : [DIAMOND_BID.STATUS.WIN, DIAMOND_BID.STATUS.LOSS],
        setResponse,
        tab === BIDTAB.HISTORY,
      );
    }
  }, [state.page, state.limit, sortRef.current, state.sum, date, tab, nextBidData]);

  const fetchcolumn = () => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    const ctr = Columns.filter((el) => el.id === 'ctPr');
    const amnt = Columns.filter((el) => el.id === 'amt');
    Columns = Columns.filter((el) => el.id !== 'ctPr' && el.id !== 'amt');
    const columns = [
      tab === BIDTAB.HISTORY
        ? {
          Header: 'Type',
          accessor: 'bidType',
          Cell: ({ cell }) => (cell.value === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'),
        }
        : null,
      tab === BIDTAB.HISTORY
        ? {
          Header: 'Status',
          accessor: 'status',
          Cell: ({ cell }) =>
            cell.value === DIAMOND_BID.STATUS.WIN ? (
              <div className="activeStatus">
                <span>Win</span>
              </div>
            ) : (
              <div className="activeStatus red">
                <span>Loss</span>
              </div>
            ),
        }
        : null,
      ...Columns.slice(0, index - 1),
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ cell, row, data }) => {
          const notes = row?.original?.notes === null ? row?.original?.usernotes : row?.original?.notes;
          return (
            <Tooltip title={notes}>
              <div className="tableInput">
                <input
                  type="text"
                  onChange={(e) => ipValChange(e?.target?.value, row?.original?.trackId, data, notes)}
                  defaultValue={notes}
                  name={`input${row?.original?.trackId}`}
                />
              </div>
            </Tooltip>
          );
        },
      },
      ...Columns.slice(index - 1, index + 1),
      ctr && ctr[0] ? ctr[0] : null,
      amnt && amnt[0] ? amnt[0] : null,
      {
        Header: 'Bid Disc%',
        accessor: 'bidDiscount',
        Cell: ({ cell, row }) => {
          return row.original.isFcCol
            ? '-'
            : cell.value < 0
              ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
              : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`;
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        Cell: ({ cell }) => parseFloat(cell.value).toFixed(2),
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmount',
        Cell: ({ cell }) => parseFloat(cell.value).toFixed(2),
      },
      ...Columns.slice(index + 1, Columns.length),
    ]
      .filter((el) => el && el)
      .map((col) => ({ ...col, id: col.accessor }));
    return columns;
  };

  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const TIME = [
    { name: 'Today', dateFrom: moment().startOf('day'), dateTo: moment() },
    {
      name: 'Yesterday',
      dateFrom: moment().startOf('day').add(-1, 'days'),
      dateTo: moment().endOf('day').add(-1, 'days'),
    },
    {
      name: 'Last 7 Days',
      dateFrom: moment().startOf('day').add(-6, 'days'),
      dateTo: moment(),
    },
    {
      name: 'This Month',
      dateFrom: moment().startOf('month'),
      dateTo: moment(),
    },
    { name: 'This Year', dateFrom: moment().startOf('year'), dateTo: moment() },
    { name: 'All', dateFrom: moment(user.createdAt), dateTo: moment() },
    { name: 'Custom Range', datePicker: true },
  ];

  const getTitle = () => {
    if (tab === BIDTAB.MYBIDCART) {
      return JSON.stringify(nextBidData) !== '[]' && dataList && dataList.length
        ? TITLE.BID + ' (' + dataList?.length + ')'
        : TITLE.BID + ' (0)';
    } else {
      return JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length
        ? TITLE.BID + ' (' + state.count + ')'
        : TITLE.BID + ' (0)';
    }
  };

  const getSelection = () => {
    return tab === BIDTAB.HISTORY ? null : (
      <SelectStone
        profilListing={getBidType() ? 'listingSelected diamondListStone my_bid' : ''}
        sum={state.sum}
        currentType={currentType.current}
      />
    );
  };
  const ipValChange = (value, ide, row, defaultValue) => {
    let line = row;
    if (ide) {
      let ind = line?.findIndex((item) => item?.trackId === ide);
      line[ind].notes = value;
      line[ind].defaultValue = defaultValue;
    } else {
      line = line?.map((l) => ({ ...l, note: value, defaultValue }));
    }
    setData(line);
  };
  const handleSaveNotes = () => {
    // Collect notes from all rows (assuming each row has a unique ID)
    const modifiedNotes = [];
    const ref = data?.forEach((item) => {
      if ((item?.defaultValue || item?.defaultValue === '') && item?.notes !== item?.defaultValue) {
        modifiedNotes.push({ id: item?.trackId, note: item?.notes });
      }
    });
    if (modifiedNotes?.length > 0) {
      const payload = {
        notes: modifiedNotes,
        trackType: 10,
        type: "bid"
      };
      const request = {
        ...ADD_NOTES,
        request: payload,
      };
      UtilService.callApi(request, function (err, data) {
        if (err) {
          setData([]);
          fetch();
          return err;
        } else if (data && data.code === 'OK') {
          setData([]);
          fetch();
          OpenNotification({
            type: 'success',
            title: data?.message || 'Your Request is updated successfully.',
          });
        }
      });
    }
  };

  const getAction = () => {
    return tab === BIDTAB.LIVE ? (
      <DiamondListingAction
        {...state}
        {...props}
        nocart
        nowatch
        nonote
        noreminder
        // myBidCartStone
        noconfirm={nextBidData?.sub_types?.subTypeCode !== 'BIWI'}
        noquote
        update={get(USER_PERMIT, 'MY_BID.update', false)}
        nonocompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        nohold
        nofinalcalc
        noenquiry
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        currentType={currentType.current}
        nopaginate
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        saveNote={true}
        onSave={handleSaveNotes}
      />
    ) : null;
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className="bidEndBlock d-flex align-items-center">
        {JSON.stringify(nextBidData) !== '[]' ? (
          <span className="bidEndLabel">
            <IntlMessages id="app.BidEndIn" /> :
          </span>
        ) : (
          ''
        )}
        {JSON.stringify(nextBidData) !== '[]' ? (
          <Countdown
            currTime={currTime}
            date={
              nextBidData &&
              nextBidData.sub_types &&
              nextBidData.sub_types.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate)
            }
            bidEndTime={bidEndTime}
          />
        ) : (
          ''
        )}
      </div>
    ) : null;
  };

  const getRadio = () => {
    return (
      <Radio.Group
        value={tab}
        onChange={(e) => {
          setTab(e.target.value);
          if (!window.location.search && e.target.value === '2') {
            window.history.pushState(`${window.location.pathname}`, '', `?cart`);
          } else {
            window.history.pushState(`${window.location.pathname}`, '', '/my-bid');
          }
        }}
      >
        <Radio value={BIDTAB.LIVE}>
          <IntlMessages id="app.myBid" />
        </Radio>
        <Radio value={BIDTAB.MYBIDCART}>
          <IntlMessages id="app.MyBidCart" />
        </Radio>
      </Radio.Group>
    );
  };
  return (
    <div className="searchResultListWrapper">
      {dimaondDetailPage === 'diamond-detail' || dimaondDetailPage === 'dna' ? (
        ''
      ) : (
        <div className="selectStonHeader">
          <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
            <Heading title={getTitle()} />
            {JSON.stringify(nextBidData) !== '[]' ? (
              <div className="d-flex">
                <div className="d-flex align-items-center j-content-center mr-10 flex-wrap">
                  <div style={{ display: 'block' }}>{getRadio()}</div>
                  <Link
                    className="commonOutline smallButton"
                    to={
                      nextBidData?.sub_types?.subTypeCode === 'BIWI'
                        ? '/' + LISTINGPAGES.SEARCH_DIAMOND
                        : '/' + LISTINGPAGES.BID
                    }
                  >
                    <IntlMessages id="app.GoToBid" />
                  </Link>
                </div>
                {getBidBlock()}
              </div>
            ) : (
              ' '
            )}
            {JSON.stringify(nextBidData) !== '[]' ? getSelection() : ''}
          </div>
        </div>
      )}
      {tab === BIDTAB.MYBIDCART ? (
        <BidCartList {...props} fetchCarts={fetchCarts} />
      ) : (
        <div className="searchInnerResult">
          <div
            className="searchResultTable"
            style={{ height: check?.length ? `calc(100vh - 219px)` : `calc(100vh - 170px)`, overflow: 'auto' }}
          >
            {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? (
              <Table
                noCheckBox={tab === BIDTAB.HISTORY}
                data={state.data}
                columns={state.columns}
                loading={state.loading}
                ref={tableRef}
                handleSort={handleSort}
                currentType={currentType.current}
                defaultChecked={state.defaultChecked}
              />
            ) : (
              ''
            )}
            {showError ? (
              <NoDataShow
                message={'No Bid submission Found, Hurry UP..!!! Submit your bid before bid ends.'}
                noData
                subTypeCode={nextBidData?.sub_types?.subTypeCode === 'BIWI'}
              />
            ) : (
              ''
            )}
            {JSON.stringify(nextBidData) === '[]' ? (
              <NoDataShow message={'No Bid is active now. Please purchase diamond!!'} redirectURL />
            ) : (
              ''
            )}
            {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? getAction() : ''}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(BidList);
