import React, { useEffect, useReducer, useState } from 'react';
import IntlMessages from '../../../util/IntlMessages';
import { GET_NOTIFICATIONS, MARK_AS_READ, ADMIN_UPDATE_USER } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';
import moment from 'moment';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';

const NotificationItem = (props) => {
  return (
    <div onClick={props.onClick} className={`notificationDropdownDetail ${props.read && 'readMessage'}`}>
      <div className={'norificationDropdownItem'}>
        <h2>{props.title}</h2>
        {props.pcv && <div className="d-flex flex-wrap basicDetail"></div>}
        <span>{props.time}</span>
      </div>
    </div>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const getNotifications = () => {
    let obj = {
      ...GET_NOTIFICATIONS,
      request: {
        page: 1,
        limit: 10,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === 'OK') {
        if (data.data) {
          if (data.data.isPermissionChanged) {
            let setPermit = {};
            data.data.userPermissions.data.map((x) => {
              setPermit[x.module] = x.permissions;
            });
            UtilService.setLocalStorageItem(setPermit, `${LOCAL_STORAGE_VAR}-userPermissions`);
            updateUserPermission();
            forceUpdate();
          }
          setNotifyData([...data.data.list]);
          props.updatedCount();
        }
      } else {
        setNotifyData([]);
      }
    });
  };

  const MarkAsRead = (id) => {
    let obj = {
      ...MARK_AS_READ,
      request: {
        id: id,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        //console.log("mark err", err);
      } else if (data && data.code === 'OK') {
        getNotifications();
      } else {
        //setData(undefined);
      }
    });
  };

  const updateUserPermission = () => {
    let obj = {
      ...ADMIN_UPDATE_USER,
      request: {
        isPermissionChanged: false,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) throw err;
    });
  };

  useEffect(() => {
    getNotifications();
  }, [props.count]);

  return (
    <div className="notificationDropdown">
      <div className="notificationItem">
        <div className="notificationTitle">
          <IntlMessages id="app.New" />
        </div>
        <div className="notificationInner">
          {notifyData && notifyData.length >= 1
            ? notifyData.map((e, i) => {
                let time = moment(e.updatedAt).fromNow();
                return (
                  <NotificationItem
                    key={i}
                    onClick={() => {
                      MarkAsRead(e.id);
                    }}
                    title={e.title}
                    pcv={e.message}
                    time={time}
                    read={e.isRead}
                  />
                );
              })
            : 'No Notification Found'}
          <a href="/notifications" className="viewLink">
            View All Notifications
          </a>
          {/* <NotificationItem
            pcv
            read
            title="Your offer is declined by kalpesh zalavadiya"
            time="2 Hours agos"
          /> */}
        </div>
      </div>
    </div>
  );
};
export default NotificationDropdown;

/*
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IntlMessages from "../../../util/IntlMessages";
import { NOTIFICATION_LIST, NOTIFICATION_READ } from "../../../constants/apiConstant";
import UtilService from "../../../services/util";

const NotificationItem = (props) => {
    return (
        <div onClick={props.onClick} className={`notificationDropdownDetail readMessage`}>
            <h2>{props.title}</h2>
            {props.pcv &&
                <div className="d-flex flex-wrap basicDetail">
                    <div className="detail"><span className="mr-5">{props.pcv}</span></div>
                    {/* <div className="detail"><span className="mr-5">Carat : </span><span>0.10</span></div>
                    <div className="detail"><span>Value : </span><span>$1,000,00</span></div>
                </div>
            }
            <span>{props.time}</span>
        </div>
    )
}


const NotificationDropdown = (props) => {
    const [notifyData, setNotifyData] = useState([])
    const [tempFalg, setTempFalg] = useState(true)
    useEffect(() => {
        if (tempFalg) {
            getNotify()
            setTempFalg(false)
        }
    }, [])
    const getNotify = () => {

        let obj = {
            ...NOTIFICATION_LIST,
            request: {

            },
        };
        UtilService.callApi(obj, function (err, data) {

            if (err) {
                setNotifyData([])
            } else if (data && data.code === "OK") {
                if (data.data && data.data.list && data.data.list.length) {
                    setNotifyData([...data.data.list])

                }

            } else {
                setNotifyData([])

            }
        });
    }
    const readNotify = id => {
        let obj = {
            ...NOTIFICATION_READ,
            request: {
                id: id
            },
        };
        UtilService.callApi(obj, function (err, data) {
            if (err) {
            } else if (data && data.code === "OK") {
                // if (data.data && data.data.list && data.data.list.length) {
                //     setNotifyData([...data.data.list])

                // }

            } else {
                // setNotifyData([])

            }
        });
    }
    return (
        <div className="notificationDropdown">
            <div className="notificationItem">
                <IntlMessages id="app.New" />
                <div className="notificationInner">
                    {notifyData && notifyData.length !== 0 ? notifyData.map(not => {
                        let currentDate = new Date()
                        let createdDate = new Date(not.createdAt)
                        let diffInMilliSeconds = Math.abs(currentDate - createdDate) / 1000;
                        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
                        diffInMilliSeconds -= hours * 3600;
                        return <NotificationItem onClick={() => { readNotify(not.id) }} pcv={not.message} read={true} title={not.title} time={`${hours} Hours agos`} />

                    }) : "No Notification Found"}

                </div>
            </div>
            {/* <div className="notificationItem">
                <div className="notificationTitle">Yesterday </div>
                <div className="notificationInner">
                    <div className="notificationDropdownDetail">
                        <h2>You have added 2 diamonds in your cart.</h2>
                        <span>2 Hours agos</span>
                    </div>
                    <div className="notificationDropdownDetail">
                        <h2>You have added 2 diamonds in your cart.</h2>
                        <span>2 Hours agos</span>
                    </div>
                    <div className="notificationDropdownDetail">
                        <h2>You have added 2 diamonds in your cart.</h2>
                        <span>2 Hours agos</span>
                    </div>
                    <div className="notificationDropdownDetail">
                        <h2>You have added 2 diamonds in your cart.</h2>
                        <span>2 Hours agos</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NotificationDropdown
*/
