import React from 'react';
import { Link } from 'react-router-dom';
import IntlMessages from '../../../util/IntlMessages';

const HeaderAccountList = (props) => {
  return (
    <li onClick={() => (props.link && !props.isGuest ? props.history.push(props.link) : props.showGuest())}>
      <div className="userDetailListItem" onClick={() => (props.onClick ? props.onClick() : {})}>
        <Link to="" className="detailItemName">
          {props.title}
        </Link>
        <img src={require('../../../assets/svg/Header/right.svg')} alt="icon" />
      </div>
      {props.basicDetailnull ? (
        <div className="uerDetailBasic">
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Pieces" />
            </span>
            <span>{props.details ? props.details.pieces || 0 : 0}</span>
          </div>
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Carats" />
            </span>
            <span>{props.details ? parseFloat(props.details.carat || 0).toFixed(2) : 0}</span>
          </div>
          <div className="userDetailBasicItem">
            <span>
              <IntlMessages id="app.Value" />
            </span>
            <span>{props.details ? parseFloat(props.details.totals || 0).toFixed(2) : 0}</span>
          </div>
        </div>
      ) : null}
    </li>
  );
};
export default HeaderAccountList;
