import validator from 'validator';
import isEmpty from 'lodash/isEmpty';
import Messages from '../../constants/ValidationMessage';

export function signupValidator(data, clicked = false) {
  let errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }
  if (data.natureOfOrg && data.natureOfOrg.length === 0) {
    errors.natureOfOrg = Messages.BusinessType;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }
  if (data.accountName !== undefined && validator.isEmpty(data.accountName)) {
    errors.accountName = Messages.ownerName;
  }
  // if (data.userKyc && !data.userKyc.find(x => x.file === 'photoId' )) {
  //   errors.userKyc = Messages.PersonalDoc;
  // }
  // if (data.userKyc &&!data.userKyc.find(x => x.file === 'businessId' ) ) {
  //   errors.BusinessDoc = Messages.BusinessDoc;
  // }

  if (data && data.reference && data.reference[0].email !== '' && !validator.isEmail(data.reference[0].email)) {
    errors.reference1email = Messages.EmailValid;
  }

  if (data && data.reference && data.reference[1].email !== '' && !validator.isEmail(data.reference[1].email)) {
    errors.reference2email = Messages.EmailValid;
  }

  if (data.accountName !== undefined && !validator.isEmpty(data.accountName)) {
    if (data.accountName !== undefined && !validator.matches(data.accountName, /^[a-zA-Z ]*$/)) {
      errors.accountName = Messages.NameValid;
    }
  }
  // if (
  //   data.designation === undefined ||
  //   data.designation === null ||
  //   data.designation === "Select Designation" ||
  //   validator.isEmpty(data.designation)
  // ) {
  //   errors.designation = Messages.Designation;
  // }

  if ((data.designation && data.designation.length === 0) || data.designation === 'Select Designation') {
    errors.designation = Messages.Designation;
  }
  if ((data.seller && data.seller.length === 0) || data.seller === 'Select Sales Person') {
    errors.seller = Messages.SelesPerson;
  }

  if (clicked) {
    if (data.docP && data.docP.length === 0) {
      errors.PersonalType = Messages.PersonalType;
    }

    if (data.docB && data.docB.length === 0) {
      errors.BusinessType = Messages.BusinessType;
    }
  }

  if (data.lastName !== undefined && validator.isEmpty(data.lastName)) {
    errors.lastName = Messages.LastName;
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }
  if (data.emails !== undefined && validator.isEmpty(data.emails)) {
    errors.emails = Messages.EmailRequired;
  }
  if (data.emails !== undefined && !validator.isEmpty(data.emails)) {
    if (data.emails !== undefined && !validator.isEmail(data.emails)) {
      errors.emails = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }
  if (data.zipCode !== undefined && validator.isEmpty(data.zipCode)) {
    errors.zipCode = Messages.pinCode;
  }
  if (data.username !== undefined && validator.isEmpty(data.username)) {
    errors.username = Messages.UserName;
  }
  // if (data.seller !== undefined && validator.isEmpty(data.seller)) {
  //   errors.seller = Messages.Seller;
  // }

  if (data.address !== undefined && validator.isEmpty(data.address)) {
    errors.address = Messages.Address;
  }
  if (data.city === null) {
    errors.city = Messages.City;
  }

  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  if (data.companyName !== undefined && validator.isEmpty(data.companyName)) {
    errors.companyName = Messages.CompanyName;
  }
  // if (data.password !== undefined && validator.isEmpty(data.password)) {
  //     errors.password = Messages.Password;
  // }
  if (!data.noCheck) {
    if (data.password !== undefined && validator.isEmpty(data.password)) {
      errors.password = Messages.Password;
    }
  }
  let regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  if (data.password !== undefined && !validator.isEmpty(data.password)) {
    // if (
    //   data.password !== undefined &&
    //   validator.matches(data.password, regx) === false
    // ) {
    //   errors.password = Messages.PasswordValid;
    // }
    // if (
    //     data.password !== undefined &&
    //     validator.isLength(data.password, { min: 8 }) === false
    // ) {
    //     errors.password = Messages.PasswordLength;
    // }
  }

  if (data.confirmPassword !== undefined && validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }
  if (
    // data.password !== undefined &&
    data.confirmPassword !== undefined &&
    // !validator.isEmpty(data.password) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }
  return { errors, isValid: isEmpty(errors) };
}
export function signupValidatorBuy(data) {
  let errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }
  if (data.lastName !== undefined && validator.isEmpty(data.lastName)) {
    errors.lastName = Messages.LastName;
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }
  if (data.zipCode !== undefined && validator.isEmpty(data.zipCode)) {
    errors.zipCode = Messages.pinCode;
  }
  // if (data.username !== undefined && validator.isEmpty(data.username)) {
  //     errors.username = Messages.UserName;
  // }

  if (data.address !== undefined && validator.isEmpty(data.address)) {
    errors.address = Messages.Address;
  }
  if (data.city === null) {
    errors.city = Messages.City;
  }
  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }
  if (data.designation !== undefined && data.designation !== null && validator.isEmpty(data.designation)) {
    errors.designation = Messages.Designation;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  if (data.companyName !== undefined && validator.isEmpty(data.companyName)) {
    errors.companyName = Messages.CompanyName;
  }
  // if (data.password !== undefined && validator.isEmpty(data.password)) {
  //     errors.password = Messages.Password;
  // }

  let regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  if (data.password !== undefined && !validator.isEmpty(data.password)) {
    if (data.password !== undefined && validator.matches(data.password, regx) === false) {
      errors.password = Messages.PasswordValid;
    }
    if (data.password !== undefined && validator.isLength(data.password, { min: 8 }) === false) {
      errors.password = Messages.PasswordLength;
    }
  }

  if (
    data.password !== undefined &&
    !validator.isEmpty(data.password) &&
    data.confirmPassword !== undefined &&
    validator.isEmpty(data.confirmPassword)
  ) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }
  if (
    data.password !== undefined &&
    data.confirmPassword !== undefined &&
    !validator.isEmpty(data.password) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }
  return { errors, isValid: isEmpty(errors) };
}

export function forgotPassword(data) {
  let errors = {};

  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}
export function shareEmail(data) {
  let errors = {};
  if (data.name !== undefined && validator.isEmpty(data.name)) {
    errors.name = Messages.Name;
  }
  if (data.subject !== undefined && validator.isEmpty(data.subject)) {
    errors.subject = Messages.Subject;
  }
  if (data.message !== undefined && validator.isEmpty(data.message)) {
    errors.message = Messages.Message;
  }

  if (data.name !== undefined && !validator.isEmpty(data.name)) {
    if (data.name !== undefined && !validator.matches(data.name, /^[a-zA-Z ]*$/)) {
      errors.name = Messages.NameValid;
    }
  }
  if (data.to !== undefined && validator.isEmpty(data.to)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.to !== undefined && !validator.isEmpty(data.to)) {
    if (data.to !== undefined && !validator.isEmail(data.to)) {
      errors.email = Messages.EmailValid;
    }
  }
  if (data.cc !== undefined && !validator.isEmpty(data.cc)) {
    if (data.cc !== undefined && !validator.isEmail(data.cc)) {
      errors.cc = Messages.EmailValid;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}

export function profileValidator(data) {
  let errors = {};
  if (data.firstName !== undefined && validator.isEmpty(data.firstName)) {
    errors.firstName = Messages.FirstName;
  }

  if (data.firstName !== undefined && !validator.isEmpty(data.firstName)) {
    if (data.firstName !== undefined && !validator.matches(data.firstName, /^[a-zA-Z ]*$/)) {
      errors.firstName = Messages.NameValid;
    }
  }

  if (data.lastName !== undefined && !validator.isEmpty(data.lastName)) {
    if (data.lastName !== undefined && !validator.matches(data.lastName, /^[a-zA-Z ]*$/)) {
      errors.lastName = Messages.NameValid;
    }
  }
  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = Messages.EmailRequired;
  }
  if (data.email !== undefined && !validator.isEmpty(data.email)) {
    if (data.email !== undefined && !validator.isEmail(data.email)) {
      errors.email = Messages.EmailValid;
    }
  }

  if (data.mobile !== undefined && validator.isEmpty(data.mobile)) {
    errors.mobile = Messages.PhoneRequire;
  }
  if (data.mobile !== undefined && !validator.isEmpty(data.mobile)) {
    if (data.mobile !== undefined && !validator.isNumeric(data.mobile)) {
      errors.mobile = Messages.PhoneValid;
    }
  }
  if (data.whatsapp !== undefined && !validator.isEmpty(data.whatsapp)) {
    if (data.whatsapp !== undefined && !validator.isNumeric(data.whatsapp)) {
      errors.whatsapp = Messages.PhoneValid;
    }
  }

  if (data.pinCode !== undefined && validator.isEmpty(data.pinCode)) {
    errors.pinCode = Messages.pinCode;
  }

  if (data.city === null) {
    errors.city = Messages.City;
  }
  if (data.state === null) {
    errors.state = Messages.State;
  }
  if (data.country === null) {
    errors.country = Messages.Country;
  }

  if (data.city !== undefined && data.city !== null && validator.isEmpty(data.city)) {
    errors.city = Messages.City;
  }

  if (data.state !== undefined && data.state !== null && validator.isEmpty(data.state)) {
    errors.state = Messages.State;
  }

  if (data.country !== undefined && data.country !== null && validator.isEmpty(data.country)) {
    errors.country = Messages.Country;
  }

  return { errors, isValid: isEmpty(errors) };
}

export function resetPasswordValidator(data) {
  let errors = {};
  if (data.newPassword !== undefined && validator.isEmpty(data.newPassword)) {
    errors.newPassword = Messages.Password;
  }
  let regx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d][A-Za-z\d!@#$%^&*()_+]{6,19}$/;

  if (data.newPassword !== undefined && !validator.isEmpty(data.newPassword)) {
    if (data.newPassword !== undefined && validator.matches(data.newPassword, regx) === false) {
      errors.newPassword = Messages.PasswordValid;
    }
    if (data.newPassword !== undefined && validator.isLength(data.newPassword, { min: 8 }) === false) {
      errors.newPassword = Messages.PasswordLength;
    }
  }
  if (data.confirmPassword !== undefined && validator.isEmpty(data.confirmPassword)) {
    errors.confirmPassword = Messages.ConfirmPassword;
  }
  if (
    data.newPassword !== undefined &&
    data.confirmPassword !== undefined &&
    !validator.isEmpty(data.newPassword) &&
    !validator.isEmpty(data.confirmPassword)
  ) {
    if (data.newPassword !== data.confirmPassword) {
      errors.confirmPassword = Messages.SamePasword;
    }
  }

  return { errors, isValid: isEmpty(errors) };
}
