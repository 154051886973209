import React from 'react';
import StaticHeader from '../StaticHeader';
import FileUpload from '../common/UploadImage/index';
import InputBlock from '../common/InputBlock';
import '../TermsOfUse/staticContent.less';
import Heading from '../common/Heading';
import './career.less';

const CareerDetail = () => {
  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading
            title="UX/Front End Designer & Creative Web UI Graphic Designer"
            className="staticMainTitle text-center mb-50"
          />
          <div>
            <div className="width-100 d-flex j-space-between mb-20">
              <div className="width-49">
                <div className="staticContentWrapper">
                  <div className="detailList">
                    <span className="staticDetailLabel">Job Loaction :</span>
                    <span className="statisDetailvalue">Indore</span>
                  </div>
                  <div className="detailList">
                    <span className="staticDetailLabel">Salary :</span>
                    <span className="statisDetailvalue">As per Company Standards (Between Rs. 15000-35000)</span>
                  </div>
                  <div className="detailList">
                    <span className="staticDetailLabel">Experience :</span>
                    <span className="statisDetailvalue">Minimum 2 to 6 Yers of experience required</span>
                  </div>
                  <div className="detailList">
                    <span className="staticDetailLabel">Working Days :</span>
                    <span className="statisDetailvalue">5 days working (Sat & Sun Off).</span>
                  </div>
                </div>
              </div>
              <div className="width-49">
                <div className="staticContentWrapper">
                  <form className="careerForm">
                    <div className="careerFromInner j-space-between d-flex flex-wrap">
                      <InputBlock label="Name" placeholder="Name" />
                      <InputBlock label="Email Address" placeholder="Email Address " />
                      <InputBlock label="Mobile" placeholder="Mobile" />
                      <FileUpload title="Upload Resume" />
                    </div>
                    <div className="text-center">
                      <button className="commonButton">Apply</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="staticContentWrapper">
              <div className="staticList">
                <Heading title="Job Description UX/Front End Designer:-" className="popupInnerTitle" />
                <ul>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.{' '}
                  </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.{' '}
                  </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply
                    dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry.{' '}
                  </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
              </div>
            </div>
            <div className="staticContentWrapper">
              <div className="staticList">
                <Heading title="Job Description Creative Web UI Graphic Designer:-" className="popupInnerTitle" />
                <ul>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                  <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CareerDetail;
