import React, { Component, useCallback } from 'react';
import OfficeSlot from './OfficeSlot';
import SelectOption from '../../SelectOption';
import TextArea from '../../TextArea';
import LeftDatePicker from '../../LeftMultipleSelect/LeftDatePicker';
import { timeSlot } from 'constants/slot';
import Heading from '../../Heading';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import SlotItem from './SlotItem';
import UtilService from 'services/util';
import head from 'lodash/head';
import last from 'lodash/last';
import split from 'lodash/split';
import map from 'lodash/map';
import { CREATE_SCHEDULE, GET_SLOTS } from 'constants/apiConstant';
import OpenNotification from '../../CommonButton/OpenNotification';
import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { Drawer } from 'antd';
import { notify } from 'util/notify';
import { isEmpty } from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCurrentTime } from 'services/util';
import { LoaderService } from 'services/LoaderService';
import { callApi } from 'util/CallAPI';

export const Appointment = {
  location: [{ name: 'India' }, { name: 'Spain' }, { name: 'Hong Kong' }, { name: 'Dubai' }],
  virtualType: [
    { name: 'Phone Call', id: 1 },
    { name: 'Web Conference', id: 2 },
    { name: 'In Person', id: 3 },
  ],
};

export const OfficeFill = () => {
  return (
    <div>
      <div className="offerInputBox">
        <SelectOption
          selectValue={Appointment.virtualType}
          placeholder={<IntlMessages id="app.SelectVirtualType" />}
          label={<IntlMessages id="app.SelectVirtualType" />}
        />
        <TextArea label="Comment" placeholder="Comment" />
      </div>
      <div className="sideBarPopupButton">
        <a className="commonButton">Request Appointment</a>
        <a className="commonOutline">Cancel</a>
      </div>
    </div>
  );
};
class OfficeSidebar extends Component {
  get today() {
    return moment().toISOString();
  }

  get bidConfig() {
    return this.props.bid ?? this.props.bidConfig;
  }

  get initialState() {
    return {
      date: this.today,
      slot: '',
      comment: '',
      slots: [],
      apiSlot: {},
      meetingType: undefined,
    };
  }

  state = this.initialState;

  setTimers = async () => {
    const time = await getCurrentTime();

    this.setState({ currTime: time.iso });
  };
  componentDidMount = () => {
    const objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: 'ASC' }] },
    };
    UtilService.callApi(objData, async (err, res) => {
      if (err) throw err;
      if (res && res.code === 'OK') {
        this.setState({ slots: res.data.list });
      }
    });
    this.setTimers();
  };

  handleSave = async () => {
    const { comment, slot, date, apiSlot, meetingType } = this.state;

    if (!slot) return OpenNotification({ type: 'error', title: 'Please select slot.' });
    if (!date) return OpenNotification({ type: 'error', title: 'Please select date.' });
    const start = moment(`${date} ${head(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm a').toISOString();
    const end = moment(`${date} ${last(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm a').toISOString();

    if (
      !isEmpty(this.props.checked.find((x) => x?.wSts === 'B' || x?.wSts === 'BA')) &&
      !isEmpty(this.bidConfig?.data)
    ) {
      const bidEnd = this.bidConfig?.data?.sub_types?.currentBidEndDate || this.bidConfig?.sub_types?.currentBidEndDate;

      const bidEndDate = moment(bidEnd).format('YYYY-MM-DD');
      const slotDate = moment(date).format('YYYY-MM-DD');
      if (slotDate > bidEndDate) {
        return OpenNotification({
          type: 'error',
          title:
            'Sorry, you can not book an appointment for this time. A select appointment like at least an hour before then bid end.',
        });
      }
    }

    // const arr = await this.props.checked.filter((x) => x.wSts != "SWO")


    const obj = {
      purpose: comment,
      date: end,
      type: 2,
      start: start,
      end: end,
      cabinSlot: [apiSlot],
      diamonds: map(this.props.checked, 'id'),
      meetingType: meetingType,
      csType: 1,
    };

    const objData = { ...CREATE_SCHEDULE, request: obj };
    LoaderService.startLoading();
    const [err, data] = await callApi(objData);

    if (err) {
      return OpenNotification({ type: 'error', title: err.data.message });
    }
    if (data && data.code === 'OK') {
      OpenNotification({ type: 'success', title: data.message });
      this.props.clearAll();
      this.props.onClose();
    }
    LoaderService.stopLoading();
  };

  get workingDays() {
    const workingDates = [];
    //for bid end date
    const currentBidEnd =
      this.bidConfig?.data?.sub_types?.currentBidEndDate || this.bidConfig?.sub_types?.currentBidEndDate;
    const bidEnd = moment(currentBidEnd, 'YYYY-MM-DD');
    const currentDate = moment(this.state.currTime, 'YYYY-MM-DD');
    const bidEndDate = bidEnd.diff(currentDate, 'days');

    let days =
      this.props.checked.find((x) => x?.wSts === 'B' || x?.wSts === 'BA') && !isEmpty(currentBidEnd)
        ? bidEndDate
        : this.props.quoteDay
          ? 3
          : 7;
    let date = moment(new Date());

    while (days > 0) {
      date = date.add(1, 'days');
      if (date.isoWeekday() !== 7) {
        days -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }

    return workingDates;
  }

  render() {
    const isMobile = window.innerWidth <= 767;
    const { companyName } = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    const finalSlots = this.state.slots;
    console.log('================this.props.checked====================');
    console.log(this.props.checked);
    console.log('====================================');
    return (
      <>
        <div className="appoinmentOfficeRightTop">
          <Heading className="popupInnerTitle" title={<IntlMessages id="app.Appointment" />}></Heading>
          <span className="companyName">({companyName})</span>
          {this.state.date && (
            <span className="appointmentDate">{moment(this.state.date).format('ddd, MMM Do YYYY')}</span>
          )}
          <Heading className="popupInnerTitle" title={'Location'}></Heading>
          <span className="companyName">Mumbai</span>
        </div>
        <div className="officeBookWrapper">
          {/* {!this.bidConfig && ( */}
          <div className="officeBookLeft height_auto">
            <div className="officeDateListing">
              <div className="officeTopLeft">
                <LeftDatePicker
                  date={this.state.date ? this.state.date : ''}
                  Singledate
                  title={<IntlMessages id="app.SelectMonth" />}
                  onDateChange={(dt) => {
                    this.setState({ date: moment(dt).toISOString() });
                  }}
                  disabledDate={(current) => {
                    return current <= moment().startOf('day') || moment(current).isoWeekday() === 7;
                  }}
                />
              </div>
              <div className="cardListinOffice">
                {this.workingDays.map((date) => (
                  <div
                    key={date}
                    onClick={() => this.setState({ date })}
                    className={`cardListOfficeItem ${moment(this.state.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && `active`
                      }`}
                  >
                    <div className="cardListOfficeTop">
                      <div className="cardListOfficeDate">{moment(date).format('ddd, MMM Do YYYY')}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="officeBookRight">
            <div className="soltWrapper">
              <div className="slotBookLeft">
                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">
                      <IntlMessages id="app.SelectTimeSlot" />
                    </div>
                    <div className="officeBookSoltInner">
                      {this.state.date &&
                        finalSlots.map((slot) => {
                          const start = moment(slot.start).format('hh:mm A');
                          const end = moment(slot.end).format('hh:mm A');
                          const display = `${start} - ${end}`;
                          const disabled = moment(`${moment(this.state.date).format('YYYY-MM-DD')} ${end}`) < moment();

                          return (
                            <SlotItem
                              key={display}
                              time={display}
                              className={this.state.slot === display ? `slotBook` : disabled ? `alreadyBooked` : ''}
                              tooltip={<IntlMessages id="app.customerCare.BookAppointment" />}
                              onClick={() =>
                                disabled
                                  ? notify.error({ message: 'Slot Expired' })
                                  : this.setState({ slot: display, apiSlot: slot })
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="selectVirtualAction pos_rel">
                  <div className="commonTextArea from-group">
                    <label>
                      <IntlMessages id="app.comment" />
                    </label>
                    <IntlMessages id="app.comment">
                      {(placeholder) => (
                        <textarea
                          placeholder={placeholder}
                          onChange={(e) => this.setState({ comment: e.target.value })}
                        >
                          {this.state.comment}
                        </textarea>
                      )}
                    </IntlMessages>
                  </div>
                  <div className="d-flex align-items-center j-space-between mt-20">
                    <SelectOption
                      value={this.state.meetingType}
                      placeholder={<IntlMessages id="app.SelectVirtualType" />}
                      selectValue={Appointment.virtualType}
                      label={<IntlMessages id="app.AvailableRoom" />}
                      onChange={(value) => this.setState({ meetingType: value })}
                    />
                    {isMobile ? (
                      <div className="sideBarPopupButton">
                        <a className="commonButton" onClick={() => this.handleSave()}>
                          <IntlMessages id="app.RequestAppointment" />
                        </a>
                      </div>
                    ) : (
                      <button onClick={() => this.handleSave()}>
                        <IntlMessages id="app.RequestAppointment" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  connect((store) => ({
    bidConfig: store.bidConfig,
  })),
)(OfficeSidebar);
