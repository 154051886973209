import React, { useState, useEffect } from 'react';
import { STATUS_COLOR } from '../common/DiamondListing/Status';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import { getParams, LISTINGPAGES, getStoneInfo, FLAGICON } from './DiamondListFunctions';
import { DIAMOND_BID } from '../../constants/Common';
import Countdown from '../Offer/Timer';
import moment from 'moment';
import { OFFERSTATUS } from '../Offer';
import BidCalculationMobile from 'components/common/DiamondListing/BidCalculationMobile';
import BidValueCalculationMobile from 'components/common/DiamondListing/BidAmountCalcMobile';
import BidPrCalculationMobile from 'components/common/DiamondListing/BidPrCalcMobile';
import QuoteDiscCalcMobile from 'components/common/DiamondListing/QuoteDisCalcMobile';
import QuoteCtPrCalcMobile from 'components/common/DiamondListing/QuoteCtPrCalcMobile';
import QuoteValueCalcMobile from 'components/common/DiamondListing/QuoteValueCalcMobile';
import BidCartDis from 'components/common/DiamondListing/bidCartDiscountMobile';
import BidCartValueMobile from 'components/common/DiamondListing/BidCartValueMobile';
import BidCartPrMobile from 'components/common/DiamondListing/BidCartPrMobile';
import { Modal } from 'antd';

const MobileViewListCard = (props) => {
  const [locationPopupOpen, setLocationPopupOpen] = useState(false);
  useEffect(() => {
    if (props.areAllChecked) {
      props.value();
    }
  }, []);
  return !props.quotePage ? (
    <div
      className={`diamondListMobile as_app ${props.activeClass ? props.activeClass : null} `}
      onClick={props.onClick}
    >
      <div
        className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}
`}
      >
        <Modal
          visible={locationPopupOpen}
          footer={null}
          onCancel={() => setLocationPopupOpen(false)}
          className="center-banner"
        >
          <span className='locationLabel'>Location : {props?.data?.countryNm}</span>
        </Modal>

        {!props.quotePage && (
          <>
            <div className="caratStatus">
              <p>
                <span className="cVal">{formatNumber(parseFloat(props.data?.crt).toFixed(2))}</span>
                <small className="cName">Carat</small>
              </p>
              <p className={`sStatus ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}`}>
                <span className="lDot"></span>
                <span className="lName">{props.data?.wSts}</span>
              </p>
            </div>
            <div className="diamodListItemContent">
              <div className="priceCaratContainer">
                {FLAGICON[props?.data?.countryNm?.toUpperCase()] ? (
                  <img
                    src={FLAGICON[props?.data?.countryNm?.toUpperCase()]}
                    style={{ width: '20px', height: '15px', alignSelf: 'center' }}
                  />
                ) : (
                  <img
                    onClick={() => setLocationPopupOpen(!locationPopupOpen)}
                    src={require('../../assets/svg/info.svg')}
                    style={{ width: '10px', height: '10px', alignSelf: 'center' }}
                  />
                )}
                <p className="sId">
                  <a href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}>{props.data?.vStnId}</a>
                </p>
                <p className="sShape">{props.data.shpNm}</p>
                <p className="sDis">
                  {' '}
                  {!props.data.isFcCol ? (
                    props.data?.back ? (
                      <span>{formatNumber(parseFloat(props.data.back).toFixed(2))}%</span>
                    ) : (
                      ''
                    )
                  ) : (
                    ''
                  )}
                </p>
                <p className="priceCarat">
                  {props.data.ctPr ? <span>{formatNumber(parseFloat(props.data.ctPr).toFixed(2))} $/Cts</span> : ''}
                </p>
              </div>
              <div className="stoneInfo">
                <p>
                  <span className="sColor">
                    {props.data.isFcCol
                      ? props.data?.fcColNm
                          ?.split(' ')
                          ?.map((x) => x.charAt(0))
                          ?.join()
                          .replaceAll(',', '')
                      : props.data.colNm}
                  </span>
                  <span className="sFlu">{props.data?.clrNm}</span>
                  <span className="sc">{props.data?.cutNm ?? '-'}</span>
                  <span className="sc">{props.data?.polNm ?? '-'}</span>
                  <span className="sc">{props.data?.symNm ?? '-'}</span>
                  <span className="in">{props.data?.fluNm ?? '-'}</span>
                  <span className="lab">{props.data?.lbNm ?? '-'}</span>
                </p>
                <p className="sAmt">
                  {props.data.amt ? <span>{formatNumber(parseFloat(props.data.amt).toFixed(2))} $/Amt</span> : ''}
                </p>
              </div>
              <div className="smeasure">
                <p>
                  <span className="title">M : </span>
                  <span className="val">{props.data.msrmnt ?? '-'}</span>
                </p>
                <p>
                  <span className="title">R : </span>
                  <span className="val">{props.data.ratio ?? '-'}</span>
                </p>
                <p>
                  <span className="title">TD% : </span>
                  <span className="val">{props.data.depPer ?? '-'}</span>
                </p>
                <p>
                  <span className="title">TB% : </span>
                  <span className="val">{props.data.tblPer ?? '-'}</span>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {props.myBidList && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Bid Dis(%): </span>
            <span>{parseFloat(props.data.bidDiscount).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Pr/Ct: </span>
            <span>{parseFloat(props.data.bidPricePerCarat).toFixed(2)}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Bid Amount: </span>
            <span>{parseFloat(props.data.bidAmount).toFixed(2)}</span>
          </div>
        </div>
      )}
      {props.quote && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Final Quote: </span>
            <QuoteDiscCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Final Rate: </span>
            <QuoteCtPrCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Final Value: </span>
            <QuoteValueCalcMobile {...props.quotePopUp} original={props.data} />
          </div>
        </div>
      )}
      {props.bid && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Type: </span>
            <span>{props.data.bidType === DIAMOND_BID.TYPE.OPEN ? 'Look' : 'Blind'}</span>
          </div>
          <div className="quoteExtraItem">
            <span>Status: </span>
            <span>{props.data.status === DIAMOND_BID.STATUS.WIN ? 'Win' : 'Loss'}</span>
          </div>
        </div>
      )}
      {props.finalCalc && (
        <>
          <div className="d-flex j-space-between quoteExtraDetail">
            <div className="quoteExtraItem">
              {props.data.calcDiscount ? <span>Final Disc%: </span> : ''}
              {!props.data.isFcCol ? (
                props.data.calcDiscount ? (
                  <span>{parseFloat(props.data.calcDiscount).toFixed(2)}</span>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </div>
            <div className="quoteExtraItem">
              {props.data.calcPricePerCarat ? <span>Final Rate: </span> : ''}
              {props.data.calcPricePerCarat ? <span>${parseFloat(props.data.calcPricePerCarat).toFixed(2)}</span> : ''}
            </div>
            <div className="quoteExtraItem">
              {props.data.calcAmount ? <span>Final Value: </span> : ''}
              {props.data.calcAmount ? <span>${parseFloat(props.data.calcAmount).toFixed(2)}</span> : ''}
            </div>
          </div>
          <div
            className="d-flex j-space-between quoteExtraDetail"
            style={{border: 'none', marginTop: '-15px' }}
          >
            <div className="quoteExtraItem">
              <span>Net Disc%: </span>
              <span>{props.data.netDis}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Net Pr/Ct: </span>
              <span>${props.data.netPriceCt}</span>
            </div>
            <div className="quoteExtraItem">
              <span>Net Amount: </span>
              <span>${props.data.netAmount}</span>
            </div>
          </div>
        </>
      )}
      {props.bidCartPopup && (
        <div className="d-flex j-space-between quoteExtraDetail" onClick={props.onClick}>
          <div className="quoteExtraItem">
            <span>Bid Disc(%): </span>
            <BidCartDis {...props.bidCartPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Pr/Ct: </span>
            <BidCartPrMobile {...props.bidCartPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Value: </span>
            <BidCartValueMobile {...props.bidCartPopup} original={props.data} />
          </div>
        </div>
      )}
      {props.bidPopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Bid Disc(%): </span>
            <BidCalculationMobile {...props.bidPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Pr/Ct: </span>
            <BidPrCalculationMobile {...props.bidPopup} original={props.data} />
          </div>
          <div className="quoteExtraItem">
            <span>Bid Value: </span>
            <BidValueCalculationMobile {...props.bidPopup} original={props.data} />
          </div>
        </div>
      )}
      {/* {props.watchpopup && (
        <div className="d-flex j-space-between quoteExtraDetail">
          <div className="quoteExtraItem">
            <span>Exp. Disc(%): </span>
            <span>{props.watchpopup(props.data)}</span>
          </div>
        </div>
      )} */}
      {/* {props.data?.remarks && (
        <div className="d-flex j-space-between quoteExtraDetail" >
          <div className="quoteExtraItem">
            <span>Notes: </span>
            <span>{props.data.remarks || '-'}</span>
          </div>
        </div>
      )} */}
    </div>
  ) : (
    props.quotePage && (
      <div
        className={`diamondListMobile as_app ${props.activeClass ? props.activeClass : null} `}
        onClick={props.onClick}
      >
        <div
          className={`diamodListItemWrapper ${STATUS_COLOR[props.data.wSts] ? STATUS_COLOR[props.data.wSts] : 'green'}
    ${props.quotePage ? 'quotePageWrapper' : null}`}
        >
          <div className="diamodListItemContent">
            <div className="mobileListItem activeStatus pending">
              <span>{OFFERSTATUS[props.data.offerStatus]}</span>
              {moment(props.data.offerValidDate) - moment() < 0 ? (
                <span>Offer Expired</span>
              ) : (
                <Countdown
                  date={props.data.offerStatus === 1 ? moment(props.data.offerValidDate) : moment()}
                  currTime={moment()}
                />
              )}
            </div>
            <div className="priceCaratContainer">
              <p className="sId">
                <a href={'/' + LISTINGPAGES.DETAIL + '/' + props.data.id}>{props.data?.vStnId}</a>
              </p>
              <p className="sShape">{props.data.shpNm}</p>
              <p className="sDis">
                {' '}
                {(props.data.back ? parseFloat(props.data.back).toFixed(2) + ' / ' : '') +
                  parseFloat(props.data.ctPr).toFixed(0)}
              </p>
              <p className="priceCarat">
                {' '}
                {(props.data.newDiscount ? parseFloat(props.data.newDiscount).toFixed(2) + ' / ' : '') +
                  parseFloat(props.data.newPricePerCarat).toFixed(0)}
              </p>
            </div>
            <div className="stoneInfo">
              <p>
                <span className="sColor">
                  {props.data.isFcCol
                    ? props.data?.fcColNm
                        ?.split(' ')
                        ?.map((x) => x.charAt(0))
                        ?.join()
                        .replaceAll(',', '')
                    : props.data.colNm}
                </span>
                <span className="sFlu">{props.data?.clrNm}</span>
                <span className="sc">{props.data?.cutNm ?? '-'}</span>
                <span className="sc">{props.data?.polNm ?? '-'}</span>
                <span className="sc">{props.data?.symNm ?? '-'}</span>
                <span className="in">{props.data?.fluNm ?? '-'}</span>
                <span className="lab">{props.data?.lbNm ?? '-'}</span>
              </p>
              <p className="sAmt">
                {props.data.newDiscount ? (
                  <span>{formatNumber(parseFloat(props.data.newDiscount).toFixed(2))} Bargain(%)</span>
                ) : (
                  ''
                )}
              </p>
            </div>
            <div className="smeasure">
              <p>
                <span className="title">M : </span>
                <span className="val">{props.data.msrmnt ?? '-'}</span>
              </p>
              <p>
                <span className="title">R : </span>
                <span className="val">{props.data.ratio ?? '-'}</span>
              </p>
              <p>
                <span className="title">TD% : </span>
                <span className="val">{props.data.depPer ?? '-'}</span>
              </p>
              <p>
                <span className="title">TB% : </span>
                <span className="val">{props.data.tblPer ?? '-'}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
export default MobileViewListCard;