import React, { Component } from 'react';
import LangDropdown from './LangDropdown';
import { Select, Dropdown } from 'antd';
import SalesPerson from '../../../common/SalesPerson';
import { LOCAL_STORAGE_VAR, BASE_URL_ATTACH } from '../../../../constants/Common';
import { connect } from 'react-redux';
import DefaultUser from '../../UserProfileDefault';

import './headerstrip.less';

class HeaderStrip extends Component {
  state = {
    date: new Date(),
    salesOption: false,
    rates: {},
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`) || false,
    currentLocale: 'Asia/Kolkata',
  };

  // componentDidMount() {
  //   this.timerID = setInterval(() => this.tick(), 1000);
  // }

  // componentWillUnmount() {
  //   clearInterval(this.timerID);
  // }

  // tick = () => this.setState({ date: new Date() });

  salesDropdown = () => this.setState({ salesOption: !this.state.salesOption });

  HeaderStirpItem = (props) => {
    return (
      <div className="headerStripItem">
        <span>{props.title}</span>
        {props.country && (
          <Select
            value={this.state.currentLocale}
            dropdownClassName="timeLagSelectDropdown"
            className="timeLagSelect"
            onChange={(value) => this.setState({ currentLocale: value })}
          >
            <Select.Option value="Asia/Kolkata">India</Select.Option>
            <Select.Option value="Asia/Hong_Kong">HK</Select.Option>
            <Select.Option value="America/New_York">USA</Select.Option>
            <Select.Option value="Asia/Dubai">Dubai</Select.Option>
            <Select.Option value="Asia/Jerusalem">Israel</Select.Option>
            <Select.Option value="Australia/Sydney">Australia</Select.Option>
            <Select.Option value="Asia/Tokyo">Japan</Select.Option>
            {/* <Option value="America/New_York">Vitenam</Option> */}
          </Select>
        )}
        <span className="headerStripValue">{props.value}</span>
      </div>
    );
  };

  render() {
    const { salesData /* , rates */ } = this.props;
    // const { name, email, mobile, countryCode } = JSON.parse(
    //   window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)),
    // );
    // const phone = `+${countryCode} ${mobile}`;

    return (
      <div>
        <div className={`HeaderStripBlock ${this.props.className}`}>
          {/* <div className="HeaderStripLeft">
            {this.HeaderStirpItem({ title: <IntlMessages id="app.AdvancePayment" />, value: 73.4 })}

            {this.HeaderStirpItem({ title: "COD", value: 73.4 })}

            {this.HeaderStirpItem({
              country: true,
              title: <IntlMessages id="app.Time" />,
              value: moment().tz(this.state.currentLocale).format("HH:mm:ss"),
            })}
          </div> */}

          <div className="HeaderStripRight">
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
            {salesData && !this.state.isGuest && (
              <Dropdown
                getPopupContainer={(trigger) => trigger}
                overlay={
                  <div className="salesPersonDropdown">
                    <SalesPerson
                      email={salesData && salesData.email ? salesData.email : '--'}
                      phone={salesData && salesData.mobile ? salesData.mobile : '--'}
                      skype={salesData && salesData.skype ? salesData.skype : '--'}
                    />
                  </div>
                }
                trigger={['click']}
              >
                <div className="SalesPersonWrapper" onClick={(e) => e.preventDefault()}>
                  <>
                    <div className="salesPersonProfile">
                      {salesData && salesData.photoId ? (
                        <img className="profilePicImg" src={`${BASE_URL_ATTACH}/${salesData.photoId}`} alt="profile" />
                      ) : (
                        <DefaultUser />
                        // <img
                        //   src={require("../../../../assets/img/user-default.png")}
                        //   alt="profile"
                        // />
                      )}
                    </div>
                    <div className="salesPersonDetail" onClick={this.salesDropdown}>
                      {salesData && (
                        <span>
                          {salesData.firstName} {salesData.lastName}
                        </span>
                      )}
                      {this.state.salesOption ? (
                        <img
                          className="buttonArrow"
                          src={require('../../../../assets/svg/Dashboard/up-arrow-black.svg')}
                          alt=""
                        />
                      ) : (
                        <img
                          className="buttonArrow"
                          src={require('../../../../assets/svg/Dashboard/down-arrow.svg')}
                          alt=""
                        />
                      )}
                    </div>
                  </>
                </div>
              </Dropdown>
            )}
          </div>
        </div>
        {/* <div className="downArrow" onClick={this.props.onClick}>
          <img
            className="closeImage"
            src={require("../../../../assets/svg/Header/down-arrow.svg")}
            alt=""
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = ({ sales }) => {
  return {
    salesData: sales.salesPerson,
  };
};

export default connect(mapStateToProps, null)(HeaderStrip);
