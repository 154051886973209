/* eslint-disable react/display-name */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, findIndex, find, isNumber, isEmpty, reject } from 'lodash';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import Table from '../DiamondList/TableBack';
import { getState, isArrayEqual } from '../DiamondList';
import { isNumeric } from '../../util/utils';
import { getColumn, handleCartBidUpsert, fetchBidCart } from '../DiamondList/DiamondListFunctions';
import UtilService, { getNextBidData } from '../../services/util';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
import NoDataShow from '../common/NoDataShow';
import { trackTypeObj } from 'constants/Common';
import { ADD_NOTES, BID_CART_DELETE } from 'constants/apiConstant';
import { Tooltip } from 'antd';

const BidCartList = (props) => {
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = 'bd';
  const [state, setState] = useState(() => cloneDeep(getState()));
  const [dataList, setDataList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [nextBidData, setNextBidData] = useState({});
  const [data, setData] = useState([]);
  const sortRef = useRef([]);
  const selectedRow = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const checked = selectedRow;

  const clearAllSelectedRows = useCallback(() => {
    dispatch({ type: 'RESET_SELECTED_ROWS' });
  }, [dispatch]);

  useEffect(() => {
    async function setTimers() {
      const nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === 'E_NOT_FOUND') {
            setState({ ...state, loading: false, data: [] });
          }
        });
      setNextBidData(nextBid ? nextBid : []);
      nextBid && nextBid.sub_types && fetchcolumn();
    }
    setTimers();
    checked && clearAllSelectedRows();
  }, []);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetchcolumn();
      }
    },
    [sortRef.current],
  );

  const checkInterval = (value, oldValue, discount, nextBidData) => {
    value = Number(value);
    oldValue = Number(oldValue);

    const diffInterval = nextBidData.sub_types?.diffInterval;
    const v1 = parseInt(Math.round(value * 100));
    const v2 = parseInt(Math.round(oldValue * 100));
    const diff = Math.abs(v1 - v2);
    const inInterval = diff % (diffInterval * 100) === 0 || diff === 0;
    if (!inInterval) {
      OpenNotification({
        type: 'error',
        title: `You can only change discount in ${Math.abs(diffInterval)}% interval`,
      });
    }
    return inInterval;
  };
  const handleDiscountChange = (value, row, datas, nextBidData) => {
    let lDataList = localStorage.getItem('data');
    lDataList = lDataList ? JSON.parse(lDataList) : [...datas];
    const data = lDataList;
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    value = Number(value); // converting to number
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types?.calColumn;
    data[index].back = back;
    const subTypeCode = nextBidData.sub_types?.subTypeCode;
    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = checkInterval(value, oldValue, back, nextBidData);
    const maxPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
    const upInterval = nextBidData.sub_types?.upwardInterval;
    const downInterval = nextBidData.sub_types?.downwardInterval * -1;
    const max =
      calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (upInterval / 100) : upInterval + Math.abs(back);
    const min = calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (downInterval / 100) : back + downInterval;
    const minDiscount = parseFloat(data[index].ctPr + data[index].ctPr * (downInterval / 100)).toFixed(2);
    if (calColumn !== 'ctPr') {
      if (value >= min && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
        data[index].bidDiscount = value;
        const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
        data[index].bidPricePerCarat = bigPricePerCarat;
        data[index].bidAmount = bigPricePerCarat * data[index].crt;
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        if (isInIntervalDiff) {
          if (!(value >= back)) {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
            });
          } else {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
            });
          }
        }
      }
    } else {
      if (
        parseFloat(maxPricePerCarat) >= data[index].bidMinPricePerCarat &&
        parseFloat(maxPricePerCarat) <= max &&
        isInIntervalDiff &&
        value >= -100 &&
        value <= 100
      ) {
        data[index].bidDiscount = value;
        const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
        data[index].bidPricePerCarat = bigPricePerCarat;
        data[index].bidAmount = bigPricePerCarat * data[index].crt;
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        if (isInIntervalDiff) {
          if (!(parseFloat(maxPricePerCarat) >= data[index].ctPr)) {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(data[index].bidMinAmount).toFixed(
                      2,
                    )}. Please, try again.`,
            });
          } else {
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(data[index].bidMinAmount).toFixed(
                      2,
                    )}. Please, try again.`,
            });
          }
        }
      }
    }
    setDataList(data);
  };

  const handleDiscountBlur = (...args) => handleDiscountChange(...args, true);

  const getNewBid = (value, row, datas, nextBidData) => {
    let lDataList = localStorage.getItem('data');
    lDataList = lDataList ? JSON.parse(lDataList) : [...datas];
    const data = lDataList;
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - parseFloat(value) / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types?.calColumn;
    data[index].back = back;

    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = data[index].isFcCol
      ? checkInterval(value, data[index].bidPricePerCarat || data[index].ctPr, back, nextBidData)
      : checkInterval(discountValue, oldValue, back, nextBidData);
    const upInterval = nextBidData.sub_types?.upwardInterval;
    const downInterval = nextBidData.sub_types?.downwardInterval * -1;
    const subTypeCode = nextBidData.sub_types?.subTypeCode;
    const max =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
        : upInterval + Math.abs(back);
    const min =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
        : back + downInterval;

    const minValue = data[index].ctPr + data[index].ctPr * (downInterval / 100);
    if (calColumn !== 'ctPr' && !data[index].isFcCol) {
      if (
        parseFloat(discountValue) >= min &&
        parseFloat(discountValue) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        data[index].bidPricePerCarat = value;
        data[index].bidAmount = (value || 0) * data[index].crt;
        data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = data[index].isFcCol
          ? data[index].bidPricePerCarat
          : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        isInIntervalDiff &&
          OpenNotification({
            type: 'error',
            title:
              subTypeCode !== 'BIWI'
                ? 'Your bidding value must be higher than base value.'
                : `Your bidding amount can not be lower then ${parseFloat(minValue * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
          });
      }
    } else {
      if (
        parseFloat(value) >= data[index].bidMinPricePerCarat &&
        parseFloat(value) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        data[index].bidPricePerCarat = value;
        data[index].bidAmount = (value || 0) * data[index].crt;
        data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
      } else {
        data[index].bidDiscount = oldValue;
        data[index].bidPricePerCarat = data[index].isFcCol
          ? data[index].bidPricePerCarat
          : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
        data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
        isInIntervalDiff &&
          OpenNotification({
            type: 'error',
            title:
              subTypeCode !== 'BIWI'
                ? 'Your bidding value must be higher than base value.'
                : `Your bidding amount can not be lower then ${parseFloat(data[index].bidMinAmount).toFixed(
                    2,
                  )}. Please, try again.`,
          });
      }
    }
    setDataList(data);
  };

  const handleNewBidBlur = (...args) => getNewBid(...args, true);

  const handleValueChange = (value, row, datas, nextBidData) => {
    const data = [...datas];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };

    const bidPricePerCarat = parseFloat(value / data[index].crt).toFixed(2);
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - bidPricePerCarat / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = nextBidData.sub_types.calColumn;
    data[index].back = back;
    const subTypeCode = nextBidData.sub_types.subTypeCode;
    const upInterval = nextBidData.sub_types.upwardInterval;
    const downInterval = nextBidData.sub_types.downwardInterval * -1;
    const minCtPr = data[index].ctPr + data[index].ctPr * (downInterval / 100);
    const minAmount = parseFloat(minCtPr).toFixed(2) * data[index].crt;

    const oldValue = data[index].bidDiscount || back;
    const isInIntervalDiff = data[index].isFcCol
      ? checkInterval(value, data[index].bidAmount || data[index].amt, back, nextBidData)
      : checkInterval(discountValue, oldValue, back, nextBidData);
    const max =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
        : upInterval + Math.abs(back);
    const min =
      calColumn === 'ctPr' || data[index].isFcCol
        ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
        : back + downInterval;
    if (calColumn !== 'ctPr' && !data[index].isFcCol) {
      if (
        parseFloat(discountValue) >= min &&
        parseFloat(discountValue) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        if (data[index].isFcCol) {
          data[index].bidAmount = value;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
        } else {
          data[index].bidDiscount = discountValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + (discountValue * data[index].rap) / 100).toFixed(
            2,
          );
          data[index].bidAmount = value;
        }
      } else {
        if (data[index].isFcCol) {
          data[index].bidAmount = data[index].bidAmount;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        } else {
          data[index].bidDiscount = data[index].back;
          data[index].bidPricePerCarat = parseFloat(
            data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
          ).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(2)}. Please, try again.`,
            });
        }
      }
    } else {
      if (
        parseFloat(bidPricePerCarat) >= data[index].bidMinAmount &&
        parseFloat(bidPricePerCarat) <= max &&
        isInIntervalDiff &&
        parseFloat(discountValue) >= -100 &&
        parseFloat(discountValue) <= 100
      ) {
        if (data[index].isFcCol) {
          data[index].bidAmount = value;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
        } else {
          data[index].bidDiscount = discountValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + (discountValue * data[index].rap) / 100).toFixed(
            2,
          );
          data[index].bidAmount = value;
        }
      } else {
        if (data[index].isFcCol) {
          data[index].bidAmount = data[index].bidAmount;
          data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
          data[index].bidDiscount = 0;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(data[index].bidMinAmount).toFixed(
                      2,
                    )}. Please, try again.`,
            });
        } else {
          data[index].bidDiscount = data[index].back;
          data[index].bidPricePerCarat = parseFloat(
            data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
          ).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(data[index].bidMinAmount).toFixed(
                      2,
                    )}. Please, try again.`,
            });
        }
      }
    }
    setDataList(data);
  };

  const handleValueBlur = (...args) => handleValueChange(...args, true);
  const fetchcolumn = async () => {
    const nextBid = await getNextBidData().then();
    const downInterval = nextBid.sub_types.downwardInterval * -1;
    const calColumn = nextBid.sub_types.calColumn;
    const row = await fetchBidCart().then();
    const data = row.list
      ?.map((x) => {
        return {
          ...x.diamond,
          transactionId: x?.id,
          notes: x?.notes,
          usernotes: x?.usernotes,
        };
      })
      .map((x) => {
        const minDis =
          x.ctPr < x.rap
            ? -1 * Math.abs(parseFloat(Number(x.back)).toFixed(2)) + downInterval
            : Math.abs(parseFloat(Number(x.back)).toFixed(2)) + downInterval;
        if (calColumn !== 'ctPr' && !x.isFcCol) {
          return {
            ...x,
            bidDiscount: minDis,
            bidPricePerCarat: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2),
            bidAmount: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2) * x.crt,
          };
        } else {
          const minCtPr = parseFloat(x.ctPr + (x.ctPr * downInterval) / 100).toFixed(2);
          return {
            ...x,
            bidPricePerCarat: parseFloat(x.ctPr + (x.ctPr * downInterval) / 100).toFixed(2),
            bidAmount: parseFloat(minCtPr).toFixed(2) * x.crt,
            bidDiscount: parseFloat((1 - minCtPr / x.rap) * -100).toFixed(2),
            bidMinAmount: parseFloat(minCtPr).toFixed(2) * x.crt,
            bidMinPricePerCarat: minCtPr,
          };
        }
      });
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr', 'rap'].includes(el.id)),
      {
        Header: 'Notes',
        accessor: 'notes',
        Cell: ({ cell, row }) => {
          const notes = row?.original?.notes === null ? row?.original?.usernotes : row?.original?.notes;
          return (
            <Tooltip title={notes}>
              <div className="tableInput">
                <input
                  type="text"
                  onChange={(e) => ipValChange(e.target.value, row.original.transactionId, data, notes)}
                  defaultValue={notes}
                  name={`input${row.original.transactionId}`}
                />
              </div>
            </Tooltip>
          );
        },
      },

      find(Columns, { id: 'rap' }),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),

      {
        Header: 'Bid Disc%',
        accessor: 'bidDiscount',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.back).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidDiscount)
              ? parseFloat(row.original.bidDiscount).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidDiscount) {
              setValue(parseFloat(row.original.bidDiscount).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = (e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleDiscountBlur(_value, row.original, data, nextBid)
              : handleDiscountBlur(0, row.original, data, nextBid);
          };

          const sign = Number(value) > 0 ? '+' : '';
          if (row.original.isFcCol) {
            return '-';
          }
          return (
            <div className="tableInput">
              <span className="add-sign">{sign}</span>
              <input
                type="number"
                style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          );
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.ctPr).toFixed(2));
          }, [row.original.ctPr]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidPricePerCarat)
              ? parseFloat(row.original.bidPricePerCarat).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidPricePerCarat) {
              setValue(parseFloat(row.original.bidPricePerCarat).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);
          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleNewBidBlur(_value, row.original, data, nextBid)
              : handleNewBidBlur(0, row.original, data, nextBid);
          });

          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmount',
        Cell: ({ cell, row }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(row.original.amt).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidAmount)
              ? parseFloat(row.original.bidAmount).toFixed(2)
              : isNumber(originalValue)
              ? parseFloat(originalValue).toFixed(2)
              : '0.00',
          );

          React.useEffect(() => {
            if (row.original.bidAmount) {
              setValue(parseFloat(row.original.bidAmount).toFixed(2));
            }
          }, [row.original]);

          const handleChange = React.useCallback(
            (e) => {
              e.preventDefault();
              // this.setState({ showModal: true });
              const _value = e.target.value;
              if (isNumeric(_value)) setValue(_value);
            },
            [value],
          );

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            // this.setState({ showModal: false });
            const _value = e.target.value;
            isNumeric(_value, true)
              ? handleValueBlur(_value, row.original, data, nextBid)
              : handleValueBlur(0, row.original, data, nextBid);
          }, []);
          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ].filter((el) => el && el);
    setDataList(data);
    setState({
      // count: res?.data.length,
      columns,
    });
  };
  const ipValChange = (value, ide, row, defaultValue) => {
    let line = row;

    if (ide) {
      let ind = line.findIndex((item) => item.transactionId === ide);
      line[ind].notes = value;
      line[ind].defaultValue = defaultValue;
    } else {
      line = line.map((l) => ({ ...l, notes: value, defaultValue }));
    }
    setData(line);
  };
  const handleSaveNotes = () => {
    // Collect notes from all rows (assuming each row has a unique ID)
    const modifiedNotes = [];
    const ref = data?.forEach((item) => {
      if ((item?.defaultValue || item?.defaultValue === '') && item?.notes !== item?.defaultValue) {
        modifiedNotes.push({ id: item?.transactionId, note: item?.notes });
      }
    });
    if (modifiedNotes?.length > 0) {
      const payload = {
        notes: modifiedNotes,
        trackType: 10,
      };
      const request = {
        ...ADD_NOTES,
        request: payload,
      };
      UtilService.callApi(request, function (err, data) {
        if (err) {
          setData([]);
          fetchCarts();
          return err;
        } else if (data && data.code === 'OK') {
          setData([]);
          fetchCarts();
          OpenNotification({
            type: 'success',
            title: data?.message || 'Your Request is updated successfully.',
          });
        }
      });
    }
  };

  const fetchCarts = () => {
    return fetchcolumn() && props?.fetchCarts();
  };

  const handleBidCancel = React.useCallback(() => {
    const obj = BID_CART_DELETE;
    obj.request = {
      id: checked?.map((stone) => stone?.transactionId),
      trackType: trackTypeObj.BIDCART,
    };
    UtilService.callApi(obj, (err, data) => {
      if (data?.code == 'OK') {
        OpenNotification({
          type: 'success',
          title: data?.message,
        });
        return fetchCarts();
      }
    });
  }, [checked, fetchCarts]);

  const cartBidStoneCreate = (check) => {
    const newData = [];
    dataList.map((d) => {
      check.map((c) => {
        if (d.id === c.id) {
          newData.push(d);
        }
      });
    });
    if (!isEmpty(check)) {
      let showModal = false;
      newData.map((x) => {
        if (
          Number(x.bidDiscount ? x.bidDiscount : x.back) === Number(x.back) ||
          Number(x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) === Number(x.ctPr)
        ) {
          showModal = true;
        }
      });
      if (showModal && nextBidData.sub_types.subTypeCode !== 'BIWI') {
        OpenNotification({
          type: 'error',
          title: 'Bid discount and discount can not be same for bid.',
        });
      } else {
        handleCartBidUpsert(
          newData,
          nextBidData.id,
          nextBidData.sub_types.subTypeCode,
          nextBidData.sub_types.result,
          // (flag) => {
          //   self.props.onClose();
          //   self.props.clearAll();
          //   if (flag && self.props.fetch) self.props.fetch();
          // },
        );
      }
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s).',
      });
    }
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        cartbidStone
        {...state}
        {...props}
        // bidStone
        nocart
        cartBidStoneCreate={cartBidStoneCreate}
        nowatch
        nonote
        noreminder
        noconfirm={nextBidData?.sub_types?.subTypeCode !== 'BIWI'}
        noquote
        download
        print
        currentType={currentType}
        nopaginate
        nonocompare
        saveNote={true}
        onSave={handleSaveNotes}
        office
        nohold
        nofinalcalc
        enquiry
        share
        cancel
        onClose={handleBidCancel}
        clearAll={clearAllSelectedRows}
        fetch={fetchcolumn}
      />
    );
  };

  localStorage.setItem('data', JSON.stringify(dataList));
  return (
    <div className="searchResultListWrapper">
      <div className="searchInnerResult">
        <div
          className="searchResultTable"
          style={{ height: checked?.length ? `calc(100vh - 219px)` : `calc(100vh - 170px)`, overflow: 'auto' }}
        >
          {JSON.stringify(nextBidData) !== '[]' && dataList && dataList.length ? (
            <Table
              // noCheckBox={tab === BIDTAB.HISTORY}
              data={dataList}
              columns={state.columns}
              loading={state.loading}
              ref={tableRef}
              handleSort={handleSort}
              currentType={currentType}
              defaultChecked={state.defaultChecked}
            />
          ) : (
            ''
          )}
          {dataList.length === 0 ? (
            <NoDataShow
              message={'No Bid submission Found, Hurry UP..!!! Submit your bid before bid ends.'}
              noData
              subTypeCode={nextBidData?.sub_types?.subTypeCode === 'BIWI'}
            />
          ) : (
            ''
          )}
          {JSON.stringify(nextBidData) === '[]' ? (
            <NoDataShow message={'No Bid is active now. Please purchase diamond!!'} redirectURL />
          ) : (
            ''
          )}
          {JSON.stringify(nextBidData) !== '[]' && dataList.length ? getAction() : ''}
        </div>
      </div>
    </div>
  );
};

export default BidCartList;
