import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import IntlMessages from '../../util/IntlMessages';
import { Form, notification, Radio, Drawer, Checkbox } from 'antd';
import IntlTelInput from 'react-intl-tel-input';
import cloneDeep from 'lodash/cloneDeep';
import clone from 'lodash/clone';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { BASE_URL, LOCAL_STORAGE_VAR, MIME_TYPES } from '../../constants/Common';
import UtilService from '../../services/util';
import Loader from '../../components/common/Loader';
import OpenNotification from '../common/CommonButton/OpenNotification';
import InputBlock from '../common/InputBlock';
import SelectOption from '../common/SelectOption';
import Heading from '../common/Heading';
import TermsCondition from '../TermsConditionPopup';
import DatePicker from '../common/DatePicker';
import moment from 'moment';
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  SIGNUP_USER_FRONT,
  GET_MASTERS,
  UPLOAD_FILE,
  GET_CITY_SEARCH,
} from '../../constants/apiConstant';
import CheckBox from '../common/CheckBox';
import Logo from '../Header/Logo';
import FileUpload from '../common/ProfileUpload/index';
import LeftDatePicker from '../common/LeftMultipleSelect/LeftDatePicker';
import { signupValidator } from './SignupValidator';
import { numberValidator } from '../../services/Commonfunction';
import { findIndex, isObject, remove } from 'lodash';
import { compose } from 'redux';

import PDFICON from 'assets/svg/pdf-icon.svg';
import 'react-intl-tel-input/dist/main.css';

const CheckboxGroup = Checkbox.Group;
export const companyDetail = {
  Country: [
    { value: 'Afghanistan ' },
    { value: 'Albania ' },
    { value: 'Algeria ' },
    { value: 'American Samoa' },
    { value: 'Andorra' },
    { value: 'Angola' },
    { value: 'India' },
  ],
  State: [
    { value: 'Maharashtra ' },
    { value: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh' },
    { value: 'Assam ' },
    { value: 'Bihar' },
    { value: 'Dadra and Nagar Haveli ' },
    { value: 'Daman and Diu' },
  ],
  City: [
    { value: 'Achalpur' },
    { value: 'Ahmadnagar' },
    { value: 'Akola' },
    { value: 'Amravati' },
    { value: 'AURANGABAD' },
    { value: 'Miraj' },
    { value: 'MUMBAI' },
  ],
  companyType: [
    { id: 'Partnership', name: 'Partnership' },
    { id: 'Individual', name: 'Individual' },
    { id: 'Private Limited / Limited', name: 'Private Limited / Limited' },
    { id: 'Proprietor', name: 'Proprietor' },
  ],
  seller: [
    { id: 'Abhishek Karnavat', name: 'Abhishek Karnavat' },
    { id: 'Elza Vaz', name: 'Elza Vaz' },
  ],
  CompanyBest: [
    { id: 'Wholesaler', name: 'Wholesaler' },
    { id: 'Retailer', name: 'Retailer' },
    { id: 'Jewellery Manufacturer', name: 'Jewellery Manufacturer' },
    { id: 'Polished Distributor', name: 'Polished Distributor' },
    { id: 'End User', name: 'End User' },
    { id: 'Other', name: 'Other' },
    { id: 'FM Retailers', name: 'FM Retailers' },
    { id: 'FM Diamantaire', name: 'FM Diamantaire' },
  ],
};

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      mobileData: '',
      mobileValid: true,
      citySearch: '',
      prefixList: [
        { id: 'Mr', name: 'Mr' },
        { id: 'Mrs', name: 'Mrs' },
        { id: 'Miss', name: 'Miss' },
      ],
      designationList: [
        { id: 'Buyer', name: 'Buyer' },
        { id: 'Director', name: 'Director' },
        { id: 'Partner', name: 'Partner' },
        { id: 'Manager', name: 'Manager' },
        { id: 'Sales', name: 'Sales' },
      ],
      howList: [],
      otherText: '',
      reference1: { companyName: '', name: '', mobile: '', countryCode: '91', phone: '', email: '' },
      reference2: { companyName: '', name: '', mobile: '', countryCode: '91', phone: '', email: '' },
      formData: {
        prefix: 'Mr',
        howKnow: '',
        accountName: '',
        designation: 'Select Designation',
        dob: null,
        anniversary: null,
        registrationType: 1,
        firstName: '',
        lastName: '',
        email: '',
        emails: '',
        username: '',
        password: '',
        confirmPassword: '',
        countryCode: '91',
        companyName: '',
        photoId: false,
        businessId: false,
        address: '',
        country: {},
        state: {},
        city: {},
        pinCode: '',
        fax: '',
        phone1: '',
        phone2: '',
        mobile: '',
        broker: false,
        brokerName: '',
        brokerEmail: '',
        brokerMobile: '',
        brokerPhoneNo: '',
        isTermsCondAgree: true,
        updateRequired: false,
        natureOfOrg: [],
        businessType: '',
        seller: 'Select Sales Person',
        kyc: [],
        website: '',
      },
      allCountries: [],
      allCities: [],
      countries: [],
      states: [],
      cities: [],
      photoPdf: '',
      bIdPdf: '',
      registerButton: false,
      isDeviceUrl: false,
      roleType: '',
      accountError: false,
      visible: false,
      sellers: [],
      allSellers: [],
      errors: {},
      BUSINESS_TYPE: [],
      NATURE_OF_ORG: [],
      BUSINESS_DOCS: [],
      PERSONAL_DOCS: [],
      docP: null,
      docB: null,
      clicked: false,
    };
  }

  componentDidMount() {
    const urlQuery = Object.fromEntries(new URLSearchParams(window.location.search));
    if (!isEmpty(urlQuery)) this.setState({ formData: { ...this.state.formData, ...urlQuery } });
    const isDeviceUrl = window.location.pathname.includes('/device/signup');
    this.setState({ isDeviceUrl: isDeviceUrl });
    // this.fetchCountries();
    this.fetchSellers();
    this.getMasters();
    window.addEventListener('click', (e) => {
      if (document.activeElement && document.activeElement.localName !== 'button') {
        notification.destroy();
      }
    });
    // this.getCityFilterList()
  }

  getCityFilterList = () => {
    const obj = {
      ...GET_CITY_SEARCH,
      request: { search: this.state.citySearch },
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) throw err;
      else if (data && data.code === 'OK') {
        this.setState({
          cities: data.data,
        });
      }
    });
  };
  getMasters = () => {
    const obj = {
      ...GET_MASTERS,
      request: {
        masters: ['BUSINESS_TYPE', 'NATURE_OF_ORG', 'DOC_TYPE_BUSINESS', 'DOC_TYPE_PERSONAL'],
      },
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) throw err;
      else if (data && data.code === 'OK') {
        this.setState({
          BUSINESS_TYPE: data.data.BUSINESS_TYPE || [],
          NATURE_OF_ORG: data.data.NATURE_OF_ORG || [],
          BUSINESS_DOCS: data.data.DOC_TYPE_BUSINESS || [],
          PERSONAL_DOCS: data.data.DOC_TYPE_PERSONAL || [],
        });
      }
    });
  };

  fetchCountries() {
    const obj = {
      // method: "post",
      // url: "/admin/country/paginate",
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        this.setState({
          allCountries: data.data || [],
          countries: data.data || [],
        });
      }
    });
  }

  fetchSellers() {
    const obj = {
      // method: "post",
      // url: "/front/sellers",
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        this.setState({
          sellers: data.data || [],
          allSellers: data.data || [],
        });
      }
    });
  }

  fetchStates(countryId) {
    const obj = {
      // method: "post",
      // url: "/admin/state/paginate",
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        this.setState({
          states: data.data || [],
        });
      }
    });
  }

  fetchCities(stateId, countryId) {
    const obj = { ...GET_CITY_LIST, request: { state: stateId, country: countryId } };
    UtilService.callApi(obj, (err, data) => {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({ type: 'error', title: err.data.message });
      } else if (data && data.code === 'OK') {
        if (stateId) {
          this.setState({ cities: data.data || [] });
        } else if (countryId) {
          this.setState({ cities: data.data || [] });
        }
      }
    });
  }

  handleOnChange(key, value) {
    const { formData } = this.state;
    if (key === 'phone') {
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        if (formData[key].length === 0) {
          if (value === '0') {
            formData[key] = value.trim();
          } else {
            formData[key] = value.trim();
          }
        } else {
          formData[key] = value.trim();
        }
      }
    } else {
      if (key === 'username') {
        value = value.replace(/ /g, '');
        formData[key] = value.trim();
      } else {
        formData[key] = value;
      }
    }
    // delete errors[key];
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors[key];
    }

    this.setState({ formData, errors });
    this.onBlur(key, value);
  }

  handleOnChangeReference(key, value) {
    const { reference1, errors } = this.state;
    reference1[key] = value;
    this.setState({ reference1, errors });
  }
  handleOnChangeReference2(key, value) {
    const { reference2, errors } = this.state;
    reference2[key] = value;
    // delete errors[key];
    this.setState({ reference2, errors });
  }

  handleCountryChange(e) {
    const country = this.state.countries.filter((c) => c.id === e)[0];
    if (country) {
      const formData = this.state.formData;
      formData.country = country ? country : {};
      formData.state = {};
      formData.city = {};
      this.setState(
        {
          formData: formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          this.fetchStates(e);
          this.fetchCities(null, e);
        },
      );
    }
  }

  handleStateChange(e) {
    const state = this.state.states.filter((c) => c.id === e)[0];
    if (state) {
      const formData = this.state.formData;
      formData.city = '';
      formData.state = state ? state : {};
      this.setState(
        {
          formData: formData,
          cities: [],
        },
        () => {
          this.fetchCities(e);
        },
      );
    }
  }

  handleCityChange(e) {
    const city = this.state.cities.filter((c) => c.id === e)[0];
    if (city) {
      const formData = this.state.formData;
      formData.city = city ? city : {};
      if (city.countryId) {
        this.setState({ countries: [city.countryId] });
        formData.country = city.countryId ? city.countryId : {};
      }
      if (city.stateId) {
        this.setState({ states: [city.stateId] });
        formData.state = city.stateId ? city.stateId : {};
      }
      let errors = null;
      if (this.state.errors) {
        errors = Object.assign('', this.state.errors);
        delete errors['city'];
        delete errors['country'];
        delete errors['state'];
      }

      this.setState({ formData: formData, errors });
    }
  }

  handleSignup = (e) => {
    e.preventDefault();
    const reqObj = clone(this.state.formData);
    this.setState({ clicked: true });
    reqObj.city = isEmpty(reqObj.city) ? null : reqObj.city.id;
    reqObj.state = isEmpty(reqObj.state) ? null : reqObj.state.id;
    reqObj.country = isEmpty(reqObj.country) ? null : reqObj.country.id;
    reqObj.emails = isEmpty(reqObj.emails) ? '' : reqObj.emails;
    reqObj.mobiles = isEmpty(reqObj.mobile) ? null : [reqObj.mobile];
    reqObj.otherReferences = [this.state.reference1, this.state.reference2];
    delete reqObj.photoId;
    delete reqObj.businessId;
    if (this.state.otherText) {
      if (this.state.howList && this.state.howList.length) {
        const temp = this.state.howList.filter((a) => a !== 'Other');
        temp.push(this.state.otherText);
        temp.toString();
        reqObj.howToKnow = temp;
      }
    } else {
      if (this.state.howList && this.state.howList.length) {
        const temp = this.state.howList;
        temp.toString();
        reqObj.howToKnow = temp;
      } else {
        reqObj.howToKnow = '';
      }
    }
    const obj = {
      ...SIGNUP_USER_FRONT,
      request: reqObj,
    };
    // if (this.handleValidation()) {
    // if (this.state.formData['userKyc'].length === 0) {
    //   this.setState({ showError: true });
    //   notification.error({
    //     message: 'Required Fields',
    //     placement: 'bottomLeft',
    //     description: 'Please upload both personal and business document.',
    //   });
    //   // return;
    // } else {
    //   // if (!this.state.formData["userKyc"][0]) {
    //   if (findIndex(this.state.formData.userKyc, { file: 'photoId' }) < 0) {
    //     this.setState({ showError: true });
    //     notification.error({
    //       message: 'Required Fields',
    //       placement: 'bottomLeft',
    //       description: 'Please upload personal document.',
    //     });
    //   }
    // if (findIndex(this.state.formData.userKyc, { file: 'businessId' }) < 0) {
    //   this.setState({ showError: true });

    //   notification.error({
    //     message: 'Required Fields',
    //     placement: 'bottomLeft',
    //     description: 'Please upload business document.',
    //   });
    // }
    // }
    const errors = signupValidator({ ...obj.request, docP: this.state.docP, docB: this.state.docB }, true);
    if (!errors.isValid) {
      this.setState({ errors: errors.errors || {} });
    } else {
      this.setState({ showError: false });
      reqObj.emails = isEmpty(reqObj.emails) ? null : [reqObj.emails];

      if (this.state.mobileValid) {
        UtilService.callApi(obj, (err, data) => {
          if (err && err.data) {
            if (err.data.code === 'E_USER_NOT_FOUND') {
              return OpenNotification({
                type: 'error',
                title: err.data.message,
              });
            }
            if (
              ['E_DUPLICATE_EMAIL', 'E_DUPLICATE', 'E_DUPLICATE_MOBILE', 'E_DUPLICATE_USERNAME'].includes(err.data.code)
            ) {
              return OpenNotification({
                type: 'error',
                title: err.data.message,
              });
            }
          }

          if (data && data.code === 'OK') {
            OpenNotification({
              type: 'success',
              title: data.message,
            });
            this.setState({ registerButton: true });
            // if (this.state.isDeviceUrl) {
            //   window.RegisterCallBack.postMessage(data.message);

            // }
            localStorage.setItem(`${LOCAL_STORAGE_VAR}-thank-you`, JSON.stringify(true));
            this.props.history.push('/thank-you');
            // OpenNotification({
            //   type: "success",
            //   title: "Registered successfully !",
            // });
          }
        });
      }
      // }
    }
  };

  // handleValidation() {
  //   let formData = this.state.formData;
  //   let errors = this.state.errors;
  //   let formIsValid = true;
  //   let blankField = [];
  //   let invalidFields = [];
  //   if (!formData["firstName"] || isEmpty(formData["firstName"])) {
  //     blankField.push("First Name");
  //     formIsValid = false;
  //     errors["firstName"] = true;
  //   } else if (formData["firstName"]) {
  //     if (!formData["firstName"].match(/^[a-zA-Z ]+$/)) {
  //       invalidFields.push("firstName");
  //       formIsValid = false;
  //       errors["firstName"] = true; //"Please enter valid First Name.";
  //     } else {
  //       errors["firstName"] = undefined;
  //     }
  //   }

  //   if (!formData["lastName"] || isEmpty(formData["lastName"])) {
  //     blankField.push("Last Name");
  //     formIsValid = false;
  //     errors["lastName"] = true;
  //   } else if (formData["lastName"]) {
  //     if (!formData["lastName"].match(/^[a-zA-Z ]+$/)) {
  //       invalidFields.push("lastName");
  //       formIsValid = false;
  //       errors["lastName"] = true; //"Please enter valid Last Name.";
  //     } else {
  //       errors["lastName"] = undefined;
  //     }
  //   }
  //   if (formData["userKyc"].length === 0) {
  //     blankField.push("Upload Documents");
  //     formIsValid = false;
  //     errors["userKyc"] = true;
  //   } else {
  //     if (!formData["userKyc"][0]) {
  //       blankField.push("Upload Personal Document");
  //       formIsValid = false;
  //       errors["userKyc"] = true;
  //     }
  //     if (!formData["userKyc"][1]) {
  //       blankField.push("Upload Business Document");
  //       formIsValid = false;
  //       errors["userKyc"] = true;
  //     }
  //   }
  //   if (!formData["email"] || isEmpty(formData["email"])) {
  //     blankField.push("Email Address");
  //     formIsValid = false;
  //     errors["email"] = true;
  //   } else if (formData["email"]) {
  //     if (
  //       formData["email"] &&
  //       !formData["email"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)
  //     ) {
  //       invalidFields.push("Email");
  //       formIsValid = false;
  //       errors["email"] = true; //"Please enter valid Email.";
  //     } else {
  //       errors["email"] = undefined;
  //     }
  //   }

  //   if (!formData["username"] || isEmpty(formData["username"])) {
  //     blankField.push("Username");
  //     formIsValid = false;
  //     errors["username"] = true;
  //   }

  //   // if (formData["username"]) {
  //   //   if (
  //   //     formData["username"] &&
  //   //     !formData["username"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]$/)
  //   //   ) {
  //   //     invalidFields.push("username");
  //   //     formIsValid = false;
  //   //     errors["username"] = true;
  //   //   } else {
  //   //     errors["username"] = undefined;
  //   //   }
  //   // }

  //   if (!formData["password"]) {
  //     blankField.push(" Password");
  //     formIsValid = false;
  //     errors["password"] = true;
  //   } else if (
  //     formData["password"] &&
  //     // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
  //     formData["password"].length < 3
  //   ) {
  //     invalidFields.push("Password");
  //     formIsValid = false;
  //     errors["password"] = true; //"Password must be 3 digit long.";
  //   }

  //   if (!formData["confirmPassword"]) {
  //     blankField.push("Confirm Password");
  //     formIsValid = false;
  //     errors["confirmPassword"] = true; //"Please enter confirm password";
  //   } else if (
  //     formData &&
  //     formData["confirmPassword"] &&
  //     formData["confirmPassword"].length &&
  //     formData["confirmPassword"] !== formData.password
  //   ) {
  //     invalidFields.push("Confirm Password");
  //     formIsValid = false;
  //     errors["confirmPassword"] = true; //"Please make sure your passwords match.";
  //   }

  //   if (!formData["address"] || isEmpty(formData["address"])) {
  //     blankField.push("Address");
  //     formIsValid = false;
  //     errors["address"] = true;
  //   }

  //   if (formData.registrationType !== 2) {
  //     if (!formData["country"] || isEmpty(formData["country"])) {
  //       blankField.push("Country");
  //       formIsValid = false;
  //       errors["country"] = true;
  //     }
  //   }

  //   if (!formData["state"] || isEmpty(formData["state"])) {
  //     blankField.push("State");
  //     formIsValid = false;
  //     errors["state"] = true;
  //   }

  //   if (!formData["city"] || isEmpty(formData["city"])) {
  //     blankField.push("City");
  //     formIsValid = false;
  //     errors["city"] = true;
  //   }

  //   if (!formData["pinCode"] || isEmpty(formData["pinCode"])) {
  //     blankField.push("Pincode");
  //     formIsValid = false;
  //     errors["pinCode"] = true;
  //   } else if (formData["pinCode"] && !formData["pinCode"].match(/^(\d{4}|\d{6})$/)) {
  //     invalidFields.push("Pincode");
  //     formIsValid = false;
  //     errors["pinCode"] = true;
  //   }

  //   if (!formData["mobile"] || isEmpty(formData["mobile"])) {
  //     blankField.push("Mobile No.");
  //     formIsValid = false;
  //     errors["mobile"] = true;
  //   } else if (
  //     formData["mobile"] &&
  //     (formData["mobile"].length < 10 || formData["mobile"].length > 15)
  //   ) {
  //     invalidFields.push("Mobile No.");
  //     formIsValid = false;
  //     errors["mobile"] = errors["mobile"];
  //   }

  //   // if (!formData.phone1 || !isEmpty(formData["phone1"])) {
  //   //   blankField.push("Phone 1");
  //   //   formIsValid = false;
  //   //   errors["phone1"] = true;
  //   // } else
  //   if (formData["phone1"] && (formData["phone1"].length < 10 || formData["phone1"].length > 15)) {
  //     invalidFields.push("Phone 1");
  //     formIsValid = false;
  //     errors["phone1"] = errors["phone1"];
  //   }

  //   // if (!formData["phone2"] || !isEmpty(formData["phone2"])) {
  //   //   blankField.push("Phone 2");
  //   //   formIsValid = false;
  //   //   errors["phone2"] = true;
  //   // } else
  //   if (formData["phone2"] && (formData["phone2"].length < 10 || formData["phone2"].length > 15)) {
  //     invalidFields.push("Phone 2");
  //     formIsValid = false;
  //     errors["phone2"] = errors["phone2"];
  //   }

  //   // if (!formData.fax || !isEmpty(formData["fax"])) {
  //   //   blankField.push("Fax No");
  //   //   formIsValid = false;
  //   //   errors["fax"] = true;
  //   // } else
  //   // if (formData["fax"] && !formData["fax"].match(/^\+?[0-9]{6,}$/)) {
  //   //   invalidFields.push("Phone 2");
  //   //   formIsValid = false;
  //   //   errors["fax"] = errors["fax"];
  //   // }

  //   if (!formData.companyName || isEmpty(formData.companyName)) {
  //     blankField.push("Company Name");
  //     formIsValid = false;
  //     errors["companyName"] = true;
  //   } else if (formData["companyName"] && !formData["companyName"].match(/^[a-zA-Z0-9 ]+$/)) {
  //     invalidFields.push("Company Name");
  //     formIsValid = false;
  //     errors["companyName"] = true; //"Please enter valid Last Name.";
  //   }
  //   if (!formData.accountName || isEmpty(formData.accountName)) {
  //     blankField.push("Owner Name");
  //     formIsValid = false;
  //     errors["accountName"] = true;
  //   } else if (formData["accountName"] && !formData["accountName"].match(/^[a-zA-Z0-9 ]+$/)) {
  //     invalidFields.push("Owner Name");
  //     formIsValid = false;
  //     errors["accountName"] = true; //"Please enter valid Last Name.";
  //   }

  //   if (
  //     (formData["registrationType"] === 2 || formData["registrationType"] === 3) &&
  //     isEmpty(formData["businessType"])
  //   ) {
  //     blankField.push("Company Type");
  //     formIsValid = false;
  //     errors["businessType"] = true;
  //   }

  //   if (formData["registrationType"] === 2 && isEmpty(formData["gstNo"])) {
  //     blankField.push("GST No.");
  //     formIsValid = false;
  //     errors["gstNo"] = true;
  //   } else if (
  //     formData["registrationType"] === 2 &&
  //     !formData["gstNo"].match(/\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/)
  //   ) {
  //     invalidFields.push("GST No");
  //     formIsValid = false;
  //     errors["gstNo"] = true;
  //   }

  //   if (formData["panNo"]) {
  //     if (
  //       formData["registrationType"] === 3 &&
  //       (isEmpty(formData["panNo"]) || !formData["panNo"].match(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/))
  //     ) {
  //       blankField.push("PAN No.");
  //       formIsValid = false;
  //       errors["panNo"] = true;
  //     } else if (
  //       formData["registrationType"] === 3 &&
  //       !formData["panNo"].match(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/)
  //     ) {
  //       invalidFields.push("PAN No");
  //       formIsValid = false;
  //       errors["panNo"] = true;
  //     }
  //   }

  //   if (isEmpty(formData["natureOfOrg"])) {
  //     blankField.push("Company's Best");
  //     formIsValid = false;
  //     errors["natureOfOrg"] = true;
  //   }

  //   if (formData["broker"] && isEmpty(formData["brokerName"])) {
  //     blankField.push("Broker Name");
  //     formIsValid = false;
  //   }

  //   if (formData["brokerName"]) {
  //     if (!formData["brokerName"].match(/^[a-zA-Z ]+$/)) {
  //       invalidFields.push("brokerName");
  //       formIsValid = false;
  //       errors["brokerName"] = true; //"Please enter valid First Name.";
  //     } else {
  //       errors["brokerName"] = undefined;
  //     }
  //   }

  //   if (formData["brokerEmail"]) {
  //     if (
  //       formData["brokerEmail"] &&
  //       !formData["brokerEmail"].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)
  //     ) {
  //       invalidFields.push("Broker Email");
  //       formIsValid = false;
  //       errors["brokerEmail"] = true; //"Please enter valid brokerEmail.";
  //     } else {
  //       errors["brokerEmail"] = undefined;
  //     }
  //   }

  //   if (formData["broker"] && isEmpty(formData["brokerMobile"])) {
  //     blankField.push("Broker Mobile No.");
  //     formIsValid = false;
  //     errors["brokerMobile"] = true;
  //   } else if (formData["brokerMobile"] && errors["brokerMobile"]) {
  //     invalidFields.push("Broker Mobile No.");
  //     formIsValid = false;
  //     errors["brokerMobile"] = errors["brokerMobile"];
  //   }

  //   if (blankField.length > 0) {
  //     let arr = blankField;
  //     let outStr = "";
  //     if (arr.length === 1) {
  //       outStr = arr[0];
  //     } else if (arr.length === 2) {
  //       outStr = arr.join(" and ");
  //     } else if (arr.length > 2) {
  //       outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
  //     }
  //     notification.error({
  //       message: "Required Fields",
  //       placement: "bottomLeft",
  //       description: outStr,
  //     });
  //   }
  //   if (invalidFields.length > 0) {
  //     let arr = invalidFields;
  //     let outStr = "";
  //     if (arr.length === 1) {
  //       outStr = arr[0];
  //     } else if (arr.length === 2) {
  //       outStr = arr.join(" and ");
  //     } else if (arr.length > 2) {
  //       outStr = arr.slice(0, -1).join(", ") + " and " + arr.slice(-1);
  //     }
  //     notification.error({
  //       message: "Invalid Fields",
  //       placement: "bottomLeft",
  //       description: outStr,
  //     });
  //   }
  //   this.setState({ errors: errors });
  //   return formIsValid;
  // }

  handleCancel(e) {
    e.preventDefault();

    const prevData = clone(this.state);
    this.setState({
      howList: [],
      otherText: '',
      reference1: {
        companyName: '',
        name: '',
        mobile: '',
        countryCode: prevData.reference1.countryCode,
        phone: '',
        email: '',
      },
      reference2: {
        companyName: '',
        name: '',
        mobile: '',
        countryCode: prevData.reference2.countryCode,
        phone: '',
        email: '',
      },
      name1: '',
      name2: '',
      docP: '',
      docB: '',
      formData: {
        website: '',
        howKnow: '',
        accountName: '',
        prefix: 'Mr',
        designation: 'Select Designation',
        dob: null,
        anniversary: null,
        registrationType: 1,
        firstName: '',
        lastName: '',
        email: '',
        emails: '',
        username: '',
        photoId: '',
        businessId: '',
        password: '',
        confirmPassword: '',
        countryCode: prevData.formData.countryCode,
        companyName: '',
        address: '',
        country: {},
        state: {},
        city: {},
        pinCode: '',
        fax: '',
        phone1: '',
        phone2: '',
        mobile: '',
        broker: false,
        brokerName: '',
        brokerEmail: '',
        brokerMobile: '',
        brokerPhoneNo: '',
        isTermsCondAgree: true,
        updateRequired: false,
        natureOfOrg: [],
        businessType: '',
        seller: '',
        kyc: [],
        wechat: '',
        skype: '',
      },
      errors: null,
    });
  }

  TermPopup = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  disabledBirthDate = (current) => {
    return current && current > moment().subtract(18, 'years');
  };
  disabledAnniversaryDate = (current) => {
    return (
      current &&
      this.state.formData.dob &&
      // current > this.state.formData.dob &&
      current < moment(this.state.formData.dob).add(18, 'years')
    );
  };
  //
  dateHandleChange = (e, val, key) => {
    const { formData } = this.state;
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign('', this.state.errors);
      delete errors[key];
    }
    // delete errors[key];

    let da = null;
    if (e) {
      da = new Date(e._d).toISOString();
    }
    formData[key] = da;

    this.setState({ formData, errors });
  };

  uploadDocument = (ee, type, stsType) => {
    const e = cloneDeep(ee);
    const formData = this.state.formData ?? {};
    const errors = isObject(this.state.errors) ? this.state.errors : {};

    const blob = e.target.files[0];
    const fileReader = new FileReader();
    fileReader.onloadend = (ev) => {
      const arr = new Uint8Array(ev.target.result).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'user');
        data.append('file', e.target.files[0]);

        const request = { ...UPLOAD_FILE, request: data };
        UtilService.callApi(request, (err, res) => {
          if (err) return;
          errors[type] = undefined;
          formData[type] = res?.data?.files?.[0]?.absolutePath;

          const index = findIndex(this.state.formData.kyc, { file: type });
          if (index > -1) {
            formData.kyc[index].path = res.data.files[0].absolutePath;
          } else {
            formData.kyc.push({
              path: res.data.files[0].absolutePath,
              file: type,
              docType: type === 'photoId' ? this.state.docP : this.state.docB,
              type: stsType,
            });
          }
          this.setState({ ...formData, ...errors });
          OpenNotification({
            type: 'success',
            title: 'Document Uploaded Successfully !',
          });
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  onBlur = (name, val) => {
    if (name === 'username') {
      val = val.replace(/ /g, '');
    }
    const data = { [name]: val };
    if (name === 'confirmPassword') {
      data.password = this.state.formData.password;
      data.noCheck = true;
    } else {
      delete data.noCheck;
    }

    const errors = signupValidator({ ...data, docP: this.state.docP, docB: this.state.docB });
    if (!errors.isValid) {
      this.setState({ errors: errors.errors || {} });
    }
  };

  render() {
    const { formData, reference1, reference2, countries, states, cities, errors, sellers, docB, docP } = this.state;
    const plainOptions = [
      { label: 'Through Advertisement', value: 'Through Advertisement' },
      { label: 'By Internet / Web search', value: 'By Internet / Web search' },
      {
        label: 'Via Fair Participation / Catalogue / Trade Visit',
        value: 'Via Fair Participation / Catalogue / Trade Visit',
      },
      { label: 'Other', value: 'Other' },
    ];
    return (
      <div className="loginDetail registerForm">
        <div className="registerMainScroll">
          <Loader loading={this.props.loading} />
          <div className="loginHead">
            <div className="loginLogo d-none">
              <Logo />
            </div>
            <h3>
              <IntlMessages id="app.signup.registration" />
            </h3>
          </div>
          <div className="accountType"></div>
          <div className="registerMainBlock">
            <Form autoComplete="off" onSubmit={this.handleSignup} className="">
              <div id="section_1">
                <Heading title="Personal Information" className="popupInnerTitle p-0" />
                <div className="registerFiledBlock">
                  <SelectOption
                    defaultValue="Mr."
                    value={formData.prefix}
                    selectValue={this.state.prefixList}
                    onChange={(e) => this.handleOnChange('prefix', e)}
                    label="Prefix"
                  />
                  <InputBlock
                    hasError={errors?.firstName ?? false}
                    onBlur={(e) => this.onBlur('firstName', e.target.value)}
                    onChange={(e) => this.handleOnChange('firstName', e.target.value)}
                    value={formData.firstName}
                    type="text"
                    label="First Name *"
                    placeholder="Enter First Name"
                    name="firstName"
                    id="firstName"
                    autoComplete="nope"
                  />
                  <InputBlock
                    hasError={errors?.lastName ?? false}
                    onBlur={(e) => this.onBlur('lastName', e.target.value)}
                    onChange={(e) => this.handleOnChange('lastName', e.target.value)}
                    value={formData.lastName}
                    type="text"
                    label="Last Name *"
                    placeholder="Enter Last Name"
                  />
                  <div className="from-group inputBlock countryCodeDropdown">
                    <label className="CommonLabel">Mobile No *</label>
                    <div className="mobileCodeBlock f-width">
                      <span className="countryCode">+{formData.countryCode}</span>
                      <IntlTelInput
                        onPhoneNumberBlur={(status, value) => {
                          this.onBlur('mobile', value);
                          status
                            ? this.setState({ mobileValid: true, mobileData: '' })
                            : this.setState({
                                mobileValid: false,
                                mobileData: 'Please enter the valid mobile number.',
                              });
                        }}
                        format={false}
                        formatOnInit={false}
                        onPhoneNumberChange={(valid, number, d) => {
                          const { formData } = this.state;
                          const re = /^[0-9\b]+$/;
                          if (number === '' || re.test(number)) {
                            let errors = null;
                            if (this.state.errors) {
                              errors = Object.assign('', this.state.errors);
                              delete errors['mobile'];
                            }

                            formData['mobile'] = number ? number : '';

                            this.setState({
                              formData,
                              errors,
                              mobileValid: true,
                              mobileData: '',
                            });
                          }
                        }}
                        value={formData.mobile}
                        onSelectFlag={(de, dial, fault) => {
                          const { formData } = this.state;
                          formData['mobile'] = '';
                          formData['countryCode'] = `${dial.dialCode}`;

                          let errors = null;
                          if (this.state.errors) {
                            errors = Object.assign('', this.state.errors);
                            delete errors['mobile'];
                          }

                          this.setState({ formData });
                        }}
                        preferredCountries={['in']}
                        containerClassName="intl-tel-input"
                        inputClassName="form-control"
                        fieldName="mobile"
                        customPlaceholder={(a, obj) => {
                          return `${obj.name}`;
                        }}
                        // placeholder={false}
                      />
                    </div>
                    {errors?.mobile ? (
                      <span style={{ color: 'red' }}>{errors.mobile}</span>
                    ) : (
                      !this.state.mobileValid &&
                      formData.mobile && <span style={{ color: 'red' }}>{this.state.mobileData}</span>
                    )}
                  </div>
                  <InputBlock
                    onBlur={(e) => this.onBlur('email', e.target.value)}
                    // hasError={errors?.email ?? false}
                    onChange={(e) => this.handleOnChange('email', e.target.value)}
                    value={formData.email}
                    type="text"
                    label="Email *"
                    placeholder="Enter Email"
                  />
                  <InputBlock
                    hasError={errors?.username ?? false}
                    onBlur={(e) => this.onBlur('username', e.target.value)}
                    onChange={(e) => this.handleOnChange('username', e.target.value.trim())}
                    value={formData.username}
                    type="text"
                    label="Username *"
                    placeholder="Enter Username"
                  />
                  <InputBlock
                    info
                    hasError={errors?.password ?? false}
                    onBlur={(e) => this.onBlur('password', e.target.value)}
                    onChange={(e) => this.handleOnChange('password', e.target.value)}
                    value={formData.password}
                    type="password"
                    label="Password *"
                    placeholder="Enter Password"
                  />
                  <InputBlock
                    hasError={errors?.confirmPassword ?? false}
                    onBlur={(e) => this.onBlur('confirmPassword', e.target.value)}
                    onChange={(e) => this.handleOnChange('confirmPassword', e.target.value)}
                    value={formData.confirmPassword}
                    type="password"
                    label="Confirm Password *"
                    placeholder="Enter Confirm Password"
                  />
                  <SelectOption
                    hasError={errors?.designation ?? false}
                    value={formData.designation ? formData.designation : 'Select Designation'}
                    selectValue={this.state.designationList}
                    placeholder="Select Designation"
                    onChange={(e) => this.handleOnChange('designation', e)}
                    label="Designation *"
                  />
                  <InputBlock
                    onChange={(e) => this.handleOnChange('wechat', e.target.value)}
                    value={formData.wechat}
                    type="text"
                    label="Wechat Id"
                    placeholder="Enter Wechat Id"
                  />
                  <InputBlock
                    onChange={(e) => this.handleOnChange('skype', e.target.value)}
                    value={formData.skype}
                    type="text"
                    label="Skype Id"
                    placeholder="Enter Skype Id"
                  />
                  <DatePicker
                    expiryDate={formData.dob}
                    handleChange={(e, val) => this.dateHandleChange(e, val, 'dob')}
                    single
                    disabledDate={this.disabledBirthDate}
                    label="Birth Date"
                    placeholder="Select Birth Date"
                  />
                  <DatePicker
                    expiryDate={formData.anniversary}
                    handleChange={(e, val) => this.dateHandleChange(e, val, 'anniversary')}
                    single
                    disabledDate={this.disabledAnniversaryDate}
                    label="Anniversary Date"
                    placeholder="Select Anniversary Date"
                  />
                  <SelectOption
                    hasError={errors?.seller ?? false}
                    placeholder="Select Sales Person"
                    onChange={(e) => this.handleOnChange('seller', e)}
                    value={formData.seller ? formData.seller : 'Select Sales Person'}
                    selectValue={sellers}
                    showSearch
                    label="Sales Person *"
                  />
                </div>
              </div>
              <div id="section_2">
                <Heading title="Company Information" className="popupInnerTitle p-0" />
                <div className="registerFiledBlock">
                  <InputBlock
                    hasError={errors?.companyName ?? false}
                    onBlur={(e) => this.onBlur('companyName', e.target.value)}
                    type="text"
                    label="Company Name *"
                    placeholder="Enter Company Name"
                    onChange={(e) => this.handleOnChange('companyName', e.target.value)}
                    value={formData.companyName}
                  />
                  <InputBlock
                    hasError={errors?.accountName ?? false}
                    onBlur={(e) => this.onBlur('accountName', e.target.value)}
                    type="text"
                    label="Owner Name *"
                    placeholder="Enter Owner Name"
                    onChange={(e) => this.handleOnChange('accountName', e.target.value)}
                    value={formData.accountName}
                  />
                  <SelectOption
                    hasError={errors?.natureOfOrg ?? false}
                    mode="multiple"
                    className="multiSelect pt-0"
                    placeholder="Select Business Type"
                    value={formData.natureOfOrg}
                    selectValue={this.state.NATURE_OF_ORG}
                    showArrow={true}
                    onChange={(e) => this.handleOnChange('natureOfOrg', e)}
                    label="Business Type *"
                  />
                  <InputBlock
                    hasError={errors?.emails ?? false}
                    onBlur={(e) => this.onBlur('emails', e.target.value)}
                    onChange={(e) => this.handleOnChange('emails', e.target.value)}
                    value={formData.emails}
                    type="text"
                    label="Email *"
                    placeholder="Enter Email"
                  />
                  <InputBlock
                    hasError={errors?.address ?? false}
                    onBlur={(e) => this.onBlur('address', e.target.value)}
                    onChange={(e) => this.handleOnChange('address', e.target.value)}
                    value={formData.address}
                    type="text"
                    label="Address *"
                    placeholder="Enter Address"
                  />
                  <InputBlock
                    hasError={errors?.website ?? false}
                    onBlur={(e) => this.onBlur('website', e.target.value)}
                    onChange={(e) => this.handleOnChange('website', e.target.value)}
                    value={formData.website}
                    type="text"
                    label="Website"
                    placeholder="Enter Website"
                  />
                  <SelectOption
                    hasError={errors?.city ?? false}
                    citySearch={this.state.citySearch}
                    getFil={(e) => this.setState({ citySearch: e }, () => this.getCityFilterList())}
                    register
                    defaultValue={this.state.citySearch}
                    value={!isEmpty(formData.city) ? formData.city.name : 'Select City'}
                    selectValue={cities}
                    onChange={(e) => this.handleCityChange(e)}
                    showSearch
                    label="City *"
                  />
                  <SelectOption
                    disabled
                    defaultValue="Select State"
                    value={!isEmpty(formData.state) ? formData.state.name : 'Select State'}
                    selectValue={states}
                    onChange={(e) => this.handleStateChange(e)}
                    label="State *"
                    showSearch
                    hasError={errors?.state ?? false}
                  />
                  <SelectOption
                    disabled
                    defaultValue="Select Country"
                    value={
                      !isEmpty(formData.country) && formData.registrationType !== 2
                        ? formData.country.name
                        : formData.registrationType === 2
                        ? 'India'
                        : 'Select Country'
                    }
                    selectValue={countries}
                    // disabled={formData.registrationType === 2 ? true : false}
                    onChange={(e) => this.handleCountryChange(e)}
                    label="Country *"
                    showSearch
                    hasError={errors?.country ?? false}
                  />
                  <InputBlock
                    onChange={(e) => this.handleOnChange('pinCode', e.target.value)}
                    hasError={errors?.pinCode ?? false}
                    onBlur={(e) => this.onBlur('pinCode', e.target.value)}
                    type="number"
                    value={formData.pinCode}
                    label="Pin Code *"
                    placeholder="Enter Pin Code"
                  />
                </div>
              </div>

              <div id="section_5">
                <Heading title="Upload Documents" className="popupInnerTitle p-0" />

                <div className="registerFiledBlock">
                  <SelectOption
                    value={
                      find(this.state.PERSONAL_DOCS, { id: this.state.docP })
                        ? find(this.state.PERSONAL_DOCS, {
                            id: this.state.docP,
                          }).name
                        : undefined
                    }
                    placeholder={'Select Document'}
                    selectValue={this.state.PERSONAL_DOCS}
                    onChange={(value) => {
                      this.setState({ docP: value });
                    }}
                    label="Personal Identity Proof"
                    hasError={this.state.docP === null ? errors?.PersonalType : false}
                  />
                  <div className="from-group width-66">
                    <FileUpload
                      // required={true}
                      label="Photo Identity Proof"
                      title="Browse for an image to upload"
                      dec="Passport, Driving Licence (scan and upload only .jpg, .jpeg, .png and .pdf files)"
                      accept=".jpg,.jpeg,.png,.pdf"
                      onChange={(e) => {
                        if (this.state.docP) {
                          this.uploadDocument(e, 'photoId', 'personal');
                        } else {
                          e.target.value = null;
                          this.setState((prev) => ({
                            errors: {
                              ...prev.errors,
                              PersonalType: 'Please select Personal Document Type first.',
                            },
                          }));
                        }
                      }}
                    />
                    {formData.photoId ? (
                      ''
                    ) : (
                      <span style={{ color: 'red' }}>{errors && errors.kyc ? errors.kyc : ''}</span>
                    )}
                    {formData.photoId && (
                      <div className="fileUploadedBlockItem">
                        <img
                          crossOrigin="anonymous"
                          height="100px"
                          width="100px"
                          src={formData.photoId.includes('.pdf') ? PDFICON : BASE_URL + '/' + formData.photoId}
                          alt=""
                        />
                        <div
                          onClick={() => {
                            const { formData, errors } = this.state;
                            remove(formData.kyc, {
                              file: 'photoId',
                            });
                            formData['photoId'] = '';
                            delete errors['photoId'];
                            this.setState({ formData, errors });
                          }}
                          className="deleteIcon"
                        >
                          <img src={require('../../assets/svg/close-color.svg')} alt="Remove file" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="registerFiledBlock">
                  <SelectOption
                    value={
                      find(this.state.BUSINESS_DOCS, { id: this.state.docB })
                        ? find(this.state.BUSINESS_DOCS, {
                            id: this.state.docB,
                          }).name
                        : undefined
                    }
                    placeholder={'Select Document'}
                    selectValue={this.state.BUSINESS_DOCS}
                    onChange={(value) => this.setState({ docB: value })}
                    label="Business Licence/Registration"
                    hasError={this.state.docB === null ? errors?.BusinessType : false}
                  />
                  <div className="from-group width-66">
                    <FileUpload
                      // required={true}
                      //label={`Business Identity Proof ${this.state.formData.country.name === 'India' ? '*' : ''}`}
                      label="Business Identity Proof"
                      title="Browse for an image to upload"
                      dec="Pan card, GST, Business Card (scan and upload only .jpg, .jpeg, .png and .pdf files)"
                      accept=".jpg,.jpeg,.png,.pdf"
                      onChange={(e) => {
                        if (this.state.docB) {
                          this.uploadDocument(e, 'businessId', 'business');
                        } else {
                          e.target.value = null;
                          this.setState((prev) => ({
                            errors: {
                              ...prev.errors,
                              BusinessType: 'Please select Business Document Type first.',
                            },
                          }));
                        }
                      }}
                    />
                    {formData.businessId ? (
                      ''
                    ) : (
                      <span style={{ color: 'red' }}>{errors && errors.BusinessDoc ? errors.BusinessDoc : ''}</span>
                    )}
                    {formData.businessId && (
                      <div className="fileUploadedBlockItem">
                        <img
                          crossOrigin="anonymous"
                          height="100px"
                          width="100px"
                          src={formData.businessId.includes('.pdf') ? PDFICON : BASE_URL + '/' + formData.businessId}
                          alt=""
                        />
                        <div
                          onClick={() => {
                            const { formData, errors } = this.state;
                            remove(formData.kyc, {
                              file: 'businessId',
                            });
                            formData['businessId'] = '';
                            delete errors['businessId'];
                            this.setState({ formData, errors });
                          }}
                          className="deleteIcon"
                        >
                          <img src={require('../../assets/svg/close-color.svg')} alt="Remove file" />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div id="section_4">
                <Heading title="References" className="popupInnerTitle p-0" />
                <div className="registerFiledBlock">
                  <div className="from-group singleBlock">
                    <label className="commonLabel">Reference 1</label>
                  </div>

                  <>
                    <InputBlock
                      type="text"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      onChange={(e) => this.handleOnChangeReference('companyName', e.target.value)}
                      value={reference1.companyName}
                    />
                    <InputBlock
                      type="text"
                      label="Contact Person"
                      placeholder="Enter Contact Person"
                      onChange={(e) => this.handleOnChangeReference('name', e.target.value)}
                      value={reference1.name}
                    />
                    <div className="from-group inputBlock countryCodeDropdown">
                      <label className="CommonLabel">Mobile No</label>
                      <div className="mobileCodeBlock f-width">
                        <span className="countryCode">+{reference1.countryCode}</span>
                        {/* <IntlTelInput
                          onPhoneNumberBlur={(status) => {
                            status
                              ? this.setSate({ mobileValid: true, mobileData: '' })
                              : this.setState({
                                  mobileValid: false,
                                  mobileData: 'Please enter the valid mobile number.',
                                });
                          }}
                          format={false}
                          formatOnInit={false}
                          onPhoneNumberChange={(valid, number) => {
                            let { reference1 } = this.state;
                            const re = /^[0-9\b]+$/;
                            if (number === '' || re.test(number)) {
                              reference1['mobile'] = number ? number : '';

                              this.setState({ reference1, mobileValid: true, mobileData: '' });
                            }
                          }}
                          value={reference1.mobile}
                          onSelectFlag={(de, dial) => {
                            let { reference1 } = this.state;
                            reference1['mobile'] = '';
                            reference1['countryCode'] = `${dial.dialCode}`;

                            this.setState({ reference1 });
                          }}
                          preferredCountries={['in']}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          fieldName="mobile"
                          customPlaceholder={(a, obj) => `${obj.name}`}
                        /> */}

                        <IntlTelInput
                          onPhoneNumberBlur={(status) => {
                            status
                              ? this.setState({ mobileValid: true, mobileData: '' })
                              : this.setState({
                                  mobileValid: false,
                                  mobileData: 'Please enter the valid mobile number.',
                                });
                          }}
                          format={false}
                          formatOnInit={false}
                          onPhoneNumberChange={(valid, number, d) => {
                            const { reference1 } = this.state;
                            const re = /^[0-9\b]+$/;
                            if (number === '' || re.test(number)) {
                              reference1['mobile'] = number ? number : '';
                              this.setState({ reference1, mobileValid: true, mobileData: '' });
                            }
                          }}
                          value={reference1.mobile}
                          onSelectFlag={(de, dial) => {
                            const { reference1 } = this.state;
                            reference1['mobile'] = '';
                            reference1['countryCode'] = `${dial.dialCode}`;
                            this.setState({ reference1 });
                          }}
                          preferredCountries={['in']}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          fieldName="mobile"
                          customPlaceholder={(a, obj) => `${obj.name}`}
                        />
                      </div>
                    </div>
                    <InputBlock
                      mobileISD
                      mobileSTD
                      ISDplaceholder="ISD"
                      STDplaceholder="STD"
                      type="number"
                      label="Phone No"
                      placeholder="Enter Phone No"
                      onKeyPress={numberValidator}
                      onChange={(e) => this.handleOnChangeReference('phone', e.target.value)}
                      value={reference1.phone}
                    />

                    <InputBlock
                      type="text"
                      label="Email"
                      placeholder="Enter Email"
                      onChange={(e) => this.handleOnChangeReference('email', e.target.value)}
                      hasError={errors?.reference1email ?? false}
                      value={reference1.email}
                    />
                    <div className="from-group singleBlock">
                      <label className="commonLabel">Reference 2</label>
                    </div>

                    <InputBlock
                      type="text"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      onChange={(e) => this.handleOnChangeReference2('companyName', e.target.value)}
                      value={reference2.companyName}
                    />
                    <InputBlock
                      type="text"
                      label="Contact Person"
                      placeholder="Enter Contact Person"
                      onChange={(e) => this.handleOnChangeReference2('name', e.target.value)}
                      value={reference2.name}
                    />
                    <div className="from-group inputBlock countryCodeDropdown">
                      <label className="CommonLabel">Mobile No</label>
                      <div className="mobileCodeBlock f-width">
                        <span className="countryCode">+{reference2.countryCode}</span>
                        <IntlTelInput
                          onPhoneNumberBlur={(status) => {
                            status
                              ? this.setState({ mobileValid: true, mobileData: '' })
                              : this.setState({
                                  mobileValid: false,
                                  mobileData: 'Please enter the valid mobile number.',
                                });
                          }}
                          format={false}
                          formatOnInit={false}
                          onPhoneNumberChange={(valid, number, d) => {
                            const { reference2 } = this.state;
                            const re = /^[0-9\b]+$/;
                            if (number === '' || re.test(number)) {
                              reference2['mobile'] = number ? number : '';
                              this.setState({ reference2, mobileValid: true, mobileData: '' });
                            }
                          }}
                          value={reference2.mobile}
                          onSelectFlag={(de, dial) => {
                            const { reference2 } = this.state;
                            reference2['mobile'] = '';
                            reference2['countryCode'] = `${dial.dialCode}`;
                            this.setState({ reference2 });
                          }}
                          preferredCountries={['in']}
                          containerClassName="intl-tel-input"
                          inputClassName="form-control"
                          fieldName="mobile"
                          customPlaceholder={(a, obj) => `${obj.name}`}
                        />
                      </div>
                    </div>
                    <InputBlock
                      mobileISD
                      mobileSTD
                      ISDplaceholder="ISD"
                      STDplaceholder="STD"
                      type="number"
                      label="Phone No"
                      placeholder="Enter Phone No"
                      onKeyPress={numberValidator}
                      onChange={(e) => this.handleOnChangeReference2('phone', e.target.value)}
                      value={reference2.phone}
                    />
                    <InputBlock
                      type="text"
                      label="Email"
                      placeholder="Enter Email"
                      onChange={(e) => this.handleOnChangeReference2('email', e.target.value)}
                      hasError={errors?.reference2email ?? false}
                      value={reference2.email}
                    />
                  </>
                </div>
              </div>
              <div>
                <div id="section_5">
                  <Heading title="How Do You Know About Us?" className="popupInnerTitle p-0" />
                  <div className="registerFiledBlock terms-con">
                    <CheckboxGroup
                      options={plainOptions}
                      value={this.state.howList}
                      onChange={(e) => this.setState({ howList: e, showOther: Boolean(e?.includes?.('Other')) })}
                    />
                    {this.state.showOther && (
                      <InputBlock
                        type="text"
                        label="Recommendation by some one"
                        placeholder="Recommendation by some one"
                        onChange={(e) => this.setState({ otherText: e.target.value })}
                        value={this.state.otherText}
                        className="mt-20"
                      />
                    )}
                  </div>
                </div>
                <CheckBox
                  checked={formData.isTermsCondAgree}
                  onChange={(e) => this.handleOnChange('isTermsCondAgree', e.target.checked)}
                  label={
                    <span>
                      <span>I have read and accept </span>
                      <a className="linkTag" onClick={this.TermPopup}>
                        Terms and Conditions.
                      </a>
                    </span>
                  }
                />
              </div>
            </Form>
          </div>
          <Drawer
            onClose={this.onClose}
            visible={this.state.visible}
            wrapClassName="diamondListinSidebar"
            destroyOnClose
          >
            <TermsCondition />
          </Drawer>
          <div className="mt-30 loginButton">
            <button
              style={!formData.isTermsCondAgree ? { cursor: 'not-allowed' } : {}}
              onClick={(e) => this.handleSignup(e)}
              disabled={!formData.isTermsCondAgree}
              className="commonButton"
            >
              <IntlMessages id="app.signup.registerButton" />
            </button>
            <button onClick={(e) => this.handleCancel(e)} className="commonOutline">
              Reset
            </button>
          </div>
          <div className="registerLogLink">
            <IntlMessages id="app.already-have-acc" />
            &nbsp;
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                this.state.isDeviceUrl
                  ? window.LoginCallBack.postMessage('LoginCallBack')
                  : void (window.location.href = '/');
              }}
            >
              <IntlMessages id="app.sign-In" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => ({ ...props, loading: props.commonData.loading });

export default compose(connect(mapStateToProps), withRouter)(SignUpForm);
