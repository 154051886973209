import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Heading from '../../common/Heading';
import InputBlock from '../../common/InputBlock';
import SelectOption from '../../common/SelectOption';
import UtilService from '../../../services/util';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { notification } from 'antd';
import { BASE_URL, MIME_TYPES, LOCAL_STORAGE_VAR } from '../../../constants/Common';
import OpenNotification from '../../common/CommonButton/OpenNotification';
import ProfileUpload from '../../common/ProfileUpload';
import IntlMessages from '../../../util/IntlMessages';
import UploadImage from '../../common/UploadImage/index';
import DefaultUser from '../../common/UserProfileDefault';

import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  UPLOAD_FILE,
  UPDATE_USER_PROFILE,
  GET_CITY_LIST,
  GET_USER_PROFILE,
} from '../../../constants/apiConstant';
/* eslint jsx-a11y/anchor-is-valid: 0 */
export const companyDetail = {
  BusinessType: [
    {
      id: 'Independent Jeweler',
      name: 'Independent Jeweler',
    },
    {
      id: 'Online Jewelry Store',
      name: 'Online Jewelry Store',
    },
    {
      id: 'Diamond Dealer/ Broker',
      name: 'Diamond Dealer/ Broker',
    },
    {
      id: 'Diamond Manufacturer / Cutter',
      name: 'Diamond Manufacturer / Cutter',
    },
    {
      id: 'Jewelry Manufacturer',
      name: 'Jewelry Manufacturer',
    },
    {
      id: 'Jewelry Retail Chain',
      name: 'Jewelry Retail Chain',
    },
    {
      id: 'Pawn shop',
      name: 'Pawn shop',
    },
  ],
};
class CompanyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      cities: [],
      states: [],
      countries: [],
      errors: [],
      doc: '',
    };
  }

  componentDidMount() {
    this.getCountries();
    this.fetchAccount();
  }

  getCountries = () => {
    const self = this;
    const obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    const self = this;
    const obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    const self = this;
    const obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ cities: data.data });
      }
    });
  };

  fetchAccount() {
    const self = this;
    const obj = {
      ...GET_USER_PROFILE,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.map((k) => {
            if (k.type === 'business') self.setState({ doc: [k.docType] });
          });
        self.setState({
          data: {
            ...data.data,
            name: data.data.companyName,
          },
        });
        self.getStates(data.data.country);
        self.getCities(data.data.state);
      }
    });
    // self.props.onClose();
  }

  updateAccount() {
    const self = this;
    const reqObj = clone(self.state.data);
    //reqObj.kyc = [{ ...reqObj.kyc, path: reqObj.businessId }];
    reqObj.kyc = self.props.kyc;

    const obj = {
      ...UPDATE_USER_PROFILE,
      request: reqObj,
    };
    if (self.handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          if (err.data.code === 'E_USER_NOT_FOUND') {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
          if (err.data.code === 'E_DUPLICATE') {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
        }

        if (data && data.code === 'OK') {
          OpenNotification({
            type: 'success',
            title: 'Company details are updated successfully.',
          });
          const setObj = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
          setObj.account = reqObj;
          UtilService.setLocalStorageItem(setObj, `${LOCAL_STORAGE_VAR}-user`);
          self.props.fetchAccount();
          self.props.onClose();
        }
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    const self = this;
    const errors = clone(this.state.errors);
    const formData = clone(this.state.data);

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'image');
        data.append('destination', 'image');
        data.append('file', e.target.files[0]);

        const objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === 'OK') {
            errors[type] = undefined;
            if (type === 'businessId') {
              self.props.handleKyc('business', data.data.files[0].absolutePath, self.state.doc);
            }
            formData[type] = data.data.files[0].absolutePath;
            self.setState({ data: formData, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  handleInputChange = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  handleValidation = () => {
    const formData = this.state.data;
    const errors = this.state.errors;
    let formIsValid = true;
    const blankField = [];
    const invalidFiels = [];

    if (!formData['name']) {
      blankField.push('Company Name');
      formIsValid = false;
    } else {
      errors['name'] = undefined;
    }

    if (!formData['address']) {
      blankField.push('Address 1');
      formIsValid = false;
    }

    if (!formData['country'] || isEmpty(formData['country'])) {
      blankField.push('Country');
      formIsValid = false;
      errors['country'] = true;
    } else {
      errors['country'] = undefined;
    }

    if (!formData['state'] || isEmpty(formData['state'])) {
      blankField.push('State');
      formIsValid = false;
      errors['state'] = true;
    } else {
      errors['state'] = undefined;
    }

    if (!formData['city'] || isEmpty(formData['city'])) {
      blankField.push('City');
      formIsValid = false;
      errors['city'] = true;
    } else {
      errors['city'] = undefined;
    }

    if (!formData['zipCode'] || isEmpty(formData['zipCode'])) {
      blankField.push('Zipcode');
      formIsValid = false;
      errors['zipCode'] = true;
    } else if (
      formData['zipCode'] &&
      !formData['zipCode'].match(/^[0-9]{4,6}$/)
      // !formData["pinCode"].match(/^(\d{3}|\d{{7})$/)
    ) {
      invalidFiels.push('Zipcode');
      formIsValid = false;
      errors['zipCode'] = true;
    }

    if (blankField.length > 0) {
      const arr = blankField;
      var outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      const arr = invalidFiels;
      var outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    const { countries, states, cities, data } = this.state;
    const { BUSINESS_TYPE } = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
    const BUSINESS_DOCS = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_BUSINESS;

    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessages id="app.CompanyDetails" />} className="popupInnerTitle"></Heading>
          <div className="personalProfileWrapper">
            <div className="editProfileLogo">
              {/* <img
                alt=""
                src={
                  isEmpty(data.vendorLogo)
                    ? require("../../../assets/img/user-default.png")
                    : BASE_URL + "/" + data.vendorLogo
                } */}
              {data.vendorLogo ? <img src={`${BASE_URL}/${data.vendorLogo}`} alt="" /> : <DefaultUser size="lg-size" />}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessages id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, 'vendorLogo')}
              />
              <a onClick={() => this.handleInputChange('vendorLogo', '')}>
                <IntlMessages id="app.RemoveProfile" />
              </a>
            </div>
          </div>
        </div>
        <div className="profileDetailSpace">
          <IntlMessages id="app.CompanyName">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('name', e.target.value)}
                placeholder={placeholder}
                value={data.name}
                disabled={data.name}
                label={
                  <>
                    <IntlMessages id="app.CompanyName" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <SelectOption
            defaultValue={<IntlMessages id="app.BusinessType" />}
            value={isEmpty(data.businessType) ? undefined : find(BUSINESS_TYPE, { id: [data.businessType] }).name}
            selectValue={BUSINESS_TYPE}
            onChange={(e) => {
              this.handleInputChange('businessType', e[0]);
            }}
            label={<IntlMessages id="app.SelectBusinessType" />}
          />
          <IntlMessages id="app.AddressLine1">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('address', e.target.value)}
                placeholder={placeholder}
                value={data.address}
                label={
                  <>
                    <IntlMessages id="app.AddressLine1" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.AddressLine2">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('landMark', e.target.value)}
                placeholder={placeholder}
                value={data.landMark}
                label={<IntlMessages id="app.AddressLine2" />}
              />
            )}
          </IntlMessages>
          <SelectOption
            //defaultValue={<IntlMessages id="app.Country" />}
            value={data.country}
            selectValue={countries}
            onChange={(value) => {
              const data = clone(this.state.data);
              data['state'] = undefined;
              data['city'] = undefined;

              data['country'] = value;
              this.getStates(value);
              this.setState({ data, cities: [] });
            }}
            label={
              <>
                <IntlMessages id="app.SelectCountry*" />
              </>
            }
          />
          <SelectOption
            defaultValue={<IntlMessages id="app.State" />}
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              const data = clone(this.state.data);

              data['state'] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.SelectState*" />
              </>
            }
          />
          <SelectOption
            defaultValue={<IntlMessages id="app.City" />}
            value={data.city}
            selectValue={cities}
            onChange={(value) => {
              const data = clone(this.state.data);
              data['city'] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.SelectCity*" />
              </>
            }
          />
          <IntlMessages id="app.ZipCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('zipCode', e.target.value)}
                type="number"
                placeholder={placeholder}
                value={data.zipCode}
                label={
                  <>
                    <IntlMessages id="app.ZipCode" />*
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.CompanyCode">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('vendorCode', e.target.value)}
                placeholder={placeholder}
                value={data.vendorCode}
                label={<IntlMessages id="app.CompanyCode" />}
              />
            )}
          </IntlMessages>
          <SelectOption
            //value={data.kyc ? data.kyc.name : undefined}
            value={
              find(BUSINESS_DOCS, { id: this.state?.doc })
                ? find(BUSINESS_DOCS, { id: this.state?.doc })?.name
                : undefined
            }
            placeholder={'Select Document'}
            selectValue={BUSINESS_DOCS}
            onChange={(value) => {
              this.setState({ doc: value }, () => this.props.handleKyc('business', null, this.state.doc));
            }}
            label="Document Type"
          />

          <UploadImage
            file={data.businessId}
            // file={
            //   !isEmpty(data.kyc)
            //     ? data.kyc.map((k) => {
            //         if (k.type === "business") {
            //           return k.path;
            //         }
            //       })
            //     : ""
            // }
            onUpload={(e) => this.uploadDocument(e, 'businessId')}
            leftAlign
          />
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={() => this.updateAccount()}>
            <IntlMessages id="app.SaveCompanyDetail" />
          </a>
          <a className="commonButton" onClick={() => this?.props?.onClose()}>
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}
export default withRouter(CompanyInformation);
