import React, { useState, useEffect } from 'react';
import '../TermsOfUse/staticContent.less';
import Heading from '../common/Heading';
import InputBlock from '../common/InputBlock';
import SelectOption from '../common/SelectOption';
import DatePicker from '../common/DatePicker';
import TextArea from '../common/TextArea';
import StaticHeader from '../StaticHeader';
import TimerPicker from '../common/TimerPicker';
import './appointmentbook.less';
import OpenNotification from '../common/CommonButton/OpenNotification';
import {
  GET_COUNTRY_LIST,
  FETCH_SELLERS,
  GET_STATE_LIST,
  GET_CITY_LIST,
  TAKE_APPOINTMENT,
  GET_MASTERS,
} from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { isEmpty, clone } from 'lodash';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import moment from 'moment';
import { notification } from 'antd';

export const companyDetail = {
  Country: [
    { value: 'Afghanistan ' },
    { value: 'Albania ' },
    { value: 'Algeria ' },
    { value: 'American Samoa' },
    { value: 'Andorra' },
    { value: 'Angola' },
    { value: 'India' },
  ],
  Appointcountry: [
    { name: 'Hong Kong', value: 'Hong Kong', id: 'Hong Kong' }, //"5cf0a19de6799a22f0cd81dc"
    { name: 'Israel', value: 'Israel ', id: 'Israel' }, //"5cf0a19de6799a22f0cd828b"
    { name: 'Mumbai', value: 'Mumbai', id: 'Mumbai' }, //"5cf0a19de6799a22f0cd825a"
    { name: 'New York', value: 'New York ', id: 'New York' }, //"5cf0a19de6799a22f0cd8226"
  ],
  State: [
    { value: 'Maharashtra ' },
    { value: 'Andhra Pradesh' },
    { value: 'Arunachal Pradesh' },
    { value: 'Assam ' },
    { value: 'Bihar' },
    { value: 'Dadra and Nagar Haveli ' },
    { value: 'Daman and Diu' },
  ],
  City: [
    { value: 'Achalpur' },
    { value: 'Ahmadnagar' },
    { value: 'Akola' },
    { value: 'Amravati' },
    { value: 'AURANGABAD' },
    { value: 'Miraj' },
    { value: 'MUMBAI' },
  ],
  seller: [{ value: 'ABHISHEK KARNAVAT' }, { value: 'AKSHIT BHALAWAT' }],
};

const BookAppointment = () => {
  const [formData, setFormData] = useStateWithCallback({
    companyName: '',
    name: '',
    mobileNumber: '',
    email: '',
    country: {},
    state: {},
    city: {},
    address: '',
    date: new Date().toISOString(),
    time: undefined,
    timeString: '',
    appointmentFor: '',
    demand: '',
    allCountries: [],
    allCities: [],
    countries: [],
    states: [],
    cities: [],
    seller: '',
    sellers: [],
    allSellers: [],
    errors: {},
    shows: [],
    show: '',
  });

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const fetchCountries = () => {
    let obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        setFormData({
          ...formData,
          allCountries: data.data || [],
          countries: data.data || [],
        });
      }
    });
  };

  const fetchSellers = () => {
    let obj = {
      ...FETCH_SELLERS,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        data.data = data.data.map((d) => {
          d.name = UtilService.getFullName(d);
          return d;
        });
        formData.sellers = data.data || [];
        formData.allSellers = data.data || [];
      }
    });
  };

  const fetchShows = () => {
    let obj = {
      ...GET_MASTERS,
      request: { masters: ['SHOW_TYPE'] },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        formData.shows = data.data.SHOW_TYPE || [];
      }
    });
  };

  const onTimeChange = (time, timeString) => {
    setFormData({
      ...formData,
      time: time && time,
      timeString: timeString && formData.date.split('T')[0] + 'T' + moment(time).toISOString().split('T')[1],
    });
  };
  const fetchStates = (countryId) => {
    let obj = {
      ...GET_STATE_LIST,
      request: {
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        formData.states = data.data || [];
      }
    });
  };

  const fetchCities = (stateId, countryId) => {
    let obj = {
      ...GET_CITY_LIST,
      request: {
        state: stateId,
        country: countryId,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        if (stateId) {
          setFormData({
            ...formData,
            cities: data.data || [],
          });
        } else if (countryId) {
          setFormData({
            ...formData,
            cities: data.data || [],
          });
        }
      }
    });
  };

  const handleCountryChange = (e) => {
    let country = formData.countries.filter((c) => c.id === e)[0];
    if (country) {
      formData.country = country ? country : {};
      formData.state = {};
      formData.city = {};
      setFormData(
        {
          ...formData,
          states: [],
          cities: [],
          allCities: [],
        },
        () => {
          fetchStates(e);
          fetchCities(null, e);
        },
      );
    }
  };

  const handleStateChange = (e) => {
    let state = formData.states.filter((c) => c.id === e)[0];
    if (state) {
      formData.city = '';
      formData.state = state ? state : {};
      setFormData(
        {
          ...formData,
          cities: [],
        },
        () => {
          fetchCities(e);
        },
      );
    }
  };

  const handleCityChange = (e) => {
    let city = formData.cities.filter((c) => c.id === e)[0];
    if (city) {
      formData.city = city ? city : {};
      setFormData({ ...formData });
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();

    let reqObj = clone(formData);

    delete reqObj['allCountries'];
    delete reqObj['allSellers'];
    delete reqObj['allCities'];
    delete reqObj['cities'];
    delete reqObj['countries'];
    delete reqObj['errors'];
    delete reqObj['sellers'];
    delete reqObj['states'];
    delete reqObj['time'];

    reqObj.city = isEmpty(reqObj.city) ? null : reqObj.city.id;
    reqObj.state = isEmpty(reqObj.state) ? null : reqObj.state.id;
    reqObj.country = isEmpty(reqObj.country) ? null : reqObj.country.id;
    reqObj.email = isEmpty(reqObj.email) ? null : [reqObj.email];
    reqObj.mobileNumber = isEmpty(reqObj.mobileNumber) ? null : [reqObj.mobileNumber];

    let obj = {
      ...TAKE_APPOINTMENT,
      request: {
        date: reqObj.date,
        mobile: reqObj.mobileNumber ? reqObj.mobileNumber.toString() : '',
        address: reqObj.address,
        city: reqObj.city,
        state: reqObj.state,
        country: reqObj.country,
        email: reqObj.email ? reqObj.email.toString() : '',
        fName: reqObj.name,
        compName: reqObj.companyName,
        in: reqObj.timeString,
        compAddress: reqObj.appointmentFor,
        demand: reqObj.demand,
        salesExecutive: reqObj.seller,
      },
    };
    if (handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          if (err) {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
          if (
            ['E_DUPLICATE_EMAIL', 'E_DUPLICATE', 'E_DUPLICATE_MOBILE', 'E_DUPLICATE_USERNAME'].includes(err.data.code)
          ) {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
        }

        if (data && data.code === 'OK') {
          OpenNotification({
            type: 'success',
            title: data.message,
          });
        }
      });
    }
  };

  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];
    if (!formData['name'] || isEmpty(formData['name'])) {
      blankField.push('Name');
      formIsValid = false;
      errors['name'] = true;
    }
    if (formData['name']) {
      if (!formData['name'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('name');
        formIsValid = false;
        errors['name'] = true;
      } else {
        errors['name'] = undefined;
      }
    }
    if (!formData['companyName'] || isEmpty(formData['companyName'])) {
      blankField.push('Company Name');
      formIsValid = false;
      errors['companyName'] = true;
    }
    if (formData['companyName']) {
      if (!formData['companyName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('Company Name');
        formIsValid = false;
        errors['companyName'] = true;
      } else {
        errors['companyName'] = undefined;
      }
    }
    if (!formData['email'] || isEmpty(formData['email'])) {
      blankField.push('Email Address');
      formIsValid = false;
      errors['email'] = true;
    }
    if (formData['email']) {
      if (!formData['email'].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)) {
        invalidFields.push('Email');
        formIsValid = false;
        errors['email'] = true;
      } else {
        errors['email'] = undefined;
      }
    }
    if (!formData['address'] || isEmpty(formData['address'])) {
      blankField.push('Address');
      formIsValid = false;
      errors['address'] = true;
    }
    // if (formData["address"]) {
    //   if (
    //     !formData["address"].match(/^[a-zA-Z0-9!,@#\$%\^\&*\)\(+=._-]{6,}$/g)
    //   ) {
    //     invalidFields.push("address");
    //     formIsValid = false;
    //     errors["address"] = true; //"Please enter valid Address .";
    //   } else {
    //     errors["address"] = undefined;
    //   }
    // }

    // if (!formData["demand"] || isEmpty(formData["demand"])) {
    //   blankField.push("Demand");
    //   formIsValid = false;
    //   errors["demand"] = true;
    // }

    // if (!formData["country"] || isEmpty(formData["country"])) {
    //   blankField.push("Country");
    //   formIsValid = false;
    //   errors["country"] = true;
    // }
    // if (!formData["state"] || isEmpty(formData["state"])) {
    //   blankField.push("State");
    //   formIsValid = false;
    // }
    // if (!formData["city"] || isEmpty(formData["city"])) {
    //   blankField.push("City");
    //   formIsValid = false;
    //   errors["city"] = true;
    // }
    if (!formData['date'] || isEmpty(formData['date'])) {
      blankField.push('Date');
      formIsValid = false;
      errors['date'] = true;
    }
    if (!formData['time'] || isEmpty(formData['time'])) {
      blankField.push('Time');
      formIsValid = false;
      errors['time'] = true;
    }
    if (!formData.mobileNumber || isEmpty(formData.mobileNumber)) {
      blankField.push('Mobile Number');
      formIsValid = false;
      errors['mobileNumber'] = true;
    }
    if (formData.mobileNumber.length < 10 || formData.mobileNumber.length > 15) {
      invalidFields.push('Mobile Number');
      formIsValid = false;
      errors['mobileNumber'] = true;
    }
    // if (isEmpty(formData["seller"])) {
    //   blankField.push("Sales Person");
    //   formIsValid = false;
    //   errors["seller"] = true;
    // }
    // if (formData.show) {
    if (isEmpty(formData['appointmentFor'])) {
      blankField.push('Country For Appointment');
      formIsValid = false;
      errors['appointmentFor'] = true;
    }
    // }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const handleCancel = (e) => {
    e.preventDefault();
    formData.appointmentFor = '';
    formData.companyName = '';
    formData.name = '';
    formData.mobileNumber = '';
    formData.email = '';
    formData.country = {};
    formData.state = {};
    formData.city = {};
    formData.address = '';
    formData.date = undefined;
    formData.time = undefined;
    formData.timeString = '';
    formData.demand = '';
    formData.allCountries = [];
    formData.allCities = [];
    formData.countries = [];
    formData.states = [];
    formData.cities = [];
    formData.seller = '';
    formData.sellers = [];
    formData.allSellers = [];
    formData.errors = {};
    fetchCountries();
    fetchSellers();
    fetchShows();
  };

  useEffect(() => {
    fetchCountries();
    fetchSellers();
    fetchShows();
  }, []);

  useEffect(() => {
    fetchSellers();
  }, [formData.seller]);

  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading title="Appointment" className="staticMainTitle text-center mb-50" />
          <div>
            <div className="appointmentDetail d-flex">
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img src={require('../../assets/svg/AppointmentBook/location.svg')} alt="" />
                </div>
                <div className="appointmentContent">
                  <span>
                  901, Infinity Tower, India.
                  </span>
                </div>
              </div>
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img src={require('../../assets/svg/AppointmentBook/call.svg')} alt="" />
                </div>
                <div className="appointmentContent">
                  <span>XYZ</span>
                  <span>+91-00-00000000</span>
                </div>
              </div>
              <div className="appointmentDetailItem">
                <div className="appointmentDetailImg">
                  <img src={require('../../assets/svg/AppointmentBook/clock.svg')} alt="" />
                </div>
                <div className="appointmentContent">
                  <span>From : 0/00/0000 0:00:00 AM</span>
                  <span>To : 0/00/0000 0:00:00 PM</span>
                </div>
              </div>
            </div>
            <div className="appointmentBookForm">
              <Heading title="For New Clients" className="popupInnerTitle mb-50 text-center" />
              <form onSubmit={handleSignup}>
                <div className="d-flex flex-wrap">
                  <InputBlock
                    label="Company Name *"
                    placeholder="Company Name"
                    value={formData.companyName}
                    onChange={(e) => handleChange('companyName', e.target.value)}
                    hasError={formData.errors['companyName'] ? true : false}
                  />
                  <InputBlock
                    label="Name *"
                    placeholder="Name"
                    value={formData.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    hasError={formData.errors['name'] ? true : false}
                  />
                  <InputBlock
                    label="Mobile Number *"
                    placeholder="Mobile Number"
                    type="number"
                    value={formData.mobileNumber}
                    onChange={(e) => handleChange('mobileNumber', e.target.value)}
                    hasError={formData.errors['mobileNumber'] ? true : false}
                  />
                  <InputBlock
                    label="Email *"
                    placeholder="Email"
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                    hasError={formData.errors['email'] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Country"
                    value={!isEmpty(formData.country) ? formData.country.name : 'Country'}
                    selectValue={formData.countries}
                    onChange={(e) => handleCountryChange(e)}
                    label="Select Country"
                    showSearch
                    hasError={formData.errors['country'] ? true : false}
                  />
                  <SelectOption
                    defaultValue="State"
                    value={!isEmpty(formData.state) ? formData.state.name : 'State'}
                    selectValue={formData.states}
                    onChange={(e) => handleStateChange(e)}
                    label="Select State"
                    showSearch
                    hasError={formData.errors['state'] ? true : false}
                  />
                  <SelectOption
                    defaultValue="City"
                    value={!isEmpty(formData.city) ? formData.city.name : 'City'}
                    selectValue={formData.cities}
                    onChange={(e) => handleCityChange(e)}
                    showSearch
                    label="Select City"
                    hasError={formData.errors['city'] ? true : false}
                  />
                  <InputBlock
                    label="Address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={(e) => handleChange('address', e.target.value)}
                    hasError={formData.errors['address'] ? true : false}
                  />
                  <SelectOption
                    defaultValue="Show"
                    onChange={(e) => {
                      handleChange('show', e);
                    }}
                    value={formData.show ? formData.show : 'Show'}
                    selectValue={formData.shows}
                    showSearch
                    label="Select Show"
                    hasError={formData.errors['show'] ? true : false}
                  />
                  {/* {formData.show && ( */}
                  <SelectOption
                    selectValue={companyDetail.Appointcountry}
                    label="Appointment For*"
                    value={formData.appointmentFor}
                    onChange={(e) => handleChange('appointmentFor', e)}
                    value={formData.appointmentFor ? formData.appointmentFor : 'Country For Appointment'}
                    showSearch
                    hasError={formData.errors['appointmentFor'] ? true : false}
                  />
                  {/* )} */}
                  <DatePicker
                    label="Preferred Date*"
                    disabledDate={(current) => {
                      return current && current <= moment().startOf('day');
                    }}
                    expiryDate={formData.date}
                    placeholder="Date"
                    format="ll"
                    handleChange={(date) =>
                      setFormData({
                        ...formData,
                        date: date && date.toISOString(),
                      })
                    }
                    hasError={formData.errors['date'] ? true : false}
                  />
                  <TimerPicker label="Preferred Time*" value={formData.time} onChange={onTimeChange} />
                  <SelectOption
                    defaultValue="Select Sales Person"
                    onChange={(e) => {
                      handleChange('seller', e);
                    }}
                    value={formData.seller ? formData.seller : 'Select Sales Person'}
                    selectValue={formData.sellers}
                    showSearch
                    label="Sales Person*"
                    hasError={formData.errors['seller'] ? true : false}
                  />
                  <TextArea
                    className="width-66"
                    label="Demand"
                    placeholder="Demand"
                    value={formData.demand}
                    onChange={(e) => handleChange('demand', e.target.value)}
                    hasError={formData.errors['demand'] ? true : false}
                  />
                </div>
                <div className="d-flex appointmentButton mt-20">
                  <button onClick={(e) => handleSignup(e)} className="commonButton">
                    Save
                  </button>
                  <button onClick={handleCancel} className="commonOutline">
                    Reset
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookAppointment;
