import React, { useMemo, useState } from 'react';
import { find, findIndex, isInteger, isNumber, map, sum } from 'lodash';
import { isNumeric, parseDecimal } from '../../../util/utils';

const BidValueCalculationMobile = ({ original, ...props }) => {
  const originalValue = React.useMemo(() => {
    return Number(parseFloat(original.bidAmount).toFixed(2));
  }, [original.bidAmount]);

  // const updatedValue = React.useMemo(() => {
  //   return original.bidDiscount;
  // }, [original.bidDiscount]);

  const [value, setValue] = React.useState(
    isNumber(original.bidAmount)
      ? parseFloat(original.bidAmount).toFixed(2)
      : isNumber(originalValue)
      ? parseFloat(originalValue).toFixed(2)
      : '0.00',
  );

  React.useEffect(() => {
    setValue(parseFloat(original.bidAmount).toFixed(2));
  }, [original]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();
    props.showError({ showModal: true });
    const _value = e.target.value;
    if (isNumeric(_value)) setValue(_value);
  }, []);

  const handleBlur = React.useCallback((e) => {
    e.preventDefault();
    props.hideError({ showModal: false });
    const _value = e.target.value;
    isNumeric(_value, true) ? props.handleValueBlur(_value, original) : props.handleValueBlur(0, original);
  }, []);

  return (
    <div className="tableInput">
      <input value={value} onChange={handleChange} onBlur={handleBlur} />
    </div>
  );
};

export default BidValueCalculationMobile;
