import React from 'react';
import { Link } from 'react-router-dom';
import './brandLogo.less';

const BrandLogoItem = (props) => {
  return (
    <Link className="brandLogoItem" onClick={() => window.open(props.Link, '_blank')} target="_blank">
      <img src={props.image} alt="" />
    </Link>
  );
};

const BrandLogo = () => {
  return (
    <div className="d-flex mt-10 brandLogoContain">
      {/* <BrandLogoItem
        Link="https://www.debeersgroup.com/globalsightholdersales/en/index.html"
        image={require("../../../assets/img/BrandLogo/3.jpg")}
      />
      <BrandLogoItem
        Link="https://www.forevermark.com/"
        image={require("../../../assets/img/BrandLogo/2.jpg")}
      />
      <BrandLogoItem
        Link="https://www.ddcorp.ca"
        image={require("../../../assets/img/BrandLogo/1.png")}
      />
      <BrandLogoItem
        Link="https://www.canadamark.com/"
        image={require("../../../assets/img/BrandLogo/4.jpg")}
      />
      <BrandLogoItem
        Link="https://www.riotinto.com/"
        image={require("../../../assets/img/BrandLogo/5.png")}
      />
      <BrandLogoItem
        Link="https://www.responsiblejewellery.com/"
        image={require("../../../assets/img/BrandLogo/5.jpg")}
      /> */}
    </div>
  );
};
export default BrandLogo;
