import React, { Component } from 'react';
import NotifyListItem from './NotifyListItem';

class NotificationSetting extends Component {
  render() {
    return (
      <div className="notificationMainBlock">
        <div>
          <div className="notificationTable">
            <div className="notificationTableHead">
              <div className="notifiHeadeItem">Inventory Notification</div>
              <div className="notifiHeadeItem">Frequently</div>
              <div className="notifiHeadeItem">Email</div>
              <div className="notifiHeadeItem">Push</div>
              <div className="notifiHeadeItem">SMS</div>
            </div>
            <NotifyListItem title="All Inventory" dueDates emailoff smsoff />
            <NotifyListItem title="New Inventory" />
          </div>
        </div>
      </div>
    );
  }
}

export default NotificationSetting;
