import React, { Component } from 'react';
import { Drawer } from 'antd';
import DiamondListingAction from '../../common/DiamondListing/DiamondListingAction';
import { LISTINGPAGES } from '../../../components/DiamondList/DiamondListFunctions';
import WatchlistPopup from '../../common/DiamondListing/WatchlistPopup';
import QuotePopup from '../../common/DiamondListing/QuotePopup';
import OfficeSidebar from '../../common/DiamondListing/OfficeSidebar';
import HoldPopup from '../../common/DiamondListing/HoldPopup';
import CommonModal from '../../common/CommonModal';
import ShareDropdown from '../../common/DiamondListing/ShareDropdown';
import { get } from 'lodash';
import BidPopup from 'components/common/DiamondListing/BidPopup';
import { queryStringToObject } from 'util/utils';
import ConfirmStonePopup from '../../common/DiamondListing/ConfirmStonePopup';
import { getGridHeight } from '../../DiamondList';
class MobileDiamondDetail extends Component {
  state = { visible: false };

  render() {
    const data = this.props.data;
    const parent = this.props.parent;
    const gridHeight = getGridHeight(['headerTopMenu', 'covidStrip', 'HeaderStripBlock', 'diamondDetailBottomBox'], 15);
    return (
      <div className="mobilediamondDetail">
        {/* <div className="d-flex flex-wrap"> */}
        <div className="mobilediamond diamondDetailLeft">{parent.leftPart(data)}</div>
        <div className="diamondDetailRight mobileRightDetail">{this.props.params}</div>
        {/* </div> */}
        {(window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
          window.location.pathname.split('/')[1] === LISTINGPAGES.DNA) && (
          <DiamondListingAction
            // nowatch
            noquote
            nofinalcalc
            // nooffice
            nonote
            nonocompare
            // noshare
            noreminder
            nodownload
            noprint
            nopaginate
            quotes
            checked={data ? [data] : []}
            // viewcerti
            // downloadzip
            // sharexray
            downloadZip={() => parent.downloadZip(data)}
            // verifyCerti={() => parent.verifyCerti(data)}
            // shareXray={parent.sendMail}
          />
        )}
        {parent.getEmailModal(data)}
      </div>
    );
  }
}
export default MobileDiamondDetail;
