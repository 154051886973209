import React, { Component } from 'react';
import { connect } from 'react-redux';
import languageData from '../../../../constants/languageData';
import { switchLanguage } from '../../../../appRedux/actions/Common';
import { Select } from 'antd';
import ipapi from 'ipapi.co';
import find from 'lodash/find';

const { Option } = Select;

class LangDropdown extends Component {
  state = {
    country_code: '',
    langDefaultVal: (this.props.commonData.locale && this.props.commonData.locale.name) || languageData[0].name,
  };

  componentDidMount() {
    let self = this;
    var callback = function (res) {
      if (res && res.country_code) {
        let findCountry = find(languageData, {
          country_code: res.country_code,
        });
        let setLanguage = {
          country_code: res.country_code,
        };
        if (findCountry && findCountry.languageId) {
          setLanguage.langDefaultVal = findCountry.name;
          self.props.switchLanguage(findCountry.languageId);
        }
        self.setState(setLanguage);
      }
    };

    ipapi.location(callback);
  }

  render() {
    let groupClass = 'formSelect from-group';
    if (this.props.hasError) {
      groupClass = groupClass + ' erroroMess';
    }
    return (
      <div className={groupClass}>
        <label>{this.props.label}</label>
        <Select
          dropdownClassName="selectDopdown"
          defaultValue={this.state.langDefaultVal}
          style={{ width: 80 }}
          onChange={this.props.switchLanguage}
          getPopupContainer={(trigger) => {
            return trigger;
          }}
        >
          {languageData.map((language) => (
            <Option key={language.languageId} value={language.languageId}>
              {language.name}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps, { switchLanguage })(LangDropdown);
