import React from 'react';
import DiamondTotalCard from './DiamondTotalCard';
import { getPath, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import moment from 'moment';
import { Appointment } from './OfficeSidebar';
import { OFFICESTATUS, LOCAL_STORAGE_VAR } from '../../../constants/Common';



const TableGrouping = (props) => {
  let self = props
  let Master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));
  return (
    <>
      {!props.row.isMatchFooter && (
        <div className="tableGropupingBox">
          <div className="tableGroupTitle">
            <span className="tableGroupTitleInner">
              {props.Collapse && (
                <div className="tableCollapse" onClick={props.onClick}>
                  <img src={props.img} alt="" />
                </div>
              )}
              {props.multipleCheckBox && <div className="checkBoxSelect">{props.children}</div>}
              {props.row && (props.row.groupNo || props.row.groupingTitle) && (
                <span>
                  {getPath() === LISTINGPAGES.MYBID
                    ? `Date: ${props.row.groupNo || props.row.groupingTitle}`
                    : `Pair ID: ${props.row.groupNo || props.row.groupingTitle}`}
                </span>
              )}
              {props.row && props.row.memoNo && <span>{props.row.memoNo}</span>}
              {props.row && props.row.isOfferHeader && props.row.updatedAt && (
                <span>{moment(props.row.updatedAt).format('DD/MM/YYYY')}</span>
              )}
              {props.row && props.row.isUpcomingHeader && (
                <span>{moment(props.row.inDt).format('DD/MM/YYYY')} 3 PM IST</span>
              )}
              {props.row && props.row.isOfficeHeader && (
                <span>
                  {moment(props.row.date).format('DD/MM/YYYY')} - [
                  {moment(props.row.cabinSlot[0].start).format('hh:mm A')}
                  {' - '}
                  {moment(props.row.cabinSlot[0].end).format('hh:mm A')}]{' - '}
                  {Appointment.virtualType.filter((x) => x.id === props.row.meetingType)[0].name}
                </span>
              )}
              {props.row && props.row.isEnquiryRemarks && LISTINGPAGES.ENQUIRY && <span>{props.row.remarks}</span>}
              {props.row && props.row.shpNm && getPath() === LISTINGPAGES.LIST && <span>{Master.SHAPE.find((x) => x.code === props.row.shpNm)?.name}</span>
              }
            </span>
            <div className="tableHeaderMain">{/* {props.row ? props.row.totalDiamonds : 0} Diamond */}</div>
          </div>
        </div>
      )}
      <tr className="tableGroupingTr">
        {!props.columns && (
          <td className="groupingTableAmount" style={{ color: 'transparent' }}>
            0
          </td>
        )}
        {props.columns
          ? props.columns.map((x, index) => {
            const column = props.col?.cells[index]?.column
            let left = column?.totalLeft
            return (
              <td
                className={"groupingTableAmount" + (x.sticky ? ' bg-white' : '')}
                style={{ width: `${x.width.toString()}px`, position: x.sticky && 'sticky', zIndex: x.sticky || index === 0 ? '3' : '1', background: x.sticky || index === 0 ? 'white' : 'inherit', left: x.sticky ? left : undefined }}
              >
                {props.displayTotal.includes(x.id) && <DiamondTotalCard row={props.row} col={x.id} />}
              </td>
            );
          })
          : null}
      </tr>
    </>
  );
};
export default TableGrouping;
