import UtilService from 'services/util';
import { objectToQueryString } from './utils';

export const withURLParams = (input, params = []) => {
  const prefix = !input.url.endsWith('/') ? '/' : '';
  return { ...input, url: `${input.url}${prefix}${params.join('/')}` };
};

export const withQueryParams = (input, params = {}) => {
  return { ...input, url: `${input.url}${objectToQueryString(params)}` };
};

export const callApi = (...args) => {
  return new Promise((resolve) => UtilService.callApi(...args, (...args) => resolve(args)));
};
