import React, { Component } from 'react';
import { Row, Col } from 'antd';
import IntlMessages from '../../util/IntlMessages';
import { withRouter } from 'react-router-dom';
import './index.css';

class VerifyDevice extends Component {
  render() {
    return (
      <>
        <div className="">
          <div>
            <section className=" thank-you ">
              <div className="thank-container verfiyDevice">
                <Row className="text-center flex-wrap" align="middle" type="flex">
                  <Col lg={12} md={24} xl={12} xs={24} className="thank-you-img">
                    {/* <img src={require("../../assets/img/thank-you.png")} alt="" /> */}
                  </Col>
                  <Col lg={12} md={24} xl={12} xs={24} className="text-center">
                    <div className="verifyContent">
                      <span className="verifyTopTitle">Dear “Customer Name”,</span>
                      <h1 className="extraTitle">New device signed in with your account</h1>
                      <h3>
                        Your Unique Diamax Pvt Ltd account was signed in to a new device. Please review below activity detail:
                      </h3>
                      <ul className="verifyBlock">
                        <li>
                          <span className="verifyBlockTitle">Username</span>
                          <span className="verifyBlockDec">kalpesh (kalpesh@coruscate.com)</span>
                        </li>
                        <li>
                          {/* <span className='verifyBlockImg'>
                                    <img src={require('./time.svg')} alt='time' />
                                </span> */}
                          <span className="verifyBlockTitle">Date & Time</span>
                          <span className="verifyBlockDec">23rd April 2020, 10:01 PM.</span>
                        </li>
                        <li>
                          <span className="verifyBlockTitle">Location</span>
                          <span className="verifyBlockDec">Surat, Gujarat</span>
                        </li>
                        <li>
                          <span className="verifyBlockTitle">Device</span>
                          <span className="verifyBlockDec">Mozilla Firefox, Windows PC</span>
                        </li>
                        <li>
                          <span className="verifyBlockTitle">IP Address</span>
                          <span className="verifyBlockDec">101.102.1.123</span>
                        </li>
                      </ul>
                      <>
                        <h3>We are here to help you, was that you or do you know this activity?</h3>
                        <div className="verifyCheckbox">
                          <div className="">
                            <button type="button" className="button primary-fill">
                              <span>No, I don’t kow</span>
                            </button>
                            <button type="button" className="button primary-fill">
                              <span>Yes, I know</span>
                            </button>
                          </div>
                        </div>
                      </>
                      <h3>
                        Thank you for being here, you have already selected your answer that it was{' '}
                        <b style={{ fontWeight: 'bold' }}> not </b> you. Your account and data is safe.
                      </h3>
                      <div className="verifyNote">
                        <span>Note</span>
                        <span>
                          If customer already have chosen the response then instead of yes and no, this message will be
                          displays.
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(VerifyDevice);
