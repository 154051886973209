import React, { useState } from 'react';
import StaticHeader from '../StaticHeader';
import './feedback.less';
import '../TermsOfUse/staticContent.less';
import Heading from '../common/Heading';
import TextArea from '../common/TextArea';
import { Radio, Slider } from 'antd';

const Feedback = () => {
  const marks = {
    10: '1',
    20: '2',
    30: '3',
    40: '4',
    50: '5',
    60: '6',
    70: '7',
    80: '8',
    90: '9',
    100: '10',
  };
  const [value, Setvalue] = useState(1);
  const [value1, Setvalue1] = useState(false);
  const [value2, Setvalue2] = useState(false);
  const [value3, Setvalue3] = useState(false);
  const [value4, Setvalue4] = useState(true);
  const onChange = (e) => {
    Setvalue(value);
    //   value: e.target.value,
  };
  const onClose = (e) => {
    Setvalue1(false);
    Setvalue2(false);
    Setvalue3(false);
    Setvalue4(false);
  };
  const other = (e) => {
    Setvalue1(true);
  };
  const yes1 = (e) => {
    Setvalue2(true);
  };
  const yes2 = (e) => {
    Setvalue3(true);
  };
  const no = (e) => {
    Setvalue4(true);
  };
  const SmileBlock = (props) => {
    return (
      <div className={`feedbackRatingWrapper ${props.activeClass && 'active'}`}>
        {/* <div className="feedbackratingIcon">
                    <img src={props.image} alt=""/>
                </div> */}
        <span className="feedbackRationTitle">{props.number}</span>
      </div>
    );
  };
  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain white-bg">
        <div className="statiContentBox">
          {/* <Heading title="Feedback" className="staticMainTitle text-center mb-50" /> */}
          <div className="d-flex feddbackWrapper">
            <div className="feedbackLeft">
              <div className="feedbackLeftBoxInner">
                <div className="feedbackContent">
                  <h2>Fell free to drop us your Feedback</h2>
                </div>
                <img src={require('../../assets/img/feddback/1.png')} alt="" />
              </div>
            </div>
            <div className="feddbackDetail">
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading title="1. How did you hear about us?" className="innerPageSmallTitle" />
                  <Radio.Group defaultValue={value} onChange={onChange}>
                    <Radio value={1} onClick={onClose}>
                      Print Media{' '}
                    </Radio>
                    <Radio value={2} onClick={onClose}>
                      Social Media
                    </Radio>
                    <Radio value={3} onClick={onClose}>
                      Events/Exhibitions
                    </Radio>
                    <Radio value={4} onClick={other}>
                      Others
                    </Radio>
                  </Radio.Group>
                  {value1 && (
                    <div className="mt-10">
                      <TextArea placeholder="Enter Your Comment" />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto ">
                <div>
                  <Heading title="2. Are you having trouble finding anything? " className="innerPageSmallTitle" />
                  <Radio.Group defaultValue={1} onChange={onChange}>
                    <Radio value={1} onClick={onClose}>
                      NO{' '}
                    </Radio>
                    <Radio value={2} onClick={yes1}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value2 && (
                    <div className="mt-10">
                      <TextArea placeholder="Enter Your Comment" />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading title="3. Is there anything missing on this page?" className="innerPageSmallTitle" />
                  <Radio.Group defaultValue={1} onChange={onChange}>
                    <Radio value={1} onClick={onClose}>
                      NO{' '}
                    </Radio>
                    <Radio value={2} onClick={yes2}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value3 && (
                    <div className="mt-10">
                      <TextArea placeholder="Enter Your Comment" />
                    </div>
                  )}
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading title="4. Is our pricing better?" className="innerPageSmallTitle" />
                  <Radio.Group defaultValue={1} onChange={onChange}>
                    <Radio value={1} onClick={no}>
                      NO{' '}
                    </Radio>
                    <Radio value={2} onClick={onClose}>
                      Yes
                    </Radio>
                  </Radio.Group>
                  {value4 && (
                    <div className="mt-10">
                      <TextArea placeholder="Enter Your Comment" />
                    </div>
                  )}
                </div>
              </div>

              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="5. How likely are you to recommend our website to a friend or other company?"
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock  number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock activeClass number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider marks={marks} step={10} defaultValue={30} />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="6. How did your experience compare to your expectations?"
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider marks={marks} step={10} defaultValue={30} />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="7. Which of our competitors did you consider before choosing us?"
                    className="innerPageSmallTitle"
                  />
                  <TextArea placeholder="Enter Your Comment" />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading title="8. What are the features you wish our website had?" className="innerPageSmallTitle" />
                  <TextArea placeholder="Enter Your Comment" />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="9. Compared to our competitors, is our product quality better, worse, or about the same? "
                    className="innerPageSmallTitle"
                  />
                  <Radio.Group defaultValue={1} onChange={onChange}>
                    <Radio value={1}>Better</Radio>
                    <Radio value={2}>Worse</Radio>
                    <Radio value={3}>About the Same</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="10. How easy was it to use our website? Did you have any problems?"
                    className="innerPageSmallTitle"
                  />
                  <div className="feedbackratingBlock">
                    {/* <SmileBlock number="1" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="2" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="3" image={require("../../assets/svg/Feedback/1.svg")}/>
                                    <SmileBlock number="4" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="5" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="6" image={require("../../assets/svg/Feedback/2.svg")}/>
                                    <SmileBlock number="7" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="8" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="9" image={require("../../assets/svg/Feedback/3.svg")}/>
                                    <SmileBlock number="10" image={require("../../assets/svg/Feedback/smiley.svg")}/> */}
                    <Slider marks={marks} step={10} defaultValue={30} />
                  </div>
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading
                    title="11. What is/are the most useful feature(s) on our website?"
                    className="innerPageSmallTitle"
                  />
                  <TextArea placeholder="Enter Your Comment" />
                </div>
              </div>
              <div className="staticContentWrapper height-auto">
                <div>
                  <Heading title="12. How can we help you complete your purchase?" className="innerPageSmallTitle" />
                  <TextArea placeholder="Enter Your Comment" />
                </div>
              </div>
              <div className="staticContentWrapper height-auto width-100">
                <div>
                  <Heading
                    title="13. If you could suggest changes about our website, what would it be?"
                    className="innerPageSmallTitle"
                  />
                  <TextArea placeholder="Enter Your Comment" />
                </div>
              </div>
              <div className="mt-10 text-center feedbackAction">
                <button className="commonButton">Submit</button>
                <button className="commonOutline">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Feedback;
