import React from 'react';

export default function Loader({ loading = true, fixed }) {
  if (loading) {
    return (
      <div
        id="loader"
        className={`main-loader ${fixed ? '--fixed' : ''}`}
        style={{
          width: fixed ? 'calc(100% + 30px)' : '100%',
        }}
      >
        <div id="loader-inner">
          <div className="">
            <img src={require('../../assets/img/loader.svg')} alt="Unique Diamax Pvt Ltd" />
          </div>
          {/* <input type="hidden" id="progress_width" value="0" /> */}
        </div>
      </div>
    );
  }
  return null;
}
