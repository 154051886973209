import React, { Component } from 'react';
import GuestLoginFrom from './Auth/GuestLoginForm';
import Logo from './common/Logo';
import AuthPoster from './Auth/AuthPoster';

class GuestLogin extends Component {
  render() {
    return (
      <>
        <div className="loginMainWrapper">
          <AuthPoster />
          <div className="LoginRightBlock">
            <GuestLoginFrom />
          </div>
        </div>
        {/* // <div className="h-full">
      //   <div className="login-container h-full d-flex width-full">
      //     <GuestLoginFrom />
      //     <div className="event-block h-full">
      //       <div className="login-logo text-center">
      //         <div className="login-brand mb-15">
      //           <Logo />
      //         </div>
      //         <div className="login-brand-name white-text">
      //           Welcome to Unique Diamax Pvt Ltd.
      //         </div>
      //       </div>
      //       <div className="event-slider">
      //         <div className="slider-wrap">
      //           <div className="slider">
      //             <div className="slide">

      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div> */}
      </>
    );
  }
}

export default GuestLogin;
