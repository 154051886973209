import React from 'react';
import { TimePicker } from 'antd';
import './timerpicker.less';
import moment from 'moment';

const Timerpicker = (props) => {
  return (
    <div className="timerPicker from-group">
      <label>{props.label}</label>
      <TimePicker onChange={props.onChange} value={props.value} defaultOpenValue={moment('00:00:00', 'HH:mm:ss')} />
    </div>
  );
};
export default Timerpicker;
