import React, { Component } from 'react';
import { formatNumber } from '../../components/common/DiamondListing/SelectStone';
import { getParams, LISTINGPAGES } from '../../components/DiamondList/DiamondListFunctions';
import { find, get } from 'lodash';
import { GET_DASHBOARD_DATA, GET_BANNERS } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { connect } from 'react-redux';
import { salesPersonAction } from '../../appRedux/actions/DashActions';
import { BASE_URL, LOCAL_STORAGE_VAR, FILE_URLS, LAB_LINKS } from '../../constants/Common';
import SavedSearch from './SavedSearch';
import { withRouter, Link } from 'react-router-dom';
import { Modal } from 'antd';
import { getNextBidData } from '../../services/util';
import { isString, isEmpty, isArray } from 'util/utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import Slider from 'react-slick';
import { specialDiamondList } from '../../containers/APIFunctions';
import { BidService } from 'services/BidService';
import CommonModal from 'components/common/CommonModal';
import { ATTEND_SHOW, NOT_ATTEND_SHOW } from '../../constants/apiConstant';

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
class Dashoard extends Component {
  state = {
    showData: this.props.showData,
    dashData: undefined,
    bannerData: undefined,
    isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
    topBanner: {},
    otherBanners: [],
    homeTopCenter: [],
    sales: {},
    centerBanner: {},
    modalVisible: false,
    showModal: false,
    bidDiamond: '',
    dealOfTheDay: '',
    specialStoneList: [],
    accurateStock: 0,
    suggestedStock: 0,
    myBidCount: 0,
    bidCartCount: 0,
    isClickNo: false,
  };

  async componentDidMount() {
    this.getBannerData();
    this.getDashBoardData();
    specialDiamondList({ sectionType: 14 }, (cb) => this.setState({ accurateStock: cb.data }));

    specialDiamondList({ sectionType: 14, suggestedStockType: 'suggested' }, (cb) =>
      this.setState({ suggestedStock: cb.data }),
    );

    const dealOfTheDay = await getNextBidData(2)
      .then()
      .catch(() => undefined);
    this.setState({ dealOfTheDay: dealOfTheDay ?? {} });

    specialDiamondList(!dealOfTheDay ? { wSts: 'E' } : { wSts: 'DD' }, (cb) =>
      this.setState({ specialStoneList: cb.data || [] }),
    );
  }

  // Get dashboard data
  getDashBoardData = () => {
    const obj = {
      ...GET_DASHBOARD_DATA,
      request: {
        savedSearch: true,
        recentSearch: true,
        track: true,
        orderData: true,
        isStockCount: true,
        shippingCount: true,
        seller: true,
        account: true,
        bidStnSts: this.props.bidConfig?.sub_types?.allowDiamondFilterWSts,
        from: moment().startOf('day').add(-6, 'days'),
        to: moment(),
      },
    };
    UtilService.callApi(obj, (err, data) => {
      if (err) {
        this.setState({ dashData: undefined });
      } else if (data && data.code === 'OK') {
        if (data.data) {
          this.setState({
            dashData: data.data,
            sales: data.data.seller,
            tracks: data.data.tracks,
            myBidCount: data.data.myBidCount,
            bidCartCount: data.data.bidCart,
          });
          // self.props.throwSalesData(data.data.seller);
        }
      } else {
        localStorage.remove(`${LOCAL_STORAGE_VAR}-quoteDay`);
        this.setState({ dashData: undefined });
      }
    });
  };

  getBannerData = () => {
    const obj = {
      ...GET_BANNERS,
      request: { page: 1, limit: 100, filter: { bannerType: 1 }, isSkipSave: true },
    };

    UtilService.callApi(obj, (err, res) => {
      this.setState({ bannerData: undefined });

      if (!err && res.code === 'OK') {
        if (isArray(res?.data?.list)) {
          const bannerTop = find(res.data.list, { type: '1' });
          const otherBanner = find(res.data.list, { type: '2' });
          const bannerCenter = find(res.data.list, { type: '23' });
          const homeTopCenter = find(res.data.list, { type: '15' });
          const centerImage = localStorage.getItem('image')
            ? localStorage.getItem('image') === 'true'
              ? true
              : false
            : true;
          localStorage.setItem('image', centerImage);

          this.setState({
            showModal: true,
            modalVisible: centerImage,
            otherBanners: otherBanner,
            topBanner: bannerTop,
            centerBanner: bannerCenter,
            homeTopCenter: homeTopCenter,
            redirect: bannerCenter?.url || undefined,
          });
        }
      }
    });
  };

  getAttendShow = () => {
    // const obj = {
    //   method: 'get',
    //   url: '/web/v1/common/show?isAvailableForShow=true',
    //   showLoader: true,
    // };

    // UtilService.callApi(obj, (err, res) => {
    //   console.log(err, res);
    //   if (err) return reject(err);
    //   if (res?.code === 'OK') return resolve(res.data);
    //   reject(err);
    // });

    return new Promise((resolve, reject) => {
      const obj = {
        ...ATTEND_SHOW,
      };
      // console.log(this.state.isClickNo);
      UtilService.callApi(obj, (err, res) => {
        if (err) return reject(err);
        if (res?.code === 'OK') return resolve(res.data);
        reject(err);
      });
    });
  };

  handleCancel = () => {
    this.setState({ modalVisible: false });
    localStorage.setItem('image', false);
  };

  handleCancelShowPopup = () => {
    this.setState({ showModal: false, isClickNo: true });
    localStorage.setItem('hongkongModal', false);
    return new Promise((resolve, reject) => {
      const obj = {
        ...NOT_ATTEND_SHOW,
      };
      // console.log(this.state.isClickNo);
      UtilService.callApi(obj, (err, res) => {
        if (err) return reject(err);
        if (res?.code === 'OK') return resolve(res.data);
        reject(err);
      });
    });
  };

  handleCloseIconFunction = () => {
    this.setState({ showModal: false, isClickNo: true });
  };

  render() {
    const { dashData, topBanner, otherBanners, sales = {}, centerBanner } = this.state;
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const centerImage = localStorage.getItem('image');
    const bidDiamond = BidService.bid ?? {};
    const hkModal = localStorage.getItem('hongkongModal');
    // console.log( this.props.isAvailableInShow, "hongkong",hkModal);
    return (
      <>
        {Boolean(centerImage === 'true' && centerBanner?.images && hkModal === 'false') && (
          <Modal visible={this.state.modalVisible} footer={null} onCancel={this.handleCancel} className="center-banner">
            <a href={this.state.redirect} style={{ cursor: !this.state.redirect ? 'default' : undefined }}>
              <img src={BASE_URL + centerBanner.images} />
            </a>
          </Modal>
        )}

        {Boolean(hkModal === 'true') && (this.props.isAvailableInShow !== 1 || this.props.isAvailableInShow !== 2) && this.props?.showData?.ispopupshow === true && (
          <CommonModal
            maskClosable={false}
            visible={this.state.showModal}
            handleCloseIcon={this.handleCloseIconFunction}
            handleOk={() => {
              this.getAttendShow();
              this.setState({ showModal: false });
              localStorage.setItem('hongkongModal', false);
            }}
            handleCancel={() => {
              this.handleCancelShowPopup();
            }}
            title={this.props.showData?.title}
            footerShow
            submitTitle="Yes"
            cancelTitle="No"
          >
            <h3 align="center" style={{ color: 'rgba(0,0,0,0.70)' }}>
              {this.props.showData?.description}
            </h3>
          </CommonModal>
        )}
        <div className="dashboardWrapper bg_grey_new">
          <div className="dashbaord_left">
            {dashData && dashData.stockCount && dashData.stockCount.length !== 0 && (
              <div className="my_count ">
                {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && (
                  <div className="count_block search-count-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/search-black.svg')} alt="" />
                    </div>
                    <Link to="/diamond-search">
                      <div className="count_text">
                        {/* <h1>{dashData.stockCount[0].stockStone}</h1> */}
                        <h3>&nbsp;</h3>
                        <h3>Search Diamonds</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'EXCLUSIVE.view', false) && (
                  <div className="count_block special-stone-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/special-stone-black.svg')} alt="" />
                    </div>
                    <Link to="/special-stone">
                      <div className="count_text">
                        <h1>{dashData.stockCount[0]?.specialStone}</h1>
                        <h3>Exclusive Stone</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'UPCOMING.view', false) && (
                  <div className="count_block upcoming-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/upcoming-black.svg')} alt="" />
                    </div>
                    <Link to="/upcoming">
                      <div className="count_text">
                        <h1>{dashData?.stockCount?.[0]?.upcomingStone}</h1>
                        <h3>New Arrival</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {/* DO NOT DELETE THIS>> ITS USE FOR SHOW */}
                {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) &&
                  this.state?.showData &&
                  this.state?.showData?.isShowEnable === true && (
                    <div className="count_block upcoming-box">
                      <div className="showStone">
                        <img src={require('../../assets/svg/sidebar/JCK/show_blue.svg')} alt="" />
                      </div>
                      <Link to="/show">
                        <div className="count_text">
                          <h1>{this.state?.showData?.shortName}</h1>
                          <h3>Show</h3>
                        </div>
                      </Link>
                    </div>
                  )}

                {get(USER_PERMIT, 'CART.view', false) && (
                  <div className="count_block cart-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/my-cart-black.svg')} alt="" />
                    </div>
                    <Link to="/account/cart">
                      <div className="count_text">
                        <h1>{this.state.tracks[1]?.pieces}</h1>
                        <h3>My Cart</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'WATCHLIST.view', false) && (
                  <div className="count_block watchlist-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/watchlist-black.svg')} alt="" />
                    </div>
                    <Link to="/account/watchlist">
                      <div className="count_text">
                        <h1>{this.state.tracks[2]?.pieces}</h1>
                        <h3>My Watchlist</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'QUOTE.view', false) && (
                  <div className="count_block quote-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/my-offer-black.svg')} alt="" />
                    </div>
                    <Link to="/account/quote-list">
                      <div className="count_text">
                        <h1>{this.state.tracks[4]?.pieces}</h1>
                        <h3>My Quote</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'ENQUIRY.view', false) && (
                  <div className="count_block enquiry-box">
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/my-enquiry.svg')} alt="" />
                    </div>
                    <Link to="/account/enquiry">
                      <div className="count_text">
                        <h1>{this.state.tracks[6]?.pieces}</h1>
                        <h3>My Enquiry</h3>
                      </div>
                    </Link>
                  </div>
                )}
                {get(USER_PERMIT, 'OFFICE.view', false) && (
                  <div
                    onClick={() => {
                      this.props.history.push('/account/office-list');
                    }}
                    className="count_block appointment-box"
                  >
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/appointment.svg')} alt="" />
                    </div>
                    <div className="count_text">
                      <h1>{this.state.tracks[3]?.pieces}</h1>
                      <h3>My Office View</h3>
                    </div>
                  </div>
                )}
                {get(USER_PERMIT, 'BID.view', false) && !isEmpty(this.props.bidConfig) ? (
                  <div
                    onClick={() => {
                      this.props.history.push(
                        this.props.bidConfig.sub_types?.subTypeCode === 'BIWI'
                          ? '/search-bid-diamond'
                          : '/new-arrival-bid-it',
                      );
                    }}
                    className="count_block bid-count-box"
                  >
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/new-arrivals-black.svg')} alt="" />
                    </div>
                    <div className="count_text">
                      {this.props.bidConfig.sub_types?.subTypeCode !== 'BIWI' ? (
                        <h1>{dashData.stockCount[0].bidStone}</h1>
                      ) : (
                        <h3>
                          <b>
                            {'BIWI'}
                            <span className="m-live-red">Live</span>
                          </b>
                        </h3>
                      )}
                      <h3>Bid Stone</h3>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {get(USER_PERMIT, 'MY_BID.view', false) && !isEmpty(bidDiamond) ? (
                  <div
                    onClick={() => {
                      this.props.history.push('/my-bid');
                    }}
                    className="count_block bid-box"
                  >
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/upcoming-black.svg')} alt="" />
                    </div>
                    <div className="count_text">
                      <h1>{this.state.myBidCount}</h1>
                      <h3>My Bid</h3>
                    </div>
                  </div>
                ) : (
                  ''
                )}
                {get(USER_PERMIT, 'BID.view', false) && !isEmpty(bidDiamond) ? (
                  <div
                    onClick={() => {
                      this.props.history.push('/my-bid?cart');
                    }}
                    className="count_block bid-cart-box"
                  >
                    <div className="icon">
                      <img src={require('../../assets/svg/sidebar/bid-cart.svg')} alt="" />
                    </div>
                    <div className="count_text">
                      <h1>{this.state.bidCartCount}</h1> <h3>My Bid Cart</h3>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            )}

            <div className="collection_wrap three-banner">
              {this.state.homeTopCenter?.images?.map((x, i) => {
                const value = this.state.homeTopCenter?.url?.split(',');
                return (
                  <a key={i} href={value?.[i]} className="collection_banner" target="_blank" rel="noreferrer">
                    <img src={BASE_URL + x} alt="" />
                  </a>
                );
              })}
            </div>

            <div className="deal_block_wrap">
              <div className="deal_block_container ">
                <div className="slider_block">
                  <div className="title_block">
                    <h3 className="title">
                      {JSON.stringify(this.state.dealOfTheDay) !== '{}' ? 'Deal Of The Day' : 'Exclusive Stone'}
                    </h3>
                    <Link to={JSON.stringify(this.state.dealOfTheDay) !== '{}' ? '/deal-of-the-day' : '/special-stone'}>
                      <span>View All</span>
                    </Link>
                  </div>
                  <Slider {...settings}>
                    {this.state.specialStoneList?.map((block, index) => (
                      <div className="daimondGridViewItem" key={index}>
                        <div className="DiamondGridViewImg">
                          {block.img && <img src={FILE_URLS.img.replace('***', block.vStnId)} alt="" />}
                        </div>
                        <div className="gridViewContent">
                          <div className="gridLeftContent">
                            <h2>{block.shpNm}</h2>
                            <div className="d-flex st_id">
                              <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                                {block.vStnId}
                              </a>
                              <a
                                className="labName"
                                onClick={() => {
                                  if (block.lbNm && block.rptNo) {
                                    window.open(LAB_LINKS[block.lbNm.toUpperCase()].replace('', block.rptNo));
                                  }
                                }}
                              >
                                <span
                                  className={
                                    block.lbNm === 'GIA'
                                      ? 'gia-cer-color'
                                      : block.lbNm === 'IGI'
                                        ? 'ige-cer-color'
                                        : 'other-cer-color'
                                  }
                                >
                                  {block.lbNm}
                                </span>
                              </a>
                            </div>
                            <p>{getParams(block).join(' ')}</p>

                            <span className="st_amt">${formatNumber(Math.round(block.amt))}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
              {otherBanners && otherBanners.images && otherBanners.images.length !== 0 && (
                <div className="bannerImg">
                  <div className="banner_container">
                    {!isEmpty(bidDiamond) ? (
                      !isEmpty(bidDiamond?.sub_types?.bidImage) ? (
                        <a className="collection_banner" target="_blank">
                          <img
                            className="collection_banner_img"
                            src={BASE_URL + bidDiamond?.sub_types?.bidImage}
                            alt="banner"
                          />
                        </a>
                      ) : null
                    ) : !isEmpty(topBanner?.images) ? (
                      <a href={topBanner?.url} className="collection_banner" target="_blank" rel="noreferrer">
                        <img className="collection_banner_img" src={BASE_URL + topBanner?.images[0]} alt="banner" />
                      </a>
                    ) : null}
                  </div>
                  {otherBanners.images[0] && (
                    <div className="banner_container">
                      <a href={otherBanners?.url} className="collection_banner" target="_blank" rel="noreferrer">
                        <img className="collection_banner_img" src={BASE_URL + otherBanners.images[0]} alt="banner" />
                      </a>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="dashboard_right">
            <Tabs className="search_tabs diamond_data">
              <TabList>
                <Tab>Recent Search</Tab>
                <Tab>Saved Search</Tab>
              </TabList>

              <TabPanel>
                {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && (
                  <div className="diamond_block_wrap">
                    {dashData &&
                      dashData.recentSearch &&
                      dashData.recentSearch.list &&
                      dashData.recentSearch.list.length !== 0 ? (
                      dashData.recentSearch.list.map((search, i) => {
                        return <SavedSearch key={i} save saveId={search.id} saveDetail={search} />;
                      })
                    ) : (
                      <div className="no_data">
                        <span>Your recent search is empty.</span>
                      </div>
                    )}
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && (
                  <div className="diamond_block_wrap">
                    {dashData &&
                      dashData.savedSearch &&
                      dashData.savedSearch.list &&
                      dashData.savedSearch.list.length !== 0 ? (
                      dashData.savedSearch.list.map((search, i) => (
                        <SavedSearch key={i} save saveId={search.id} saveDetail={search} />
                      ))
                    ) : (
                      <div className="no_data">
                        <span>Your saved search is empty.</span>
                      </div>
                    )}
                  </div>
                )}
              </TabPanel>
            </Tabs>
            <Tabs className="search_tabs bg_light diamond_data">
              <TabList>
                <Tab>Suggested Stock</Tab>
                <Tab>Accurate Stock</Tab>
              </TabList>
              <TabPanel>
                {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && (
                  <div className="diamond_block_wrap">
                    {this.state.suggestedStock === 0 && (
                      <>
                        <div className="no_data dashbaord_right_continer">
                          <span>No suggestion stock found.</span>
                        </div>
                        <div className="no_data dashbaord_right_continer">
                          <Link to="/diamond-search">Search Diamonds</Link>
                        </div>
                      </>
                    )}

                    {this.state.suggestedStock?.length
                      ? this.state.suggestedStock.map((block, i) => (
                        <div key={i} className="diamond_block">
                          <div className="diamond_block_data">
                            <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                              {block.shpNm} {block.vStnId} {getParams(block).join(' ')}
                            </a>
                          </div>
                        </div>
                      ))
                      : ''}
                  </div>
                )}
              </TabPanel>
              <TabPanel>
                {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && (
                  <div className="diamond_block_wrap">
                    {this.state.accurateStock?.length
                      ? this.state.accurateStock.map((block, i) => (
                        <div key={i} className="diamond_block">
                          <div className="diamond_block_data">
                            <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                              {block.shpNm} {block.vStnId} {getParams(block).join(' ')}
                            </a>
                          </div>
                        </div>
                      ))
                      : ''}

                    {this.state.accurateStock === 0 && (
                      <>
                        <div className="no_data dashbaord_right_continer">
                          <span>No accurate stock found.</span>
                        </div>
                        <div className="no_data dashbaord_right_continer">
                          <Link to="/diamond-search">Search Diamonds</Link>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </TabPanel>
            </Tabs>
            {Boolean(sales?.firstName || sales?.email || sales?.mobile) && (
              <div className="diamond_data">
                <div className="title_block">
                  <h3 className="title">sales person detail</h3>
                </div>
                <div className="diamond_block sales_person_details">
                  <div className="sales_content">
                    <div className="p_name">
                      <img src={require('../../assets/svg/user-detail.svg')} />
                      {sales && sales.firstName ? (
                        <span>
                          {sales.firstName} {sales.lastName}
                        </span>
                      ) : (
                        <span> - </span>
                      )}
                    </div>
                    <div className="contact_links">
                      <a href={`https://api.whatsapp.com/send?${sales?.mobile}`} target="_blank">
                        <img src={require('../../assets/svg/Dashboard/whatsapp.svg')} />
                      </a>
                      <Link>
                        <img src={require('../../assets/svg/Dashboard/skype.svg')} />
                      </Link>
                      <Link>
                        <img src={require('../../assets/svg/Dashboard/wechat.svg')} />
                      </Link>
                    </div>
                  </div>
                  <div className="ph_mail">
                    <a href={'tel:' + sales.mobile} className="ph">
                      {sales && sales.mobile ? <span>{sales.mobile}</span> : <span> - </span>}
                    </a>
                    <a href={'mailto:' + sales.email}>
                      <span>{sales.email}</span>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

// export default Dashoard;

const mapDispatchToProps = (dispatch) => {
  return {
    throwSalesData: (data) => dispatch(salesPersonAction(data)),
  };
};
export default connect(
  (state) => ({
    bidConfig: state.bidConfig.data,
    showData: state.auth.showData,
    userEmail: state.auth?.authUser?.email,
    isAvailableInShow: state?.auth?.authUser?.isAvailableInShow,
  }),
  mapDispatchToProps,
)(withRouter(Dashoard));
