import React from 'react';
import IntlMessages from '../../../util/IntlMessages';
/* eslint jsx-a11y/anchor-is-valid: 0 */
const AddressItem = (props) => {
  return (
    <div className="addressItem">
      <div className="addressTopBlock">
        <h2>{props.name}</h2>
        <div className="addressAction">
          <a onClick={props.onClick}>
            {/* <IntlMessages id="app.Edit" /> */}
            Edit
          </a>
          <a>
            <IntlMessages id="app.Delete" />
          </a>
        </div>
      </div>
      <p>{props.address1}</p>
      <p>{props.address2}</p>
      <p>
        {props.city},{props.state} - {props.zipcode}
      </p>
      <p>{props.country}</p>
    </div>
  );
};
export default AddressItem;
