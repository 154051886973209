import React, { Component } from 'react';
import SelectOption from '../../SelectOption';
import TextArea from '../../TextArea';
import LeftDatePicker from '../../LeftMultipleSelect/LeftDatePicker';

import Heading from '../../Heading';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import SlotItem from './SlotItem';
import UtilService from 'services/util';
import head from 'lodash/head';
import last from 'lodash/last';
import split from 'lodash/split';
import map from 'lodash/map';
import { CREATE_SCHEDULE, GET_SLOTS } from 'constants/apiConstant';
import OpenNotification from '../../CommonButton/OpenNotification';
import { LOCAL_STORAGE_VAR } from 'constants/Common';

import { notify } from 'util/notify';
import { getCurrentTime } from 'services/util';

import event from 'config/event.json';
import { isEmpty } from 'util/utils';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';

export const Booth_Appointment = {
  location: [{ name: 'India' }, { name: 'Spain' }, { name: 'Hong Kong' }, { name: 'Dubai' }],
  virtualType: [
    // { name: 'Phone Call', id: 1 },
    // { name: 'Web Conference', id: 2 },
    { name: 'In Person', id: 3 },
  ],
  appointmentFor: [
    { name: 'Formal Meet', id: 1 },
    { name: 'Stone Enquiry', id: 2 },
  ],
};

const APPOINTMENT_FOR = { FormalMeet: 1, StoneEnquiry: 2 };

class BoothSideBar extends Component {
  get initialState() {
    return {
      data: this.props,
      slot: '',
      date: moment(this.props.data.startDate),
      comment: '',
      slots: [],
      apiSlot: {},
      meetingType: 3,
      appointmentFor: isEmpty(this.props.checked) ? 1 : 2,
    };
  }

  state = this.initialState;

  setTimers = async () => {
    const time = await getCurrentTime();
    this.setState({ currTime: time.iso });
  };

  componentDidMount = () => {
    const objData = {
      ...GET_SLOTS,
      request: { sort: [{ end: 'ASC' }] },
    };
    UtilService.callApi(objData, async (err, res) => {
      if (err) throw err;
      if (res && res.code === 'OK') {
        this.setState({ slots: res.data.list });
      }
    });
    this.setTimers();
  };

  handleSave = () => {
    const { comment, slot, data, date, apiSlot, meetingType, appointmentFor } = this.state;
    if (!slot) return OpenNotification({ type: 'error', title: 'Please select slot.' });
    if (!data?.data?.startDate) return OpenNotification({ type: 'error', title: 'Please select date.' });
    const startDate = moment(data?.data?.startDate).add(1, 'day').toISOString();
    const start = moment(`${startDate} ${head(split(slot, ' - '))}`, 'YYYY-MM-DD HH:mm a').toISOString();
    const end = moment(
      `${this.state.data?.data?.endDate} ${last(split(slot, ' - '))}`,
      'YYYY-MM-DD HH:mm a',
    ).toISOString();

    if (appointmentFor == APPOINTMENT_FOR.StoneEnquiry && isEmpty(this.props.checked)) {
      return OpenNotification({
        type: 'error',
        title: 'Please select stone(s) for Booth Appointment.',
      });
    }
    const obj = {
      purpose: comment,
      date: date,
      type: 2,
      start: start,
      end: end,
      cabinSlot: [apiSlot],
      diamonds: map(this.props.checked, 'id'),
      meetingType: meetingType,
      appointmentFor: appointmentFor,
      csType: 2,
    };

    const objData = { ...CREATE_SCHEDULE, request: obj };
    UtilService.callApi(objData, (err, data) => {
      if (err) {
        return OpenNotification({ type: 'error', title: err.data.message });
      }
      if (data && data.code === 'OK') {
        OpenNotification({ type: 'success', title: data.message });
        this.props.onClose();
      } else {
        this.props.onClose();
      }
    });
  };

  get workingDays() {
    const workingDates = [];
    //for bid end date
    const jckEnd = moment(this.state.data?.data?.endDate);
    const jckStart = moment(this.state.data?.data?.startDate);
    const jckDate = jckEnd.diff(jckStart, 'days');
    let days = jckDate;
    let date = moment(jckStart);
    workingDates.push(moment(date).toISOString());
    while (days > 0) {
      date = date.add(1, 'days');
      if (date.isoWeekday()) {
        days -= 1;
        workingDates.push(moment(date).toISOString());
      }
    }

    return workingDates;
  }

  render() {
    const isMobile = window.innerWidth <= 767;
    const { companyName } = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    const finalSlots = this.state.slots;
    const endDate = moment(this.state.data?.data?.endDate).format('DD');
    return (
      <>
        <div className="appoinmentOfficeRightTop">
          <Heading className="popupInnerTitle" title={<IntlMessages id="app.boothAppointment" />} />
          <div className="companynamediv">
            <span className="companyName">{companyName ? companyName : ''}</span> <br />
            {this.state.data?.data?.startDate && (
              <span className="appointmentDate">
                {moment(this.state.data?.data?.startDate).format('ddd, MMM Do YYYY')}
              </span>
            )}
          </div>
          <Heading className="popupInnerTitle" title={'Location'} />
          <span className="companyName">{`${this.state.data?.data?.boothNo}${this.state.data?.data?.boothNo.trim() ? "," : ""} ${this.state.data?.data?.address}`}</span>
        </div>
        <div className="officeBookWrapper">
          <div className="officeBookLeft height_auto">
            <div className="officeDateListing">
              {/* <div className="officeTopLeft">
                <LeftDatePicker
                  date={this.state.data?.data?.startDate ? this.state.data?.data?.startDate : ''}
                  Singledate
                  title={<IntlMessages id="app.SelectMonth" />}
                  onDateChange={(dt) => {
                    const validDate = moment(dt).format('DD');
                    if (validDate <= endDate) {
                      OpenNotification({
                        type: 'error',
                        title: `You can select between ${moment(this.state.data?.data?.startDate).format(
                          'ddd, MMM Do YYYY',
                        )} to ${moment(this.state.data?.data?.endDate).format('ddd, MMM Do YYYY')}`,
                      });
                    } else {
                      this.setState({ date: moment(dt).toISOString() });
                    }
                  }}
                  disabledDate={(current) => {
                    return current <= moment().startOf('day') || moment(current).isoWeekday() === 7;
                  }}
                />
              </div> */}
              <div className="cardListinOffice">
                {this.workingDays?.map((date) => {
                  return (
                    <div
                      key={date}
                      onClick={() => this.setState({ date })}
                      className={`cardListOfficeItem ${moment(this.state.date).format('DD/MM/YYYY') === moment(date).format('DD/MM/YYYY') && `active`
                        }`}
                    >
                      <div className="cardListOfficeTop">
                        <div className="cardListOfficeDate">{moment(date).format('ddd, MMM Do YYYY')}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="officeBookRight">
            <div className="soltWrapper">
              <div className="slotBookLeft">
                <div className="officeBookSolt smallScroll grayScroll">
                  <div className="officeBookSoltItem">
                    <div className="officeBookSoltTitle">
                      <IntlMessages id="app.SelectTimeSlot" />
                    </div>
                    <div className="officeBookSoltInner">
                      {this.state.data.data.startDate &&
                        finalSlots.map((slot) => {
                          const start = moment(slot.start).format('hh:mm A');
                          const end = moment(slot.end).format('hh:mm A');
                          const display = `${start} - ${end}`;
                          const disabled = moment(`${moment(this.state.date).format('YYYY-MM-DD')} ${end}`) < moment();

                          return (
                            <SlotItem
                              key={display}
                              time={display}
                              className={this.state.slot === display ? `slotBook` : disabled ? `alreadyBooked` : ''}
                              tooltip={<IntlMessages id="app.customerCare.BookAppointment" />}
                              onClick={() =>
                                disabled
                                  ? notify.error({ message: 'Slot Expired' })
                                  : this.setState({ slot: display, apiSlot: slot })
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
                <div className="selectVirtualAction pos_rel">
                  <div className="commonTextArea from-group">
                    <label>
                      <IntlMessages id="app.comment" />
                    </label>
                    <IntlMessages id="app.comment">
                      {(placeholder) => (
                        <textarea
                          placeholder={placeholder}
                          onChange={(e) => this.setState({ comment: e.target.value })}
                        >
                          {this.state.comment}
                        </textarea>
                      )}
                    </IntlMessages>
                  </div>
                  <div className="commonTextArea from-group mt-10">
                    <label style={{ color: 'black' }}>
                      <IntlMessages id="app.appointmentFor" />
                    </label>
                  </div>
                  <div className="d-flex align-items-center j-space-between mt-20">
                    <SelectOption
                      value={this.state.appointmentFor}
                      placeholder={<IntlMessages id="app.appointmentFor" />}
                      selectValue={Booth_Appointment.appointmentFor}
                      label={<IntlMessages id="app.appointmentFor" />}
                      onChange={(value) => this.setState({ appointmentFor: value })}
                    />
                  </div>
                  <div className="d-flex align-items-center j-space-between mt-20">
                    <SelectOption
                      value={this.state.meetingType}
                      placeholder={<IntlMessages id="app.SelectVirtualType" />}
                      selectValue={Booth_Appointment.virtualType}
                      label={<IntlMessages id="app.AvailableRoom" />}
                      onChange={(value) => this.setState({ meetingType: value })}
                      disabled
                    />
                    {isMobile ? (
                      <div className="sideBarPopupButton">
                        <a className="commonButton" onClick={() => this.handleSave()}>
                          <IntlMessages id="app.RequestAppointment" />
                        </a>
                      </div>
                    ) : (
                      <button onClick={() => this.handleSave()}>
                        <IntlMessages id="app.RequestAppointment" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default compose(
  connect((store) => ({
    data: store?.auth?.showData,
  })),
)(BoothSideBar);
