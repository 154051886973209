import React, { Component } from 'react';
import { useTable, useFlexLayout } from 'react-table';
import Heading from '../../common/Heading';
import ResetPassword from './ResetPassword';
import { Tooltip, Drawer } from 'antd';
import IntlMessages from '../../../util/IntlMessages';

const data = [
  {
    key: '1',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '2',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '3',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '4',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '5',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '6',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '7',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
  {
    key: '8',
    name: 'coruscate',
    email: 'vishalvirani@gmail.com',
    contact: '90992 23388',
    username: 'Vishal Virani',
    type: 'Admin',
  },
];
let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 100;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.pageHeadHeight = document.getElementsByClassName('accountTopBlock')[0];
  allContainers.pinHeight = document.getElementsByClassName('accountTabBox')[0];
  allContainers.actionBtnHeight = document.getElementsByClassName('userManageTop')[0];
  allContainers.accountSubTab = document.getElementsByClassName('accountSubTab')[0];
  allContainers.diamondListingAction = document.getElementsByClassName('diamondListingAction')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    // {console.log(offsetHeight,"height")};
    return true;
  });
  return totalHeight;
}
class ManageUserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridHeight: 0,
      visible: false,
    };
  }
  showDrawer1 = () => {
    this.setState({
      visible: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    let self = this;
    gridHeight = setGridHeightFn();
    if (self.state.gridHeight !== gridHeight) self.setState({ gridHeight });
    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Mobile No.',
        accessor: 'contact',
      },
      {
        Header: 'Username',
        accessor: 'username',
      },
      {
        Header: 'User Type',
        accessor: 'type',
      },
      {
        Header: 'Sharing Links',
        accessor: 'social',
        Cell: ({ row, cell }) => (
          <div className="cardIcon justify-content-center">
            <img src={require('../../../assets/svg/Account/whatsapp.svg')} alt="" />
            <img src={require('../../../assets/svg/Account/skype.svg')} alt="" />
            <img src={require('../../../assets/svg/Account/wechat.svg')} alt="" />
          </div>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'action',
        Cell: ({ row, cell }) => (
          <div className="cardIcon justify-content-center">
            <Tooltip title="Update">
              <img src={require('../../../assets/svg/Account/update.svg')} alt="" />
            </Tooltip>
            <Tooltip title={<IntlMessages id="app.resetpassword" />}>
              <img src={require('../../../assets/svg/Account/resetpass.svg')} alt="" onClick={this.showDrawer1} />
            </Tooltip>
            <Tooltip title="Delete">
              <img src={require('../../../assets/svg/Account/delet.svg')} alt="" />
            </Tooltip>
          </div>
        ),
      },
    ];
    const Table = ({ columns, data }) => {
      const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
      });

      return (
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} width="100px">
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.slice(0, 10).map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td width="100px" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    };
    return (
      <>
        <div className="editProfilePage">
          <div className="contactDirectoryWrapper">
            <div className="contactListMain">
              {/* <div className="d-flex justify-content-between align-items-center userManageTop">
                <Heading title="Manage User List" className="p-0 m-0" />
                <button className="commonButton" onClick={this.props.onClick}>
                  Add User
                </button>
              </div> */}
              <div className="contactList" style={{ height: gridHeight, overflow: 'auto' }}>
                <Table columns={columns} data={data} />
              </div>
            </div>
            <div className="botoomStickyBar diamondListingAction justify-content-center">
              <button className="bottomStickyButton" onClick={this.props.onClick}>
                <span>Add User</span>
              </button>
            </div>
          </div>
        </div>

        <Drawer title={false} onClose={this.onClose} visible={this.state.visible} wrapClassName="diamondListinSidebar">
          <ResetPassword />
        </Drawer>
      </>
    );
  }
}

export default ManageUserList;
