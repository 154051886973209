import React from 'react';
import { LIMIT } from 'components/DiamondList/TableBack';
import { isEmpty, isFunction, isNumber, isObject } from './utils';
import { MOBILE_MAX_WIDTH } from 'constants/Common';
import { LoaderService } from 'services/LoaderService';

export const useBoolean = (initialValue = false) => {
  const [state, _setState] = React.useState(Boolean(initialValue));
  const setState = React.useMemo(() => {
    return {
      true: () => _setState(true),
      false: () => _setState(false),
      toggle: () => _setState((v) => !v),
    };
  }, []);

  return [state, setState];
};

export const useCompositeState = (initialState = {}) => {
  const [state, _setState] = React.useState(initialState);
  const initialStateRef = React.useRef(initialState);

  const setState = React.useCallback((...args) => {
    const callback = isFunction(args?.[0]) ? args?.[0] : undefined;
    const object = isObject(args?.[0]) ? args?.[0] : {};
    const spread = !isEmpty(args?.[1]) ? args?.[1] : true;

    _setState((state) => {
      const _object = callback ? callback(state) : object;
      return spread ? { ...state, ..._object } : { ..._object };
    });
  }, []);

  const resetState = React.useCallback(() => {
    _setState(initialStateRef.current);
  }, []);

  return [state, setState, resetState];
};

export const useRefEffect = (ref, value) => {
  React.useEffect(() => void (ref.current = value), [ref, value]);
};

export const useCounter = (init = 0) => {
  const [count, set] = React.useState(init);
  const inc = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? ++n : 1), cb(n), n)), []);
  const dec = React.useCallback((cb = () => {}) => set((n) => ((n = n > 0 ? --n : 0), cb(n), n)), []);
  return [count, inc, dec];
};

export function useLoading(show = false) {
  const [count, inc, dec] = useCounter(0);
  const countRef = React.useRef(count);

  React.useEffect(() => void (countRef.current = count), [count]);

  const start = React.useCallback(() => {
    inc(() => show && LoaderService.startLoading());
  }, [inc, show]);

  const stop = React.useCallback(() => {
    dec(() => show && LoaderService.stopLoading());
  }, [dec, show]);

  React.useEffect(() => {
    const hasClass = document?.body?.classList?.contains?.('progress');

    void (count > 0
      ? !hasClass && document?.body?.classList?.add?.('progress')
      : hasClass && document?.body?.classList?.remove?.('progress'));
  }, [count]);

  React.useEffect(() => {
    return () => {
      if (show && isNumber(countRef.current) && countRef.current > 0) {
        LoaderService.adjustCount(-Math.abs(countRef.current));
      }
    };
  }, [show]);

  React.useDebugValue(count);
  return [Boolean(count), start, stop];
}

export const usePagination = (page = 1, limit = LIMIT) => {
  const [state, _setState] = React.useState({ page, limit });
  const setPagination = React.useCallback((page, limit) => _setState((state) => ({ ...state, page, limit })), []);
  return [state?.page, state?.limit, setPagination];
};

export const useList = (list = [], count = 0) => {
  const [state, _setState] = React.useState({ list, count });
  const setList = React.useCallback((list, count) => _setState((state) => ({ ...state, list, count })), []);
  const resetList = React.useCallback(() => _setState(() => ({ list: [], count: 0 })), []);
  return [state?.list, state?.count, setList, resetList];
};

export const useIsMobile = () => {
  const [isMobile, _setIsMobile] = React.useState();

  const setIsMobile = React.useCallback(() => {
    _setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', setIsMobile);
    return () => window.removeEventListener('resize', setIsMobile);
  }, [setIsMobile]);

  return [isMobile];
};
