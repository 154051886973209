import React, { Component } from 'react';
import './thankyou.less';

class ThankYou extends Component {
  render() {
    return (
      <div className="thankYouWrapper">
        <div className="thankYouInnerBox">
          <div className="thankYouMainBox">
            <div className="thankYouImgBlock">
              <img src={require('../../assets/svg/ThankYou/1.svg')} alt="" />
            </div>
            <div className="thankYouPageContent">
              <h2>Thanks for Register!</h2>
              <p>You have been successfully registered, You will be contacted by administrator shortly.</p>
              <a href="/signin">
                <button className="commonButton">Login</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ThankYou;
