import * as _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import axios from '../util/Api';
import { userSignOut } from '../appRedux/actions/Auth';
import { BASE_URL, LOCAL_STORAGE_VAR, FILE_TYPES_PDF, FILE_TYPES } from '../constants/Common';
import '../assets/scss/global.css';

import { commonLogout, fields, requiredFields, fieldsrequiredGuest, fieldsGuest } from '../constants/Common';
import { CREATE_ANALYTICS, USER_LOGOUT, GET_SERVER_TIME, BID_CONFIG, LOGOUT } from '../constants/apiConstant';
import { logInfo, logWarn, objectToQueryString } from 'util/utils';
import { getPath, LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';
import { BidService } from './BidService';

const myUploadProgress = (data) => {
  const showProgressDoc = document.querySelectorAll('#loader.APILoader .showProgress span')[0];
  const showProgressMain = document.querySelectorAll('#loader.APILoader .showProgress')[0];
  if (showProgressDoc) {
    if (showProgressMain && showProgressMain.classList && showProgressMain.classList.contains('display-none')) {
      showProgressMain.classList.remove('display-none');
    }
    showProgressDoc.innerText = `${data}%`;
    showProgressDoc.style.width = `${data}%`;
  }
};

const UtilService = {
  // roundNumer(number, place) {
  //     return +(Math.round(number + `e+${place}`) + `e-${place}`)
  // },
  subString(string, start, end) {
    return string.substring(start, end);
  },
  displayDate: (date) => {
    return date ? moment(date).format('MMM DD YYYY, HH:mm:ss a') : '-';
  },
  callApi(config, cb) {
    const options = {
      headers: {
        'Content-Type': config.isMultipart ? undefined : 'application/json',
        devicetype: config.deviceType ?? 1,
      },
    };
    const token = config.auth ?? config.updateToken ?? UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);
    if (token) options.headers.Authorization = `JWT ${token}`;
    if (config.isMultipart) {
      options.transformRequest = {};
      options.onUploadProgress = (e) => myUploadProgress(Math.round((e.loaded * 100) / e.total));
    }
    if (config.showLoader) {
      document.body.classList.add('loading-indicator');
    }

    axios
      .request({ url: config?.url, method: config?.method, baseURL: BASE_URL, data: config?.request, ...options })
      .then(async ({ data }) => {
        try {
          if (data.code === 'E_UNAUTHORIZED') {
            return commonLogout();
          } else {
            cb(data.code !== 'OK', data);
          }
        } catch (error) {
          logWarn(error);
        }
        document.body.classList.remove('loading-indicator');
      })
      .catch((error) => {
        try {
          cb(error?.response);
        } catch (error) {
          logWarn(error);
        }
        document.body.classList.remove('loading-indicator');
      });
  },

  SummationOfDiamond(list, cb) {
    let finalData = {
      totalpcs: 0,
      totalCarat: 0,
      // totalAvgDis: 0,
      totalAmount: 0,
      totalPricePerCarat: 0,
      // fancyrapAvg: 0,
    };
    let fancyTotalPcs = 0,
      fancyTotalCarat = 0,
      fancyAmount = 0;
    let nonFancyTotalPcs = 0,
      nonFancyTotalCarat = 0,
      // nonFancyrapAvg = 0,
      nonFancyAmount = 0;
    const checkFancyData = _.partition(list, function (o) {
      return o.rap === 0;
    });

    _.forEach(list, function (value) {
      if (value.rap === 0) {
        fancyTotalPcs = _.size(checkFancyData[0]);
        fancyTotalCarat = _.sumBy(checkFancyData[0], 'crt');
        fancyAmount = _.sumBy(checkFancyData[0], 'amt');
      } else {
        nonFancyTotalPcs = _.size(checkFancyData[1]);
        nonFancyTotalCarat = _.sumBy(checkFancyData[1], 'crt');
        nonFancyAmount = _.sumBy(checkFancyData[1], 'amt');
        // nonFancyrapAvg = _.sumBy(checkFancyData[1], "rapAvg");
      }
    });

    const totalpcs = fancyTotalPcs + nonFancyTotalPcs;
    const totalCarat = fancyTotalCarat + nonFancyTotalCarat;
    const totalAmount = fancyAmount + nonFancyAmount;
    // let rapAvg = nonFancyrapAvg;
    // let totalAvgDis = (nonFancyAmount / rapAvg) * 100 - 100;
    const totalPricePerCarat = totalAmount / totalCarat;
    finalData = {
      totalpcs: totalpcs ? totalpcs : 0,
      totalCarat: totalCarat ? totalCarat : 0,
      // totalAvgDis: totalAvgDis ? totalAvgDis : 0,
      totalAmount: totalAmount ? totalAmount : 0,
      totalPricePerCarat: totalPricePerCarat ? totalPricePerCarat : 0,
    };

    cb(null, finalData);
  },

  showErrorInBulk(obj, err, isSimple = false) {
    const blank = [];
    const invalid = [];
    let fill = true;
    _.each(requiredFields, function (field) {
      if (field === 'iAgree' && obj.iAgree === false) {
        fill = false;
        blank.push(fields['iAgree']);
      } else if (obj[field] === '') {
        fill = false;
        blank.push(fields[field]);
      }
    });

    if (err) {
      if (isSimple) {
        Object.keys(err).map((field) => {
          invalid.push(fields[field]);
          return true;
        });
      } else {
        _.each(err, function (field) {
          invalid.push(fields[field.errors[0].field]);
        });
      }
    }
    if (blank.length > 0) {
      // let blankFields = blank.map((f, i) => {
      //     return <span>{f + (i !== blank.length - 1 ? ", " : "")}</span>;
      // });

      const arr = blank;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalid.length > 0) {
      const blankFields = invalid.map((f, i) => {
        return <span key={i}>{f + (i !== invalid.length - 1 ? ', ' : '')}</span>;
      });
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: blankFields,
      });
    }

    return {
      fill,
      invalid,
      blank,
    };
  },

  showErrorInBulkGuest(obj, err, isSimple = false) {
    const blank = [];
    const invalid = [];
    let fill = true;
    _.each(fieldsrequiredGuest, function (field) {
      if (obj[field] === '') {
        fill = false;
        blank.push(fieldsGuest[field]);
      }
    });
    if (err) {
      if (isSimple) {
        Object.keys(err).map((field) => {
          invalid.push(fieldsGuest[field]);
          return true;
        });
      } else {
        _.each(err, function (field) {
          invalid.push(fieldsGuest[field.errors[0].field]);
        });
      }
    }
    if (blank.length > 0) {
      const blankFields = blank.map((f, i) => <span key={i}>{f + (i !== blank.length - 1 ? ', ' : '')}</span>);
      notification.error({ message: 'Required Fields', description: blankFields, placement: 'bottomLeft' });
    }
    if (invalid.length > 0) {
      const blankFields = invalid.map((f, i) => <span key={i}>{f + (i !== invalid.length - 1 ? ', ' : '')}</span>);
      notification.error({ message: 'Invalid Fields', description: blankFields, placement: 'bottomLeft' });
    }
    return { fill, invalid, blank };
  },
  getFullName(user) {
    if (!user || _.isEmpty(user)) return null;

    user.firstName = user.firstName ? user.firstName : '';
    user.lastName = user.lastName ? user.lastName : '';

    const fullName =
      user.firstName.charAt(0).toUpperCase() +
      user.firstName.slice(1).toLowerCase() +
      ' ' +
      user.lastName.charAt(0).toUpperCase() +
      user.lastName.slice(1).toLowerCase();

    return fullName;
  },
  getLocalStorageItem(name) {
    const localItem = localStorage.getItem(name);
    if (localItem && localItem.length && localItem !== 'undefined') {
      const decode = decodeURIComponent(escape(window.atob(localItem)));
      return JSON.parse(decode);
    }
    return null;
  },
  setLocalStorageItem(userString, name) {
    const encryptedString = window.btoa(unescape(encodeURIComponent(JSON.stringify(userString))));
    localStorage.setItem(name, encryptedString);
  },
  fileMimeType(blob, pdf, cb) {
    const type = blob.type;

    const fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      let header = '';
      for (let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      let validity = false;
      if (pdf) {
        if (type in FILE_TYPES_PDF) {
          validity = FILE_TYPES_PDF[type].includes(header.toLowerCase());

          cb(validity);
        } else {
          cb(false);
        }
      } else {
        if (type in FILE_TYPES) {
          validity = FILE_TYPES[type].includes(header.toLowerCase());
          cb(validity);
        } else {
          cb(false);
        }
      }
    };
    fileReader.readAsArrayBuffer(blob);
  },
};

export const getTrackData = (str) => {
  const [page, section, action] = str.split('/');
  return { page, section, action };
};

export const track = (data) =>
  // localStorage[`${LOCAL_STORAGE_VAR}-token`] &&
  //     localStorage[`${LOCAL_STORAGE_VAR}-token`].length
  UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) &&
  UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`).length
    ? UtilService.callApi({
        // method: "post",
        // url: "/apis/analytics/create",
        ...CREATE_ANALYTICS,
        request: getTrackData(data),
      })
    : '';

window.track = track;

export const getShortPath = () => window.location.pathname.split('/')[1];

export const loaderActivityFn = (showLoader) => {
  const getLoaderEle = document.querySelectorAll('#loader.APILoader')[0];
  const getProgressBar = document.querySelectorAll('#loader.APILoader .showProgress')[0];
  if (showLoader) {
    getLoaderEle.classList.add('loading');
  } else if (getLoaderEle && getLoaderEle.classList && getLoaderEle.classList.contains('loading')) {
    getLoaderEle.classList.remove('loading');
    if (getProgressBar && getProgressBar.classList && !getProgressBar.classList.contains('display-none')) {
      getProgressBar.classList.add('display-none');
    }
  }
};

window.shortPath = getShortPath;

export const getCookie = (cname) => {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
  }
  return '';
};

export const logoutAPICall = async () => {
  BidService.reset();

  const tokens = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);
  try {
    await axios.request({ ...USER_LOGOUT, headers: { Authorization: `JWT ${tokens}` } }).then((x) => {
      if (x.data.code === 'OK') {
        localStorage.clear();
        window.location.href = window.location.origin + '/signin';
      }
    });
  } catch (error) {
    logInfo({ error });
  }
};

export const logoutCheck = async () => {
  const userDetail = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);
  const token = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);
  try {
    await axios.request({
      ...LOGOUT,
      data: {
        userDetail,
        path: window.location.pathname,
        createDate: moment(),
      },
      headers: { Authorization: `JWT ${token}` },
    });
  } catch (error) {
    logInfo({ error });
  }
};

export const getCurrentTime = () => {
  return new Promise((resolve, reject) => {
    const objData = { ...GET_SERVER_TIME };
    UtilService.callApi(objData, (err, res) => {
      if (err) return reject(err);
      if (res?.code === 'OK') return resolve(res.data);
      reject(err);
    });
  });
};

export const getNextBidData = (type = null) => {
  return new Promise((resolve, reject) => {
    const objData = { ...BID_CONFIG };
    objData.url = `${objData.url}${objectToQueryString({
      type: type ? type : [LISTINGPAGES.DEALTHEOFDAY, LISTINGPAGES.MYDEALOFDAYLIST].includes(getPath()) ? 2 : 1,
    })}`;

    UtilService.callApi(objData, (err, data) => {
      if (err) reject(err);
      if (data && data.code === 'OK') return resolve(data.data);
      reject(err);
    });
  });
};

export default UtilService;
