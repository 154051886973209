import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
import clone from 'lodash/clone';
import has from 'lodash/has';
import trim from 'lodash/trim';
import isEmpty from 'lodash/isEmpty';
import { Modal, notification } from 'antd';
import UtilService from '../../services/util';
import cloneDeep from 'lodash/cloneDeep';
import BrandLogo from '../common/BrandLogo';
import DownloadBlock from '../common/DownloadBlock';

import axios from 'axios';
import InputBlock from '../common/InputBlock';
import { LOCAL_STORAGE_VAR, MIME_TYPES } from '../../constants/Common';
import { setLoader } from '../../appRedux/actions/Common';
import { LOGIN_USER, UPDATE_USER_TOKEN, UPLOAD_FILE, ADMIN_UPDATE_USER } from '../../constants/apiConstant';
import CheckBox from '../common/CheckBox';
import Logo from '../Header/Logo';
import Maintanance from './SystemUnder';
import { Captcha } from 'assets/js/Captcha';

import UploadImage from '../common/UploadImage/index';
import { compose } from 'redux';
import { store } from 'appRedux/store';
import { SHOW_DATA, USER_DATA, USER_TOKEN_SET } from 'constants/ActionTypes';

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _captcha: Captcha.generateCaptcha(),
      formData: {
        username: '',
        password: '',
      },
      rememberMe: false,
      checked: true,
      rememberCheckVal: false,
      loading: false,
      error: null,
      verifyEmail: false,
      showModal: false,
      errors: {},
      userData: {},
      visible: false,
      Kyc: {
        imageOne: '',
        imageTwo: '',
      },
    };
  }

  componentDidMount() {
    const rememberMe = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-rememberMe`));
    if (rememberMe) {
      const prevUser = localStorage.getItem(`${LOCAL_STORAGE_VAR}-remember-user`);
      if (prevUser && prevUser.length && prevUser !== 'undefined') {
        if (prevUser.indexOf('username') > 0) {
          localStorage.clear();
        } else {
          const user = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-remember-user`);
          const formData = {
            username: user.username,
            password: user.password,
          };
          this.setState({ formData, rememberMe });
        }
      }
      window.addEventListener('click', (e) => {
        if (document.activeElement && document.activeElement.localName !== 'button') {
          notification.destroy();
        }
      });
      // let user = UtilService.getLocalStorageItem(
      //   `${LOCAL_STORAGE_VAR}-remember-user`
      // );
      // let formData = {
      //   username: user.username,
      //   password: user.password,
      // };
      // this.setState({ formData, rememberMe });
    }
  }
  // handleSubmit = () => {
  //  return window.location.assign('/maintanance');

  // };

  handleSubmit = () => {
    const reqObj = { ...this.state.formData };
    const _captcha = this.state._captcha;
    this.setState({ _captcha: Captcha.generateCaptcha() });

    if (reqObj.captcha !== _captcha) {
      this.handleOnChange('captcha', '');
      return OpenNotification({
        type: 'error',
        title: 'Please enter correct Captcha',
      });
    }

    if (this.handleValidation()) {
      const obj = {
        ...LOGIN_USER,
        request: {
          ...reqObj,
          masters: [
            'COLOR',
            'CLARITY',
            'SHAPE',
            'CUT',
            'FLUORESCENCE',
            'SHADE',
            'LAB',
            'POLISH',
            'SYMMETRY',
            'LOCATION',
            'BLACK_INCLUSION',
            'OPEN_INCLUSION_TABLE',
            'MILKEY',
            'WHITE_INCLUSION_CROWN',
            'OPEN_INCLUSION_CROWN',
            'EYECLEAN',
            'OPEN_INCLUSION_PAVILION',
            'ORIGIN',
            'BLACK_INCLUSION_CROWN',
            'H_AND_A',
            'WHITE_INCLUSION_TABLE',
            'FANCY_COLOR',
            'INTENSITY',
            'OVERTONE',
            'KEY_TO_SYMBOLS',
            'GIRDLE',
            'CULET',
            'GIRDLE_COND',
            'CULET_COND',
            'COMPANY_SIZE',
            'BLACK_INCLUSION_TABLE',
            'BLACK_INCLUSION_SIDE',
            'WHITE_INCLUSION_SIDE',
            'BRILLIANCY',
            'EYECLEAN',
            'BUSINESS_TYPE',
            'NATURE_OF_ORG',
            'DOC_TYPE_PERSONAL',
            'DOC_TYPE_BUSINESS',
            'OPEN_INCLUSION_PAVILION',
            'EXTRA_FACET_PAVILION',
            'EXTRA_FACET_CROWN',
            'GRAINING',
            'LUSTER',
            'DAY_TERM',
          ],
        },
      };

      UtilService.callApi(obj, (err, data) => {
        // self.props.setLoader(false);
        if (err) {
          if (err.data.code === 'EMAIL_NOT_VERIFIED') {
            this.setState({ verifyEmail: true });
          } else {
            return OpenNotification({
              type: 'error',
              title: err.data.message || err.data.data.message || 'Failed to Login.',
            });
          }
        }
        if (data && data.code === 'OK') {
          // console.log('first', data?.data?.user?.isAvailableInShow);
          localStorage.setItem(
            'hongkongModal',
            data?.data?.user?.isAvailableInShow !== 1 && data?.data?.user?.isAvailableInShow !== 2 ? 'true' : 'false',
          );
          console.log(localStorage.getItem('hongkongModal'));
          this.setState({ userData: data }, () => {
            if (data.data.user.kycRequired) {
              if (this.state.userData.data.user.account && isEmpty(this.state.userData.data.user.account.kyc)) {
                this.setState({ visible: true });
              } else {
                this.getRedirection(data);
              }
            } else {
              this.getRedirection(data);
            }
          });
        }
      });
    }
  };

  handleOnChange = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState(formData);
  };

  handleVerifyEmail = () => {
    const self = this;
    const api = 'admin/v1/user/resendVerificationEmail';
    const obj = {
      username: this.state.username,
    };
    const objData = {
      method: 'post',
      url: api,
      // ...RESEND_VERIFY_MAIL,
      request: obj,
    };

    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        self.setState({ verifyEmail: false });
      }
    });
  };

  handleUpdateToken = () => {
    const self = this;
    const api = '/auth/update-token';
    const objData = {
      // method: "post",
      // url: api,
      ...UPDATE_USER_TOKEN,
      request: {
        masters: [
          'COLOR',
          'CLARITY',
          'SHAPE',
          'CUT',
          'FLUORESCENCE',
          'SHADE',
          'LAB',
          'POLISH',
          'SYMMETRY',
          'LOCATION',
          'BLACK_INCLUSION',
          'OPEN_TABLE',
          'MILKEY',
          'WHITE_INCLUSION_CROWN',
          'OPEN_CROWN',
          'EYECLEAN',
          'OPEN_PAVILION',
          'ORIGIN',
          'BLACK_INCLUSION_CROWN',
          'H_AND_A',
          'WHITE_INCLUSION_TABLE',
          'FANCY_COLOR',
          'INTENSITY',
          'OVERTONE',
          'KEY_TO_SYMBOLS',
          'GIRDLE',
          'CULET',
          'GIRDLE_COND',
          'CULET_COND',
          'COMPANY_SIZE',
          'DAY_TERM',
        ],
      },
      updateToken: self.state.updateToken, //token
    };
    UtilService.callApi(objData, async function (err, data) {
      if (err) throw err;
      if (data && data.code === 'OK') {
        if (!has(data.data.user, ['isTermsCondAgree']) || !data.data.user.isTermsCondAgree) {
          self.setState({ showTCModal: true });
        } else {
          self.getRedirection(data);
        }
      }
    });
  };

  // handleAcceptTerms = () => {
  //   let self = this;
  //   let api = "/admin/user/update";
  //   let objData = {
  //     method: "put",
  //     url: api,
  //     request: { isTermsCondAgree: true },
  //     updateToken: self.state.updateToken,
  //   };
  //   UtilService.callApi(objData, async function (err, data) {
  //     if (err) throw err;
  //     if (data && data.code === "OK") {
  //       self.handleUpdateToken();
  //     }
  //   });
  // };
  getRedirection = (res) => {
    const user = res.data.user;

    const masters = res.data.masters;
    const columns = res.data.columns;
    const userPermissions = res.data.userPermissions;
    const keywords = res.data.keywords;
    const token = res.data.token.jwt;
    const terms = res.data.user.accountTerm;
    const showDetails = res?.data?.showDetails;
    store.dispatch({ type: USER_TOKEN_SET, payload: token });
    store.dispatch({ type: USER_DATA, payload: user });
    if (showDetails) {
      store.dispatch({ type: SHOW_DATA, payload: showDetails });
    }
    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);

    localStorage.setItem(`${LOCAL_STORAGE_VAR}-master`, JSON.stringify(masters));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(columns));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-keywords`, JSON.stringify(keywords));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-terms`, JSON.stringify(terms));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, res.data.isTrusted);
    if (showDetails) {
      localStorage.setItem(`${LOCAL_STORAGE_VAR}-show-details`, JSON.stringify(showDetails));
    }
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-rememberMe`, this.state.rememberMe);
    if (this.state.rememberMe) {
      UtilService.setLocalStorageItem(
        {
          username: this.state.formData.username,
          password: this.state.formData.password,
        },
        `${LOCAL_STORAGE_VAR}-remember-user`,
      );

      // localStorage.setItem(
      //   `${LOCAL_STORAGE_VAR}-remember-user`,
      //   JSON.stringify({
      //     username: this.state.formData.username,
      //     password: window.btoa(this.state.formData.password),
      //   })
      // );
    } else {
      localStorage.removeItem(`${LOCAL_STORAGE_VAR}-remember-user`);
    }

    const setPermit = {};
    userPermissions.data.map((x) => {
      if (x.module === 'order' && user.Setting) {
        x.permissions.insert = user.Setting.Is_PlaceOrder ? user.Setting.Is_PlaceOrder : false;
      }

      if (x.module === 'pricing' && user.Setting && user.Setting.Is_NoPrice !== true) {
        x.permissions.view = user.Setting.Is_NoPrice !== true ? true : false;
        x.permissions.all = user.Setting.Is_NoPrice !== true ? true : false;
      }

      setPermit[x.module] = x.permissions;
    });

    if (setPermit && !setPermit.pricing) {
      if (user.Setting && user.Setting.Is_NoPrice !== true) {
        setPermit.pricing = {};
        setPermit.pricing.view = user.Setting.Is_NoPrice !== true ? true : false;
        setPermit.pricing.all = user.Setting.Is_NoPrice !== true ? true : false;
      }
    }

    UtilService.setLocalStorageItem(setPermit, `${LOCAL_STORAGE_VAR}-userPermissions`);

    axios.defaults.headers.common['access-token'] = 'JWT ' + token;
    if (this.props?.location?.state?.fromDNA && this.props?.location?.state?.stoneId)
      this.props.history.push(`/diamond-detail/${this.props?.location?.state?.stoneId}`);
    else if (window.location.href.split('=')[1]) {
      this.props.history.push(window.location.href.split('=')[1]);
    } else {
      // this.props.history.push("/diamond-search");
      this.props.history.push('/dashboard');
    }
  };

  handleValidation = () => {
    const formData = this.state.formData;
    const errors = this.state.errors;
    let formIsValid = true;
    const blankField = [];
    const invalidFiels = [];
    if (!formData['username'] || isEmpty(formData['username'])) {
      blankField.push('User Name');
      errors['username'] = true;
      formIsValid = false;
    }
    if (!formData['password'] || isEmpty(formData['password'])) {
      blankField.push('Password');
      errors['password'] = true;
      formIsValid = false;
    }
    if (!formData['captcha'] || isEmpty(formData['captcha'])) {
      blankField.push('captcha');
      errors['captcha'] = true;
      formIsValid = false;
    }

    if (blankField.length > 0) {
      const arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'topRight',
        description: outStr,
      });
    }
    if (invalidFiels.length > 0) {
      const arr = invalidFiels;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'topRight',
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  uploadDocument = (ee, type, img) => {
    const e = cloneDeep(ee);
    const self = this;

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'user');
        data.append('file', e.target.files[0]);
        const userToken = self.state.userData.data.token.jwt;
        const objData = {
          ...UPLOAD_FILE,
          auth: userToken,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === 'OK') {
            if (data.data && data.data.files && data.data.files.length !== 0) {
              if (img === 1) {
                self.setState({
                  Kyc: {
                    imageTwo: self.state.Kyc.imageTwo,
                    imageOne: data.data.files[0].absolutePath,
                  },
                });
              } else {
                self.setState({
                  Kyc: {
                    imageOne: self.state.Kyc.imageOne,
                    imageTwo: data.data.files[0].absolutePath,
                  },
                });
              }
            }
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };
  createKyc = () => {
    const self = this;
    if (self.state.Kyc && self.state.Kyc.imageOne !== '' && self.state.Kyc.imageTwo !== '') {
      const user = self.state.userData;
      const objReq = {
        // url: ACCOUNT_UPDATE_KYC.url + user.data.user.account.id,
        // method: ACCOUNT_UPDATE_KYC.method,
        // showLoader: ACCOUNT_UPDATE_KYC.showLoader,
        ...ADMIN_UPDATE_USER,
        request: {
          kyc: [
            { path: self.state.Kyc.imageOne, type: 'personal' },
            { path: self.state.Kyc.imageTwo, type: 'business' },
          ],
          isKycUploaded: true,
          isApproved: 1,
        },
        auth: user.data.token.jwt,
      };
      UtilService.callApi(objReq, function (err, data) {
        if (err) throw err;
        if (data && data.code === 'OK') {
          user.data.user.account.isKycUploaded = true;
          user.data.user.account.kyc = objReq.request.kyc;
          // UtilService.setLocalStorageItem(user.data.user, `${LOCAL_STORAGE_VAR}-user`)
          self.getRedirection(user);

          OpenNotification({
            type: 'success',
            title: 'Your KYC documents are successfully submitted.',
          });
          self.onClose();
        }
      });
    } else {
      OpenNotification({
        type: 'error',
        title: 'Please upload both the images',
      });
    }
  };

  render() {
    const { formData, errors } = this.state;

    return (
      <>
        <div className="loginDetail d-flex align-center">
          <div className="width-100">
            <div className="loginHead">
              <h3 className="">Welcome to</h3>
              <div className="loginLogo">
                <Logo />
              </div>
              <DownloadBlock />
              {/* <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
              has been the industry's standard dummy text ever since the 1500s,
            </p> */}
            </div>
            <div className="loginBox">
              {/* <Loader loading={this.state.loading} /> */}
              <InputBlock
                onChange={(e) => this.handleOnChange('username', trim(e.target.value))}
                value={formData.username}
                type="text"
                label="Username or Email"
                placeholder="Enter Username or Email"
                hasError={errors['username'] ? true : false}
              />
              {/* <div className="form-control">
              <label htmlFor="Email-Address" className="d-block">
                <IntlMessages id="app.signin.username" />
              </label>
              <input
                onChange={this.handleOnChange.bind(this)}
                value={username}
                type="text"
                id="username"
                name="username"
                autoComplete="off"
              />
            </div> */}
              <InputBlock
                onChange={(e) => this.handleOnChange('password', e.target.value)}
                value={formData.password}
                type="password"
                label="Password"
                hasError={errors['password'] ? true : false}
                placeholder="Enter Password"
                loginKeyDown={() => {
                  if (formData.username && formData.password) {
                    this.handleSubmit();
                  }
                }}
              />
              <div className="d-flex j-space-between align-items-center flex-wrap">
                <InputBlock
                  onChange={(e) => this.handleOnChange('captcha', e.target.value)}
                  value={formData.captcha}
                  type="captcha"
                  label="Captcha"
                  hasError={errors['captcha'] ? true : false}
                  placeholder="Enter Captcha"
                  loginKeyDown={() => {
                    if (formData.username && formData.captcha) {
                      this.handleSubmit();
                    }
                  }}
                />
                <Captcha data={this.state._captcha} />
              </div>

              {/* <div className="form-control">
              <label htmlFor="Password" className="d-block">
                <IntlMessages id="app.signin.password" />
              </label>
              <input
                onChange={this.handleOnChange.bind(this)}
                value={password}
                type="password"
                id="password"
                name="password"
                autoComplete="off"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (username && password) {
                      this.handleSubmit();
                    }
                  }
                }}
              />
            </div> */}
              <div className="d-flex j-space-between align-items-center loginLinkBlock">
                <div className="rememberCheckBox">
                  <CheckBox
                    id="remember-check"
                    checked={this.state.rememberMe}
                    value={this.state.rememberMe}
                    onChange={(e) => this.setState({ rememberMe: e.target.checked })}
                  />
                  <span className="rememberLabel">Remember Me</span>
                </div>
                <Link to="/forgot-password" className="primary-text forgot-password text-style">
                  Forgot Password?
                </Link>
              </div>
              <div className="loginButton" style={{ "display": "flex", "width": "100%", "margin": "0 auto", "justifyContent": "center" }}>
                <button className="commonButton" onClick={this.handleSubmit.bind(this)}>
                  Login
                </button>
                {/* <button
                  onClick={() => {
                    this.props.history.push('/guest-login');
                  }}
                  className="commonOutline pad-lr-30"
                >
                  Guest Login
                </button> */}
              </div>

              {/* <hr style={{ marginBottom: "10px", marginTop: "10px" }} className="login-shapret" /> */}

              {/* </form> */}
              {this.state.verifyEmail && (
                <Modal
                  centered
                  className="email-verified-modal"
                  visible={this.state.verifyEmail}
                  title={[
                    <div key="deletebuttonH2" className="modal__title primary-text" id="modal-1-title">
                      Email Verification
                    </div>,
                  ]}
                  onCancel={() => this.setState({ verifyEmail: false })}
                  footer={[
                    <div key="deletebuttonReminder">
                      <center>
                        <button
                          className="commonButton"
                          style={{ padding: '9px 25px' }}
                          onClick={this.handleVerifyEmail}
                        >
                          Resend Verify Email
                        </button>
                        <button
                          className="commonOutline"
                          style={{ padding: '9px 25px' }}
                          onClick={() => this.setState({ verifyEmail: false })}
                        >
                          Cancel
                        </button>
                      </center>
                    </div>,
                  ]}
                  width={620}
                >
                  <h3 align="center" style={{ color: 'rgba(0,0,0,0.70)' }}>
                    Your registered email hasn't verified yet. If you haven't get verification link resend it and
                    verify. Thank you.
                  </h3>
                </Modal>
              )}
            </div>

            <div className="registerLogLink">
              Don’t have login details?
              <Link to="/signup">Register</Link>
            </div>
            <BrandLogo />
            <div className="qr-code">
              <p>
                Scan QR to enter <span>Unique Diamax Pvt Ltd Universe</span>
              </p>
              <img src={require('../../assets/img/qr-code.jpg')} />
            </div>
          </div>
        </div>
        <Modal
          visible={this.state.showModal}
          title={[
            <h2 key="deletebuttonH2" className="modal__title primary-text" id="modal-1-title">
              Confirmation
            </h2>,
          ]}
          onCancel={() => this.setState({ showModal: false })}
          footer={[
            <div key="deletebuttonReminder">
              <button
                className="modal__btn button primary-fill"
                style={{ padding: '9px 25px' }}
                onClick={this.handleUpdateToken}
              >
                Yes
              </button>
              <button
                className="button button-outline primary-outline"
                style={{ padding: '9px 25px' }}
                onClick={() => this.setState({ showModal: false })}
              >
                No
              </button>
            </div>,
          ]}
          width={620}
        >
          <h3 align="center" style={{ color: 'rgba(0,0,0,0.70)' }}>
            It appears that, you are already Logged-In on other Device. Do you wish to Continue here and logout from
            earlier device?
          </h3>
        </Modal>
        <Modal
          visible={this.state.visible}
          title={[
            <h2 key="deletebuttonH2" className="modal__title primary-text" id="modal-1-title">
              Upload KYC - Identity Proof
            </h2>,
          ]}
          onCancel={() => this.setState({ visible: false })}
          footer={[
            <div key="deletebuttonReminder">
              <button
                className="modal__btn button primary-fill"
                style={{ padding: '9px 25px' }}
                onClick={this.createKyc}
              >
                Yes
              </button>
              <button
                className="button button-outline primary-outline"
                style={{ padding: '9px 25px' }}
                onClick={() => this.setState({ visible: false })}
              >
                No
              </button>
            </div>,
          ]}
          width={620}
        >
          <div style={{ marginBottom: '15px' }} className="d-flex j-content-center updateProfileItem">
            <UploadImage
              title="Personal Identity"
              file={this.state.Kyc.imageOne}
              leftAlign
              onUpload={(e) => this.uploadDocument(e, 'photoId', 1)}
            />
            <UploadImage
              title="Business Identity"
              file={this.state.Kyc.imageTwo}
              leftAlign
              onUpload={(e) => this.uploadDocument(e, 'photoId', 2)}
            />
          </div>
        </Modal>
      </>
    );
  }
}

export default compose(
  connect(
    (store) => ({
      token: store?.auth.token,
    }),
    { setLoader },
  ),
  withRouter,
)(LoginForm);
