import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Heading from '../common/Heading';
import { svgFiles } from '../common/Svg';
import { Select, Dropdown, Tooltip } from 'antd';
import { Input } from 'antd';
import downArrow from '../../assets/svg/Search/down-arrow.svg';
import upArrow from '../../assets/svg/Search/up-arrow.svg';
import MobileBottomAction from './MobileBottomAction';
import KeyToSymbol from './KeyToSymbol';
import SavedSearchPopup from './SavedSearchPopup';
import _, { get } from 'lodash';
import InputRange from './InputRange';
import Carat from './Carat';
import { handleSelectSearch, SEARCH_PAGES } from '../../containers/APIFunctions';
import AddDemandPopup from './AddDemandPopup';
import UtilService from '../../services/util';
import IntlMessages from '../../util/IntlMessages';
import { DIAMOND_WEB_STATUS, MULTIPLE_SEARCH } from '../../constants/Common';
import CheckBox from '../common/CheckBox';
import TooltipBox from './TooltipBox';
import { getGridHeight } from '../DiamondList';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import { GuestUserPopup } from '../../components/GuestUserPopup';
import { flatten, map, includes } from 'lodash';
import SelectOption from '../common/SelectOption';
import NoDataShow from '../common/NoDataShow';
import { isEmpty } from 'util/utils';
import GridHeightContainer from 'containers/GridHeightContainer/GridHeightContainer';
import { BidService } from 'services/BidService';
import { LISTINGPAGES } from 'components/DiamondList/DiamondListFunctions';
/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const tooltipMasters = [
  'cut',
  'pol',
  'sym',
  'flu',
  'hA',
  'brlncy',
  'shd',
  'cult',
  // "blkSd",
  // "blkTbl",
  // "wSd",
  // "wTbl",
  'grdl',
];

const fancyParams = ['inten', 'ovrtn', 'fcCol'];
class DiamondSearch extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      shapeExtend: false,
      showParameter: false,
      showOtherParams: true,
      showKeyToSymbol: false,
      incPatterns: false,
      isGuest: localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`),
      showGuest: false,
      value: 1,
      isShow: false,
      isAll: false,
      diamondCertiValue: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.commonData.modifySearch && this.props.commonData.modifySearch !== nextProps.commonData.modifySearch) {
      const toggles = ['isSeal', 'opPav', 'opTbl', 'opCrwn'];
      toggles.map((tgl) => {
        if (nextProps.commonData.modifySearch[tgl]) {
          let val = null;
          if (tgl === 'isSeal')
            val =
              nextProps.commonData.modifySearch[tgl] === true
                ? 'YES'
                : nextProps.commonData.modifySearch[tgl] === false
                  ? 'NO'
                  : null;
          else {
            const yesno = nextProps.commonData.modifySearch[tgl][0];
            const arr = this.props.diamParams[tgl].filter((el) => el.id.includes(yesno));
            val = arr[0] ? arr[0].code : null;
          }
          if (val) {
            this.props.handleToggle(tgl, val);
            this.props.handleChange(tgl, val);
          }
        }
      });
    }
    if (nextProps.location !== this.props.location) {
      this.props.handleRefresh();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.forceUpdate());
  }

  componentDidUpdate(prevProps) {
    if (this.props.commonData.strip !== prevProps.commonData.strip) this.forceUpdate();
  }

  moreLess = () => {
    this.setState({
      shapeExtend: !this.state.shapeExtend,
    });
  };

  getMaster = (key) => {
    const { diamParams, selectedMaster } = this.props;
    const allList = ['col', 'clr', 'fcCol', 'inten', 'ovrtn'];
    return (
      <>
        <ul className="diamondSearchList">
          {includes(allList, key) && (
            <li
              className={selectedMaster[key] && selectedMaster[key].length === diamParams[key].length ? 'active' : ''}
              onClick={() => {
                diamParams[key].length &&
                  this.props.handleSelection(
                    flatten(map(diamParams[key], 'id')),
                    selectedMaster[key] && selectedMaster[key].length > 0 ? false : true,
                    key,
                  );
              }}
            >
              <span>All</span>
            </li>
          )}
          {diamParams[key] &&
            diamParams[key].map((d, index) => {
              d.checked = false;
              _.each(d.id, function (id) {
                if (selectedMaster[key] && selectedMaster[key].includes(id)) {
                  d.checked = true;
                }
              });
              return _.includes(tooltipMasters, key) ? (
                <TooltipBox title={d.description}>
                  <li
                    onClick={(e) => {
                      this.props.handleSelection(d.id, !d.checked, key);
                    }}
                    className={d.checked && `active`}
                  >
                    {d.webDisplay}
                  </li>
                </TooltipBox>
              ) : (
                <li
                  onClick={(e) => {
                    this.props.handleSelection(d.id, !d.checked, key);
                    setTimeout(() => {
                      if (this.props.noBGM) {
                        if (key === 'clr') {
                          this.props.handleCombineSearch('noBGM', false, true)
                          this.props.handleCombineSearch('noBGM', true, true)
                        }
                      }
                    }, 300);


                  }}
                  className={d.checked && `active`}
                >
                  {d.webDisplay}
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  getToggle = (key) => {
    return (
      <ul className="diamondSearchList">
        <li
          className={this.props[key] === 'YES' && `active`}
          onClick={() => {
            this.props.handleToggle(key, 'YES');
            this.props.handleChange(key, 'YES');
          }}
        >
          YES
        </li>
        <li
          className={this.props[key] === 'NO' && `active`}
          onClick={() => {
            this.props.handleToggle(key, 'NO');
            this.props.handleChange(key, 'NO');
          }}
        >
          NO
        </li>
      </ul>
    );
  };

  handleFocus = () => {
    this.inputRef.current.blur();
  };
  render() {
    const gridHeight = getGridHeight(['HeaderSticky'], 15);
    const bidDiamond = BidService.bid;
    const { diamParams = {}, selectedMaster = {}, searchList = [], isFancy } = this.props;
    // const userAccess = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const PATH = window.location.pathname;
    const selectedId =
      selectedMaster.or && selectedMaster.or[0] && selectedMaster.or[0]?.vStnId === undefined ? true : false;

    return !isEmpty(bidDiamond) && [LISTINGPAGES.SEARCH_DIAMOND, LISTINGPAGES.BID].includes(PATH.split('/')[1]) ? (
      <GridHeightContainer
        className="diamondSearch new_ui"
        subtractFrom=".HeaderSticky,.botoomStickyBar"
        adjustment={-15}
      >
        {/* <GridHeightContainer className="diamondSearch new_ui" style={{ height: gridHeight, overflow: 'auto' }}> */}
        <div className="d-flex justify-space-between searchTopBox align-items-center">
          {/* <Heading title={<IntlMessages id="app.search-diamond" />} /> */}
          <div className="d-flex align-items-center flex-wrap-xs">
            <Heading
              className="mb-0"
              title={
                `/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['MATCH_PAIR'] ? (
                  'Couple Stone Search'
                ) : `/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] ? (
                  'Search Bid Diamond'
                ) : (
                  <IntlMessages id="app.search-diamond" />
                )
              }
            />
            {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['SEARCH'] &&
              get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) &&
              (this.props.count >= 500 ? (
                <div
                  className={
                    this.props.count === 0 || this.props.count >= 500 ? `red search_limit` : `green search_limit`
                  }
                >
                  <span>Please narrow your search, as result is >500 Diamonds</span>
                </div>
              ) : (
                ''
              ))}
          </div>
          {`/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR'] && (
            <div className="searchTopInputValue d-flex align-items-center">
              <div className="searchTopBlock">
                <IntlMessages id="app.StnIDRprtNo" defaultMessage="search">
                  {(placeholder) => (
                    <Input
                      ref={this.inputRef}
                      type="text"
                      placeholder={placeholder}
                      autoComplete="off"
                      value={this.props.stoneIds}
                      onChange={(e) => {
                        this.props.handleChange('stoneIds', e.target.value);
                      }}
                      // onBlur={() => {
                      //   this.props.handleSetStoneIds();
                      // }}
                      onPressEnter={(e) => {
                        this.handleFocus();
                        this.props.handleSetStoneIds();
                      }}
                    />
                  )}
                </IntlMessages>
              </div>
            </div>
          )}
        </div>

        {this.props.packetNo && this.props.handleSearch()}
        <div
          className="diamondSearchWrapper curved"
        // style={isDesktop && (
        //     { height: gridHeight, overflow: "auto" }
        //   )}
        >
          <div className="diamondSearchItem">
            {/* <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.Shape" />
              </label>
            </div> */}
            <div className="diamondSearchDetail width-100" style={{ paddingLeft: '15px' }}>
              <ul className={`diamondSearchShape ${this.state.shapeExtend && 'active'}`}>
                <li
                  className={selectedMaster.shp && selectedMaster.shp.length === diamParams.shp.length ? 'active' : ''}
                  onClick={() => {
                    diamParams.shp.length &&
                      this.props.handleSelection(
                        flatten(map(diamParams.shp, 'id')),
                        selectedMaster.shp && selectedMaster.shp.length > 0 ? false : true,
                        'shp',
                      );
                  }}
                >
                  <span>All</span>
                </li>
                {diamParams.shp &&
                  diamParams.shp.map((s) => {
                    s.checked = false;
                    _.each(s.id, function (id) {
                      if (selectedMaster.shp && selectedMaster.shp.includes(id)) {
                        s.checked = true;
                      }
                    });
                    return (
                      <Tooltip title={_.capitalize(s.webDisplay)} placement="bottom">
                        <li
                          className={s.checked && `active`}
                          onClick={(e) => this.props.handleSelection(s.id, !s.checked, 'shp', 'shape')}
                        >
                          <span>
                            {svgFiles.find((svg) => svg.name === s.code)
                              ? svgFiles.find((svg) => svg.name === s.code).file
                              : ''}
                          </span>

                          <span>{_.capitalize(s.webDisplay)}</span>
                        </li>
                      </Tooltip>
                    );
                  })}
              </ul>
            </div>
          </div>

          <Carat
            data={diamParams.carat}
            or={this.props.or}
            clearSelection={!isEmpty(this.props?.selectedMaster?.or) && this.props.clearSelection}
            masterIndex={this.props.masterIndex && this.props.masterIndex.crt ? this.props.masterIndex.crt : []}
            handleCaratSelection={this.props.handleCaratSelection}
            handleAddCustomCarat={this.props.handleAddCustomCarat}
          />
          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.clarity" />
              </label>

              {!isEmpty(this.props?.selectedMaster?.clr) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('clr')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('clr')}</div>
          </div>

          {isFancy && (
            <div className="diamondSearchItem">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.color" />
                </label>
              </div>
              <div className="diamondSearchDetail">{this.getMaster('fcCol')}</div>
            </div>
          )}
          {!isFancy && (
            <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.color" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.col) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('col')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
                <div className="colorTab">
                  <a className={`${this.props.clrSwitch && 'active'}`} onClick={() => this.props.handleClrSwitch(0)}>
                    <IntlMessages id="app.white" />
                  </a>
                  <a className={`${!this.props.clrSwitch && 'active'}`} onClick={() => this.props.handleClrSwitch(1)}>
                    <IntlMessages id="app.fancy" />
                  </a>
                </div>
              </div>
              <div className="diamondSearchDetail clearity_s fancy_color">
                {this.props.clrSwitch && this.getMaster('col')}
                {/* {!this.props.clrSwitch && this.getMaster("fcCol")} */}
                {!this.props.clrSwitch && (
                  <>
                    <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
                      <div className="diamondSearchDetail">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.intensity" />
                            </strong>
                          }
                          value={selectedMaster.inten}
                          selectValue={diamParams['inten']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'inten');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.intensity" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                    <div className="diamondSearchItem">
                      <div className="diamondSearchDetail ">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.overtone" />
                            </strong>
                          }
                          value={selectedMaster.ovrtn}
                          selectValue={diamParams['ovrtn']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'ovrtn');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.overtone" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                    <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
                      <div className="diamondSearchDetail">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.color" />
                            </strong>
                          }
                          value={selectedMaster.fcCol}
                          selectValue={diamParams['fcCol']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'fcCol');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.color" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {
            // !this.props.clrSwitch && (
            //   <>
            //     <div
            //       className={`diamondSearchItem ${
            //         !this.props.clrSwitch && "fancyColorBlock"
            //       }`}
            //     >
            //       {/* <div className="diamondSearchLabel">
            //         <label>
            //           <IntlMessages id="app.intensity" />
            //         </label>
            //       </div> */}
            //       <div className="diamondSearchDetail">
            //         <SelectOption
            //           mul
            //           mode={"multiple"}
            //           defaultValue={<IntlMessages id="app.intensity" />}
            //           value={selectedMaster.inten}
            //           selectValue={diamParams["inten"]}
            //           onChange={(value) => {
            //             this.props.handleFancy(value, "inten");
            //           }}
            //           label={
            //             <>
            //               <IntlMessages id="app.intensity" />
            //             </>
            //           }
            //         />
            //         {/* {this.getMaster("inten")} */}
            //       </div>
            //     </div>
            //     <div className="diamondSearchItem">
            //       {/* <div className="diamondSearchLabel">
            //         <label>
            //           <IntlMessages id="app.overtone" />
            //         </label>
            //       </div> */}
            //       <div className="diamondSearchDetail">
            //         <SelectOption
            //           mul
            //           mode={"multiple"}
            //           defaultValue={<IntlMessages id="app.overtone" />}
            //           value={selectedMaster.ovrtn}
            //           selectValue={diamParams["ovrtn"]}
            //           onChange={(value) => {
            //             this.props.handleFancy(value, "ovrtn");
            //           }}
            //           label={
            //             <>
            //               <IntlMessages id="app.overtone" />
            //             </>
            //           }
            //         />
            //         {/* {this.getMaster("ovrtn")} */}
            //       </div>
            //     </div>
            //   </>
            // )
          }

          <div className="d-flex flex-wrap">
            <div className="diamondSearchItem makeGroup">
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>Finishing</label>

                  {(!isEmpty(this.props?.selectedMaster?.cut) ||
                    !isEmpty(this.props?.selectedMaster?.pol) ||
                    !isEmpty(this.props?.selectedMaster?.sym)) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('finishing')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={this.props.threeEX && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('threeEX', !this.props.threeEX, false);
                      }}
                    >
                      3EX
                    </li>
                    <li
                      className={this.props.twoVG && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('twoVG', !this.props.twoVG, false);
                      }}
                    >
                      2VG
                    </li>
                    <li
                      className={this.props.threeVG && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('threeVG', !this.props.threeVG, false);
                      }}
                    >
                      3VG
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.cut" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.cut) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('cut')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="diamondSearchDetail">{this.getMaster('cut')}</div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.polish" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.pol) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('pol')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="diamondSearchDetail">{this.getMaster('pol')}</div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <span>
                    <IntlMessages id="app.symm" />
                  </span>
                  {!isEmpty(this.props?.selectedMaster?.sym) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('sym')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
                </label>
              </div>
              <div className="diamondSearchDetail">{this.getMaster('sym')}</div>
            </div>
          </div>

          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>Floro</label>
              {!isEmpty(this.props?.selectedMaster?.flu) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('flu')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('flu')}</div>
          </div>

          <div className="diamondSearchItem d-flex j-space-between diamondsearchleftRight">
            <div className="diamondsearchleft labInnerBlock">
              <div className="d-flex align-items-center flex-wrap width-100">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.lab" />
                  </label>
                  {!isEmpty(this.props?.selectedMaster?.lb) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('lb')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="diamondSearchDetail">{this.getMaster('lb')}</div>
              </div>
            </div>
            <div className="diamondSearchDetail width-50 diamondSearchSingle">
              <ul className="diamondSearchList">
                <li
                  className={this.props.noBGM && `active`}
                  onClick={() => this.props.handleCombineSearch('noBGM', !this.props.noBGM, true)}
                >
                  NO BGM
                </li>
                <li
                  className={this.props.noBlack && `active`}
                  onClick={() => this.props.handleCombineSearch('noBlack', !this.props.noBlack, true)}
                >
                  NO BLACK
                </li>
              </ul>
            </div>
          </div>

          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.location" />
              </label>
              {!isEmpty(this.props?.selectedMaster?.loc) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('loc')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('loc')}</div>
          </div>

          {!this.state.isGuest && (
            <div className="diamondSearchItem">
              <div className="diamondSearchLabel">
                <label>Ranges</label>
                {(!isEmpty(this.props?.selectedMaster?.ctPr) ||
                  !isEmpty(this.props?.selectedMaster?.amt) ||
                  !isEmpty(this.props?.selectedMaster?.back)) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('range')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
              </div>

              <div className="diamondSearchDetail">
                <div className="d-flex align-items-center flex-wrap searchSelectValue">
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams['ctPr']}
                    title="Price Range/Ct"
                    selectedRange={this.props.selectedRange}
                    apiKey="ctPr"
                    noNegative={true}
                  />
                  <InputRange
                    title="Discount Range"
                    allowSame
                    Min={-100}
                    Max={100}
                    master={diamParams['back']}
                    selectedRange={this.props.selectedRange}
                    apiKey="back"
                  // noNegative={true}
                  />
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams['amt']}
                    title="Total Stone Amt"
                    selectedRange={this.props.selectedRange}
                    apiKey="amt"
                    noNegative={true}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showOtherParams: !this.state.showOtherParams,
              })
            }
          >
            <span>Technicals</span>
            {this.state.showOtherParams ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.showOtherParams && (
            <div className="fullBlockInnerBlock d-flex flex-wrap">
              <div className="width-100 two-cols">
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Shade" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.shd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('shd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('shd')}</div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.hna" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.hA) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('hA')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('hA')}</div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>Luster</label>
                    {!isEmpty(this.props?.selectedMaster?.lstr) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('lstr')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('lstr')}</div>
                </div>
                {/* <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>Graining</label>
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('grning')}</div>
                </div> */}
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.eye-clean" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.eCln) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('eCln')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('eCln')}</div>
                </div>
              </div>
            </div>
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showKeyToSymbol: !this.state.showKeyToSymbol,
              })
            }
          >
            <span>
              <IntlMessages id="app.KeyToSymbolReportComments" />
            </span>

            {this.state.showKeyToSymbol ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.showKeyToSymbol && (
            <KeyToSymbol
              data={diamParams['keyToSymbol']}
              selectedKeyToSymbol={this.props.selectedKeyToSymbol}
              clearSelection={!isEmpty(this.props?.selectedMaster?.kToSArr) && this.props.clearSelection}
            />
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                incPatterns: !this.state.incPatterns,
              })
            }
          >
            <span>
              <IntlMessages id="app.InclusionPatterns" />
            </span>
            {this.state.incPatterns ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.incPatterns && (
            <div className="fullBlockInnerBlock d-flex flex-wrap inclusiveBlock">
              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.WhiteInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.wTbl) || !isEmpty(this.props?.selectedMaster?.wSd)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('whiteInclusion')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.wTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('wTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('wTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Side" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.wSd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('wSd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('wSd')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.BlackInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.blkTbl) || !isEmpty(this.props?.selectedMaster?.blkSd)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('blackInclusion')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Black</label>
                    {!isEmpty(this.props?.selectedMaster?.blkTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('blkTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('blkTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Side Black</label>
                    {!isEmpty(this.props?.selectedMaster?.blkSd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('blkSd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('blkSd')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.OpenInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.opTbl) || !isEmpty(this.props?.selectedMaster?.opPav)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('openInc')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Open Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.opTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Side Open Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.opPav) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opPav')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opPav')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">Extra Facet</label>
                {!isEmpty(this.props?.selectedMaster?.eFact) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('extra')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.pav" />
                    </label>
                    {(!isEmpty(this.props?.selectedMaster?.eFact) || !isEmpty(this.props?.selectedMaster?.opCrwn)) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('eFact')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('eFact')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.crown" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.opCrwn) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opCrwn')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opCrwn')}</div>
                </div>
              </div>
            </div>
          )}

          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showParameter: !this.state.showParameter,
              })
            }
          >
            <span>
              <IntlMessages id="app.param" />
            </span>
            {this.state.showParameter ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>

          {this.state.showParameter && (
            <div className="fullBlockInnerBlock">
              {(!isEmpty(this.props?.selectedMaster?.tblPer) ||
                !isEmpty(this.props?.selectedMaster?.depPer) ||
                !isEmpty(this.props?.selectedMaster?.ratio) ||
                !isEmpty(this?.props?.selectedMaster?.length) ||
                !isEmpty(this?.props?.selectedMaster?.width) ||
                !isEmpty(this?.props?.selectedMaster?.height) ||
                !isEmpty(this?.props?.selectedMaster?.cAng) ||
                !isEmpty(this?.props?.selectedMaster?.cHgt) ||
                !isEmpty(this?.props?.selectedMaster?.grdlPer) ||
                !isEmpty(this?.props?.selectedMaster?.pAng) ||
                !isEmpty(this?.props?.selectedMaster?.pHgt)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span
                      className="clearSelection"
                      style={{ marginLeft: '20px', cursor: 'pointer', fontSize: '25px', color: 'black' }}
                      onClick={() => this.props.clearSelection('parameter')}
                    >
                      &times;
                    </span>
                  </Tooltip>
                )}
              <div className="diamondSearchItem b-b-0">
                <div className="diamondSearchDetail fullWidthBlock">
                  <div className="d-flex align-items-center flex-wrap plr-15">
                    <InputRange
                      title={<IntlMessages id="app.diamonddetails.Additionalinfo.Tableper" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['tblPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="tblPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.total-depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['depPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="depPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.ratio" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['ratio']}
                      selectedRange={this.props.selectedRange}
                      apiKey="ratio"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.length" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['length']}
                      selectedRange={this.props.selectedRange}
                      apiKey="length"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.width" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['width']}
                      selectedRange={this.props.selectedRange}
                      apiKey="width"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['height']}
                      selectedRange={this.props.selectedRange}
                      apiKey="height"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams['cAng']}
                      selectedRange={this.props.selectedRange}
                      apiKey="cAng"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crown-height%" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams['cHgt']}
                      selectedRange={this.props.selectedRange}
                      apiKey="cHgt"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.diamonddetails.Additionalinfo.Girdle" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['grdlPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="grdlPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.pavAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams['pAng']}
                      selectedRange={this.props.selectedRange}
                      apiKey="pAng"
                      noNegative={true}
                    />

                    <InputRange
                      title={<IntlMessages id="app.pavHt" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams['pHgt']}
                      selectedRange={this.props.selectedRange}
                      apiKey="pHgt"
                      noNegative={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <MobileBottomAction
          {...this.props}
          saveSearchPopup={() => this.props.handleReverseState('showSavedSearch')}
          showDemand={() => this.props.handleReverseState('showDemand')}
          bid={`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT']}
        />
        <div className="botoomStickyBar diamondSearchSticky">
          <div className="width-full d-flex align-items opt_btn justify-space-between">
            <div className="d-flex align-items-center">
              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['SEARCH'] &&
                get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && (
                  <>
                    <a
                      className={
                        this.props.count === 0 || this.props.count >= 500
                          ? `bottomStickyButton disable`
                          : `bottomStickyButton`
                      }
                      onClick={() =>
                        this.props.multipleSearch.length !== 0
                          ? this.props.handleMutipleSearch(true)
                          : // this.props.handleMultipleSearchApiCall()
                          this.props.handleSearch()
                      }
                    >
                      <span>
                        <IntlMessages id="app.search-btn-footer" />
                        {/* {`(${this.props.count || 0})`} */}
                      </span>
                    </a>

                    {get(USER_PERMIT, 'UPCOMING.view', false) && (
                      <a className="bottomStickyButton" onClick={() => this.props.handleUpcoming()}>
                        <span>Search Upcoming</span>
                      </a>
                    )}

                    {MULTIPLE_SEARCH && (
                      <a
                        className={
                          this.props.count === 0 || this.props.count >= 500
                            ? `bottomStickyButton disable`
                            : `bottomStickyButton`
                        }
                        onClick={() => this.props.handleMutipleSearch()}
                      >
                        <span>
                          <IntlMessages id="app.add-more-search" />
                          {`${this.props.multipleSearch.length ? `(${this.props.multipleSearch.length})` : ''}`}
                        </span>
                      </a>
                    )}
                  </>
                )}
              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] && (
                <>
                  <a
                    className={
                      this.props.count === 0 || this.props.count >= 500
                        ? `bottomStickyButton disable`
                        : 'bottomStickyButton'
                    }
                    onClick={() =>
                      this.props.multipleSearch.length !== 0
                        ? this.props.handleMutipleSearch(true)
                        : // this.props.handleMultipleSearchApiCall()
                        this.props.handleSearch()
                    }
                  >
                    <span>
                      <IntlMessages id="app.search-btn-footer" />
                      {/* {`(${this.props.count || 0})`} */}
                    </span>
                  </a>
                </>
              )}
              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] && (
                <>
                  <a
                    className={`bottomStickyButton`}
                    onClick={() => {
                      if (!this.state.isGuest) {
                        this.props.history.push('/my-bid');
                      } else {
                        this.setState({
                          showGuest: true,
                        });
                      }
                    }}
                  >
                    <span>My Bid</span>
                  </a>
                </>
              )}

              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['MATCH_PAIR'] && (
                <>
                  {get(USER_PERMIT, 'SEARCH_MATCH_PAIR.view', false) && (
                    <>
                      <a
                        className={`bottomStickyButton`}
                        onClick={() => {
                          if (!this.state.isGuest) {
                            this.props.handleMatchPair();
                          } else {
                            this.setState({
                              showGuest: true,
                            });
                          }
                        }}
                      >
                        <span>
                          <IntlMessages id="app.search-btn-footer" />
                          {/* {`(${this.props.count || 0})`} */}
                        </span>
                      </a>
                    </>
                  )}
                </>
              )}
              {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['BIT_IT'] && (
                <a
                  className={
                    `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR']
                      ? this.props.count === 0 || this.props.count >= 500
                        ? `bottomStickyButton disable`
                        : `bottomStickyButton`
                      : `bottomStickyButton`
                  }
                  onClick={() => {
                    if (!this.state.isGuest) {
                      this.props.handleReverseState('showSavedSearch');
                    } else {
                      this.setState({
                        showGuest: true,
                      });
                    }
                  }}
                >
                  <span>
                    {this.props.searchId ? (
                      <IntlMessages id="app.update-search" />
                    ) : (
                      <IntlMessages id="app.AddSaveNSearch" />
                    )}
                  </span>
                </a>
              )}
              {get(USER_PERMIT, 'DEMAND.view', false) &&
                `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['BIT_IT'] &&
                `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR'] && (
                  <a
                    className={
                      selectedId && this.props.count === 0 ? `bottomStickyButton` : `bottomStickyButton disable`
                    }
                    onClick={() => {
                      this.props.handleReverseState('showDemand');
                    }}
                  >
                    <span>Add Demand</span>
                  </a>
                )}

              <a
                className="bottomStickyButton"
                onClick={() => {
                  this.props.handleRefresh();
                }}
              >
                <span>
                  <IntlMessages id="app.refresh" />
                </span>
              </a>
            </div>

            <div className="select-diamonds">
              {this.props.count === 0 && (
                <span className={`${this.props.count === 0 ? `zeroDiamond red search_limit` : ''}`}>
                  <span>{'0 Diamonds Found.'}</span>
                </span>
              )}
              {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && (
                <Select
                  allowClear={true}
                  value={this.props.searchTitle}
                  placeholder={<IntlMessages id="app.select-saved-search" />}
                  style={{ width: 200 }}
                  onChange={(value) => {
                    if (value) {
                      handleSelectSearch(value, (searchData, name) => {
                        if (!_.isEmpty(searchData)) {
                          this.props.handleChange('searchTitle', name);
                          this.props.handleChange('searchId', value);
                          this.props.handleAfterSelectSearch(searchData);
                        }
                      });
                    } else {
                      this.props.handleRefresh();
                    }
                  }}
                >
                  {searchList.map((s) => (
                    <Option value={s.id}>{s.name}</Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
        <SavedSearchPopup {...this.props} />
        {selectedId && this.props.count === 0 && <AddDemandPopup {...this.props} />}
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open('/signup', '_self');
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </GridHeightContainer>
    ) : ![LISTINGPAGES.SEARCH_DIAMOND, LISTINGPAGES.BID].includes(PATH.split('/')[1]) ? (
      <GridHeightContainer
        className="diamondSearch new_ui"
        subtractFrom=".HeaderSticky,.botoomStickyBar"
        adjustment={-15}
      >
        {/* <GridHeightContainer className="diamondSearch new_ui" style={{ height: gridHeight, overflow: 'auto' }}> */}
        <div className="d-flex justify-space-between searchTopBox align-items-center">
          {/* <Heading title={<IntlMessages id="app.search-diamond" />} /> */}
          <div className="d-flex align-items-center flex-wrap-xs">
            <Heading
              className="mb-0"
              title={
                `/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['MATCH_PAIR'] ? (
                  'Couple Stone Search'
                ) : `/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] ? (
                  'Search Bid Diamond'
                ) : (
                  <IntlMessages id="app.search-diamond" />
                )
              }
            />
            {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['SEARCH'] &&
              get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) &&
              (this.props.count >= 500 ? (
                <div
                  className={
                    this.props.count === 0 || this.props.count >= 500 ? `red search_limit` : `green search_limit`
                  }
                >
                  <span>Please narrow your search, as result is >500 Diamonds</span>
                </div>
              ) : (
                ''
              ))}
          </div>
          {`/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR'] && (
            <div className="searchTopInputValue d-flex align-items-center">
              <div className="searchTopBlock">
                <IntlMessages id="app.StnIDRprtNo" defaultMessage="search">
                  {(placeholder) => (
                    <Input
                      ref={this.inputRef}
                      type="text"
                      placeholder={placeholder}
                      autoComplete="off"
                      value={this.props.stoneIds}
                      onChange={(e) => {
                        this.props.handleChange('stoneIds', e.target.value);
                      }}
                      // onBlur={() => {
                      //   this.props.handleSetStoneIds();
                      // }}
                      onPressEnter={(e) => {
                        this.handleFocus();
                        this.props.handleSetStoneIds();
                      }}
                    />
                  )}
                </IntlMessages>
              </div>
            </div>
          )}
        </div>

        {this.props.packetNo && this.props.handleSearch()}
        <div
          className="diamondSearchWrapper curved"
        // style={isDesktop && (
        //     { height: gridHeight, overflow: "auto" }
        //   )}
        >
          <div className="diamondSearchItem">
            {/* <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.Shape" />
              </label>
            </div> */}
            <div className="diamondSearchDetail width-100" style={{ paddingLeft: '15px' }}>
              <ul className={`diamondSearchShape ${this.state.shapeExtend && 'active'}`}>
                <li
                  className={selectedMaster.shp && selectedMaster.shp.length === diamParams.shp.length ? 'active' : ''}
                  onClick={() => {
                    diamParams.shp.length &&
                      this.props.handleSelection(
                        flatten(map(diamParams.shp, 'id')),
                        selectedMaster.shp && selectedMaster.shp.length > 0 ? false : true,
                        'shp',
                      );
                  }}
                >
                  <span>All</span>
                </li>
                {diamParams.shp &&
                  diamParams.shp.map((s) => {
                    s.checked = false;
                    _.each(s.id, function (id) {
                      if (selectedMaster.shp && selectedMaster.shp.includes(id)) {
                        s.checked = true;
                      }
                    });
                    return (
                      <Tooltip title={_.capitalize(s.webDisplay)} placement="bottom">
                        <li
                          className={s.checked && `active`}
                          onClick={(e) => this.props.handleSelection(s.id, !s.checked, 'shp', 'shape')}
                        >
                          <span>
                            {svgFiles.find((svg) => svg.name === s.code)
                              ? svgFiles.find((svg) => svg.name === s.code).file
                              : ''}
                          </span>

                          <span>{_.capitalize(s.webDisplay)}</span>
                        </li>
                      </Tooltip>
                    );
                  })}
              </ul>
            </div>
          </div>

          <Carat
            data={diamParams.carat}
            or={this.props.or}
            clearSelection={!isEmpty(this.props?.selectedMaster?.or) && this.props.clearSelection}
            masterIndex={this.props.masterIndex && this.props.masterIndex.crt ? this.props.masterIndex.crt : []}
            handleCaratSelection={this.props.handleCaratSelection}
            handleAddCustomCarat={this.props.handleAddCustomCarat}
          />
          <div className="diamondSearchItem">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.clarity" />
              </label>

              {!isEmpty(this.props?.selectedMaster?.clr) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('clr')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('clr')}</div>
          </div>

          {isFancy && (
            <div className="diamondSearchItem">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.color" />
                </label>
              </div>
              <div className="diamondSearchDetail">{this.getMaster('fcCol')}</div>
            </div>
          )}
          {!isFancy && (
            <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.color" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.col) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('col')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
                <div className="colorTab">
                  <a className={`${this.props.clrSwitch && 'active'}`} onClick={() => this.props.handleClrSwitch(0)}>
                    <IntlMessages id="app.white" />
                  </a>
                  <a className={`${!this.props.clrSwitch && 'active'}`} onClick={() => this.props.handleClrSwitch(1)}>
                    <IntlMessages id="app.fancy" />
                  </a>
                </div>
              </div>
              <div className="diamondSearchDetail clearity_s fancy_color">
                {this.props.clrSwitch && this.getMaster('col')}
                {/* {!this.props.clrSwitch && this.getMaster("fcCol")} */}
                {!this.props.clrSwitch && (
                  <>
                    <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
                      <div className="diamondSearchDetail">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.intensity" />
                            </strong>
                          }
                          value={selectedMaster.inten}
                          selectValue={diamParams['inten']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'inten');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.intensity" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                    <div className="diamondSearchItem">
                      <div className="diamondSearchDetail ">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.overtone" />
                            </strong>
                          }
                          value={selectedMaster.ovrtn}
                          selectValue={diamParams['ovrtn']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'ovrtn');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.overtone" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                    <div className={`diamondSearchItem ${!this.props.clrSwitch && 'fancyColorBlock'}`}>
                      <div className="diamondSearchDetail">
                        <SelectOption
                          mul
                          mode="multiple"
                          placeholder={
                            <strong>
                              <IntlMessages id="app.color" />
                            </strong>
                          }
                          value={selectedMaster.fcCol}
                          selectValue={diamParams['fcCol']}
                          onChange={(value) => {
                            this.props.handleFancy(value, 'fcCol');
                          }}
                        // label={
                        //   <>
                        //     <IntlMessages id="app.color" />
                        //   </>
                        // }
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {
            // !this.props.clrSwitch && (
            //   <>
            //     <div
            //       className={`diamondSearchItem ${
            //         !this.props.clrSwitch && "fancyColorBlock"
            //       }`}
            //     >
            //       {/* <div className="diamondSearchLabel">
            //         <label>
            //           <IntlMessages id="app.intensity" />
            //         </label>
            //       </div> */}
            //       <div className="diamondSearchDetail">
            //         <SelectOption
            //           mul
            //           mode={"multiple"}
            //           defaultValue={<IntlMessages id="app.intensity" />}
            //           value={selectedMaster.inten}
            //           selectValue={diamParams["inten"]}
            //           onChange={(value) => {
            //             this.props.handleFancy(value, "inten");
            //           }}
            //           label={
            //             <>
            //               <IntlMessages id="app.intensity" />
            //             </>
            //           }
            //         />
            //         {/* {this.getMaster("inten")} */}
            //       </div>
            //     </div>
            //     <div className="diamondSearchItem">
            //       {/* <div className="diamondSearchLabel">
            //         <label>
            //           <IntlMessages id="app.overtone" />
            //         </label>
            //       </div> */}
            //       <div className="diamondSearchDetail">
            //         <SelectOption
            //           mul
            //           mode={"multiple"}
            //           defaultValue={<IntlMessages id="app.overtone" />}
            //           value={selectedMaster.ovrtn}
            //           selectValue={diamParams["ovrtn"]}
            //           onChange={(value) => {
            //             this.props.handleFancy(value, "ovrtn");
            //           }}
            //           label={
            //             <>
            //               <IntlMessages id="app.overtone" />
            //             </>
            //           }
            //         />
            //         {/* {this.getMaster("ovrtn")} */}
            //       </div>
            //     </div>
            //   </>
            // )
          }

          <div className="d-flex flex-wrap">
            <div className="diamondSearchItem makeGroup">
              <div className="d-flex align-items-center flex-wrap">
                <div className="diamondSearchLabel">
                  <label>Finishing</label>

                  {(!isEmpty(this.props?.selectedMaster?.cut) ||
                    !isEmpty(this.props?.selectedMaster?.pol) ||
                    !isEmpty(this.props?.selectedMaster?.sym)) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('finishing')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                </div>
                <div className="diamondSearchDetail">
                  <ul className="diamondSearchList">
                    <li
                      className={this.props.threeEX && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('threeEX', !this.props.threeEX, false);
                      }}
                    >
                      3EX
                    </li>
                    <li
                      className={this.props.twoVG && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('twoVG', !this.props.twoVG, false);
                      }}
                    >
                      2VG
                    </li>
                    <li
                      className={this.props.threeVG && `active`}
                      onClick={() => {
                        this.props.handleCombineSearch('threeVG', !this.props.threeVG, false);
                      }}
                    >
                      3VG
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.cut" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.cut) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('cut')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="diamondSearchDetail">{this.getMaster('cut')}</div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <IntlMessages id="app.compare.polish" />
                </label>
                {!isEmpty(this.props?.selectedMaster?.pol) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('pol')}>
                      &times;
                    </span>
                  </Tooltip>
                )}
              </div>
              <div className="diamondSearchDetail">{this.getMaster('pol')}</div>
            </div>
            <div className="diamondSearchItem makeGroup">
              <div className="diamondSearchLabel">
                <label>
                  <span>
                    <IntlMessages id="app.symm" />
                  </span>
                  {!isEmpty(this.props?.selectedMaster?.sym) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('sym')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
                </label>
              </div>
              <div className="diamondSearchDetail">{this.getMaster('sym')}</div>
            </div>
          </div>

          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>Floro</label>
              {!isEmpty(this.props?.selectedMaster?.flu) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('flu')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('flu')}</div>
          </div>

          <div className="diamondSearchItem d-flex j-space-between diamondsearchleftRight">
            <div className="diamondsearchleft labInnerBlock">
              <div className="d-flex align-items-center flex-wrap width-100">
                <div className="diamondSearchLabel">
                  <label>
                    <IntlMessages id="app.lab" />
                  </label>
                  {!isEmpty(this.props?.selectedMaster?.lb) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('lb')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="diamondSearchDetail">{this.getMaster('lb')}</div>
              </div>
            </div>
            <div className="diamondSearchDetail width-50 diamondSearchSingle">
              <ul className="diamondSearchList">
                <li
                  className={this.props.noBGM && `active`}
                  onClick={() => this.props.handleCombineSearch('noBGM', !this.props.noBGM, true)}
                >
                  NO BGM
                </li>
                <li
                  className={this.props.noBlack && `active`}
                  onClick={() => this.props.handleCombineSearch('noBlack', !this.props.noBlack, true)}
                >
                  NO BLACK
                </li>
              </ul>
            </div>
          </div>

          <div className="diamondSearchItem colorClarityBox">
            <div className="diamondSearchLabel">
              <label>
                <IntlMessages id="app.location" />
              </label>
              {!isEmpty(this.props?.selectedMaster?.loc) && (
                <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                  <span className="clearSelection" onClick={() => this.props.clearSelection('loc')}>
                    &times;
                  </span>
                </Tooltip>
              )}
            </div>
            <div className="diamondSearchDetail">{this.getMaster('loc')}</div>
          </div>

          {!this.state.isGuest && (
            <div className="diamondSearchItem">
              <div className="diamondSearchLabel">
                <label>Ranges</label>
                {(!isEmpty(this.props?.selectedMaster?.ctPr) ||
                  !isEmpty(this.props?.selectedMaster?.amt) ||
                  !isEmpty(this.props?.selectedMaster?.back)) && (
                    <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                      <span className="clearSelection" onClick={() => this.props.clearSelection('range')}>
                        &times;
                      </span>
                    </Tooltip>
                  )}
              </div>

              <div className="diamondSearchDetail">
                <div className="d-flex align-items-center flex-wrap searchSelectValue">
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams['ctPr']}
                    title="Price Range/Ct"
                    selectedRange={this.props.selectedRange}
                    apiKey="ctPr"
                    noNegative={true}
                  />
                  <InputRange
                    title="Discount Range"
                    allowSame
                    Min={-100}
                    Max={100}
                    master={diamParams['back']}
                    selectedRange={this.props.selectedRange}
                    apiKey="back"
                  // noNegative={true}
                  />
                  <InputRange
                    allowSame
                    Min={0}
                    Max={99999999}
                    master={diamParams['amt']}
                    title="Total Stone Amt"
                    selectedRange={this.props.selectedRange}
                    apiKey="amt"
                    noNegative={true}
                  />
                </div>
              </div>
            </div>
          )}

          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showOtherParams: !this.state.showOtherParams,
              })
            }
          >
            <span>Technicals</span>
            {this.state.showOtherParams ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.showOtherParams && (
            <div className="fullBlockInnerBlock d-flex flex-wrap">
              <div className="width-100 two-cols">
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Shade" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.shd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('shd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('shd')}</div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.hna" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.hA) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('hA')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('hA')}</div>
                </div>

                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>Luster</label>
                    {!isEmpty(this.props?.selectedMaster?.lstr) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('lstr')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('lstr')}</div>
                </div>
                {/* <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>Graining</label>
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('grning')}</div>
                </div> */}
                <div className="diamondSearchItem b-b-0">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.eye-clean" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.eCln) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('eCln')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('eCln')}</div>
                </div>
              </div>
            </div>
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showKeyToSymbol: !this.state.showKeyToSymbol,
              })
            }
          >
            <span>
              <IntlMessages id="app.KeyToSymbolReportComments" />
            </span>

            {this.state.showKeyToSymbol ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.showKeyToSymbol && (
            <KeyToSymbol
              data={diamParams['keyToSymbol']}
              selectedKeyToSymbol={this.props.selectedKeyToSymbol}
              clearSelection={!isEmpty(this.props?.selectedMaster?.kToSArr) && this.props.clearSelection}
            />
          )}
          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                incPatterns: !this.state.incPatterns,
              })
            }
          >
            <span>
              <IntlMessages id="app.InclusionPatterns" />
            </span>
            {this.state.incPatterns ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>
          {this.state.incPatterns && (
            <div className="fullBlockInnerBlock d-flex flex-wrap inclusiveBlock">
              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.WhiteInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.wTbl) || !isEmpty(this.props?.selectedMaster?.wSd)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('whiteInclusion')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.wTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('wTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('wTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.Side" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.wSd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('wSd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('wSd')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.BlackInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.blkTbl) || !isEmpty(this.props?.selectedMaster?.blkSd)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('blackInclusion')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Black</label>
                    {!isEmpty(this.props?.selectedMaster?.blkTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('blkTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('blkTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Side Black</label>
                    {!isEmpty(this.props?.selectedMaster?.blkSd) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('blkSd')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('blkSd')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">
                  <IntlMessages id="app.OpenInclusion" />
                </label>
                {(!isEmpty(this.props?.selectedMaster?.opTbl) || !isEmpty(this.props?.selectedMaster?.opPav)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('openInc')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Table Open Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.opTbl) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opTbl')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opTbl')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>Side Open Inc</label>
                    {!isEmpty(this.props?.selectedMaster?.opPav) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opPav')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opPav')}</div>
                </div>
              </div>

              <div className="inclusion-bb">
                <label className="smallLabelInner">Extra Facet</label>
                {!isEmpty(this.props?.selectedMaster?.eFact) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span className="clearSelection" onClick={() => this.props.clearSelection('extra')}>
                      &times;
                    </span>
                  </Tooltip>
                )}

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.pav" />
                    </label>
                    {(!isEmpty(this.props?.selectedMaster?.eFact) || !isEmpty(this.props?.selectedMaster?.opCrwn)) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('eFact')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('eFact')}</div>
                </div>

                <div className="diamondSearchItem colorClarityBox">
                  <div className="diamondSearchLabel">
                    <label>
                      <IntlMessages id="app.crown" />
                    </label>
                    {!isEmpty(this.props?.selectedMaster?.opCrwn) && (
                      <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                        <span className="clearSelection" onClick={() => this.props.clearSelection('opCrwn')}>
                          &times;
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="diamondSearchDetail">{this.getMaster('opCrwn')}</div>
                </div>
              </div>
            </div>
          )}

          <div
            className="hideShowBlock"
            onClick={() =>
              this.setState({
                showParameter: !this.state.showParameter,
              })
            }
          >
            <span>
              <IntlMessages id="app.param" />
            </span>
            {this.state.showParameter ? <img src={upArrow} /> : <img src={downArrow} />}
          </div>

          {this.state.showParameter && (
            <div className="fullBlockInnerBlock">
              {(!isEmpty(this.props?.selectedMaster?.tblPer) ||
                !isEmpty(this.props?.selectedMaster?.depPer) ||
                !isEmpty(this.props?.selectedMaster?.ratio) ||
                !isEmpty(this?.props?.selectedMaster?.length) ||
                !isEmpty(this?.props?.selectedMaster?.width) ||
                !isEmpty(this?.props?.selectedMaster?.height) ||
                !isEmpty(this?.props?.selectedMaster?.cAng) ||
                !isEmpty(this?.props?.selectedMaster?.cHgt) ||
                !isEmpty(this?.props?.selectedMaster?.grdlPer) ||
                !isEmpty(this?.props?.selectedMaster?.pAng) ||
                !isEmpty(this?.props?.selectedMaster?.pHgt)) && (
                  <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
                    <span
                      className="clearSelection"
                      style={{ marginLeft: '20px', cursor: 'pointer', fontSize: '25px', color: 'black' }}
                      onClick={() => this.props.clearSelection('parameter')}
                    >
                      &times;
                    </span>
                  </Tooltip>
                )}
              <div className="diamondSearchItem b-b-0">
                <div className="diamondSearchDetail fullWidthBlock">
                  <div className="d-flex align-items-center flex-wrap plr-15">
                    <InputRange
                      title={<IntlMessages id="app.diamonddetails.Additionalinfo.Tableper" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['tblPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="tblPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.total-depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['depPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="depPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.ratio" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['ratio']}
                      selectedRange={this.props.selectedRange}
                      apiKey="ratio"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.length" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['length']}
                      selectedRange={this.props.selectedRange}
                      apiKey="length"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.width" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['width']}
                      selectedRange={this.props.selectedRange}
                      apiKey="width"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.depth" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['height']}
                      selectedRange={this.props.selectedRange}
                      apiKey="height"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams['cAng']}
                      selectedRange={this.props.selectedRange}
                      apiKey="cAng"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.crown-height%" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams['cHgt']}
                      selectedRange={this.props.selectedRange}
                      apiKey="cHgt"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.diamonddetails.Additionalinfo.Girdle" />}
                      allowSame
                      Min={0}
                      Max={100}
                      master={diamParams['grdlPer']}
                      selectedRange={this.props.selectedRange}
                      apiKey="grdlPer"
                      noNegative={true}
                    />
                    <InputRange
                      title={<IntlMessages id="app.pavAn" />}
                      allowSame
                      Min={0}
                      Max={360}
                      master={diamParams['pAng']}
                      selectedRange={this.props.selectedRange}
                      apiKey="pAng"
                      noNegative={true}
                    />

                    <InputRange
                      title={<IntlMessages id="app.pavHt" />}
                      allowSame
                      Min={0}
                      Max={99999999}
                      master={diamParams['pHgt']}
                      selectedRange={this.props.selectedRange}
                      apiKey="pHgt"
                      noNegative={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <MobileBottomAction
          {...this.props}
          saveSearchPopup={() => this.props.handleReverseState('showSavedSearch')}
          showDemand={() => this.props.handleReverseState('showDemand')}
          bid={`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT']}
        />
        <div className="botoomStickyBar diamondSearchSticky">
          <div className="width-full d-flex align-items opt_btn justify-space-between">
            <div className="d-flex align-items-center">
              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['SEARCH'] &&
                get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && (
                  <>
                    <a
                      className={
                        this.props.count === 0 || this.props.count >= 500
                          ? `bottomStickyButton disable`
                          : `bottomStickyButton`
                      }
                      onClick={() =>
                        this.props.multipleSearch.length !== 0
                          ? this.props.handleMutipleSearch(true)
                          : // this.props.handleMultipleSearchApiCall()
                          this.props.handleSearch()
                      }
                    >
                      <span>
                        <IntlMessages id="app.search-btn-footer" />
                        {/* {`(${this.props.count || 0})`} */}
                      </span>
                    </a>

                    {/* {get(USER_PERMIT, 'UPCOMING.view', false) && (
                      <a className="bottomStickyButton" onClick={() => this.props.handleUpcoming()}>
                        <span>Search Upcoming</span>
                      </a>
                    )} */}

                    {MULTIPLE_SEARCH && (
                      <a
                        className={
                          this.props.count === 0 || this.props.count >= 500
                            ? `bottomStickyButton disable`
                            : `bottomStickyButton`
                        }
                        onClick={() => this.props.handleMutipleSearch()}
                      >
                        <span>
                          <IntlMessages id="app.add-more-search" />
                          {`${this.props.multipleSearch.length ? `(${this.props.multipleSearch.length})` : ''}`}
                        </span>
                      </a>
                    )}
                  </>
                )}

              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] && (
                <>
                  <a
                    className={
                      this.props.count === 0 || this.props.count >= 500
                        ? `bottomStickyButton disable`
                        : 'bottomStickyButton'
                    }
                    onClick={() =>
                      this.props.multipleSearch.length !== 0
                        ? this.props.handleMutipleSearch(true)
                        : // this.props.handleMultipleSearchApiCall()
                        this.props.handleSearch()
                    }
                  >
                    <span>
                      <IntlMessages id="app.search-btn-footer" />
                      {/* {`(${this.props.count || 0})`} */}
                    </span>
                  </a>
                </>
              )}
              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['BIT_IT'] && (
                <>
                  <a
                    className={`bottomStickyButton`}
                    onClick={() => {
                      if (!this.state.isGuest) {
                        this.props.history.push('/my-bid');
                      } else {
                        this.setState({
                          showGuest: true,
                        });
                      }
                    }}
                  >
                    <span>My Bid</span>
                  </a>
                </>
              )}

              {`/${_.split(PATH, '/')[1]}` === SEARCH_PAGES['MATCH_PAIR'] && (
                <>
                  {get(USER_PERMIT, 'SEARCH_MATCH_PAIR.view', false) && (
                    <>
                      <a
                        className={`bottomStickyButton`}
                        onClick={() => {
                          if (!this.state.isGuest) {
                            this.props.handleMatchPair();
                          } else {
                            this.setState({
                              showGuest: true,
                            });
                          }
                        }}
                      >
                        <span>
                          <IntlMessages id="app.search-btn-footer" />
                          {/* {`(${this.props.count || 0})`} */}
                        </span>
                      </a>
                    </>
                  )}
                </>
              )}
              {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['BIT_IT'] && (
                <a
                  className={
                    `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR']
                      ? this.props.count === 0 || this.props.count >= 500
                        ? `bottomStickyButton disable`
                        : `bottomStickyButton`
                      : `bottomStickyButton`
                  }
                  onClick={() => {
                    if (!this.state.isGuest) {
                      this.props.handleReverseState('showSavedSearch');
                    } else {
                      this.setState({
                        showGuest: true,
                      });
                    }
                  }}
                >
                  <span>
                    {this.props.searchId ? (
                      <IntlMessages id="app.update-search" />
                    ) : (
                      <IntlMessages id="app.AddSaveNSearch" />
                    )}
                  </span>
                </a>
              )}
              {get(USER_PERMIT, 'DEMAND.view', false) &&
                `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['BIT_IT'] &&
                `/${_.split(PATH, '/')[1]}` !== SEARCH_PAGES['MATCH_PAIR'] && (
                  <a
                    className={
                      selectedId && this.props.count === 0 ? `bottomStickyButton` : `bottomStickyButton disable`
                    }
                    onClick={() => {
                      this.props.handleReverseState('showDemand');
                    }}
                  >
                    <span>Add Demand</span>
                  </a>
                )}

              <a
                className="bottomStickyButton"
                onClick={() => {
                  this.props.handleRefresh();
                }}
              >
                <span>
                  <IntlMessages id="app.refresh" />
                </span>
              </a>
            </div>

            <div className="select-diamonds">
              {this.props.count === 0 && (
                <span className={`${this.props.count === 0 ? `zeroDiamond red search_limit` : ''}`}>
                  <span>{'0 Diamonds Found.'}</span>
                </span>
              )}
              {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && (
                <Select
                  allowClear={true}
                  value={this.props.searchTitle}
                  placeholder={<IntlMessages id="app.select-saved-search" />}
                  style={{ width: 200 }}
                  onChange={(value) => {
                    if (value) {
                      handleSelectSearch(value, (searchData, name) => {
                        if (!_.isEmpty(searchData)) {
                          this.props.handleChange('searchTitle', name);
                          this.props.handleChange('searchId', value);
                          this.props.handleAfterSelectSearch(searchData);
                        }
                      });
                    } else {
                      this.props.handleRefresh();
                    }
                  }}
                >
                  {searchList.map((s) => (
                    <Option value={s.id}>{s.name}</Option>
                  ))}
                </Select>
              )}
            </div>
          </div>
        </div>
        <SavedSearchPopup {...this.props} />
        {selectedId && this.props.count === 0 && <AddDemandPopup {...this.props} />}
        {this.state.showGuest && (
          <GuestUserPopup
            visible={this.state.showGuest}
            register={() => {
              window.open('/signup', '_self');
              window.localStorage.clear();
              window.sessionStorage.clear();
            }}
            handleCancel={() => {
              this.setState({ showGuest: false });
            }}
          />
        )}
      </GridHeightContainer>
    ) : (
      <div className="diamondSearch new_ui" style={{ height: gridHeight, overflow: 'auto', background: 'white' }}>
        <NoDataShow
          message={'Currently UBS is Inactive, Kindly visit again. You will be notified when UBS is Active.'}
        />
      </div>
    );
  }
}

export default connect((props) => {
  return props;
}, {})(withRouter(DiamondSearch));
