import React from 'react';

import { Drawer } from 'antd';

import BoothSideBar from './OfficeSidebar/BoothSideBar';
import { useSelector } from 'react-redux';

const BoothAppointment = (props) => {
  const { currentType, visible, onClose } = props;

  const selectedRows = useSelector((state) => state?.diamondData?.selectedRows?.[currentType] ?? []);

  return (
    <Drawer onClose={onClose} visible={visible} wrapClassName="diamondListinSidebar OfficePopup" destroyOnClose>
      {visible && <BoothSideBar checked={selectedRows} onClose={onClose} />}
    </Drawer>
  );
};

export default BoothAppointment;
