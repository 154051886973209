import React, { Component, useState } from 'react';
import InputBlock from '../common/InputBlock';
import '../TermsOfUse/staticContent.less';
import Heading from '../common/Heading';
import StaticHeader from '../StaticHeader';
import './contact.less';
import isEmpty from 'lodash/isEmpty';
import OpenNotification from '../common/CommonButton/OpenNotification';
import { CONTACT_US } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { returnIfNotEmpty } from 'util/utils';

const ContactUs = (props) => {
  return (
    <></>
    // const [data, setData] = useState({});

    // const getMessage = (arr) => {
    //   var outStr = '';
    //   if (arr.length === 1) {
    //     outStr = arr[0];
    //   } else if (arr.length === 2) {
    //     outStr = arr.join(' and ');
    //   } else if (arr.length > 2) {
    //     outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
    //   }
    //   return outStr;
    // };

    // const validate = () => {
    //   let errFields = [];
    //   let required = [
    //     { key: 'name', value: 'Name' },
    //     { key: 'company', value: 'Company Name' },
    //     { key: 'email', value: 'Email' },
    //     { key: 'messege', value: 'Comment' },
    //   ];

    //   required.map((k) => {
    //     if (isEmpty(data[k.key]) || !data[k.key]) {
    //       errFields.push(k.value);
    //     }
    //   });
    //   let msg = getMessage(errFields);

    //   if (!isEmpty(errFields)) {
    //     OpenNotification({
    //       type: 'error',
    //       title: `Please enter ${msg}`,
    //     });
    //     return false;
    //   } else if (data['email'] && !data['email'].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)) {
    //     OpenNotification({
    //       type: 'error',
    //       title: `Please enter valid email address.`,
    //     });
    //     return false;
    //   } else return true;
    // };

    // const handleSubmit = () => {
    //   // console.log("data--->>", data);
    //   if (validate()) {
    //     let objData = {
    //       ...CONTACT_US,
    //       request: data,
    //     };
    //     UtilService.callApi(objData, async (err, data) => {
    //       if (err) throw err;
    //       if (data && data.code === 'CREATED') {
    //         setData({
    //           name: '',
    //           company: '',
    //           address: '',
    //           mobile: '',
    //           email: '',
    //           messege: '',
    //         });
    //         return OpenNotification({
    //           type: 'success',
    //           title: 'Your Valuable Comments, Suggestions are added successfully.',
    //         });
    //       }
    //     });
    //   }
    // };
    // const mapStyles = {
    //   maxWidth: '1140px',
    //   height: '100%',
    // };
    // return (
    //   <>
    //     <StaticHeader />
    //     <div className="staticBoxMain white-bg">
    //       <div className="statiContentBox">
    //         <div className="text-center mb-50">
    //           <Heading title="Contact Us" className="staticMainTitle" />
    //           <p>Your Valuable Comments, Suggestions, Inputs, Queries, Inquires and Concerns are of Value to us.</p>
    //         </div>
    //         <div className="d-flex j-space-between contactMainWrapper">
    //           <div className="contactLeft">
    //             <div className="contactImg">
    //               <img src={require('../../assets/img/contact/2-01.png')} alt="" />
    //             </div>
    //             <div className="contactBasicDetail d-flex flex-wrap">
    //               <div className="contactBasicDetailItem">
    //                 <h2>Online Service</h2>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     {/* <img src={require("../../assets/svg/Contact/mail.svg")} alt=""/> */}
    //                     Mail :
    //                   </div>
    //                   <div className="detailTitle">xyz@uniquegems.com</div>
    //                 </div>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     {/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
    //                     Rapnet :
    //                   </div>
    //                   <div className="detailTitle">00000</div>
    //                 </div>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     Idex :{/* <img src={require("../../assets/svg/email.svg")} alt=""/> */}
    //                   </div>
    //                   <div className="detailTitle">Unique Diamax Pvt Ltd</div>
    //                 </div>
    //               </div>
    //               <div className="contactBasicDetailItem">
    //                 <h2>Phone</h2>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     Phone No 1 :{/* <img src={require("../../assets/svg/phonecall.svg")} alt=""/> */}
    //                   </div>
    //                   <div className="detailTitle">+91-00-00000000</div>
    //                 </div>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     Phone No 2 :{/* <img src={require("../../assets/svg/phonecall.svg")} alt=""/> */}
    //                   </div>
    //                   <div className="detailTitle"> +91-00-00000000</div>
    //                 </div>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     Fax No :{/* <img src={require("../../assets/svg/phonecall.svg")} alt=""/> */}
    //                   </div>
    //                   <div className="detailTitle">+91-00-00000000</div>
    //                 </div>
    //               </div>
    //               <div className="contactBasicDetailItem width-100">
    //                 <h2>Address</h2>
    //                 <div className="detail">
    //                   <div className="detailIcon">
    //                     <img src={require('../../assets/svg/Contact/location.svg')} alt="" />
    //                   </div>
    //                   <div className="detailTitle">
    //                     901, Infinity Tower, India.
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //           <div className="contactRight">
    //             <div className="contactform">
    //               {/* <form> */}
    //               <InputBlock
    //                 value={data.name}
    //                 onChange={(e) => setData({ ...data, name: e.target.value })}
    //                 label="Person Name*"
    //                 placeholder="Person Name"
    //               />
    //               <InputBlock
    //                 value={data.company}
    //                 onChange={(e) => setData({ ...data, company: e.target.value })}
    //                 label="Company Name*"
    //                 placeholder="Company Name"
    //               />
    //               <InputBlock
    //                 value={data.address}
    //                 onChange={(e) => setData({ ...data, address: e.target.value })}
    //                 label="Address "
    //                 placeholder="Address"
    //               />
    //               <InputBlock
    //                 type="number"
    //                 value={data.mobile}
    //                 label="Contact No "
    //                 placeholder="Contact No"
    //                 onChange={(e) => setData({ ...data, mobile: e.target.value })}
    //               />
    //               <InputBlock
    //                 value={data.email}
    //                 onChange={(e) => setData({ ...data, email: e.target.value })}
    //                 label="Email*"
    //                 placeholder="Email"
    //               />
    //               <InputBlock
    //                 value={data.messege}
    //                 onChange={(e) => setData({ ...data, messege: e.target.value })}
    //                 label="Comments*"
    //                 placeholder="Comments"
    //               />
    //               <button onClick={handleSubmit} className="commonButton">
    //                 Submit
    //               </button>
    //               {/* </form> */}
    //             </div>
    //           </div>
    //         </div>
    //         {/* <div className="mapContainer">
    //           <Map
    //             width="100%"
    //             google={props.google}
    //             zoom={14}
    //             style={mapStyles}
    //             initialCenter={{
    //               lat: 00.00000,
    //               lng: 00.00000,
    //             }}
    //           >
    //             <Marker name={"Test"} />
    //           </Map>
    //         </div> */}
    //       </div>
    //     </div>
    //   </>
  );
};

// export default ContactUs;

export default GoogleApiWrapper((props) => ({
  apiKey: 'AIzaSyBHHa9BSaf_EdABnUBhtKXKfW6RWAASERE',
}))(ContactUs);
