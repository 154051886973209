import React, { Component } from 'react';
import UtilService from '../../services/util';
import clone from 'lodash/clone';
import OpenNotification from '../common/CommonButton/OpenNotification';
import InputBlock from '../common/InputBlock';
import { FORGOT_PASSWORD } from '../../constants/apiConstant';
import Logo from '../Header/Logo';
import AuthPoster from './AuthPoster';
import { forgotPassword } from './SignupValidator';
import { notification } from 'antd';

/* eslint jsx-a11y/anchor-is-valid: 0 */

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      fields: {},
      errors: {},
    };
    this.sendEmail = this.sendEmail.bind(this);
  }
  sendEmail = (e) => {
    e.preventDefault();

    let self = this;
    let reqObj = clone(self.state.fields);
    let obj = {
      // method: "post",
      // url: "/auth/forgot-password",
      ...FORGOT_PASSWORD,
      request: {
        username: self.state.fields.email,
        isFront: true,
      },
    };
    const errors = forgotPassword({ email: self.state.fields.email || '' });
    if (!errors.isValid) {
      self.setState({ errors: errors.errors });
    } else {
      UtilService.callApi(obj, function (err, data) {
        if (err) {
          OpenNotification({
            type: 'error',
            title: err.data.message,
          });
          return err;
        } else if (data && data.code === 'OK') {
          reqObj.username = '';
          self.setState({ fields: reqObj });
          OpenNotification({
            type: 'success',
            title: data.message,
          });
          setTimeout(() => {
            window.open('/', '_self');
          }, 900);
        }
      });
    }
  };

  componentDidMount() {
    window.addEventListener('click', (e) => {
      if (document.activeElement && document.activeElement.localName !== 'button') {
        notification.destroy();
      }
    });
  }

  validate() {
    let fieldName = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fieldName['username']) {
      formIsValid = false;
      errors['username'] = 'Please enter email ID';
      OpenNotification({
        type: 'error',
        title: 'Please enter email ID.',
      });
    }

    if (typeof fieldName['username'] !== 'undefined') {
      let lastAtPos = fieldName['username'].lastIndexOf('@');
      let lastDotPos = fieldName['username'].lastIndexOf('.');
      // let namePos = fieldName["username"].lastIndexOf(" ");

      if (
        !(
          (
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            fieldName['username'].indexOf('@@') === -1 &&
            lastDotPos > 2 &&
            fieldName['username'].length - lastDotPos > 2
          )
          // namePos
        )
      ) {
        formIsValid = false;
        errors['username'] = 'Please enter valid Email.';
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleOnChange = (field, e) => {
    let self = this;
    e.preventDefault();

    let errors = null;
    if (self.state.errors) {
      errors = Object.assign('', self.state.errors);
      delete errors['email'];
    }

    let fields = self.state.fields;
    fields[field] = e.target.value;
    self.setState({ fields, errors });
  };
  onBlur = (name, val) => {
    let self = this;
    let data = {
      [name]: val,
    };

    const errors = forgotPassword(data);
    if (!errors.isValid) {
      self.setState({ errors: errors.errors });
    }
  };

  render() {
    let fieldsName = this.state.fields;
    let { errors } = this.state;

    return (
      <>
        <div className="loginMainWrapper">
          <AuthPoster />
          <div className="LoginRightBlock align-middle">
            <div className="loginDetail">
              <div className="loginHead">
                <div className="loginLogo mb-20">
                  <Logo />
                </div>
                <h3>Forgot Password?</h3>
                <p>Enter your Email ID to Reset your password</p>
              </div>
              <div className="loginBox">
                <form onSubmit={this.sendEmail}>
                  <InputBlock
                    required
                    onChange={(e) => this.handleOnChange('email', e)}
                    onBlur={(e) => this.onBlur('email', e.target.value)}
                    hasError={errors !== null && errors['email'] ? errors['email'] : false}
                    value={fieldsName.email}
                    type="text"
                    label="Email"
                    placeholder="Enter your Email ID"
                    autoFocus
                  />

                  {/* <div className="form-control">
                <label htmlFor="Name" className="d-block">
                  <IntlMessages id="app.my-account.email" />

                  {fieldsName.username && fieldsName.username !== "" ? (
                    <span className="required" style={{ color: "green" }}>
                      *
                    </span>
                  ) : (
                    <span className="required">*</span>
                  )}
                </label>
                <input
                  className={this.state.errors["username"] ? "input-error" : ""}
                  onChange={this.handleOnChange.bind(this, "username")}
                  type="text"
                  id="username"
                  name="username"
                  value={fieldsName.username}
                  autoComplete="off"
                />
                <span style={{ color: "red" }}>{this.state.errors["username"]}</span>
              </div> */}

                  <div className="loginButton">
                    <button type="submit" onClick={this.sendEmail} className="commonButton">
                      Submit
                    </button>
                    <div className="registerLogLink">
                      Back To <a href="/">Login</a>
                    </div>
                  </div>
                  {/* <hr class="login-shapret"></hr> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ForgotPassword;
