import React from 'react';
import './staticContent.less';
import Heading from '../common/Heading';
import StaticHeader from '../StaticHeader';
import Header from '../Header';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';

const TermsOfUse = () => {
  return (
    <>
      {localStorage.getItem(`${LOCAL_STORAGE_VAR}-token`) !== null ? <Header /> : <StaticHeader />}
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading title="Terms & Conditions" className="staticMainTitle text-center mb-50" />

          <div className="staticContentWrapper">
            <Heading title="Terms and Conditions (T&C)" className="popupInnerTitle" />
            <div className="staticList">
              <ul>
                <li>
                  {/* Unique Diamax Pvt Ltd is the solitary proprietor of the website uniquegems.com.
                  The entire content of the website together with text, site design, logos and data
                  provided in the software, graphics and audios, Videos, icons, images etc. are
                  entirely owned by the company.{" "} */}
                  Please ensure that the following Terms & Conditions have been read and understood, prior to using our
                  website. It is important to review the below mentioned Terms & Condition prior to purchasing or
                  selecting stones on uniquegems.com.
                </li>
                {/* <li>
                  By registering on the website uniquegems.com you are granted permission to
                  browse, download and print the content for your private reference only. However,
                  you do not hold the right to change/modify/alter any content you have downloaded.
                  Registration does not grant any other right on you.{" "}
                </li>
                <li>
                  If you want to use any content of this website for commercial purposes, a prior
                  written permission needs to be obtained from the company. This document will
                  specify the scope and extent up to which the downloaded content may be used.
                </li>
                <li>
                  The purchased stones will be shipped to be delivered at your doorstep via courier
                  services such as Brinks, MalcaAmit, BVC Express, PS, or Fed EX on C&F basis{" "}
                </li>
                <li>First Come First Serve basis is used for online purchasing.</li>
                <li>
                  You are committed and bound to complete the sale once stones have been purchased.
                </li>
                <li>
                  The company will verify details once it receives an order from the client, and if
                  all conditions are met, only then will the order be processed.
                </li>
                <li>Courier agents will cover door to door insurance.</li>
                <li>
                  The company reserves the right to refuse shipments to certain international
                  destinations.
                </li> */}
                {/* <ul>
                  <p>
                    <b>Shipping and Handling Charges;</b>
                  </p>
                  <li>For Invoices below US$15000 = US$125</li>
                  <li>For Invoices above US$15000 = Free</li>
                </ul> */}
                {/* <li>
                  Shipping charges borne by us for invoices above US$15000 cover shipment from our
                  Mumbai office to our affiliate office in (Hong Kong, New York, Dubai, Antwerp &
                  Japan) & for remaining location to their nearest International Airport. Shipment
                  cost from the nearest International Airport to the buyer's residence will be borne
                  by the buyer.
                </li>
                <li>
                  For shipments to destinations in India, additional charges will apply in lieu of
                  local taxes, if any.
                </li> */}
                {/* <ul>
                  <p>
                    <b>Cancellation of orders</b>
                  </p>
                  <li>
                    Our company representative will reconfirm the order details once an order is
                    placed by you.
                  </li>
                  <li>
                    The order is deemed to have been completed the moment the stones are shipped.
                    Cancellation of the order thereafter is not possible.
                  </li>
                  <li>Exchanges and returns are not permitted.</li>
                  <li>
                    The order expires on the 5th working day of confirmation of order, if the
                    advance has not been received.
                  </li>
                </ul>
                <li>
                  Unique Diamax Pvt Ltd reserves right to modify/change/alter/delete/add to any of the
                  conditions listed above without prior notice.
                </li> */}
              </ul>
            </div>
          </div>
          <div className="staticContentWrapper">
            <Heading title="Terms of Service" className="popupInnerTitle" />
            <div className="staticList">
              <ul>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                  Unique Diamax Pvt Ltd reserves right to modify/change/alter/delete/add
                  to any of the conditions listed above without prior notice.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
