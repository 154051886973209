import React from 'react';
import Heading from '../common/Heading';
import { ListingCards } from '../common/DiamondListing/MobileComfirmStone';
import { emptyData } from '../DiamondList';

const NewArrivalBidMobile = (props) => {
  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="listingTopBlock width-100">
          <div className="d-flex justify-content-between align-items-center width-100">
            <Heading title={props.parent?.getTitle()} />
          </div>
          {props.parent?.getSelection()}
        </div>
      </div>
      {props.parent?.getBidBlock()}
      <div className="searchInnerResult">
        <div className="diamondListMobile">
          <ListingCards
            data={props.data}
            currentType={props.currentType}
            areAllChecked={props.areAllChecked}
            myBidList={props?.myBidList}
          />
          {emptyData(props.loading, props.data.length)}
        </div>
        {props.parent?.getAction()}
      </div>
      {props.parent?.getDrawer()}
    </div>
  );
};

export default NewArrivalBidMobile;
