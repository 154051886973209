import React, { Component } from 'react';
import Magnifier from 'react-magnifier';
import img from './../../assets/img/no_img.jpeg'

class MagnifierImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeKey: 'Image',
      showDetails: false,
      Path: '',
      content: '',
      zoom: 1,
      maginfierState: false,
      imageError: false
    };
  }

  render() {
    return (
      <div>
        {
          this.state.imageError ? (
            <img style={{ width: '70%', height: '70%' }} src={img} />
          ) : (
            <Magnifier
              className={this.props.className}
              onMouseMove={this.handlechange}
              src={this.props.image}
              zoomFactor={this.state.zoom}
              onWheel={this.handleWheelChange}
              onMouseEnter={() => {
                this.setState({ maginfierState: true });
              }}
              onError={() => {
                this.setState({ imageError: true });
              }}
              onMouseOut={() => {
                this.setState({ maginfierState: false });
              }}
            />
          )
        }

      </div>

    );
  }
}
export default MagnifierImage;
