import React, { useState } from 'react';
import LangDropdown from '../Header/LangDropdown';
import { Link } from 'react-router-dom';
import Logo from '../Header/Logo';
import './statisHeader.less';

const StaticHeader = () => {
  const [menu, SetMenus] = useState(false);
  const sidebarHandle = () => {
    SetMenus(true);
  };
  const drawerClose = () => {
    SetMenus(false);
  };
  const isMobile = window.innerWidth <= 767;
  return (
    <div className="staticHeaderWrapper d-flex j-space-between align-items-center">
      <div className="staticHeaderLogo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <div className={`staticMenus ${menu && 'active'}`}>
        {isMobile && <div className="sidebarOverLay" onClick={drawerClose}></div>}
        <ul className="d-flex">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/news">News & Event</Link>
          </li>
          <li>
            <Link to="/bidding-procedure">Bidding Procedure</Link>
          </li>
          <li>
            <Link to="/book-appointment">Take Appointment</Link>
          </li>
          <li>
            <Link to="/terms-condition">Terms</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
           {/* <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/">News & Event</Link>
          </li>
          <li>
            <Link to="/">Bidding Procedure</Link>
          </li>
          <li>
            <Link to="/">Take Appointment</Link>
          </li>
          <li>
            <Link to="/">Terms</Link>
          </li>
          <li>
            <Link to="/">Contact Us</Link>
          </li> */}
        </ul>
      </div>
      <div className="d-flex staticHeaderRight">
        <LangDropdown />
        <div className="headerIcon">
          <img className="defulticon" src={require('../../assets/svg/Header/user.svg')} alt="icon" />
          <img className="hoverIcon" src={require('../../assets/svg/Header/user-white.svg')} alt="icon" />
          {isMobile ? null : (
            <Link className="headerUserName" to="/">
              Login/Register
            </Link>
          )}
        </div>
      </div>
      {isMobile && (
        <div className="mobileMenu" onClick={sidebarHandle}>
          <img src={require('../../assets/svg/MobileHeader/menu.svg')} alt="menuIcon" />
        </div>
      )}
    </div>
  );
};
export default StaticHeader;
