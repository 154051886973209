import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Heading from '../../common/Heading';
import InputBlock from '../../common/InputBlock';
import UtilService from '../../../services/util';
import OpenNotification from '../../common/CommonButton/OpenNotification';
import _ from 'lodash';
import { notification } from 'antd';
import IntlMessages from '../../../util/IntlMessages';
import { RESET_PWD_BYUSER } from '../../../constants/apiConstant';
/* eslint jsx-a11y/anchor-is-valid: 0 */
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        currentPassword: '',
        newPassword: '',
        confPassword: '',
      },
      errors: {},
    };
  }

  updatePassword() {
    let self = this;
    let obj = {
      // url: "/auth/reset-password-by-user",
      // method: "post",
      ...RESET_PWD_BYUSER,
      request: self.state.formData,
    };
    if (this.handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        if (err && err.data) {
          return OpenNotification({
            type: 'error',
            title: err.data.message,
          });
        } else if (data.code === 'OK') {
          self.setState({ formData: {} });
          return OpenNotification({
            type: 'success',
            title: data.message,
          });
        }
      });
    }
  }

  handleInputChange(key, value) {
    let formData = this.state.formData;
    formData[key] = value;
    this.setState({ formData: formData });
  }
  handleValidation() {
    let self = this;
    let fields = self.state.formData;
    let errors = {};
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!fields['currentPassword']) {
      blankField.push('Current Password');
      formIsValid = false;
      errors['currentPassword'] = 'Please enter current password.';
    }

    if (!fields['newPassword']) {
      blankField.push('New Password');
      formIsValid = false;
      errors['newPassword'] = 'Please enter new password.';
    } else if (
      fields['newPassword'] &&
      // (!value.match(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/) || value.length > 15 || value.length < 6)
      fields['newPassword'].length < 3
    ) {
      invalidFields.push('Password');
      formIsValid = false;
      errors['newPassword'] = 'Password must be 3 digit long.';
    }

    if (!fields['confPassword']) {
      blankField.push('Confirm Password');
      formIsValid = false;
      errors['confPassword'] = 'Please enter confirm password';
    }
    if (
      fields &&
      fields['confPassword'] &&
      fields['confPassword'].length &&
      fields['confPassword'] !== fields.newPassword
    ) {
      invalidFields.push('Confirm Password');
      formIsValid = false;
      errors['confPassword'] = 'Please make sure your passwords match.';
    }
    if (blankField.length > 0) {
      let validateFields = blankField.map((f, index) => {
        return <span>{`${f}${index !== blankField.length - 1 ? ', ' : ''}`}</span>;
      });
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: validateFields,
      });
    }
    if (invalidFields.length > 0) {
      let validateFields = invalidFields.map((f, index) => {
        return <span>{`${f} ${index !== invalidFields.length - 1 ? ',' : ''}`}</span>;
      });
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: validateFields,
      });
    }

    self.setState({ errors: errors });
    return formIsValid;
  }

  handleCancel() {
    this.setState({
      formData: {
        currentPassword: '',
        newPassword: '',
        confPassword: '',
      },
      errors: {},
    });
    this.props.history.push('/account')
  }

  render() {
    const isMobile = window.innerWidth <= 767;
    let { formData, errors } = this.state;
    return (
      <div>
        <div className="profileHead">
          {isMobile && (
            <Heading title={<IntlMessages id="app.CurrentPassword" />} className="popupInnerTitle"></Heading>
          )}
        </div>
        <div className="profileDetailSpace">
          <IntlMessages id="app.CurrentPassword">
            {(placeholder) => (
              <InputBlock
                placeholder={placeholder}
                value={formData.currentPassword ? formData.currentPassword : ''}
                type="password"
                onChange={(e) => this.handleInputChange('currentPassword', e.target.value)}
                label={<IntlMessages id="app.CurrentPassword" />}
                hasError={errors['currentPassword'] ? true : false}
              />
            )}
          </IntlMessages>

          <IntlMessages id="app.NewPassword">
            {(placeholder) => (
              <InputBlock
                placeholder={placeholder}
                value={formData.newPassword ? formData.newPassword : ''}
                type="password"
                onChange={(e) => this.handleInputChange('newPassword', e.target.value)}
                label={<IntlMessages id="app.NewPassword" />}
                hasError={errors['newPassword'] ? true : false}
              />
            )}
          </IntlMessages>

          <IntlMessages id="app.ConfirmPassword">
            {(placeholder) => (
              <InputBlock
                placeholder={placeholder}
                value={formData.confPassword ? formData.confPassword : ''}
                type="password"
                onChange={(e) => this.handleInputChange('confPassword', e.target.value)}
                label={<IntlMessages id="app.ConfirmPassword" />}
                hasError={errors['confPassword'] ? true : false}
              />
            )}
          </IntlMessages>
        </div>
        <div className="botoomStickyBar diamondListingAction justify-content-center">
          <a className="bottomStickyButton" onClick={() => this.updatePassword()}>
            <IntlMessages id="app.ChangePassword" />
          </a>
          <a className="bottomStickyButton" onClick={() => this.handleCancel()}>
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
        {isMobile && (
          <div className="sideBarPopupButton">
            <a className="commonButton" onClick={() => this.updatePassword()}>
              <IntlMessages id="app.ChangePassword" />
            </a>
            <a className="commonButton" onClick={() => this.handleCancel()}>
              <IntlMessages id="app.Cancel" />
            </a>
          </div>
        )}
        {/* <div className="">
          <a className="commonButton" onClick={() => this.updatePassword()}>
            <IntlMessages id="app.ChangePassword" />
          </a>
          <a className="commonButton" onClick={() => this.handleCancel()}>
            <IntlMessages id="app.Cancel" />
          </a>
        </div> */}
      </div>
    );
  }
}
export default withRouter(ChangePassword);
