import React, { useEffect, useState } from 'react';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import {
  checkForMaster,
  cutDecimalPoints,
  SEARCH_ALL_CONST,
  setDateFormat,
  setTimeFormat,
} from '../../services/Commonfunction';
import { groupBy } from 'lodash';
import { withRouter } from 'react-router-dom';
import OpenNotification from '../../components/common/CommonButton/OpenNotification';
const SavedSearch = (props) => {
  const [saveDetail, setSaveDetail] = useState({});
  const [searchData, setSearchData] = useState({});
  useEffect(() => {
    setSaveDetail({ ...props.saveDetail });
    performArray(props.saveDetail);
  }, [props.saveDetail]);
  const performArray = (saveDetail) => {
    if (saveDetail && saveDetail.searchData) {
      let Master = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`));

      let tempArray = [];
      Object.keys(saveDetail.searchData).map((d, index) => {
        if (saveDetail.searchData[d] instanceof Array) {
          saveDetail.searchData[d] &&
            saveDetail.searchData[d].length !== 0 &&
            saveDetail.searchData[d].map((d2, indedx) => {
              let MasterCode = checkForMaster(d);
              if (MasterCode === 'CARAT') {
                if (saveDetail.searchData[d][0].crt) {
                  tempArray.push({
                    parentCode: MasterCode,
                    search: `${cutDecimalPoints(saveDetail?.searchData[d]?.[0]?.crt['>='])}-${cutDecimalPoints(
                      saveDetail.searchData[d][saveDetail.searchData[d].length - 1].crt['<='],
                    )}`,
                  });
                }
              } else {
                if (Master && MasterCode !== undefined && Master[MasterCode] && Master[MasterCode].length !== 0) {
                  Master[MasterCode].map((a) => {
                    if (d2 === a.id[0]) {
                      tempArray.push({
                        parentCode: MasterCode,
                        search: a.name,
                      });
                    }
                  });
                }
              }
            });
        }
      });
      if (tempArray && tempArray.length !== 0) {
        let groupArray = groupBy(tempArray, 'parentCode');

        setSearchData({ ...groupArray });
      }
    }
  };
  return (
    <>
      {searchData && Object.keys(searchData).length !== 0 ? (
        <div
          onClick={() => {
            if (props.save) {
              props.history.push('/diamond-list?' + props.saveId);
            }
          }}
          className="diamond_block"
        >
          <p className="d_time">
            <span style={{ fontWeight: 'bold' }}>{saveDetail.name ? saveDetail.name : '--'}</span>
          </p>

          <div className="diamond_block_data">
            {Object.keys(searchData).map((obKey) => {
              return (
                <p>
                  {obKey}:
                  {searchData[obKey].length === 1 ? (
                    <span>{searchData[obKey][0].search}</span>
                  ) : searchData[obKey].length > 1 ? (
                    <span>
                      {searchData[obKey][0].search} {obKey !== 'CARAT' && <>({`+${searchData[obKey].length - 1}`})</>}
                    </span>
                  ) : (
                    ''
                  )}
                </p>
              );
            })}
          </div>
          <p className="d_time">
            <span>{saveDetail.createdAt ? <> {setDateFormat(saveDetail.createdAt)} </> : '--'}</span>
            <span>{saveDetail.createdAt ? setTimeFormat(saveDetail.createdAt) : '--'}</span>
          </p>
        </div>
      ) : (
        <div
          className="diamond_block"
          onClick={() => {
            props.history.push('/diamond-search');
            OpenNotification({
              type: 'error',
              title: 'Please narrow down your search below 500 for search diamonds.',
            });
          }}
        >
          <p className="d_time">
            <span style={{ fontWeight: 'bold' }}>{saveDetail.name ? saveDetail.name : '--'}</span>
          </p>

          <div className="diamond_block_data">
            {SEARCH_ALL_CONST.map((variant) => {
              return (
                <p>
                  {variant}: <span>All</span>
                </p>
              );
            })}
          </div>
          <p className="d_time">
            <span>{saveDetail.createdAt ? <> {setDateFormat(saveDetail.createdAt)} </> : '--'}</span>
            <span>{saveDetail.createdAt ? setTimeFormat(saveDetail.createdAt) : '--'}</span>
          </p>
        </div>
      )}
    </>
  );
};
export default withRouter(SavedSearch);
