import React, { Component } from 'react';

class Logo extends Component {
  render() {
    return (
      <>
        <img
          width="256px"
          height="auto"
          style={{ objectFit: 'contain' }}
          src={require('../../assets/img/mainLogo.png')}
          alt="Unique Diamax Pvt Ltd"
        />
      </>
    );
  }
}

export default Logo;
