import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import './datepicker.less';

const Datepicker = (props) => {
  let groupClass = 'from-group datePicker';
  if (props.hasError) {
    groupClass = groupClass + ' error-message';
  }

  return (
    <div className={groupClass}>
      {props.label ? <label className="commonLabel">{props.label}</label> : null}
      <DatePicker
        dropdownClassName="datePickerZindex"
        format="ll"
        placeholder={props.placeholder ? props.placeholder : 'Select Date'}
        value={props.expiryDate ? moment(props.expiryDate) : undefined}
        onChange={props.handleChange}
        disabledDate={props.disabledDate}
      />
    </div>
  );
};
export default Datepicker;
