import React, { memo } from 'react';
import { getParams, LISTINGPAGES } from '../DiamondListFunctions';
import { formatNumber } from '../../common/DiamondListing/SelectStone';
import { STATUS, STATUS_COLOR } from '../../common/DiamondListing/Status';
import { LAB_LINKS, FILE_URLS } from '../../../constants/Common';
import CheckBox from '../../common/CheckBox';
import useRowSelect from '../../../util/useRowSelect';

const DiamondSearchGrid = (props) => {
  const { isRowChecked, toggleRowSelection } = useRowSelect(props.currentType);
  return (
    <div className="diamondGridView">
      {!props.loading &&
        props.data.length > 0 &&
        props.data.map((block) => {
          const isChecked = isRowChecked(block.id);
          return (
            <div key={`grid_${block.id}`} className="daimondGridViewItem">
              <div className="DiamondGridViewImg">
                <div className="diamondGrdiCheckbox">
                  <CheckBox checked={isChecked} onChange={() => toggleRowSelection(block, !isChecked)} />
                </div>
                {block.img && <img src={FILE_URLS.img.replace('***', block.vStnId)} alt="" />}
              </div>
              <div className="gridViewContent">
                <div className="gridLeftContent">
                  <h2>{block.shpNm}</h2>
                  <a href={`/${LISTINGPAGES.DETAIL}/${block.id}`} target="_blank" rel="noopener noreferrer">
                    {block.vStnId}
                  </a>
                  <p>{getParams(block).join(' ')}</p>
                </div>
                <div className="gridRightContent">
                  <a
                    onClick={() => {
                      if (block.lbNm && block.rptNo) {
                        window.open(LAB_LINKS[block.lbNm.toUpperCase()].replace('', block.rptNo));
                      }
                    }}
                  >
                    <span
                      className={
                        block.lbNm === 'GIA'
                          ? 'gia-cer-color'
                          : block.lbNm === 'IGI'
                          ? 'ige-cer-color'
                          : 'other-cer-color'
                      }
                    >
                      {block.lbNm}
                    </span>
                  </a>
                  <span>${formatNumber(Math.round(block.amt))}</span>
                  <span className="statusShow">
                    {STATUS[block.wSts]}
                    <span className={'statusIcon ' + STATUS_COLOR[block.wSts]}></span>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default memo(DiamondSearchGrid);
