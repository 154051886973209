import React from 'react';
import './salesPerson.less';
const SalesPerson = (props) => {
  //console.log("Sales", props);
  return (
    <>
      <div className="salesPeronInnerDetail">
        {props.email === '--' ? null : (
          <div className="salesPersonInnerItem">
            <div className="salesPersonIcon">
              <img src={require('../../../assets/svg/email.svg')} alt="icon" />
            </div>
            <div className="salespersonInnerDetail">{props.email}</div>
          </div>
        )}
        {props.phone === '--' ? null : (
          <div className="salesPersonInnerItem">
            <div className="salesPersonIcon">
              <img src={require('../../../assets/svg/phonecall.svg')} alt="icon" />
            </div>
            <div className="salespersonInnerDetail">{props.phone}</div>
          </div>
        )}
        <div className="salesPersonInnerItem">
          {props.phone === '--' ? null : (
            <div
              onClick={() => {
                window.open(`https://wa.me/${props.phone}?text=I need help with `, '_blank');
              }}
              className="salesPersonIcon mr-5"
            >
              <img src={require('../../../assets/svg/Dashboard/whatsapp.svg')} alt="icon" />
            </div>
          )}
          {props.skype === '--' ? null : (
            <div
              onClick={() => {
                window.open('https://web.skype.com/share?', '_blank');
              }}
              className="salesPersonIcon mr-5"
            >
              <img src={require('../../../assets/svg/Dashboard/skype.svg')} alt="icon" />
            </div>
          )}
          {/* {props.phone === "--" || !isMobile() ? null : (
            <div
              onClick={() => {
                window.open(`tel:${props.phone}`, "_blank");
              }}
              className="salesPersonIcon "
            >
              <img
                src={require("../../../assets/svg/Dashboard/chat.svg")}
                alt="icon"
              />
            </div>
          )} */}
          {props.phone === '--' ? null : (
            <div
              onClick={() => {
                window.open(`weixin://dl/chat?${props.phone}`, '_blank');
              }}
              className="salesPersonIcon "
            >
              <img src={require('../../../assets/svg/Dashboard/wechat.svg')} alt="icon" />
            </div>
          )}
          {/* <div className="salespersonInnerDetail">{props.phone}</div> */}
        </div>
      </div>
    </>
  );
};
export default SalesPerson;
