import React, { Component, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import BackButton from '../common/BackButton';
import Logo from '../common/Logo';
import UtilService from '../../services/util';
import LangDropdown from '../Header/LangDropdown';
import size from 'lodash/size';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import IntlMessages from '../../util/IntlMessages';
import { notification } from 'antd';
import OpenNotification from '../common/CommonButton/OpenNotification';
import { PWD_RESET } from '../../constants/apiConstant';
import AuthPoster from './AuthPoster';
import useStateWithCallback from '../../hooks/useStateWithCallback';
import InputBlock from '../common/InputBlock';
import { isEmpty } from 'lodash';

const ResetPassword = (props) => {
  const [formData, setFormData] = useStateWithCallback({
    confirmpassword: '',
    newPassword: '',
    errors: {},
  });

  const handleResetPassword = () => {
    if (handleValidation()) {
      let obj = {
        ...PWD_RESET,
        request: {
          token: props.match.params.token,
          newPassword: formData.newPassword,
        },
      };

      UtilService.callApi(obj, function (err, data) {
        if (data && data.code === 'OK') {
          props.history.push('/signin');
          OpenNotification({
            type: 'success',
            title: 'Password is reset successfully.',
          });
        } else {
          OpenNotification({
            type: 'error',
            title: data.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (document.activeElement && document.activeElement.localName !== 'button') {
        notification.destroy();
      }
    });
  }, []);

  const handleValidation = () => {
    let errors = formData.errors;
    let formIsValid = true;
    let blankField = [];
    let invalidFields = [];

    if (!formData['newPassword'] || isEmpty(formData['newPassword'])) {
      blankField.push('New Password');
      formIsValid = false;
      errors['newPassword'] = true;
    }
    if (!formData['confirmpassword'] || isEmpty(formData['confirmpassword'])) {
      blankField.push('Confirm password');
      formIsValid = false;
      errors['confirmpassword'] = true;
    } else if (formData['confirmpassword'] !== formData['newPassword']) {
      invalidFields.push('Passwords do not match');
      formIsValid = false;
    }

    if (blankField.length > 0) {
      let arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      let arr = invalidFields;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    setFormData({ ...formData, errors: errors });
    return formIsValid;
  };

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  return (
    <>
      {/* <div className="loginMainBlocks loginFormBlock">
          <div className="loginWrapper">
            <div className="loginLeft">
              <div className="pTrackLogo">
                <div className="text-center mb-20">
                </div>
              </div>
              <div className="pForm">
                <div className="loginBlocksMain">
                  <div className="login-block">
                    <div className="page-til d-flex align-items-center">
                      <div>
                        <h3>
                          <IntlMessages id="app.resetpassword" />
                        </h3>
                      </div>
                    </div>
                    <div className="loginPage">
                      <div className="form-control">
                        <label htmlFor="newPassword" className="d-block">
                          <IntlMessages id="app.newpassword" />

                          {passwordChange.newPassword === "" ? (
                            <span className="required">*</span>
                          ) : (
                            <span
                              className="required"
                              style={{ color: "green" }}
                            >
                              *
                            </span>
                          )}
                        </label>
                        <input
                          className={
                            this.state.errors["newPassword"]
                              ? "input-error"
                              : ""
                          }
                          onChange={this.handleOnChange}
                          type="password"
                          id="newPassword"
                          name="newPassword"
                          value={this.state.newPassword}
                          onInput={(e) => {
                            let t = e.target.value;
                            let err = clone(this.state.errors);
                            let { passwordChange } = this.state;
                            if (t.length < 3) {
                              err["newPassword"] =
                                "Password must be 3 digit long.";
                            } else if (passwordChange.confirmpassword) {
                              passwordChange.confirmpassword = "";
                              err["confirmpassword"] = undefined;
                            } else {
                              err["newPassword"] = undefined;
                            }
                            this.setState({ errors: err, passwordChange });
                          }}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["newPassword"]}
                        </span>
                      </div>
                      <div className="form-control">
                        <label htmlFor="confirmpassword" className="d-block">
                          <IntlMessages id="app.confirmpassword" />

                          {passwordChange.confirmpassword === "" ? (
                            <span className="required">*</span>
                          ) : (
                            <span
                              className="required"
                              style={{ color: "green" }}
                            >
                              *
                            </span>
                          )}
                        </label>
                        <input
                          className={
                            this.state.errors["confirmpassword"]
                              ? "input-error"
                              : ""
                          }
                          onChange={this.handleOnChange}
                          type="password"
                          id="confirmpassword"
                          name="confirmpassword"
                          value={passwordChange.confirmpassword}
                          onInput={(e) => {
                            let t = e.target.value;
                            let err = clone(this.state.errors);
                            if (
                              !isEqual(
                                this.state.passwordChange.newPassword,
                                t
                              )
                            ) {
                              err["confirmpassword"] =
                                "Please make sure your passwords match.";
                            } else {
                              err["confirmpassword"] = undefined;
                            }
                            this.setState({ errors: err });
                          }}
                        />
                        <span style={{ color: "red" }}>
                          {this.state.errors["confirmpassword"]}
                        </span>
                      </div>
                      <div className="form-control">
                        <button
                          type="submit"
                          className="button primary-fill width-full"
                          onClick={this.handleResetPassword}
                        >
                          <IntlMessages id="app.resetpasswordbutton" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      <div className="loginMainWrapper">
        <AuthPoster />
        <div className="LoginRightBlock">
          <div className="loginDetail">
            <div className="loginHead">
              <div className="loginLogo">
                <Logo />
              </div>
              <h3>
                <IntlMessages id="app.resetpassword" />
              </h3>
              <p>Enter password and confirm password and then login to continue using the website.</p>
            </div>
            <div className="loginBox">
              <InputBlock
                onChange={(e) => handleChange('newPassword', e.target.value)}
                label={<IntlMessages id="app.newpassword" />}
                type="password"
                id="newPassword"
                name="newPassword"
                placeholder="New Password"
                value={formData.newPassword}
                hasError={formData.errors['newPassword']}
              />
              <InputBlock
                onChange={(e) => handleChange('confirmpassword', e.target.value)}
                label={<IntlMessages id="app.confirmpassword" />}
                type="password"
                id="confirmpassword"
                name="confirmpassword"
                placeholder="Confirm password"
                value={formData.confirmpassword}
                hasError={formData.errors['confirmpassword']}
              />
              <div className="loginButton">
                <button type="submit" className="commonButton resetPass" onClick={handleResetPassword}>
                  <IntlMessages id="app.resetpasswordbutton" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ResetPassword);
