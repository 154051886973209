import React from 'react';
import StaticHeader from '../StaticHeader';
import '../TermsOfUse/staticContent.less';
import { Link } from 'react-router-dom';
import Heading from '../common/Heading';
import './career.less';

const CareerItem = (props) => {
  return (
    <Link className="careerBoxItems" to="/career-detail">
      <div className="careerImgBlock">
        <img src={props.image} alt="" />
      </div>
      <div className="careerDetailBlock">
        <h2>{props.title}</h2>
        <div className="detail">
          <span className="careerdetailLabel">location:</span>
          <span className="careerDetailValue">Indore</span>
        </div>
        <div className="detail">
          <span className="careerdetailLabel">Salary:</span>
          <span className="careerDetailValue">{props.salary}</span>
          <span className="careerDetailValue">{props.salaryCount}</span>
        </div>
      </div>
    </Link>
  );
};

const Career = () => {
  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading title="Career" className="staticMainTitle text-center mb-50" />
          <div className="careerBoxWrapepr d-flex flex-wrap">
            <CareerItem
              title="SEO/SMO Executive"
              salaryCount="(Between Rs. 15000-35000)"
              salary="As per Company Standards"
              image={require('../../assets/img/career/1.jpg')}
            />
            <CareerItem
              title="UX/Front End Designer & Creative Web UI Graphic Designer"
              salaryCount="(Between Rs. 15000-35000)"
              salary="As per Company Standards"
              image={require('../../assets/img/career/2.jpg')}
            />
            <CareerItem
              title="PHp Developer (Codeigniter/Laravel)"
              salary="As per Company Standards"
              salaryCount="(Between Rs. 25000-45000)"
              image={require('../../assets/img/career/3.jpg')}
            />
            <CareerItem
              title="Senior Accuntant(USA Night Shift)"
              salary="Not Define"
              image={require('../../assets/img/career/4.jpg')}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Career;
