import React, { memo, useEffect, useState, useMemo } from 'react';
import Heading from '../Heading';
import MobileViewListCard from '../../DiamondList/MobileViewListCard';
import { Drawer } from 'antd';
import useRowSelect from '../../../util/useRowSelect';
import { HeadingCalc } from './ConfirmStonePopup';
import find from 'lodash/find';
import IntlMessages from '../../../util/IntlMessages';
import { calculate } from './SelectStone';
import { useSelector } from 'react-redux';

/* eslint jsx-a11y/anchor-is-valid: 0 */

export const ListingCards = memo(
  ({
    data,
    currentType,
    finalCalc = false,
    bidPopup = false,
    quote = false,
    quoteClick,
    checked = false,
    bid = false,
    note = false,
    watchpopup = false,
    quotePage = false,
    quotePopUp = false,
    myBidList = false,
    areAllChecked = false,
    bidCartPopup,
    ...props
  }) => {
    const { isRowChecked, toggleRowSelection, toggleAllRowSelection } = useRowSelect(currentType);
    const checkedRow = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
    const newPricedRows = checkedRow; //.map(x => newDiamondPrice(x, 'charge' + fm))
    const summation = useMemo(() => calculate(newPricedRows, props?.parent?.termCal), [checkedRow, props?.parent?.termCal]);
    const sysDis_with_positive = summation.sysDis_with_positive;
    const finalValue = parseFloat(summation.final_net_value).toFixed(2);
    const showDiscAmount = parseFloat(Math.abs((finalValue * showDis) / 100).toFixed(2));
    const showDis = props?.showDis?.data;
    const addDis = parseFloat(summation.addDis).toFixed(2);

    const getTotalNetAmount = (data) => {
      let totalNetAmount = 0;
      if (data && data?.length > 0) {
        const res = data?.forEach((cell) => {
          const amnt = cell.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          totalNetAmount += netAmountAfterTermDisc;
          return;
        });
      }
      return totalNetAmount;
    };
    const getNetDiscAmountPriceCt = (x) => {
      const amnt = x.amt;
      const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
      const amntPer = (amnt * sysDis_with_positive) / 100;
      const amntafterSysDis = amnt - amntPer;
      const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

      const netAmountAfterTermDisc =
        termDisFromAmtafterSyaDis < 0
          ? amntafterSysDis + termDisFromAmtafterSyaDis
          : amntafterSysDis - termDisFromAmtafterSyaDis;

      const promDisc =
        props.promotionDis?.data !== 0
          ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
          : props.showDis?.data !== 0
          ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
          : null;

      const totalNetAmount = getTotalNetAmount(data);
      let netAmount;
      if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
        if (showDiscAmount > props.showDis?.maxAmt) {
          if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
            netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
          } else {
            const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
            const updatedNetAmount = netAmountAfterTermDisc - disc;
            netAmount = updatedNetAmount;
          }
        } else {
          netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
        }
      } else if (props.promotionDis?.data !== 0) {
        if (promDisc > props.promotionDis?.maxAmt) {
          if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
            netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
          } else {
            const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
            const updatedNetAmount = netAmountAfterTermDisc - disc;
            netAmount = updatedNetAmount;
          }
        } else {
          netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
        }
      } else {
        netAmount = netAmountAfterTermDisc;
      }
      const netPriceperCarat = netAmount / x?.crt;
      const netPriceCrtRap = netPriceperCarat / x?.rap;
      const netDiscount = netPriceCrtRap > 1 ? -(1 - netPriceCrtRap) * 100 : (1 - netPriceCrtRap) * 100;

      const netDis = isNaN(netDiscount)
        ? '0.00'
        : `${netPriceCrtRap > 1 ? '+' : '-'}${parseFloat(netDiscount).toFixed(2)}`;
      const netPriceCt = isNaN(netPriceperCarat) ? '0.00' : parseFloat(netPriceperCarat).toFixed(2);
      const finalNetAmount = isNaN(netAmount) ? '0.00' : parseFloat(netAmount).toFixed(2);

      return { netDis, netPriceCt, netAmount: finalNetAmount };
    };

    return (
      <>
        {data?.map((x) => {
          const isChecked = !checked ? isRowChecked(x.id) : find(checked, { id: x.id }) ? true : false;
          const calcNetData = getNetDiscAmountPriceCt(x);
          x['netDis'] = calcNetData?.netDis;
          x['netPriceCt'] = calcNetData?.netPriceCt;
          x['netAmount'] = calcNetData?.netAmount;

          return (
            <MobileViewListCard
              quote={quote}
              quotePage={quotePage}
              note={note}
              watchpopup={watchpopup}
              bidCartPopup={bidCartPopup}
              bidPopup={bidPopup}
              bid={bid}
              finalCalc={finalCalc}
              quoteClick={quoteClick}
              data={x}
              quotePopUp={quotePopUp}
              onClick={() => (areAllChecked ? ' ' : toggleRowSelection(x, !isChecked))}
              activeClass={isChecked ? 'active' : ''}
              myBidList={myBidList}
              row={data}
              areAllChecked={areAllChecked}
              value={() => toggleAllRowSelection(data)}
            />
          );
        })}
      </>
    );
  },
);

const MobileConfirmStone = (props) => {
  const { toggleRowSelection, isRowChecked } = useRowSelect(props.currentType);
  const [menu, showMenu] = useState(false);
  useEffect(() => {
    props.data.map((row) => {
      toggleRowSelection(row, true);
      return true;
    });
  }, []);

  return (
    <>
      <div className="offerWrapper">
        <div className="d-flex align-items-center justify-space-between offerTopBlock">
          <div className="d-flex align-items-center">
            <Heading className="popupInnerTitle" title={<IntlMessages id="app.confirmStone" />}></Heading>
          </div>
          {HeadingCalc({ display: 'flex', width: '48%' }, props.VALUES)}
        </div>
        <div className="searchInnerResult tabInnerTable">
          <div className="diamondListMobile mobileOfferScroll">
            <ListingCards data={props.data} currentType={props.currentType} />
          </div>
          <div className="d-flex justify-content-between offerBottomBox flex-wrap"></div>
        </div>
        <div className="sideBarPopupButton">
          <a
            className="commonButton"
            onClick={() => {
              if (props.parent.checkCheck()) showMenu(true);
            }}
          >
            <IntlMessages id="app.confirmStone" />
          </a>
        </div>
      </div>
      {menu && (
        <Drawer
          title={false}
          onClose={() => showMenu(false)}
          visible={true}
          wrapClassName="diamondListinSidebar smallSidebarMobilePopup"
        >
          <div>
            <div className="offerInputBox">
              {props.parent.getcompany()}
              {props.parent.getinvoicedate()}
              {props.parent.gettextarea()}
            </div>
            <div className="sideBarPopupButton">
              <a className="commonButton" onClick={props.parent.submit}>
                Confirm
              </a>
              <a className="commonOutline" onClick={props.parent.cancel}>
                Cancel
              </a>
            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default memo(MobileConfirmStone);
