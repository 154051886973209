import React, { Component } from 'react';
import TwentyTwenty from 'react-twentytwenty';
import { Slider } from 'antd';
import './xray.less';
import _ from 'lodash';
import { DIAMOND_FTC } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import { MEDIA_URL } from '../../constants/Common';
import axios from 'axios';

class XRAY extends Component {
  constructor(props) {
    super(props);
    this.state = {
      xRayData: {},
      opacity: 1,
      xmlData: [],
      selectedInc: {},
    };
  }
  componentDidMount = () => {
    let id = _.last(_.split(window.location.pathname, '/'));
    let self = this;
    let obj = {
      filter: {
        vStnId: id,
      },
    };

    let objData = {
      ...DIAMOND_FTC,
      request: obj,
    };

    UtilService.callApi(objData, function (err, data) {
      if (err) throw err;
      else if (data && data.code === 'OK') {
        self.xRayFile(data.data.rptNo);
        self.setState({ xRayData: data.data });
      }
    });
  };

  xRayFile = (rptNo) => {
    let request = new XMLHttpRequest();
    request.open('GET', `http://s3.ap-south-1.amazonaws.com/finestargroup/XML_XRAY/${rptNo}.xml`, false);
    request.send();
    let xml = request.responseXML;
    let htmlTag = xml.getElementsByTagName('Point');
    let arr = [];

    for (let i = 0; i < htmlTag.length; i++) {
      arr.push({
        Point: htmlTag[i].attributes['Point'].value,
        Type: htmlTag[i].attributes['Type'].value,
        Name: htmlTag[i].attributes['Name'].value,
      });
    }
    this.setState({ xmlData: arr });
  };
  render() {
    let { xRayData, xmlData } = this.state;
    let xmlGrp = _.groupBy(xmlData, 'Type');
    let xAxis = this.state.selectedInc && parseInt(_.head(_.split(this.state.selectedInc.Point, ',')));
    let yAxis = this.state.selectedInc && parseInt(_.last(_.split(this.state.selectedInc.Point, ',')));

    return (
      <>
        <div className="xrayLogoHead">
          <img src={require('../../assets/img/Xray/fnlogo.png')} />
        </div>
        <div className="xrayBoxWrapper">
          <div className="xrayWrapperTop d-flex">
            <div className="xRayWraperLeft">
              <div className="xRayBoxImage d-flex">
                <div className="xRayImageBox">
                  <TwentyTwenty
                    slider={<div className="sliderCursor" />}
                    left={
                      <>
                        <img
                          className="opacityhightImage"
                          style={{ opacity: this.state.opacity }}
                          src={`${MEDIA_URL}/Real_XRAY/${xRayData.rptNo}.png`}
                        />
                        <img className="opacitylowImage" src={`${MEDIA_URL}/Plotting_XRAY/${xRayData.rptNo}.png`} />
                      </>
                    }
                    right={<img src={`${MEDIA_URL}/Plotting_XRAY/${xRayData.rptNo}.png`} />}
                  />
                </div>
                {!_.isEmpty(this.state.selectedInc) && (
                  <div
                    className="cursorpointerBlock"
                    style={{
                      top: yAxis - 37,
                      right: 540 - xAxis,
                    }}
                  >
                    <span>{this.state.selectedInc && this.state.selectedInc.Name}</span>
                    <div className="pointerItem">
                      <div className="pointerMarker"></div>
                    </div>
                  </div>
                )}

                <div className="xraySlider">
                  <Slider
                    onChange={(sl) => this.setState({ opacity: sl / 100 })}
                    vertical
                    value={this.state.opacity * 100}
                  />
                </div>
              </div>
              <div className="xRayBoxDetail">
                <div className="xRayBoxSelectOption">
                  {xmlGrp && xmlGrp['Inclusion'] && (
                    <div className="xRayBoxItem">
                      <h2>Inclusion</h2>
                      <ul>
                        {xmlGrp['Inclusion'].map((i) => (
                          <li
                            className={this.state.selectedInc === i && 'active'}
                            onClick={() => this.setState({ selectedInc: i })}
                          >
                            {i.Name} - Medium Side Black Inclusion
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {xmlGrp && xmlGrp['Key To symbol'] && (
                    <div className="xRayBoxItem">
                      <h2>Key To Symbols (GIA)</h2>
                      <ul>
                        {xmlGrp['Key To symbol'].map((i) => (
                          <li
                            className={this.state.selectedInc === i && 'active'}
                            onClick={() => this.setState({ selectedInc: i })}
                          >
                            {i.Name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {xmlGrp && xmlGrp['Open'] && (
                    <div className="xRayBoxItem">
                      <h2>Open</h2>
                      <ul>
                        {xmlGrp['Open'].map((i) => (
                          <li
                            className={this.state.selectedInc === i && 'active'}
                            onClick={() => this.setState({ selectedInc: i })}
                          >
                            {i.Name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="xRayWrapperRight">
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle">Diamond Details</h2>
                <div className="diamondDetailMoreHalf">
                  <ul>
                    <li>
                      <span>Packet No</span>
                      <span>{xRayData.vStnId}</span>
                    </li>
                    <li>
                      <span>Report No</span>
                      <span>{xRayData.rptNo}</span>
                    </li>
                    <li>
                      <span>Lab</span>
                      <span>{xRayData.lbNm}</span>
                    </li>
                    <li>
                      <span>Rap.($)</span>
                      <span>{xRayData.rap}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle">Diamond Grading</h2>
                <div className="diamondDetailMoreHalf">
                  <ul>
                    <li>
                      <span>Shape </span>
                      <span>{xRayData.shpNm}</span>
                    </li>
                    <li>
                      <span>Carat </span>
                      <span>{xRayData.crt}</span>
                    </li>
                    <li>
                      <span>Color</span>
                      <span>{xRayData.colNm}</span>
                    </li>
                    <li>
                      <span>Clarity</span>
                      <span>{xRayData.clrNm}</span>
                    </li>
                    <li>
                      <span>Cut</span>
                      <span>{xRayData.cutNm}</span>
                    </li>

                    <li>
                      <span>Polish</span>
                      <span>{xRayData.polNm}</span>
                    </li>
                    <li>
                      <span>Fluorescence </span>
                      <span>{xRayData.fluNm}</span>
                    </li>
                    <li>
                      <span>Symmetry </span>
                      <span>{xRayData.symNm}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle">Measurements</h2>
                <div className="diamondDetailMoreHalf">
                  <ul>
                    <li>
                      <span>Shade </span>
                      <span>{xRayData.shdNm}</span>
                    </li>
                    <li>
                      <span>Table % </span>
                      <span>{xRayData.tblPer}</span>
                    </li>
                    <li>
                      <span>Depth %</span>
                      <span>{xRayData.depPer}</span>
                    </li>
                    <li>
                      <span>Length</span>
                      <span>{xRayData.length}</span>
                    </li>
                    <li>
                      <span>Width</span>
                      <span>{xRayData.width}</span>
                    </li>
                    <li>
                      <span>Depth</span>
                      <span>{xRayData.depPer}</span>
                    </li>
                    <li>
                      <span>Crown Angle </span>
                      <span>{xRayData.cAng}</span>
                    </li>
                    <li>
                      <span>Crown Height </span>
                      <span>{xRayData.cHgt}</span>
                    </li>
                    <li>
                      <span>Pav Angle</span>
                      <span>{xRayData.pAng}</span>
                    </li>
                    <li>
                      <span>Pav Height</span>
                      <span>{xRayData.pHgt}</span>
                    </li>
                    <li>
                      <span>Girdle</span>
                      <span>{xRayData.girdleStr}</span>
                    </li>
                    <li>
                      <span>Culet</span>
                      <span>{xRayData.cultNm}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="xrayDetailInnerItem">
                <h2 className="detailSmallTitle">Inclusion Details</h2>
                <div className="diamondDetailMoreHalf">
                  <ul>
                    <li>
                      <span>Center Natts</span>
                      <span>{xRayData.blkTblNm}</span>
                    </li>
                    <li>
                      <span>Side Natts</span>
                      <span>{xRayData.blkSdNm}</span>
                    </li>
                    <li>
                      <span>Center White</span>
                      <span>{xRayData.wTblNm}</span>
                    </li>
                    <li>
                      <span>Side White</span>
                      <span>{xRayData.wSdNm}</span>
                    </li>
                    <li>
                      <span>Laser Ins</span>
                      <span>{xRayData.lsrInc}</span>
                    </li>

                    <li>
                      <span>Heart & Arrow</span>
                      <span>{xRayData.hANm}</span>
                    </li>
                    <li>
                      <span>Table Open </span>
                      <span>{xRayData.opTblNm}</span>
                    </li>
                    <li>
                      <span>Pavillion Open </span>
                      <span>{xRayData.opPavNm}</span>
                    </li>
                    <li>
                      <span>Crown Open </span>
                      <span>{xRayData.opCrwnNm}</span>
                    </li>
                    <li>
                      <span>Eye Clean </span>
                      <span>{xRayData.eClnNm}</span>
                    </li>
                    <li>
                      <span>Brilliancy </span>
                      <span>{xRayData.brlncyNm}</span>
                    </li>
                    <li>
                      <span>Ratio </span>
                      <span>{xRayData.ratio}</span>
                    </li>
                    <li className="fullWidth">
                      <span>Key To symbol </span>
                      <span>{xRayData.kToSStr}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default XRAY;
