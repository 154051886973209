import _ from 'lodash';
import UtilService from '../services/util';
import OpenNotification from '../components/common/CommonButton/OpenNotification';
import {
  SEARCH_DIAMOND_LIST,
  SEARCH_LIST,
  CREATE_SAVED_SEARCH,
  CREATE_MATCH_PAIR,
  SELECT_SEARCH,
} from '../constants/apiConstant';
import { LISTINGPAGES } from '../components/DiamondList/DiamondListFunctions';
import { isEmpty, includes, split, concat } from 'lodash';
import { BidService } from 'services/BidService';
import { isArray } from 'util/utils';

export const SEARCH_PAGES = {
  SEARCH: '/diamond-search',
  MATCH_PAIR: '/search-match-pair',
  BIT_IT: '/search-bid-diamond',
};

export const handleSearch = async (filter, cb) => {
  const path = `${split(window.location.pathname, '/')[1]}`;

  let apiObj = { ...filter };
  if (LISTINGPAGES.SEARCH_DIAMOND === path) {
    apiObj = { ...apiObj, wSts: BidService.bid?.sub_types?.allowDiamondFilterWSts };
  }

  if (!isEmpty(filter.isFm)) {
    apiObj = { ...apiObj, lbNm: 'FM' };
  }

  if (includes(filter.wSts, 'B')) {
    apiObj = { ...apiObj, prcStg: undefined };
  }

  _.each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = val;
  });

  const request = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = { ...SEARCH_DIAMOND_LIST, request };

  return UtilService.callApi(objData, (err, res) => {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      }
    } else if (res && res.code === 'OK') {
      if (res.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: res.data[0].filter.id });
      } else {
        cb({ NOSTONE: '', id: res.data[0].filter.id });
      }
    }
  });
};

export const handleMultiSearch = (filters, cb) => {
  const masterApiObj = [];
  filters.map((filter) => {
    let apiObj = {
      ...filter,
    };

    if (!isEmpty(filter.isFm)) {
      apiObj = {
        ...apiObj,
        lbNm: 'FM',
      };
    }

    if (includes(filter.wSts, 'B')) {
      delete apiObj['prcStg'];
      apiObj = {
        ...apiObj,
      };
    }

    _.each(apiObj, (val, key) => {
      if (_.isArray(val) && val.length === 0) delete apiObj[key];
      else if (_.isArray(val)) apiObj[key] = val;
    });
    masterApiObj.push(apiObj);
  });

  const obj = {
    filters: masterApiObj,
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      }
    } else if (data && data.code === 'OK') {
      const ids = [];
      data.data.map((d) => ids.push(d.filter.id));
      cb({ ids });
    }
  });
};

export const getSearchList = (cb) => {
  const obj = {
    type: 2,
    isAppendMasters: true,
  };
  const api = SEARCH_LIST.url;
  const objData = {
    method: SEARCH_LIST.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data.list);
    }
  });
};

export const handleSavedSearch = (filter, title, id, cb) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    //else if (_.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === '') {
    return OpenNotification({
      type: 'error',
      title: 'Please enter search title.',
    });
  }

  const obj = {
    filter: apiObj,
    name: title,
    searchType: 2,
    sort: [{ createdAt: 'DESC' }],
    id: id,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: 'error',
        title: err.data.message || 'Something went wrong.',
      });
    } else if (data && data.code === 'OK') {
      cb(data.data.id);
    }
  });
};

export const handleSelectSearch = (searchId, cb) => {
  const obj = {
    id: searchId,
    isAppendMasters: true,
  };
  const api = SELECT_SEARCH.url;
  const objData = {
    method: SELECT_SEARCH.method,
    url: api,
    request: obj,
  };
  UtilService.callApi(objData, function (err, data) {
    if (err) throw err;
    if (data && data.code === 'OK') {
      cb(data.data.list[0].searchData, data.data.list[0].name, data.data.list[0].type);
    }
  });
};

export const getCount = (filter, cb) => {
  const PATH = `/${split(window.location.pathname, '/')[1]}`;
  const searchId = _.last(_.split(window.location.pathname, '/'));
  let apiObj = {
    ...filter,
    //wSts: ["A", "B", "H"]
  };
  const path = `${split(window.location.pathname, '/')[1]}`;

  if (LISTINGPAGES.SEARCH_DIAMOND === path) {
    apiObj = { ...apiObj, wSts: BidService.bid?.sub_types?.allowDiamondFilterWSts };
  }

  if (!isEmpty(filter.isFm)) {
    apiObj = { ...apiObj, lbNm: 'FM' };
  }

  if (includes(filter.wSts, 'B')) {
    delete apiObj['prcStg'];
    apiObj = { ...apiObj, prcStg: undefined };
  }

  _.each(apiObj, (val, key) => {
    if (isArray(val) && val.length === 0) delete apiObj[key];
    else if (isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (PATH === SEARCH_PAGES.SEARCH) {
    const { excludeFilter, ...rest } = apiObj;
    apiObj = JSON.stringify(filter.excludeFilter) === '{}' ? { ...rest } : apiObj;
  }

  const data = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [apiObj],
  };

  const obj = {
    isNotReturnTotal: true,
    isReturnCountOnly: true,
    isSkipSave: true,
    filters: [apiObj],
  };

  return UtilService.callApi(
    {
      ...SEARCH_DIAMOND_LIST,
      request: searchId === LISTINGPAGES.SEARCHMATCHPAIR ? data : obj,
      showLoader: false,
    },
    (err, res) => {
      if (!err && res.code === 'OK') {
        cb(res.data[0].count);
      }
    },
  );
};

export const handleAddDemand = (filter, title, date, cb) => {
  const apiObj = {
    ...filter,
  };
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    //else if (_.isEmpty(val)) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = _.uniq(val);
  });

  if (_.trim(title) === '') {
    return OpenNotification({
      type: 'error',
      title: 'Please enter demand title.',
    });
  }

  if (!date) {
    return OpenNotification({
      type: 'error',
      title: 'Please select date for adding demand.',
    });
  }
  const obj = {
    filter: [apiObj],
    name: title,
    searchType: 3,
    expiryDate: date,
  };

  const api = CREATE_SAVED_SEARCH.url;
  const objData = {
    method: CREATE_SAVED_SEARCH.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      OpenNotification({
        type: 'error',
        title: err.data.message || 'Something went wrong.',
      });
    } else if (data && data.code === 'OK') {
      cb(true);
      OpenNotification({
        type: 'success',
        title: 'Your demand is added successfully.',
      });
    }
  });
};

export const fetchUpcoming = async (filter, cb) => {
  const apiObj = { ...filter };
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    isNotReturnTotal: true,
    isReturnCountOnly: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      }
    } else if (data && data.code === 'OK') {
      if (data.data[0].count === 0) {
        cb({ NOSTONE: 'NOSTONE', id: data.data[0].filter.id });
      } else {
        cb({ NOSTONE: '', id: data.data[0].filter.id });
      }
    }
  });
};

export const handleCoupleStone = (cb, filter) => {
  const apiObj = filter;
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });
  const obj = {
    filter: apiObj,
    // isPredefinedPair: true,
    page: 1,
    limit: 1000,
    // isSkipSave: true,
  };
  const api = CREATE_MATCH_PAIR.url;
  const objData = {
    method: CREATE_MATCH_PAIR.method,
    url: api,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err && err.data && err.data.message) {
      return OpenNotification({
        type: 'error',
        title: err.data.message,
      });
    } else if (data && data.code === 'OK') {
      cb(data.data.filter.id);
    }
  });
};

export const specialDiamondList = async (filter, cb) => {
  const apiObj = { ...filter };
  _.each(apiObj, (val, key) => {
    if (_.isArray(val) && val.length === 0) delete apiObj[key];
    else if (_.isArray(val)) apiObj[key] = val;
  });

  const obj = {
    filters: [apiObj],
    //isUpcoming: true,
    limit: 250,
    page: 1,
    sort: [],
    isSkipSave: true,
  };

  const objData = {
    ...SEARCH_DIAMOND_LIST,
    request: obj,
  };

  UtilService.callApi(objData, function (err, data) {
    if (err) {
      if (err.data && err.data.message) {
        return;
      }
    } else if (data && data.code === 'OK') {
      if (data.data[0].count === 0) {
        cb({ data: data.data[0].count });
      } else {
        cb({ data: data.data[0].diamonds });
      }
    }
  });
};
