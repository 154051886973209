import React from 'react';
import '../TermsOfUse/staticContent.less';
import { connect } from 'react-redux';
import StaticHeader from '../StaticHeader';
import { withRouter } from 'react-router-dom';
import Heading from '../common/Heading';

let gridHeight = 0;
export function setGridHeightFn() {
  let allContainers = {};
  let totalHeight = window.innerHeight - 25;
  allContainers.headerHeight = document.getElementsByClassName('headerTopMenu')[0];
  allContainers.covidStrip = document.getElementsByClassName('covidStrip')[0];
  allContainers.HeaderStripBlock = document.getElementsByClassName('HeaderStripBlock ')[0];
  Object.keys(allContainers).map((key) => {
    if (allContainers[key] && allContainers[key].offsetHeight) {
      totalHeight = totalHeight - allContainers[key].offsetHeight;
    }
    return true;
  });
  return totalHeight;
}
const BiddingProcedure = () => {
  gridHeight = setGridHeightFn();
  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain" style={{ minHeight: gridHeight, overflow: 'auto' }}>
        <div className="statiContentBox">
          <Heading title="Bidding Procedure" className="staticMainTitle text-center mb-50" />
          <div className="staticContentWrapper">
            {/* <Heading title="Terms of Service" className="popupInnerTitle"/> */}
            <div className="staticList">
              <ul>
                <li>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
              </ul>
            </div>
          </div>
          <div className="staticContentWrapper" id="disclaimer">
            <Heading title="Disclaimer" className="popupInnerTitle" />
            <div className="staticList">
              <ul>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
                <li>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </li>
                <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(BiddingProcedure));
