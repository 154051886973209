import React from 'react';
import { get } from 'lodash/get';
import { withRouter, Link, useLocation } from 'react-router-dom';

import { Storage } from 'services/Storage';
import { isEmpty } from 'util/utils';

import HeaderStrip from '../common/Header/HeaderStrip';
import IntlMessages from 'util/IntlMessages';
import { commonLogout } from 'constants/Common';
import 'react-tippy/dist/tippy.css';
import { useSelector } from 'react-redux';

function MobileSidebar() {
  const location = useLocation();

  const { data: bidConfig, loading: bidConfigLoading } = useSelector((store) => store?.bidConfig);
  const { showData } = useSelector((store) => store?.auth);
  const subTypeCode = bidConfig.bid?.sub_types?.subTypeCode;
  const P = Storage.get('userPermissions');
  return (
    <div className="headerMenuBox">
      <div className="headerMeniInner">
        <HeaderStrip />
        <ul id="links" className="">
          <li>
            <Link className={`menuItem ${location.pathname === `/dashboard` && `active`}`} to="/dashboard">
              <span>Dashboard</span>
            </Link>
          </li>
          {P?.SEARCH_DIAMOND?.view && (
            <li className={'d-flex align-items-center'}>
              <Link to="/diamond-search" className={`menuItem ${location.pathname === `/diamond-search` && `active`}`}>
                Search
              </Link>
            </li>
          )}
          {P?.BID?.view && (
            <li>
              <Link
                className={`menuItem ${location.pathname === `/new-arrival-bid-it` ||
                  (location.pathname === `/search-bid-diamond` && `active`)
                  }`}
                to={subTypeCode === 'BIWI' ? '/search-bid-diamond' : '/new-arrival-bid-it'}
              >
                <span className="menu_link">UBS</span>
                <div
                  className={
                    bidConfigLoading
                      ? 'ribbon-icon'
                      : !isEmpty(bidConfig)
                        ? 'ribbon-icon active'
                        : 'ribbon-icon inactive'
                  }
                >
                  <span>{bidConfigLoading ? 'Loading...' : !isEmpty(bidConfig) ? 'Active' : 'Inactive'}</span>
                </div>
              </Link>
            </li>
          )}
          {/* For show Only */}
          {P?.SEARCH_DIAMOND?.view && showData && (
            <li>
              <Link className={`menuItem ${location.pathname === `/show` && `active`}`} to="/show">
                <span className="menu_link">{showData?.shortName}</span>
                <div className="ribbon-jck-icon mob">
                  <span>{showData?.fullName}</span>
                </div>
              </Link>
            </li>
          )}
          {P?.EXCLUSIVE?.view && (
            <li>
              <Link className={`menuItem ${location.pathname === `/special-stone` && `active`}`} to="/special-stone">
                <span>Exclusive Stone</span>
              </Link>
            </li>
          )}
          {P?.STONE_OF_THE_DAY?.view && (
            <li>
              <Link
                className={`menuItem ${location.pathname === `/stone-of-the-day` && `active`}`}
                to="/stone-of-the-day"
              >
                <span>Feature Stone</span>
              </Link>
            </li>
          )}
          {P?.UPCOMING?.view && (
            <li>
              <Link className={`menuItem ${location.pathname === `/upcoming` && `active`}`} to="/upcoming">
                <IntlMessages id="app.Upcoming" />
              </Link>
            </li>
          )}
          <li>
            <Link className="menuItem" to="/account">
              <IntlMessages id="app.myAccount" />
            </Link>
          </li>
          <li>
            <a className="menuItem" href="https://www.unique.diamonds/contact_us/" rel="noreferrer" target="_blank">
              Contact Us
            </a>
          </li>
          <li onClick={commonLogout}>
            <a>Log Out</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default withRouter(MobileSidebar);
