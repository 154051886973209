import React, { useMemo, useState } from 'react';
import { find, findIndex, isInteger, isNumber, map, sum } from 'lodash';
import { isNumeric, parseDecimal } from '../../../util/utils';

const BidCalculationMobile = ({ original, ...props }) => {
  const originalValue = React.useMemo(() => {
    return Number(parseFloat(original.bidDiscount).toFixed(2));
  }, [original.bidDiscount]);

  // const updatedValue = React.useMemo(() => {
  //   return original.bidDiscount;
  // }, [original.bidDiscount]);

  const [value, setValue] = React.useState(
    isNumber(original.bidDiscount)
      ? parseFloat(original.bidDiscount).toFixed(2)
      : isNumber(originalValue)
      ? parseFloat(originalValue).toFixed(2)
      : '0.00',
  );

  React.useEffect(() => {
    setValue(parseFloat(original.bidDiscount).toFixed(2));
  }, [original]);

  const handleChange = React.useCallback((e) => {
    e.preventDefault();
    props.showError({ showModal: true });
    const _value = e.target.value;
    if (isNumeric(_value)) setValue(_value);
  }, []);

  const handleBlur = React.useCallback((e) => {
    e.preventDefault();
    props.hideError({ showModal: false });
    const _value = e.target.value;
    isNumeric(_value, true) ? props.handleDiscountBlur(_value, original) : props.handleDiscountBlur(0, original);
  }, []);

  // const sign = Number(value) === 0 ||  !value ? '' : Number(value) > 0 ? '-' : '+';
  const sign = Number(value) > 0 ? '+' : '';
  // if (original.isFcCol) {
  //   return '-';
  // }
  return (
    <div className="tableInput">
      {!original.isFcCol ? (
        <>
          <span className="add-sign-mobile">{sign}</span>
          <input
            type="number"
            style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
          />{' '}
        </>
      ) : (
        '-'
      )}
    </div>
  );
};

export default BidCalculationMobile;
