const cellStyles = {
  display: 'inline-block',
  boxSizing: 'border-box',
};

const getRowStyles = (props, { instance }) => {
  return [props, { style: { display: 'flex', width: `${instance.totalColumnsWidth}px` } }];
};

export const useFixStickyLayout = (hooks) => {
  hooks.getRowProps.push(getRowStyles);
  hooks.getHeaderGroupProps.push(getRowStyles);
  hooks.getFooterGroupProps.push(getRowStyles);

  hooks.getHeaderProps.push((props, { column }) => {
    return [
      props,
      {
        style: {
          ...cellStyles,
          position: 'sticky',
          top: 0,
          left: column.sticky ? `${column.totalLeft}px` : undefined,
          //width: column.sticky ? `${column.width}px`: undefined,
          zIndex: column.sticky ? 2 : 1,
          backgroundColor: '#f1f1f1',
        },
      },
    ];
  });

  hooks.getCellProps.push((props, { cell }) => {
    return [
      props,
      {
        style: {
          ...cellStyles,
          // width: cell.column.sticky ? `${cell.column.width}px`: undefined,
        },
      },
    ];
  });

  hooks.getFooterProps.push((props, { column }) => [
    props,
    {
      style: {
        ...cellStyles,
        // width: `${column.width}px`,
      },
    },
  ]);
};
