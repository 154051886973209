import React from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Redirect, Router, Route, Switch } from 'react-router-dom';

import BiddingProcedure from 'components/BiddingProcedure';
import BookAppointment from 'components/BookAppointment';
import Career from 'components/Career';
import CareerDetail from 'components/Career/CareerDetail';
import ContactUs from 'components/ContactUs';
// import DiamondDetail from 'components/DiamondDetail';
import { DNARoute } from 'routes/DNARoute';
import { StoneDetailRoute } from 'routes/StoneDetailRoute';
import EmailVerify from 'components/EmailVerify';
import Feedback from 'components/Feedback';
import ForgotPassword from 'components/Auth/ForgotPassword';
import FrontRoute from './Routes';
import FTC from 'components/FTC';
import GuestLogin from 'components/GuestLogin';
import News from 'components/News';
import PageNotFound from 'components/PageNotFound';
import ResetPassword from 'components/Auth/ResetPassword';
import SignIn from 'components/SignIn';
import SignUp from 'components/SignUp';
import TermsOfUse from 'components/TermsOfUse';
import ThankYouAfterSignup from 'components/ThankYou';
import VerifyDevice from 'components/VerifyDevice';
import VerifyOtp from 'components/VerifyOtp';
import ViewImage from 'components/DNA/ViewImage';
import XRAY from 'components/XRAY';

import { LOCAL_STORAGE_VAR } from '../constants/Common';
import AppLocale from '../lngProvider';
import UtilService from '../services/util';

import { BidService } from 'services/BidService';
import { history } from 'util/history';
import { isEmpty, logWarn } from 'util/utils';
import PrivacyPolicyAndTerms from 'components/privacy-policy-and-terms';
// import Maintanance from 'components/Auth/SystemUnder';

const RestrictedRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        !UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`) ? (
          <Component {...props} />
        ) : (
          <Redirect to={'/dashboard'} />
        )
      }
    />
  );
};

function App() {
  const isLoggedIn = useSelector((store) => !isEmpty(store?.auth?.token));
  const locale = useSelector((store) => AppLocale[store?.commonData?.locale?.locale ?? 'en']);

  React.useEffect(() => {
    isLoggedIn ? BidService.getBidConfig() : BidService.resetBidConfig();
  }, [isLoggedIn]);

  return (
    <Router history={history}>
      <IntlProvider locale={locale.locale} messages={locale.messages}>
        <Switch>
          {/* use when site in under maintanance  */}
          {/* <Maintanance /> */}

          <RestrictedRoutes path="/" component={SignIn} exact />
          <RestrictedRoutes path="/signin" component={SignIn} />
          <RestrictedRoutes path="/signup" component={SignUp} />
          <RestrictedRoutes path="/device/signup" component={SignUp} />
          {/* <RestrictedRoutes path="/guest-login" component={GuestLogin} /> */}
          <RestrictedRoutes path="/forgot-password" component={ForgotPassword} />
          <RestrictedRoutes path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/verify-otp" component={VerifyOtp} />
          <Route path="/privacy-policy-and-terms" component={PrivacyPolicyAndTerms} />

          <Route path="/DiamFTC" component={FTC} />
          <Route path="/bidding-procedure" component={BiddingProcedure} exact />
          <Route path="/book-appointment" component={BookAppointment} exact />
          <Route path="/career" component={Career} />
          <Route path="/career-detail" component={CareerDetail} />
          <Route path="/contact" component={ContactUs} exact />
          <Route path="/dna/:id" component={DNARoute} exact />
          <Route path="/feedback" component={Feedback} exact />
          <Route path="/news" component={News} exact />
          <Route path="/not-found" component={PageNotFound} exact />
          <Route path="/terms-condition" component={TermsOfUse} exact />
          <Route path="/thank-you" component={ThankYouAfterSignup} />
          <Route path="/verify-device" component={VerifyDevice} />
          <Route path="/verify-email" component={EmailVerify} />
          <Route path="/viewImage" component={ViewImage} />
          <Route path="/xrays" component={XRAY} />

          {!isLoggedIn && (
            <Route exact path="/:name(diamond-detail|diamond-details)/:id" component={StoneDetailRoute} />
          )}

          <FrontRoute />
        </Switch>
      </IntlProvider>
    </Router>
  );
}

export default App;
