import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PersonalCardView from '../PersonalInformation/PersonalCardView';
import PersonalInformation from '../PersonalInformation';
import CompanyCardView from '../CompanyInformation/CompanyCardView';
import CompanyInformation from '../CompanyInformation';
import ChangePassword from '../ChangePassword';
import DiamondColumnListing from '../ManageDiamondSequence';
import '../../common/Tab/tab.less';
import { Drawer } from 'antd';
import IntlMessages from '../../../util/IntlMessages';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { BANNERS_TYPES, BASE_URL } from '../../../constants/Common';
import { GET_DASHBOARD_DATA, GET_BANNERS, FETCH_USER, GET_USER_PROFILE } from '../../../constants/apiConstant';
import UtilService from '../../../services/util';

const PERSONAL_DOCS =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
  [];

const BUSINESS_DOCS =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_BUSINESS) ||
  [];
class Profile extends Component {
  state = {
    visible: false,
    visible1: false,
    visible2: false,
    kyc: [],
    topBanner: {},
    persnolDetail: {},
    companyDetail: {},
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  showDrawer1 = () => {
    this.setState({
      visible1: true,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
      visible1: false,
      visible2: false,
    });
  };
  componentDidMount() {
    this.getBannerData();
    this.fetchUser();
    this.fetchAccount();
  }

  fetchUser() {
    const self = this;
    const obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.map((k) => {
            if (k.type === 'personal') self.setState({ doc: [k.docType] });
          });

        self.setState({ persnolDetail: data.data });
      }
    });
  }

  fetchAccount() {
    const self = this;
    const obj = {
      ...GET_USER_PROFILE,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.map((k) => {
            if (k.type === 'business') self.setState({ doc: [k.docType] });
          });
        self.setState({
          companyDetail: {
            ...data.data,
            name: data.data.companyName,
          },
        });
      }
    });
  }

  getBannerData = () => {
    const self = this;
    const obj = {
      ...GET_BANNERS,
      request: { page: 1, limit: 100, filter: { bannerType: 1 } },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
        self.setState({ bannerData: undefined });
      } else if (data && data.code === 'OK') {
        if (data.data) {
          // let sorted = sortBy(data.data.list, "type");
          if (data.data && data.data.list && data.data.list.length !== 0) {
            const bannerTop = find(data.data.list, {
              type: '24',
            });
            // let otherBanner = data.data.list.filter(x => x.type !== '1')
            self.setState({ topBanner: bannerTop });
          }
          // self.setState({ bannerData: sorted });
        }
      } else {
        self.setState({ bannerData: undefined });
      }
    });
  };

  handleKyc = (type, path, doc) => {
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
    let kyc = !isEmpty(this.state.kyc) ? this.state.kyc : (user && user.account && user.account.kyc) || [];

    const dt = type === 'personal' ? find(PERSONAL_DOCS, { id: doc }) : find(BUSINESS_DOCS, { id: doc });

    if (!isEmpty(kyc)) {
      kyc = kyc.map((item) => {
        if (item.type == type) {
          return {
            ...item,
            path: !isEmpty(path) ? path : item.path,
            docType: dt.id[0],
          };
        }
        return item;
      });
    }

    this.setState({ kyc });
  };

  render() {
    const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

    return (
      <div>
        <div className="">
          <Tabs className="accountTabBox">
            <TabList className="customerMaintab accountSubTab">
              <Tab onClick={() => this.fetchUser()}>
                <IntlMessages id="app.PersonalInformation" />
              </Tab>
              {!isGuest && (
                <Tab onClick={() => this.fetchAccount()}>
                  <IntlMessages id="app.CompanyInformation" />
                </Tab>
              )}
              {/* <Tab>Manage User</Tab>
                <Tab>Manage Addresses</Tab> */}
              <Tab>
                <IntlMessages id="app.ChangePassword" />
              </Tab>
              {!isGuest && (
                <Tab>
                  <IntlMessages id="app.ManageDiamondSequence" />
                </Tab>
              )}
            </TabList>
            <TabPanel>
              <PersonalCardView
                persnolDetail={this.state?.persnolDetail ?? {}}
                onClose={this.onClose}
                onClick={this.showDrawer}
                accountProfile={this.state.topBanner && this.state.topBanner.images}
              />
            </TabPanel>
            {!isGuest && (
              <TabPanel>
                <CompanyCardView
                  companyDetail={this?.state?.companyDetail ?? {}}
                  onClose={this.onClose}
                  onClick={this.showDrawer1}
                />
              </TabPanel>
            )}
            {/* <TabPanel>
                <ManageUser />
              </TabPanel>
              <TabPanel>
                <ManageAddress />
              </TabPanel> */}
            <TabPanel>
              <ChangePassword />
            </TabPanel>
            {!isGuest && (
              <TabPanel>
                <DiamondColumnListing />
              </TabPanel>
            )}
          </Tabs>
        </div>
        <Drawer
          title={false}
          onClose={this.onClose}
          visible={this.state.visible}
          wrapClassName="diamondListinSidebar profileSidebar"
        >
          <PersonalInformation
            fetchUser={() => this?.fetchUser()}
            kyc={this.state.kyc}
            handleKyc={this.handleKyc}
            onClose={this.onClose}
            accountProfile={this.state.topBanner}
          />
        </Drawer>
        <Drawer
          title={false}
          onClose={this.onClose}
          visible={this.state.visible1}
          wrapClassName="diamondListinSidebar profileSidebar"
        >
          <CompanyInformation
            fetchAccount={() => this.fetchAccount()}
            kyc={this.state.kyc}
            handleKyc={this.handleKyc}
            onClose={this.onClose}
          />
        </Drawer>
      </div>
    );
  }
}
export default withRouter(Profile);
