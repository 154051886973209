import React, { Component, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import IntlMessages from '../../util/IntlMessages';
import OpenNotification from '../common/CommonButton/OpenNotification';
import { includes, head, last, split, groupBy, isEmpty } from 'lodash';

const Carat = (props) => {
  const { or, masterIndex, data } = props;
  const [From, setFrom] = useState(undefined);
  const [To, setTo] = useState(undefined);

  const caratSizeGroup = groupBy(data, 'group');

  const handleAddCarat = () => {
    const or = props.or && props.or.customeCarat ? props.or.customeCarat : [];
    const from = From && Number(From);
    const to = To && Number(To);
    if (from && to && parseInt(from) <= parseInt(to) && !includes(or.customeCarat, { crt: { '>=': from, '<=': to } })) {
      const tags = or.concat({ crt: { '>=': from, '<=': to } });
      props.handleAddCustomCarat(tags);
    } else if (from && to && includes(or.customeCarat, { crt: { '>=': from, '<=': to } })) {
      OpenNotification({
        type: 'success',
        title: 'Carat range is already added.',
      });
    } else if (from && to && parseInt(from) > parseInt(to)) {
      OpenNotification({
        type: 'error',
        title: 'To must be greater than from.',
      });
    }
    setFrom('');
    setTo('');
  };

  const onTagClose = (tagInput = '') => {
    const or = props.or && props.or.customeCarat ? props.or.customeCarat : [];
    const tHead = head(split(tagInput, '-'));
    const tLast = last(split(tagInput, '-'));
    const tags = [];
    or.map((x) => {
      const head = x.crt['>='] !== Number(tHead);
      const last = x.crt['<='] !== Number(tLast);
      if (head && last) tags.push(x);
    });
    props.handleAddCustomCarat(tags);
  };

  return (
    <div className="diamondSearchItem">
      <div className="diamondSearchLabel">
        <label>
          <IntlMessages id="app.carat-range" />
        </label>
        {props?.clearSelection && (
          <Tooltip title="Clear Selection" getPopupContainer={(trigger) => trigger.parentElement}>
            <span className="clearSelection" onClick={() => props.clearSelection('or')}>
              &times;
            </span>
          </Tooltip>
        )}
      </div>
      <div className="diamondSearchDetail">
        <ul className="diamondSearchList caratBoxSize">
          {Object.entries(caratSizeGroup).map((carat, index) => {
            return (
              <li
                key={index}
                onClick={() => props.handleCaratSelection(index)}
                className={includes(masterIndex, index) ? 'active' : ''}
              >
                {carat[1][0]['webDisplay']}
              </li>
            );
          })}
          <li className="diamondSearchCaratRange">
            <div className="fromToValue">
              <div className={`fromToValueInput`}>
                <label>
                  <IntlMessages id="app.from" />
                </label>
                <input
                  type="number"
                  value={From}
                  min={0}
                  onKeyDown={(e) => {
                    if (e.keyCode === 189 || e.keyCode === 109) {
                      // Dash sign prevent
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </div>
              <div className="fromToValueInput">
                <label>
                  <IntlMessages id="app.to" />
                </label>
                <input
                  type="number"
                  value={To}
                  min={0}
                  onKeyDown={(e) => {
                    if (e.keyCode === 189 || e.keyCode === 109) {
                      // Dash sign prevent
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => setTo(e.target.value)}
                  onBlur={() => handleAddCarat()}
                />
              </div>
            </div>

            <div className="commonButton addCaratButton" onClick={() => handleAddCarat()}>
              <IntlMessages id="app.AddCarat" />
            </div>
            <div className=" d-flex align-items-center flex-wrap">
              <div className="caratTagButton ">
                {or &&
                  !isEmpty(or.customeCarat) &&
                  or.customeCarat.map((t) => {
                    const tagInput = `${t.crt['>=']}-${t.crt['<=']}`;
                    return (
                      <Tag className="addedCarat" key={tagInput} onClose={() => onTagClose(tagInput)} closable>
                        {tagInput}
                      </Tag>
                    );
                  })}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Carat;
