import React from 'react';
import SignUpForm from './Auth/SignUpForm';
// import AuthPoster from "./Auth/AuthPoster";

function SignUp(props) {
  return (
    <div className="loginMainWrapper registerLogWrapper">
      {/* <AuthPoster /> */}
      <div className="LoginRightBlock">
        <SignUpForm />
      </div>
    </div>
  );
}

export default SignUp;
