import React, { Component } from 'react';
import { resendOTP } from '../appRedux/actions/Auth';
import { connect } from 'react-redux';
import { Button } from 'antd';
import IntlMessages from '../util/IntlMessages';
class ResendOtp extends Component {
  componentDidMount() {
    this.props.countDown();
  }
  componentDidUpdate() {
    if (this.props.ctr > 0) {
      setTimeout(() => {
        this.props.countDown();
      }, 1000);
    } else return null;
  }

  render() {
    return (
      <div>
        <IntlMessages id="app.notgetanyOTP" />
        <Button type="link" onClick={this.props.resendOTP} disabled={this.props.isDisabled}>
          <IntlMessages id="app.ResendOTP" />
        </Button>
        {this.props.ctr || ''}
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    ctr: state.setTimer.seconds,
    isDisabled: state.setTimer.isDisabled,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resendOTP: () => {
      dispatch(resendOTP());
    },
    countDown: () => {
      dispatch({ type: 'DEC' });
    },
  };
};

export default connect(mapStatesToProps, mapDispatchToProps)(ResendOtp);
