const pre = '/web/v1';

export const API_COLUMN_SETTING = {
  url: `${pre}/grid/get-columns`,
  method: 'post',
};

export const COMMENT_UPSERT = {
  method: 'post',
  url: '/admin/diamond-comment/upsert',
};

export const DIAMOND_TRACK_DELETE = {
  method: 'post',
  url: '/admin/diamond-track/delete',
};
export const BID_CART_DELETE = {
  method: 'post',
  url: `${pre}/bid-cart/delete`,
};

export const NOTE_DELETE = {
  method: 'post',
  url: `${pre}/diamond-comment/delete`,
};

export const HIT_COUNTER = {
  method: 'post',
  url: '/admin/diamond/hit-counter',
};

export const GET_DASHBOARD_DATA = {
  method: 'post',
  url: `${pre}/user/dashboard`,
  showLoader: true,
};

export const GET_MYPURCHASE_DATA = {
  method: 'post',
  url: `${pre}/memo/paginate`,
  // showLoader: true
};

export const SEARCH_DIAMOND_LIST = {
  method: 'post',
  url: `${pre}/diamond/paginate`,
  showLoader: true,
};

export const SEARCH_LIST = {
  method: 'post',
  url: `${pre}/diamond/search/list`,
};

export const CREATE_SAVED_SEARCH = {
  method: 'post',
  url: `${pre}/diamond/search/upsert`,
};

export const CREATE_MATCH_PAIR = {
  method: 'post',
  // Previous URL
  // url: `${pre}/match-pair/diamond/filter`,
  url: `${pre}/diamond/match-pair/filter`,
  showLoader: true,
};

export const CREATE_BID_CART = {
  method: 'post',
  url: `${pre}/bid-cart/create`,
  showLoader: true,
};

export const BID_CART_PAGINATE = {
  method: 'post',
  url: `${pre}/bid-cart/paginate`,
  showLoader: true,
};

export const BID_PAGINATE = {
  method: 'post',
  url: `${pre}/dynamic-diamond-bid/paginate`,
  showLoader: true,
};

export const BID_DELETE = {
  method: 'post',
  url: `${pre}/dynamic-diamond-bid/delete`,
  showLoader: true,
};

export const SELECT_SEARCH = {
  method: 'post',
  url: `${pre}/diamond/search/list`,
};

export const CREATE_DEMAND = {
  method: 'post',
  url: `${pre}/diamond/search/upsert`,
};
//-----

export const FETCH_CONFIRM_STONE = {
  url: `${pre}/memo/paginate`,
  method: 'post',
  showLoader: true,
};

export const FETCH_DIAMOND_TRACK = {
  method: 'post',
  url: `${pre}/diamond-track/paginate`,
  showLoader: true,
};

export const FETCH_NOTES = {
  method: 'post',
  url: `${pre}/diamond-comment/by-user`,
  showLoader: true,
};

export const CREATE_TRACK = {
  method: 'post',
  url: `${pre}/diamond-track/create`,
  showLoader: true,
};

export const UPDATE_TRACK = {
  method: 'post',
  url: `${pre}/diamond-track/update`,
  showLoader: true,
};

export const COUNTER_OFFER = {
  method: 'post',
  url: `${pre}/diamond-track/counter-offer`,
  showLoader: true,
};

export const BLOCK_REALESE = {
  method: 'post',
  url: `${pre}/diamond-block/change-status`,
};

export const PRINT_PDF = {
  method: 'post',
  url: `${pre}/diamond/pdf`,
  showLoader: true,
};

export const EXPORT_EXCEL = {
  method: 'post',
  url: `${pre}/diamond/excel`,
  showLoader: true,
};

export const CONFIRM_STONE = {
  method: 'post',
  url: `${pre}/diamond-confirm/request`,
  showLoader: true,
};

export const DELETE_TRACK = {
  method: 'post',
  url: `${pre}/diamond-track/delete`,
  showLoader: true,
};

export const NOTE_UPSERT = {
  method: 'post',
  url: '/web/v1/diamond-comment/upsert',
  showLoader: true,
};

export const CREATE_BID = {
  method: 'post',
  url: `${pre}/dynamic-diamond-bid/create`,
  showLoader: true,
};

export const UPDATE_BID = {
  method: 'post',
  url: `${pre}/dynamic-diamond-bid/update`,
  showLoader: true,
};

export const SEND_EMAIL_XRAY = {
  method: 'post',
  url: `${pre}/diamond/x-ray-mail`,
};

export const VIEW_DIAMOND = {
  method: 'post',
  url: '/web/v1/diamond/view',
  showLoader: true,
};

export const DELETE_SEARCH = {
  method: 'post',
  url: '/web/v1/diamond/search/delete',
};

export const VERIFY_FINGER_PRINT = {
  method: 'post',
  url: `${pre}/verifyfingerprint`,
};
// resend finger print otp
export const RESENT_FP_OTP = {
  method: 'post',
  url: `${pre}/resend-fp-otp`,
};
// Search Diamonds by id
export const DIAMOND_DATA_BY_ID = {
  method: 'post',
  url: `${pre}/diamond/search/by-id`,
};
// TO Verify E-mail
export const VERIFY_MAIL = {
  method: 'post',
  url: `${pre}/auth/verify-email`,
};
export const GET_BANNERS = {
  method: 'post',
  url: `${pre}/banner/paginate`,
  showLoader: true,
};
// Delete Searched Diamond
export const DELETE_SEARCHED_DIAMOND = {
  method: 'post',
  url: `${pre}/diamond/search/delete`,
};
// Get Quick Search Data
export const QUICK_SEARCH = {
  method: 'post',
  url: `${pre}/diamond/quick-search`,
};

export const GET_DIAMOND_DATA = {
  method: 'post',
  url: `${pre}/diamond/paginate`,
};

export const RESET_PWD_BYUSER = {
  method: 'post',
  url: `${pre}/auth/reset-password-by-user`,
};

export const GET_COUNTRY_LIST = {
  method: 'post',
  url: `${pre}/country/paginate`,
  showLoader: true,
};

export const GET_STATE_LIST = {
  method: 'post',
  url: `${pre}/state/paginate`,
  showLoader: true,
};

export const GET_CITY_LIST = {
  method: 'post',
  url: `${pre}/city/paginate`,
  showLoader: true,
};

export const GET_USER_PROFILE = {
  method: 'post',
  url: `${pre}/user/profile`,
};

export const UPDATE_USER_PROFILE = {
  method: 'post',
  url: `${pre}/account/update`,
};
export const ACCOUNT_UPDATE_KYC = {
  method: 'put',
  url: `${pre}/account/`,
  showLoader: true,
};
export const UPLOAD_FILE = {
  method: 'post',
  url: `${pre}/upload-file`,
  showLoader: true,
};
export const USER_SETTING_LIST = {
  method: 'post',
  url: `${pre}/user-setting/paginate`,
  showLoader: true,
};
export const USER_SETTING_UPSERT = {
  method: 'post',
  url: `${pre}/user-setting/upsert`,
  showLoader: true,
};

export const FETCH_USER = {
  method: 'post',
  url: `${pre}/user/view`,
};
export const ADMIN_UPDATE_USER = {
  method: 'put',
  url: `${pre}/user/update`,
};

export const FORGOT_PASSWORD = {
  method: 'post',
  url: `${pre}/auth/forgot-password`,
};

export const LOGIN_USER = {
  method: 'post',
  url: `${pre}/auth/login`,
  showLoader: true,
};
export const GUEST_LOGIN_USER = {
  method: 'post',
  url: `${pre}/guest/auth/login`,
  showLoader: true,
};
// Resend Verification Mail
export const RESEND_VERIFY_MAIL = {
  method: 'post',
  url: `${pre}/user/resend-verification-email`,
};

export const UPDATE_USER_TOKEN = {
  method: 'post',
  url: `${pre}/auth/update-token`,
};
export const PWD_RESET = {
  method: 'post',
  url: `${pre}/auth/reset-password`,
};

export const FETCH_SELLERS = {
  method: 'post',
  url: `${pre}/register/sellers`,
};
// export const SIGNUP_USER_FRONT = {
//   method: "post",
//   url: `${pre}/front/register`,
// };
export const SIGNUP_USER_FRONT = {
  method: 'post',
  url: `${pre}/register/register`,
  showLoader: true,
};
export const DIAMOND_XRAY_MAIL = {
  method: 'post',
  url: `${pre}/diamond/x-ray-mail`,
};

export const CREATE_ANALYTICS = {
  method: 'post',
  url: `${pre}/analytics/create`,
};

export const USER_LOGOUT = {
  method: 'post',
  url: `https://betapi.unique.diamonds${pre}/auth/logout`,
};

export const LOGOUT = {
  method: 'post',
  url: `https://betapi.unique.diamonds${pre}/temp/saveData`,
};

export const FEATURE_STONE = {
  method: 'post',
  url: `${pre}/featuredStone/paginate`,
  showLoader: true,
};

export const DOWNLOAD_ZIP = {
  method: 'post',
  url: `${pre}/diamond/download-zip`,
  showLoader: true,
};

export const DOWNLOAD_CERTIFICATE = {
  method: 'post',
  url: `${pre}/diamond/download-certificate`,
  showLoader: true,
};

export const DOWNLOAD_FILE = {
  method: 'post',
  url: `${pre}/download-file`,
  showLoader: true,
};

export const DIAMOND_FTC = {
  method: 'post',
  url: `${pre}/diamond/diamond-ftc`,
};

export const CREATE_SCHEDULE = {
  method: 'post',
  url: `${pre}/cabin-schedule/create`,
};
export const GET_NOTIFICATIONS = {
  method: 'post',
  url: `${pre}/notification/list`,
  //showLoader: true,
};

export const GET_EVENTS = {
  method: 'post',
  url: `${pre}/event/paginate`,
};

export const PROJECT_SETTING = {
  method: 'post',
  url: `${pre}/project-setting/view`,
};

export const MARK_AS_READ = {
  method: 'post',
  url: `${pre}/notification/markAsRead`,
  //showLoader: true,
};

export const GET_SLOTS = {
  method: 'post',
  url: `${pre}/cabin-slot/paginate`,
};

export const FETCH_OFFICE_DIAMOND = {
  method: 'post',
  url: `${pre}/cabin-schedule/list`,
  showLoader: true,
};

export const GET_TRACK_SHIPMENTS = {
  method: 'post',
  url: `${pre}/shipping-detail/paginate`,
  showLoader: true,
};

export const GET_SHIPMENT_DETAILS = {
  method: 'post',
  url: `${pre}/shipping-detail`,
  showLoader: true,
};

export const GET_MASTERS = {
  method: 'post',
  url: `${pre}/master/list-by-code-no-auth`,
};

export const DELETE_OFFICE_DIAMOND = {
  method: 'post',
  url: `${pre}/cabin-schedule/destroyDiamondAndSlot`,
};

export const GET_NEWS_DATA = {
  method: 'post',
  url: `${pre}/news/paginate`,
  showLoader: true,
};
export const TAKE_APPOINTMENT = {
  method: 'post',
  url: `${pre}/cabin-schedule/create-data`,
  showLoader: true,
};
export const CONTACT_US = {
  method: 'post',
  url: `${pre}/contactus/create`,
  showLoader: true,
};

export const QUOTE_DAY_LIST = {
  method: 'post',
  url: `${pre}/show-selection/paginate`,
  showLoader: true,
};

export const _HIT_COUNTER = {
  method: 'post',
  url: `${pre}/diamond/search/by-id`,
};
export const GET_CITY_SEARCH = {
  method: 'post',
  url: `${pre}/city/search`,
  showLoader: true,
};

export const BLOCK_MASTERS = {
  method: 'post',
  url: `${pre}/diamond-block-master/paginate`,
};

export const ADD_HOLD = {
  method: 'post',
  url: `${pre}/diamond-block/create`,
};

export const GET_BLOCK_DIAMONDS = {
  method: 'post',
  url: `${pre}/diamond-block/paginate`,
  showLoader: true,
};

export const DIAMOND_TRACK_STATUS_UPDATE = {
  method: 'post',
  url: `${pre}/diamond-track/status-update`,
  showLoader: true,
};

export const GET_SERVER_TIME = {
  method: 'get',
  url: `${pre}/common/server-time?${Math.random()}`,
};

export const BID_CONFIG = {
  method: 'get',
  url: `${pre}/diamond-bid/dynamic-bid-config`,
};

export const GET_EXCLUSIVE_COLLECTION = {
  method: 'post',
  url: `${pre}/diamond/exclusive-search/list`,
  showLoader: true,
};

export const ADD_NOTES = {
  method: 'post',
  url: '/web/v1/bid-cart/addnotes',
  showLoader: true,
};

export const ATTEND_SHOW = {
  method: 'get',
  url: '/web/v1/common/show?isAvailableForShow=true',
  showLoader: true,
};

export const NOT_ATTEND_SHOW = {
  method: 'get',
  url: '/web/v1/common/show?isAvailableForShow=false',
  showLoader: true,
};
