import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { Row, Col } from 'antd';
import IntlMessages from '../util/IntlMessages';
import UtilService from '../services/util';
import OpenNotification from './common/CommonButton/OpenNotification';
import { VERIFY_MAIL } from '../constants/apiConstant';

class EmailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      alreadyVerified: false,
      isVerified: 0,
    };
  }

  componentDidMount() {
    let self = this;
    let id = _.last(window.location.pathname.split('verify-email/'));
    let obj = {
      ...VERIFY_MAIL,
      // method: 'post',
      // url: '/auth/verify-email',
      request: {
        emailHash: id,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ success: true });
        if (data && data.data) {
          this.setState({ isVerified: data.data.isVerified });
        }
        if (data.message === 'Email is already verified.') self.setState({ alreadyVerified: true });

        // OpenNotification({
        //     type: 'success',
        //     title:
        //         data && data.message
        // })
      } else {
        OpenNotification({
          type: 'error',
          title:
            data && data.message
              ? data.message
              : err && err.data && err.data.message
                ? err.data.message
                : 'Something went wrong',
        });
        self.props.history.push('/signin');
      }
    });
  }

  render() {
    return (
      <>
        {this.state.success && (
          <div className="thankYouWrapper">
            <div className="thankYouInnerBox">
              <div className="thankYouMainBox">
                <div className="thankYouImgBlock">
                  <img src={require('../assets/svg/ThankYou/1.svg')} alt="" />
                </div>
                <div className="thankYouPageContent">
                  <h2>Thank you, your email address is verified.</h2>
                  {/* {this.state.isVerified === 1 && ( */}
                  <p>
                    Your Unique Diamax Pvt Ltd partner account is under review. Our support team will process your application
                    soon and get in touch with you if needed. You will receive a confirmation email post verification.
                    Please ignore this message if your account is already verified.
                  </p>
                  {/* ) } */}
                </div>
                <a href="/signin">
                  <button className="commonButton">Login</button>
                </a>
              </div>
            </div>
          </div>
        )}
        {/* <div>
          {this.state.success && (
            <div>
              <section className="thank-you">
                <div className="thank-container">
                  <Row
                    className="text-center flex-wrap"
                    align="middle"
                    type="flex"
                  >
                    <Col lg={12} xs={24} className="thank-you-img">
                    </Col>
                    <Col lg={12} xs={24} className="text-center">
                      <div className="thank-you-content">
                        {this.state.alreadyVerified ? (
                          <>
                            <h1>Your email is already verified!</h1>
                          </>
                        ) : (
                          <>
                            <h1>Thank you, your email address is verified.</h1>
                            <h3>
                              Your Unique Diamax Pvt Ltd account is under review. Our
                              support team will process your application soon
                              and get in touch with you if needed. You will
                              receive a confirmation email with your login
                              details post verification.
                            </h3>
                          </>
                        )}

                        <button className="button primary-fill mt-20">
                          <a style={{ color: "#fff" }} href="/signin">
                            <IntlMessages id="app.back-to-login" />
                          </a>
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </section>
            </div>
           )}
        </div> */}
      </>
    );
  }
}

export default withRouter(EmailVerify);
