import React from 'react';

class Timer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.getTimeDifference(this.props.eventDate);
    setInterval(() => this.getTimeDifference(this.props.eventDate), 1000);
  }

  leadingZero(num) {
    return num < 10 && num > 0 ? '0' + num : num;
  }

  getTimeDifference(eventDate) {
    const time = Date.parse(eventDate) - Date.parse(new Date());
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    this.setState({ days, hours, minutes, seconds });
  }

  render() {
    return (
      <div className="countDownBlock">
        <div className="countdownItem">
          <span>{this.leadingZero(this.state.days)} </span>
          <span>{this.state.days == 1 ? 'day' : 'days'}</span>
        </div>
        <div className="countdownItem">
          <span>{this.leadingZero(this.state.hours)}</span>
          <span>{this.state.hours == 1 ? 'hour' : 'hours'}</span>
        </div>
        <div className="countdownItem">
          <span>{this.leadingZero(this.state.minutes)}</span>
          <span> {this.state.minutes == 1 ? 'minute' : 'minutes'}</span>
        </div>
        <div className="countdownItem">
          <span>{this.leadingZero(this.state.seconds)} </span>
          <span>{this.state.seconds == 1 ? 'second' : 'seconds'}</span>
        </div>
      </div>
    );
  }
}

class CountdownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [{ date: 'October 21, 2020' }],
      newDate: '',
      newName: '',
    };
  }

  addNewEvent = () => {
    let evt = { name: this.state.newName, date: this.state.newDate };
    let newEvents = this.state.events.concat(evt);
    this.setState({
      newDate: '',
      newName: '',
      events: newEvents,
    });
  };

  render() {
    let events = this.state.events.map((evt) => <Timer key={evt.date} eventName={evt.name} eventDate={evt.date} />);
    return <div className="App">{events}</div>;
  }
}

export default CountdownTimer;
