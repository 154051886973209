import React from 'react';
import CardView from '../Profile/CardView';
import { LOCAL_STORAGE_VAR } from '../../../constants/Common';

const PersonalCardView = (props) => {
  const dt = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

  return (
    <CardView
      personalDetails
      pDetails={props?.persnolDetail ?? dt}
      onClose={props.onClose}
      onClick={props.onClick}
      accountProfile={props.accountProfile}
    />
  );
};
export default PersonalCardView;
