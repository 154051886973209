import React, { Component } from 'react';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import IntlMessages from '../../util/IntlMessages';
/* eslint jsx-a11y/anchor-is-valid: 0 */
class MobileBottomAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreSearch: false,
    };
  }
  moreSearchOption = () => {
    this.setState({
      moreSearch: true,
    });
  };
  onClose = () => {
    this.setState({
      moreSearch: false,
    });
  };

  render() {
    return (
      <>
        <div className="mobileSearchBottom">
          <div className="mobileSearchActionItem">
            <Link
              onClick={this.props.count === 0 || this.props.count >= 500 ? '' : () => this.props.handleSearch()}
              className={
                this.props.count === 0 || this.props.count >= 500 ? `bottomStickyButton disable` : `bottomStickyButton`
              }
            >
              <img src={require('../../assets/svg/DiamondSearch/search.svg')} alt="" />
              <span>Search</span>
            </Link>
          </div>
          {!this.props.bid ? (
            <div className="mobileSearchActionItem">
              <a onClick={() => this.props.handleUpcoming()}>
                <img src={require('../../assets/svg/DiamondSearch/confirm.svg')} alt="" />
                <span>Search Upcoming</span>
              </a>
            </div>
          ) : (
            <div className="mobileSearchActionItem">
              <a onClick={() => this.props.history.push('/my-bid')}>
                <img src={require('../../assets/svg/DiamondSearch/refresh.svg')} alt="" />
                <span>My Bid</span>
              </a>
            </div>
          )}
          <div className="mobileSearchActionItem">
            <a onClick={() => this.props.handleRefresh()}>
              <img src={require('../../assets/svg/DiamondSearch/refresh.svg')} alt="" />
              <span>Refresh</span>
            </a>
          </div>
          {!this.props.bid ? (
            <div className="mobileSearchActionItem" onClick={this.moreSearchOption}>
              <a>
                <img src={require('../../assets/svg/DiamondSearch/more.svg')} alt="" />
                <span>More</span>
              </a>
            </div>
          ) : (
            ''
          )}
        </div>
        {this.state.moreSearch && (
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.moreSearch}
            wrapClassName="mobileSidebar mobileBottomSidebar"
          >
            <div className="MoreSearchOptionItem">
              <ul>
                <li onClick={this.props.count === 0 || this.props.count >= 500 ? '' : this.props.saveSearchPopup}>
                  <a>Save & Search</a>
                </li>
              </ul>
            </div>
            {/* <div className="MoreSearchOptionItem">
              <ul>
                <li
                  onClick={
                    this.props.count === 0 || this.props.count >= 500 ? '' : () => this.props.handleMutipleSearch()
                  }
                >
                  <a>
                    Add More Search{' '}
                    {`${this.props.multipleSearch.length ? `(${this.props.multipleSearch.length})` : ''}`}
                  </a>
                </li>
              </ul>
            </div> */}
            <div className="MoreSearchOptionItem">
              <ul>
                <li onClick={this.props.count === 0 ? this.props.showDemand : ''}>
                  <a>Add Demand</a>
                </li>
              </ul>
            </div>
          </Drawer>
        )}
      </>
    );
  }
}
export default MobileBottomAction;
