import React, { Component } from 'react';
import LoginForm from './Auth/LoginForm';
import { LOCAL_STORAGE_VAR } from '../constants/Common';
import AuthPoster from './Auth/AuthPoster';
import PopupBanner from './PopupBanner';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfo: true,
    };
  }

  componentDidMount() {
    const showInfo = JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-cookie-policy`));
    if (showInfo) {
      this.setState({ showInfo: false });
    }
  }
  setCookie = () => {
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-cookie-policy`, true);
    this.setState({ showInfo: false });
  };
  render() {
    return (
      <>
        {/* <PopupBanner /> */}
        <div
          // className={
          //   this.state.showInfo
          //     ? "loginMainBlocks loginBottomSpace loginFormBlock"
          //     : "loginMainBlocks loginFormBlock"
          // }
          className="loginMainWrapper"
        >
          <AuthPoster />
          <div className="LoginRightBlock hv-auto-set">
            <LoginForm history={this.props.history} />
            {/* {window.location.assign('/maintanance')} */}
            {/* for Under Maintanance use this */}
          </div>
          {/* {this.state.showInfo ? (
          <div className="fixBoxSticky">
            <div className="fixBoxInner">
              <p>
                By continuing to use our site you are consenting to Unique Diamax Pvt Ltd using cookies in
                accordance with our <a href="/privacy-policy">Cookie Policy.</a>{" "}
              </p>
              <button className="button button-outline" onClick={this.setCookie}>
                Got it
              </button>
            </div>
          </div>
        ) : null} */}
        </div>
      </>
    );
  }
}

export default SignIn;
