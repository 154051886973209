import React from 'react';
import IntlMessages from '../../util/IntlMessages';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';

export default function getData(permissionData) {
  let sidebarArr = [];
  sidebarArr.push({
    name: <IntlMessages id="app.Search" />,
    url: '/diamond-search',
    subMenu: false,
  });

  sidebarArr.push({
    name: <IntlMessages id="app.newArrival" />,
    url: '/new-arrival-bid-it',
    subMenu3: false,
  });
  sidebarArr.push({
    name: <IntlMessages id="app.FinestarExclusive" />,
    url: '/finestar-exclusive',
    //subMenu3: true,
  });
  // sidebarArr.push({
  //   name: <IntlMessages id="app.fancy-diamonds" />,
  //   url: "/fancy-search",
  //   subMenu1: true,
  // });
  sidebarArr.push({
    name: <IntlMessages id="app.Upcoming" />,
    url: '/upcoming',
    subMenu4: false,
  });
  if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-quoteDay`)) {
    sidebarArr.push({
      name: <IntlMessages id="app.QuoteDay" />,
      url: '/quote-day',
      subMenu4: false,
    });
  }

  // sidebarArr.push({
  //   name: <IntlMessages id="app.myAccount" />,
  //   url: "/account/profile",
  //   subMenu4: false,
  // });
  // sidebarArr.push({
  //   name: "Made In Botswana",
  //   url: "/made-in-botswana",
  // });
  sidebarArr.push({
    name: 'Suggested Stock',
    url: '/suggested-stock',
  });
  // sidebarArr.push({
  //   name: <IntlMessages id="app.Exclusive" />,
  //   url: "/diamond-search",
  //   subMenu2: true,
  // });
  return sidebarArr;
}
