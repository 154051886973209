import React, { useState, useEffect } from 'react';
import Heading from '../common/Heading';
import StaticHeader from '../StaticHeader';
import './news.less';
import { GET_NEWS_DATA } from '../../constants/apiConstant';
import UtilService from '../../services/util';
import OpenNotification from '../common/CommonButton/OpenNotification';
import moment from 'moment';
import { BASE_URL_ATTACH } from '../../constants/Common';

const NewsItem = (props) => {
  return (
    <div className="newsBlockItem">
      <div className="newsItemTop">
        <span className="newsDate">{props.date}</span>
        <span className="newShortTitle">{props.smalltitle ? props.smalltitle : '-'}</span>
      </div>
      <div className="newsImage">
        <img src={`${BASE_URL_ATTACH}${props.image}`} alt="" />
      </div>
      <div className="newsContent">
        <h2>{props.title}</h2>
        <p>{props.dec ? props.dec : '-'}</p>
      </div>
    </div>
  );
};

const News = () => {
  const [data, setData] = useState([]);

  const getNewsData = () => {
    let obj = {
      ...GET_NEWS_DATA,
    };

    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        setData(data.data.list);
      } else if (data && data.code === 'E_NOT_FOUND') {
        setData([]);
        OpenNotification({
          type: 'error',
          title: err.data.message || data.message,
        });
      }
    });
  };
  useEffect(() => {
    getNewsData();
  }, []);

  return (
    <>
      <StaticHeader />
      <div className="staticBoxMain">
        <div className="statiContentBox">
          <Heading title="News Service" className="staticMainTitle text-center mb-50" />
          <div className="newsBlockMainBox d-flex flex-wrap">
            {data.length >= 1
              ? data.map((x, i) => {
                  return (
                    <NewsItem
                      key={i}
                      title={x.title}
                      dec={x.fullDescription}
                      date={moment(x.updatedAt).format('YYYY-MM-DD h:mm:ss a')}
                      smalltitle={x.shortDescription}
                      image={x.logoImage} //{require("../../assets/img/News/1.jpg")}
                    />
                  );
                })
              : 'No Latest News'}
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
