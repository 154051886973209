import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Heading from '../../common/Heading';
import InputBlock from '../../common/InputBlock';
import SelectOption from '../../common/SelectOption';
import OpenNotification from '../../common/CommonButton/OpenNotification';
import UtilService from '../../../services/util';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { notification } from 'antd';
import { BASE_URL, MIME_TYPES, LOCAL_STORAGE_VAR } from '../../../constants/Common';
import ProfileUpload from '../../common/ProfileUpload';
import IntlMessages from '../../../util/IntlMessages';
import {
  GET_COUNTRY_LIST,
  GET_STATE_LIST,
  GET_CITY_LIST,
  FETCH_USER,
  ADMIN_UPDATE_USER,
  UPLOAD_FILE,
} from '../../../constants/apiConstant';
import DefaultUser from '../../common/UserProfileDefault';
import UploadImage from '../../common/UploadImage/index';
import find from 'lodash/find';
import join from 'lodash/join';
import split from 'lodash/split';
import { numberValidator } from '../../../services/Commonfunction';
/* eslint jsx-a11y/anchor-is-valid: 0 */

let PERSONAL_DOCS =
  (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
  [];
class PersonalInformation extends Component {
  state = {
    countries: [],
    states: [],
    cities: [],
    errors: [],
    data: {},
    doc: '',
  };

  componentDidMount() {
    this.fetchUser();
    this.getCountries();
    PERSONAL_DOCS =
      (JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)) &&
        JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)).DOC_TYPE_PERSONAL) ||
      [];
  }

  getCountries = () => {
    const self = this;
    const obj = {
      ...GET_COUNTRY_LIST,
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ countries: data.data });
      }
    });
  };

  getStates = (countryId) => {
    const self = this;
    const obj = {
      ...GET_STATE_LIST,
      request: { country: countryId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ states: data.data });
      }
    });
  };

  getCities = (stateId) => {
    const self = this;
    const obj = {
      ...GET_CITY_LIST,
      request: { state: stateId },
    };
    UtilService.callApi(obj, function (err, data) {
      if (data && data.code === 'OK') {
        self.setState({ cities: data.data });
      }
    });
  };

  fetchUser() {
    const self = this;
    const obj = {
      ...FETCH_USER,
    };
    UtilService.callApi(obj, function (err, data) {
      if (err && err.data.code === 'E_BAD_REQUEST') {
        return OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      } else if (data && data.code === 'OK') {
        !isEmpty(data.data.kyc) &&
          data.data.kyc.map((k) => {
            if (k.type === 'personal') self.setState({ doc: [k.docType] });
          });

        self.setState({ data: data.data });
        self.getStates(data.data.country);
        self.getCities(data.data.state);
      }
    });
  }

  updateUser() {
    const self = this;
    const reqObj = clone(self.state.data);

    reqObj.name = `${reqObj.firstName} ${reqObj.lastName}`;
    //reqObj.kyc = [{ ...reqObj.kyc, path: reqObj.photoId }];
    reqObj.kyc = self.props.kyc;
    reqObj.address = join([reqObj.address, reqObj.address2, reqObj.address3], ',');

    const obj = {
      ...ADMIN_UPDATE_USER,
      request: reqObj,
    };

    if (self.handleValidation()) {
      UtilService.callApi(obj, function (err, data) {
        if (data && data.code === 'OK') {
          //self.props.onClose();

          OpenNotification({
            type: 'success',
            title: 'Personal details are updated successfully.',
          });
          const storageObj = reqObj;
          storageObj.account = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`)?.account;
          UtilService.setLocalStorageItem(storageObj, `${LOCAL_STORAGE_VAR}-user`);
          self.props.onClose();
          self.props.fetchUser();
        }

        if (err?.code !== 'OK') {
          if (err.data.code === 'E_USER_NOT_FOUND') {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
          if (err.data.code === 'E_DUPLICATE') {
            return OpenNotification({
              type: 'error',
              title: err.data.message,
            });
          }
        }
        OpenNotification({
          type: 'error',
          title: err.data.message,
        });
      });
    }
  }

  uploadDocument = (ee, type) => {
    const e = cloneDeep(ee);
    const self = this;
    const errors = clone(this.state.errors);
    const formData = clone(this.state.data);

    var blob = e.target.files[0];
    var fileReader = new FileReader();
    fileReader.onloadend = function (ev) {
      var arr = new Uint8Array(ev.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if (!MIME_TYPES[blob.type] || (MIME_TYPES[blob.type] && !MIME_TYPES[blob.type].includes(header))) {
        return OpenNotification({
          type: 'error',
          title: 'File format is not supported. Please upload .jpg, .jpeg, .png or .pdf file.',
        });
      } else {
        const data = new FormData();
        data.append('folder', 'image');
        data.append('destination', 'image');
        data.append('file', e.target.files[0]);

        const objData = {
          ...UPLOAD_FILE,
          request: data,
        };
        UtilService.callApi(objData, function (err, data) {
          if (err) throw err;
          if (data && data.code === 'OK') {
            errors[type] = undefined;
            if (type === 'photoId') {
              self.props.handleKyc('personal', data.data.files[0].absolutePath, self.state.doc);
            }
            formData[type] = data.data.files[0].absolutePath;
            self.setState({ data: formData, errors });
          }
        });
      }
    };
    fileReader.readAsArrayBuffer(blob);
  };

  handleInputChange = (key, value) => {
    const { data } = this.state;
    data[key] = value;
    this.setState({ data: data });
  };

  handleValidation = () => {
    const formData = this.state.data;
    const errors = this.state.errors;
    let formIsValid = true;
    const blankField = [];
    const invalidFields = [];
    if (!formData['firstName']) {
      blankField.push('First Name');
      formIsValid = false;
    }
    if (formData['firstName']) {
      if (!formData['firstName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('firstName');
        formIsValid = false;
        errors['firstName'] = 'Please enter valid First Name.';
      } else {
        errors['firstName'] = undefined;
      }
    }

    if (!formData['lastName']) {
      blankField.push('Last Name');
      formIsValid = false;
    }
    if (formData['lastName']) {
      if (!formData['lastName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('lastName');
        formIsValid = false;
        errors['lastName'] = 'Please enter valid Last Name.';
      } else {
        errors['lastName'] = undefined;
      }
    }

    if (!formData['address']) {
      blankField.push('Address 1');
      formIsValid = false;
    } else {
      errors['address'] = undefined;
    }

    if (!formData['email']) {
      blankField.push('Email Address');
      formIsValid = false;
    }
    if (formData['email']) {
      if (formData['email'] && !formData['email'].match(/^[a-zA-Z0-9]+[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[a-zA-Z.]{2,10}$/)) {
        invalidFields.push('Email');
        formIsValid = false;
        errors['email'] = 'Please enter valid Email.';
      } else {
        errors['email'] = undefined;
      }
    }

    if (!formData['country'] || isEmpty(formData['country'])) {
      blankField.push('Country');
      formIsValid = false;
      errors['country'] = true;
    } else {
      errors['country'] = undefined;
    }

    if (!formData['state'] || isEmpty(formData['state'])) {
      blankField.push('State');
      formIsValid = false;
      errors['state'] = true;
    } else {
      errors['state'] = undefined;
    }

    if (!formData['city'] || isEmpty(formData['city'])) {
      blankField.push('City');
      formIsValid = false;
      errors['city'] = true;
    } else {
      errors['city'] = undefined;
    }

    if (!formData['mobile'] || isEmpty(formData['mobile'])) {
      blankField.push('Mobile No.');
      formIsValid = false;
    }
    if ((formData['mobile'] && formData['mobile'].length < 8) || formData['mobile'].length > 15) {
      invalidFields.push('Mobile No.');
      formIsValid = false;
      errors['mobile'] = 'Please enter valid Mobile No.';
    } else {
      errors['mobile'] = undefined;
    }

    if (!formData['pinCode'] || isEmpty(formData['pinCode'])) {
      blankField.push('Pincode');
      formIsValid = false;
      errors['pinCode'] = true;
    } else if (
      formData['pinCode'] &&
      !formData['pinCode'].match(/^[0-9]{4,6}$/)
      // !formData["pinCode"].match(/^(\d{3}|\d{{7})$/)
    ) {
      invalidFields.push('Pincode');
      formIsValid = false;
      errors['pinCode'] = true;
    }

    if (blankField.length > 0) {
      const arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      const arr = invalidFields;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  render() {
    const { countries, data, states, cities } = this.state;

    return (
      <div>
        <div className="profileHead">
          <Heading title={<IntlMessages id="app.PersonalInformation" />} className="popupInnerTitle"></Heading>
          <div className="personalProfileWrapper">
            <div className="editProfileLogo">
              {/* <img
                alt=""
                src={
                  isEmpty(data.profileImage)
                    ? require("../../../assets/img/user-default.png")
                    : BASE_URL + "/" + data.profileImage
                }
              /> */}
              {data.profileImage ? (
                <img src={`${BASE_URL}/${data.profileImage}`} alt="" />
              ) : (
                <DefaultUser size="lg-size" />
              )}
            </div>
            <div className="profileAction">
              <ProfileUpload
                label={<IntlMessages id="app.EditProfile" />}
                accept="jpg, png,jpeg,PNG,JPEG,JPG"
                onChange={(e) => this.uploadDocument(e, 'profileImage')}
              />
              <a onClick={() => this.handleInputChange('profileImage', '')}>
                <IntlMessages id="app.RemoveProfile" />
              </a>
            </div>
          </div>
        </div>
        <div className="profileDetailSpace">
          <IntlMessages id="app.FirstNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('firstName', e.target.value)}
                placeholder={placeholder}
                value={data.firstName}
                label={
                  <>
                    <IntlMessages id="app.FirstNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>

          <IntlMessages id="app.MiddleNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('middleName', e.target.value)}
                placeholder={placeholder}
                value={data.middleName}
                label={
                  <>
                    <IntlMessages id="app.MiddleNameLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.LastNameLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('lastName', e.target.value)}
                placeholder={placeholder}
                value={data.lastName}
                label={<IntlMessages id="app.LastNameLabel" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.Address1Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('address', e.target.value)}
                placeholder={placeholder}
                value={data.address && split(data.address, ',') && split(data.address, ',')[0]}
                label={<IntlMessages id="app.Address1Label" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.Address2Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('address2', e.target.value)}
                placeholder={placeholder}
                value={data.address && split(data.address, ',') && split(data.address, ',')[1]}
                label={<IntlMessages id="app.Address2Label" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.Address3Label">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('address3', e.target.value)}
                value={data.address && split(data.address, ',') && split(data.address, ',')[2]}
                placeholder={placeholder}
                label={<IntlMessages id="app.Address3Label" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.EmailLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('email', e.target.value)}
                placeholder={placeholder}
                value={data.email}
                label={
                  <>
                    <IntlMessages id="app.EmailLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <SelectOption
            value={data.country}
            selectValue={countries}
            onChange={(value) => {
              const data = clone(this.state.data);
              data['state'] = undefined;
              data['city'] = undefined;

              data['country'] = value;
              this.getStates(value);
              this.setState({ data, cities: [] });
            }}
            label={
              <>
                <IntlMessages id="app.CountryLabel" />
              </>
            }
          />
          <SelectOption
            value={data.state}
            selectValue={states}
            onChange={(value) => {
              const data = clone(this.state.data);
              data['city'] = undefined;

              data['state'] = value;
              this.getCities(value);
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.StateLabel" />
              </>
            }
          />
          <SelectOption
            value={data.city}
            selectValue={cities}
            onChange={(value) => {
              const data = clone(this.state.data);
              data['city'] = value;
              this.setState({ data });
            }}
            label={
              <>
                <IntlMessages id="app.CityLabel" />
              </>
            }
          />
          <IntlMessages id="app.pinCodeLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('pinCode', e.target.value)}
                type="number"
                placeholder={placeholder}
                value={data.pinCode}
                label={
                  <>
                    <IntlMessages id="app.pinCodeLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.WhatsAppLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('whatsapp', e.target.value)}
                type="number"
                onKeyPress={numberValidator}
                placeholder={placeholder}
                value={data.whatsapp}
                label={<IntlMessages id="app.WhatsAppLabel" />}
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.MobileNoLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('mobile', e.target.value)}
                type="number"
                onKeyPress={numberValidator}
                placeholder={placeholder}
                value={data.mobile}
                label={
                  <>
                    <IntlMessages id="app.MobileNoLabel" />
                  </>
                }
              />
            )}
          </IntlMessages>
          <IntlMessages id="app.SkypeLabel">
            {(placeholder) => (
              <InputBlock
                onChange={(e) => this.handleInputChange('skype', e.target.value)}
                placeholder={placeholder}
                value={data.skype}
                label={<IntlMessages id="app.SkypeLabel" />}
              />
            )}
          </IntlMessages>
          <SelectOption
            value={
              find(PERSONAL_DOCS, { id: this.state.doc }) ? find(PERSONAL_DOCS, { id: this.state.doc }).name : undefined
            }
            placeholder={'Select Document'}
            selectValue={PERSONAL_DOCS}
            onChange={(value) => {
              this.setState({ doc: value }, () => this.props.handleKyc('personal', null, this.state.doc));
            }}
            label="Document Type*"
          />

          <UploadImage file={data.photoId} leftAlign onUpload={(e) => this.uploadDocument(e, 'photoId')} />
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={() => this.updateUser()}>
            <IntlMessages id="app.my-account.SavePersonalDetail" />
          </a>
          <a className="commonButton" onClick={() => this?.props?.onClose()}>
            <IntlMessages id="app.Cancel" />
          </a>
        </div>
      </div>
    );
  }
}
export default withRouter(PersonalInformation);
