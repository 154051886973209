import { forEach, isArray, isEmpty, isNotEmpty, isObject, isString, logWarn } from 'util/utils';

const initialState = {
  selectedRows: {},
  selectedRowIds: {},
};

export const DiamondActionTypes = {
  SELECT_ROW: 'SELECT_ROW',
  SELECT_ROWS: 'SELECT_ROWS',
  UNSELECT_ROW: 'UNSELECT_ROW',
  UNSELECT_ROWS: 'UNSELECT_ROWS',
  RESET_SELECTED_ROWS: 'RESET_SELECTED_ROWS',
};

export const DiamondActions = {
  selectRow: (payload = {}) => ({ type: DiamondActionTypes.SELECT_ROW, ...payload }),
  unSelectRow: (payload = {}) => ({ type: DiamondActionTypes.UNSELECT_ROW, ...payload }),
  selectRows: (payload = {}) => ({ type: DiamondActionTypes.SELECT_ROWS, ...payload }),
  unSelectRows: (payload = {}) => ({ type: DiamondActionTypes.UNSELECT_ROWS, ...payload }),
  resetSelectedRows: (payload = {}) => ({ type: DiamondActionTypes.RESET_SELECTED_ROWS, ...payload }),
};

const getFormattedPayload = (state, data) => {
  const payload = isObject(data?.payload) ? [data?.payload] : isArray(data?.payload) ? data?.payload : [];
  const currentType = isString(data?.currentType) ? data?.currentType : undefined;
  const currentRows = data?.replace ? [] : state?.selectedRows?.[currentType] ?? [];
  const currentRowIds = data?.replace ? [] : state?.selectedRowIds?.[currentType] ?? [];
  const uniqueBy = data?.uniqueBy;
  return { payload, currentType, currentRows, currentRowIds, uniqueBy };
};

const updateRowClassList = (accessor, rowId, select = false) => {
  try {
    const selector =
      !isEmpty(accessor) && !isEmpty(rowId)
        ? `table.${accessor}-table tr[table-row${accessor ? `="${accessor}${rowId}"` : ''}]`
        : !isEmpty(accessor)
        ? `table.${accessor}-table tr[table-row]`
        : undefined;

    const tableRows = document.querySelectorAll(selector);

    select
      ? forEach(tableRows, (row) => row.classList.add('selectTr'))
      : forEach(tableRows, (row) => row.classList.remove('selectTr'));
  } catch (error) {
    logWarn({ updateRowClassListError: error });
  }
};

export default (state = initialState, { type, ...data }) => {
  switch (type) {
    case DiamondActionTypes.SELECT_ROW:
    case DiamondActionTypes.SELECT_ROWS: {
      const { payload, currentType, currentRows, currentRowIds, uniqueBy } = getFormattedPayload(state, data);
      if (isString(uniqueBy) && payload.length === 1) {
        const found = currentRows.find((row) => row?.uniqueBy === payload?.[0]?.uniqueBy);
        if (!isEmpty(found)) return state;
      }

      const newRows = payload
        .map((row) => (!currentRowIds.includes(row?.selectionKey ?? row?.id) ? row : undefined))
        .filter(isNotEmpty);
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);
      newRowIds.map((id) => updateRowClassList(currentType, id, true));

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: [...currentRows, ...newRows] },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: [...currentRowIds, ...newRowIds] },
      };
    }

    case DiamondActionTypes.UNSELECT_ROW:
    case DiamondActionTypes.UNSELECT_ROWS: {
      const { payload, currentType, currentRows } = getFormattedPayload(state, data);
      const rowIdsToRemove = payload.map((row) => row?.selectionKey ?? row?.id);
      rowIdsToRemove.forEach((id) => updateRowClassList(currentType, id, false));

      const newRows = currentRows.filter((row) => !rowIdsToRemove.includes(row?.selectionKey ?? row?.id));
      const newRowIds = newRows.map((row) => row?.selectionKey ?? row?.id);

      return {
        ...state,
        selectedRows: { ...state?.selectedRows, [currentType]: newRows },
        selectedRowIds: { ...state?.selectedRowIds, [currentType]: newRowIds },
      };
    }

    case DiamondActionTypes.RESET_SELECTED_ROWS: {
      const { currentType } = getFormattedPayload(state, data);
      updateRowClassList(currentType);
      if (!isEmpty(currentType)) {
        delete state?.selectedRows?.[currentType];
        delete state?.selectedRowIds?.[currentType];
        return {
          ...state,
          selectedRows: { ...state?.selectedRows },
          selectedRowIds: { ...state?.selectedRowIds },
        };
      }
      return { ...initialState };
    }

    default:
      return state;
  }
};
