import React, { useEffect, useState } from 'react';
import { GET_NOTIFICATIONS, MARK_AS_READ } from '../constants/apiConstant';
import UtilService from '../services/util';
import moment from 'moment';
import Pagination from './common/Pagination';
import { getGridHeight } from './DiamondList';

const NotificationItem = (props) => {
  return (
    <div onClick={props.onClick} className={`notificationDropdownDetail ${!props.read && 'readMessage'}`}>
      <div className={'norificationDropdownItem'}>
        <h2>{props.title}</h2>
        {props.pcv && <div className="d-flex flex-wrap basicDetail"></div>}
        <span>{props.time}</span>
      </div>
    </div>
  );
};

const NotificationDropdown = (props) => {
  const [notifyData, setNotifyData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, seTotal] = useState(0);
  const [unReadCount, setUnReadCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getNotifications = () => {
    let obj = {
      ...GET_NOTIFICATIONS,
      request: {
        page: page,
        limit: limit,
      },
    };
    setIsLoading(true);
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === 'OK') {
        if (data.data) {
          seTotal(data.data.count);
          setNotifyData([...data.data.list]);
          setUnReadCount(data.data.unReadCount);
        }
      } else {
        setNotifyData([]);
        setUnReadCount(0);
      }
    });
    setIsLoading(false);
  };

  const MarkAsRead = (id) => {
    let obj = {
      ...MARK_AS_READ,
      request: {
        id: id,
      },
    };
    UtilService.callApi(obj, function (err, data) {
      if (err) {
      } else if (data && data.code === 'OK') {
        getNotifications();
      } else {
      }
    });
  };

  useEffect(() => {
    //EventManager.emit("@notifications/unReadCount", { unReadCount });
  }, [unReadCount]);

  useEffect(() => {
    getNotifications();
  }, [page]);

  let gridHeight = getGridHeight(
    ['HeaderSticky', 'accountTopBlock', 'accountTabBox', 'botoomStickyBar', 'mainHeadingTitle'],
    30,
  );

  return (
    <div className={`notificationDropdown ${isLoading && `loading-indicator`}`}>
      <h2 className="mainHeadingTitle p-0 mb-20">Notifications</h2>
      <div className="notificationItem" style={{ height: gridHeight, overflow: 'auto' }}>
        <div className="notificationInner">
          {notifyData && notifyData.length >= 1
            ? notifyData.map((e, i) => {
                let time = moment(e.updatedAt).fromNow();
                return (
                  <NotificationItem
                    key={i}
                    onClick={() => {
                      MarkAsRead(e.id);
                    }}
                    title={e.title}
                    pcv={e.message}
                    time={time}
                    read={e.isRead}
                  />
                );
              })
            : 'No Notifications Found'}
        </div>
      </div>
      <div className="botoomStickyBar bottom-0">
        <div></div>
        <Pagination
          count={total}
          limit={limit}
          page={page}
          onPaginationChange={(page, pageSize) => {
            setPage(page);
          }}
          data={notifyData}
          noLimitChange
        />
      </div>
    </div>
  );
};
export default NotificationDropdown;
