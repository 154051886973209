import React from 'react';

const SortingClose = () => {
  return (
    <div className="shortingClose">
      <img src={require('../../../assets/svg/DiamondList/close.svg')} alt="icon" />
    </div>
  );
};
export default SortingClose;
