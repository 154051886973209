import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../PageNotFound/pageNotFound.less';
import maintanance from './maintanance.png';

class Maintanance extends Component {
  render() {
    return (
      <div className="thankYouWrapper">
        <div className="thankYouInnerBox">
          <div className="thankYouMainBox">
            <div className="thankYouImgBlocks">
              <img src={maintanance} alt="Under Maintanance" />
            </div>
            <div className="thankYouPageContent">
              <h2>
                Dear Customer, Thank you for visiting our website. Currently, Our system is under maintenance. Please
                try again after sometime.
              </h2>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Maintanance;
