import {
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  MASTER_DATA_LOGIN,
  KEYWORDS_DATA,
  OTP_VERIFICATION,
  SHOW_DATA,
} from 'constants/ActionTypes';
import { LOCAL_STORAGE_VAR } from 'constants/Common';
import UtilService from 'services/util';

const INIT_STATE = {
  token: UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`),
  initURL: '',
  authUser: UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`),
  showData: JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-show-details`)),
  masterData: JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-master`)),
  keywordsData: JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-keywords`)),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNOUT_USER_SUCCESS:
      return { ...state, token: null, authUser: null, masterData: null, initURL: '' };

    case USER_DATA:
      return { ...state, authUser: action.payload };

    case OTP_VERIFICATION:
      return { ...state, authUser: action.payload };

    case USER_TOKEN_SET:
      return { ...state, token: action.payload };

    case MASTER_DATA_LOGIN:
      return { ...state, masterData: action.payload };

    case KEYWORDS_DATA:
      return { ...state, keywordsData: action.payload };

    case SHOW_DATA:
      return { ...state, showData: action.payload };

    default:
      return state;
  }
};
