export const data = [
  {
    key: '1',
    status: 'green',
    statusname: 'Available',
    statushistory: 'Return',
    statsClass: 'return',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '2',
    status: 'red',
    statusname: 'Hold',
    statushistory: 'Sold',
    statsClass: 'sold',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '3',
    status: 'blue',
    statusname: 'Show',
    statushistory: 'Pending',
    statsClass: 'pending',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '4',
    status: 'green',
    statusname: 'Available',
    statushistory: 'Pending',
    statsClass: 'pending',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '5',
    status: 'green',
    statusname: 'Available',
    statushistory: 'Pending',
    statsClass: 'pending',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '6',
    status: 'green',
    statusname: 'Available',
    statushistory: 'Pending',
    statsClass: 'pending',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '7',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '8',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '9',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '10',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '11',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '12',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '13',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '14',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '15',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '16',
    status: 'green',
    lab: 'GIA',
    statusname: 'Available',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '17',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '18',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '19',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
  {
    key: '20',
    status: 'green',
    statusname: 'Available',
    lab: 'GIA',
    packetno: 'CS-131013',
    reportno: '2201163794',
    location: 'U.S.A.',
    dor: 'YES',
    Xray: 'Xray',
    ftc: 'FTC',
    cmno: 'CM',
    shape: 'CUSHION',
    carat: '10.03',
    color: 'YELLOW',
    clarity: 'SI2',
    shade: 'LYL',
    bri: 'EX',
    rap: '3500',
    discount: '-25.00',
    pricect: '2625.00',
    cut: 'EX',
    pol: 'EX',
    sym: 'EX',
    fol: 'MED',
    cn: 'CN1',
    sn: 'SN0',
    cw: 'CW1',
    sw: 'SW1',
    to: 'N',
    co: 'N',
    po: 'N',
    ec: 'Y',
    tb: '57.00',
    dpper: '69.90',
    length: '6.29',
    width: '6.25',
    dp: '69.80',
    ratio: '1.00',
    ca: '42.62',
    ch: '9.54',
    pa: '39.30',
    ph: '57.84',
    gridle: 'STK',
    culet: 'NON',
    ins: 'Y',
    ha: 'EX',
    reportcomment: 'Additional pinpoints are not shown....',
    keysym: 'Pinpoint',
    lower: '-',
    star: '-',
    gridleper: '0',
    seal: '-',
    roughmin: '-',
    contyryorigin: 'RUSSIA',
    certificateno: '1234567890',
  },
];
