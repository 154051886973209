export const AddressData = [
  {
    name: 'Vishla Virani',
    address1: '9th Floor, Infinity Tower, Lal Darwaja Station Road',
    address2: 'beside Ayurvedic College',
    city: 'Surat',
    state: 'Gujarat',
    zipcode: '395003',
    country: 'India',
  },
  {
    name: 'Kalpesh Zalavadiya',
    address1: '9th Floor, Infinity Tower, Lal Darwaja Station Rd',
    address2: 'beside Ayurvedic College',
    city: 'Surat',
    state: 'Gujarat',
    zipcode: '395003',
    country: 'India',
  },
];
