import { callApi, withQueryParams } from 'util/CallAPI';

import { GET_SERVER_TIME, BID_CONFIG } from 'constants/apiConstant';
import { store } from 'appRedux/store';
import { BID_ACTIONS } from 'appRedux/reducers/bidConfig';

export class BidService {
  static get state() {
    return store.getState().bidConfig;
  }

  static set bid(data) {
    store.dispatch(BID_ACTIONS.setBidConfig(data));
  }

  static get bid() {
    return BidService.state.data;
  }

  static startLoading() {
    store.dispatch(BID_ACTIONS.startLoading());
  }
  static stopLoading() {
    store.dispatch(BID_ACTIONS.stopLoading());
  }

  static get GET_BID_CONFIG_DEFAULT_PARAMS() {
    return { type: 1, t: Math.random() };
  }

  static async getCurrentTime() {
    const [err, res] = await callApi(withQueryParams(GET_SERVER_TIME, { t: Math.random() }));
    return !err ? res?.data : undefined;
  }

  static async getBidConfig(params = BidService.GET_BID_CONFIG_DEFAULT_PARAMS) {
    if (BidService.state.loading) return BidService.bid;

    BidService.startLoading();
    const [err, res] = await callApi(withQueryParams(BID_CONFIG, params));
    const payload = !err ? { data: res?.data, error: {} } : { data: {}, error: res?.data };
    BidService.bid = payload;
    BidService.stopLoading();

    return payload?.data;
  }

  static resetBidConfig() {
    BidService.bid = {};
  }
}

window.BidService = BidService;
