import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUser, guestUserLogin } from '../../appRedux/actions/Auth';
import IntlMessages from '../../util/IntlMessages';
import Loader from '../common/Loader';
import UtilService from '../../services/util';
import { Form, Checkbox, notification, Drawer } from 'antd';
import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import InputBlock from '../common/InputBlock';
import { LOCAL_STORAGE_VAR } from '../../constants/Common';
import axios from 'axios';
import CheckBox from '../common/CheckBox';
import { GUEST_LOGIN_USER } from '../../constants/apiConstant';
import OpenNotification from '../common/CommonButton/OpenNotification';
import TermsCondition from '../TermsConditionPopup';
import { numberValidator } from '../../services/Commonfunction';
import { store } from 'appRedux/store';
import { USER_DATA, USER_TOKEN_SET } from 'constants/ActionTypes';

class GuestLoginFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guestFields: {
        firstName: '',
        lastName: '',
        companyName: '',
        mobile: '',
        email: '',
      },
      checked: false,
      errors: {},
      visible: false,
    };
    this.handleGuestLogin = this.handleGuestLogin.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleGuestLogin = (e) => {
    const self = this;
    e.preventDefault();
    const reqObj = clone(this.state.guestFields);
    if (this.handleValidation()) {
      if (this.state.checked) {
        const objData = {
          ...GUEST_LOGIN_USER,
          request: {
            ...reqObj,
            masters: [
              'COLOR',
              'CLARITY',
              'SHAPE',
              'CUT',
              'FLUORESCENCE',
              'SHADE',
              'LAB',
              'POLISH',
              'SYMMETRY',
              'LOCATION',
              'BLACK_INCLUSION',
              'OPEN_TABLE',
              'MILKEY',
              'WHITE_INCLUSION_CROWN',
              'OPEN_CROWN',
              'EYECLEAN',
              'OPEN_PAVILION',
              'ORIGIN',
              'BLACK_INCLUSION_CROWN',
              'H_AND_A',
              'WHITE_INCLUSION_TABLE',
              'FANCY_COLOR',
              'INTENSITY',
              'OVERTONE',
              'KEY_TO_SYMBOLS',
              'GIRDLE',
              'CULET',
              'GIRDLE_COND',
              'CULET_COND',
              'COMPANY_SIZE',
              'MIX_TINT',
              'NATURAL',
              'OPEN_INCLUSION',
              'DAY_TERM',
            ],
          },
        };
        UtilService.callApi(objData, async function (err, data) {
          if (err) {
            return OpenNotification({
              type: 'error',
              title: err.data.message || err.data.data.message || 'Failed to Login.',
            });
          }
          if (data && data.code === 'OK') {
            self.getRedirection(data);
          }
        });
      } else {
        OpenNotification({
          type: 'error',
          title: 'You must be agree to terms and condition to create an account',
        });
      }
    }
  };

  componentDidMount() {
    window.addEventListener('click', (e) => {
      if (document.activeElement && document.activeElement.localName !== 'button') {
        notification.destroy();
      }
    });
  }

  TermPopup = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  getRedirection = (data) => {
    const user = data.data.user;
    // if (
    //   data.data.userPermissions &&
    //   (!data.data.userPermissions.data || !data.data.userPermissions.data.length)
    // ) {
    //   return OpenNotification({
    //     type: "error",
    //     title: "Failed to Login.",
    //   });
    // }
    const stepDisplay = {
      dashboard: !user.isIntoHide,
      diamondSearch: !user.isIntoHide,
      searchResult: !user.isIntoHide,
      diamondDetails: !user.isIntoHide,
    };

    const masters = data.data.masters;
    const columns = data.data.columns;
    const userPermissions = data.data.userPermissions;
    const keywords = data.data.keywords;
    const token = data.data.token.jwt;

    // localStorage.setItem(`${LOCAL_STORAGE_VAR}-token`, JSON.stringify(token));
    if (user.type === 5) {
      localStorage.setItem(`${LOCAL_STORAGE_VAR}-guest`, JSON.stringify(true));
    }

    UtilService.setLocalStorageItem(token, `${LOCAL_STORAGE_VAR}-token`);
    UtilService.setLocalStorageItem(user, `${LOCAL_STORAGE_VAR}-user`);
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-master`, JSON.stringify(masters));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-column`, JSON.stringify(columns));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-keywords`, JSON.stringify(keywords));
    localStorage.setItem(`${LOCAL_STORAGE_VAR}-tooltip-steps`, JSON.stringify(stepDisplay));

    localStorage.setItem(`${LOCAL_STORAGE_VAR}-isTrusted`, data.data.isTrusted);
    const setPermit = {};

    userPermissions.data.map((x) => {
      if (x.module === 'order' && user.Setting) {
        x.permissions.insert = user.Setting.Is_PlaceOrder ? user.Setting.Is_PlaceOrder : false;
      }

      if (x.module === 'pricing' && user.Setting && user.Setting.Is_NoPrice !== true) {
        x.permissions.view = user.Setting.Is_NoPrice !== true ? true : false;
        x.permissions.all = user.Setting.Is_NoPrice !== true ? true : false;
      }

      setPermit[x.module] = x.permissions;
      return true;
    });

    if (setPermit && !setPermit.pricing) {
      if (user.Setting && user.Setting.Is_NoPrice !== true) {
        setPermit.pricing = {};
        setPermit.pricing.view = user.Setting.Is_NoPrice !== true ? true : false;
        setPermit.pricing.all = user.Setting.Is_NoPrice !== true ? true : false;
      }
    }

    UtilService.setLocalStorageItem(setPermit, `${LOCAL_STORAGE_VAR}-userPermissions`);

    axios.defaults.headers.common['access-token'] = 'JWT ' + token;
    this.props.history.push('/dashboard');
  };

  handleValidation = () => {
    const formData = this.state.guestFields;
    const errors = this.state.errors;
    let formIsValid = true;
    const blankField = [];
    const invalidFields = [];

    if (!formData['firstName'] || isEmpty(formData['firstName'])) {
      blankField.push('First Name');
      errors['firstName'] = true;
      formIsValid = false;
    } else if (formData['firstName']) {
      if (!formData['firstName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('firstName');
        formIsValid = false;
        errors['firstName'] = true; //"Please enter valid First Name.";
      } else {
        errors['firstName'] = undefined;
      }
    }

    if (formData['lastName'] && !formData['lastName'].match(/^[a-zA-Z ]+$/)) {
      invalidFields.push('lastName');
      formIsValid = false;
      errors['lastName'] = true; //"Please enter valid First Name.";
    } else if (!formData['lastName'] || isEmpty(formData['lastName'])) {
      blankField.push('Last Name');
      errors['lastName'] = true;
      formIsValid = false;
    } else {
      errors['lastName'] = undefined;
    }

    if (!formData['email'] || isEmpty(formData['email'])) {
      blankField.push('Email Address');
      errors['email'] = true;
      formIsValid = false;
    } else if (formData['email']) {
      if (
        !formData['email'].match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        invalidFields.push('Email Address');
        formIsValid = false;
        errors['email'] = true; //"Please enter valid First Name.";
      } else {
        errors['email'] = undefined;
      }
    }
    if (!formData['mobile'] || isEmpty(formData['mobile'])) {
      blankField.push('Phone Number');
      errors['mobile'] = true;
      formIsValid = false;
    } else if (formData['mobile'].length < 10 || formData['mobile'].length > 15) {
      invalidFields.push('Phone Number');
      formIsValid = false;
      errors['mobile'] = true;
    } else {
      errors['mobile'] = undefined;
    }

    if (!formData['companyName'] || isEmpty(formData['companyName'])) {
      blankField.push('Company Name');
      errors['companyName'] = true;
      formIsValid = false;
    } else if (formData['companyName']) {
      if (!formData['companyName'].match(/^[a-zA-Z ]+$/)) {
        invalidFields.push('Company Name');
        formIsValid = false;
        errors['companyName'] = true; //"Please enter valid First Name.";
      } else {
        errors['companyName'] = undefined;
      }
    }

    if (blankField.length > 0) {
      const arr = blankField;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Required Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    if (invalidFields.length > 0) {
      const arr = invalidFields;
      let outStr = '';
      if (arr.length === 1) {
        outStr = arr[0];
      } else if (arr.length === 2) {
        outStr = arr.join(' and ');
      } else if (arr.length > 2) {
        outStr = arr.slice(0, -1).join(', ') + ' and ' + arr.slice(-1);
      }
      notification.error({
        message: 'Invalid Fields',
        placement: 'bottomLeft',
        description: outStr,
      });
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  handleOnChange = (key, value) => {
    const { guestFields } = this.state;
    guestFields[key] = value;
    this.setState(guestFields);
  };

  render() {
    const { guestFields, errors } = this.state;

    return (
      <div className="loginDetail">
        <div className="loginHead">
          <h3>
            <IntlMessages id="app.Guest.login" />
          </h3>
        </div>
        {/* <div className="page-til mb-25 d-flex align-items-center">
            <h3>
              <IntlMessages id="app.Guest.login" />
            </h3>
          </div> */}
        <Loader loading={this.props.loading} />
        <Form autoComplete="off" className="signup-form" onSubmit={this.handleGuestLogin}>
          <InputBlock
            onChange={(e) => this.handleOnChange('firstName', e.target.value)}
            value={guestFields.firstName}
            type="text"
            label="First Name"
            placeholder="First Name"
            hasError={errors['firstName'] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange('lastName', e.target.value)}
            value={guestFields.lastName}
            type="text"
            label="Last Name"
            placeholder="Last Name"
            hasError={errors['lastName'] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange('email', e.target.value)}
            value={guestFields.email}
            type="text"
            label="Email"
            placeholder="Email"
            hasError={errors['email'] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange('mobile', e.target.value)}
            value={guestFields.mobile}
            type="number"
            label="Phone Number"
            placeholder="Phone Number"
            onKeyPress={numberValidator}
            hasError={errors['mobile'] ? true : false}
          />
          <InputBlock
            onChange={(e) => this.handleOnChange('companyName', e.target.value)}
            value={guestFields.companyName}
            type="text"
            label="Company Name"
            placeholder="Company Name"
            hasError={errors['companyName'] ? true : false}
          />
          <div className="check_nowrap">
            <CheckBox
              checked={this.state.checked}
              onChange={() => {
                this.setState({ checked: !this.state.checked });
              }}
              label={
                <span>
                  <span>I have read and accept </span>
                  <a className="linkTag" onClick={this.TermPopup}>
                    Terms and Conditions.
                  </a>
                </span>
              }
            />
            {/* I have read and accept{" "}
              <a className="linkTag" onClick={this.TermPopup}>
                Terms and Conditions.
              </a> */}
            {/* </Checkbox> */}
          </div>
          <Drawer
            title={false}
            onClose={this.onClose}
            visible={this.state.visible}
            wrapClassName="diamondListinSidebar"
          >
            <TermsCondition />
          </Drawer>
          <div className="mt-10 check_nowrap">
            <CheckBox
              label={
                <span>
                  <span>Promotional offers, newsletters and stock updates </span>
                </span>
              }
            />
          </div>
          <div className="mt-30 loginButton justify-center">
            <button type="submit" className="commonButton">
              Login
            </button>
          </div>
        </Form>
        <div className="registerLogLink">
          <IntlMessages id="app.already-have-acc" />
          &nbsp;
          <a
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (this.state.isDeviceUrl) {
                if (navigator.appVersion.includes('iPhone')) {
                  window.webkit.messageHandlers.LoginCallBack.postMessage('LoginCallBack');
                } else if (navigator.appVersion.includes('Android')) {
                  window.webkit.LoginCallBack('LoginCallBack');
                }
              } else {
                window.location.href = '/signin';
              }
            }}
          >
            <IntlMessages id="app.sign-In" />
          </a>
        </div>
        <div className="registerLogLink">
          Don’t have login details?
          <Link to="/signup">Register</Link>
        </div>
      </div>
    );
  }
}

const WrappedLoginForm = Form.create()(GuestLoginFrom);

const mapStateToProps = ({ auth, commonData }) => {
  const { token } = auth;
  const { loading } = commonData;
  return { token, loading };
};

export default connect(mapStateToProps, { guestUserLogin })(withRouter(WrappedLoginForm));
