import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import APILoader from 'components/common/APILoader';
import Loader from 'components/common/Loader';
import MobileHeader from 'components/MobileHeader/index';
import SidebarHeader from 'components/SidebarHeader';
import TopHeader from 'components/TopHeaderInSidebar';

import first from 'lodash/first';
import without from 'lodash/without';

class MainApp extends Component {
  state = { visible: false };

  showModal = () => this.setState({ visible: true });
  hideModal = () => this.setState({ visible: false });

  render() {
    const { loading } = this.props;
    const isMobile = window.innerWidth <= 767;

    if (window.drift) {
      if (window.drift?.on) window.drift.on('campaign:dismiss', () => {});
      if (window.drift?.api) window.drift.api.widget.hide();
    }

    return (
      <>
        {!isMobile && <TopHeader />}
        <div className="d-flex flex-wrap pageWrapperBlock">
          {/* <OnlineChat/> */}
          {loading && <Loader loading={loading} fixed />}
          <APILoader />
          {isMobile ? <MobileHeader /> : <SidebarHeader />}
          <div className="content-area  pageHeight without-footer">{this.props.children}</div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (props) => props;

export default connect(mapStateToProps)(withRouter(MainApp));
