import React, { useState, useEffect } from 'react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { handleInsertTrack, getColumn, isMobile } from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import IntlMessages from '../../../util/IntlMessages';
import { trackTypeObj } from '../../../constants/Common';
import OpenNotification from '../CommonButton/OpenNotification';
import { HeadingCalc } from './ConfirmStonePopup';
import { useSelector } from 'react-redux';
import WatchPopupMobile from './WatchPopupMobile';
import InputBlock from '../InputBlock';
import TextArea from '../TextArea';
import { RFC_2822 } from 'moment';
/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = 'WatchlistPopup';

const WatchlistPopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState('');
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(props.checked.map((x) => ({ ...x, expBack: x.back - 1 })));
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];

  const getBackSelect = (row) => {
    const back = row.back * -1;
    return (
      <select
        dropdownClassName="popupZindex"
        value={row.expBack}
        onChange={(e) => {
          const line = data;
          const ind = findIndex(line, { id: row.id });
          line[ind].expBack = e.target.value;
          setData(line);
          setExp(!exp);
        }}
        style={{ width: 120 }}
      >
        <option value={back - 1}>{(back - 1).toFixed(2)}</option>
        <option value={back - 2}>{(back - 2).toFixed(2)}</option>
        <option value={back - 3}>{(back - 3).toFixed(2)}</option>
      </select>
    );
  };

  useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    let columns = [
      ...Columns.slice(0, index + 1),
      // {
      //   Header: 'Exp Disc%',
      //   accessor: 'expBack',
      //   id: 'expBack',
      //   Cell: ({ row }) => {
      //     const back = row.original.back;
      //     if (row.original.isFcCol) {
      //       return '-';
      //     }
      //     return (
      //       <select
      //         dropdownClassName="popupZindex"
      //         value={row.original.expBack}
      //         onChange={(e) => {
      //           let line = data;
      //           let ind = findIndex(line, { id: row.original.id });
      //           line[ind].expBack = e.target.value;
      //           setData(line);
      //           setExp(!exp);
      //         }}
      //         style={{ width: 120 }}
      //       >
      //         <option value={back - 1}>
      //           {back - 1 < 0
      //             ? `-${parseFloat(Math.abs(back - 1)).toFixed(2)}`
      //             : back - 1 === 0
      //             ? Math.abs(back - 1)
      //             : `+${parseFloat(Math.abs(back - 1)).toFixed(2)}`}
      //         </option>
      //         <option value={back - 2}>
      //           {back - 2 < 0
      //             ? `-${parseFloat(Math.abs(back - 2)).toFixed(2)}`
      //             : back - 1 === 0
      //             ? Math.abs(back - 2)
      //             : `+${parseFloat(Math.abs(back - 2)).toFixed(2)}`}
      //         </option>
      //         <option value={back - 3}>
      //           {back - 3 < 0
      //             ? `-${parseFloat(Math.abs(back - 3)).toFixed(2)}`
      //             : back - 1 === 0
      //             ? Math.abs(back - 3)
      //             : `+${parseFloat(Math.abs(back - 3)).toFixed(2)}`}
      //         </option>
      //       </select>
      //     );
      //   },
      // },
    ];
    columns = [...columns, ...Columns.filter((el) => !find(columns, { id: el.id }))];

    setColumns(columns);
  }, [exp]);

  const getWatchInput = () => {
    return (
      <div className="width-50">
        <TextArea value={comment} onChange={(e) => setComment(e.target.value)} label="Comment" placeholder="Comment" />
      </div>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add in watch.',
              });
              return;
            }
            handleInsertTrack(
              trackTypeObj.WATCHLIST,
              checked.map((c) => ({
                ...c,
                expBack: find(data, { id: c.id }).expBack,
              })),
              (status) => {
                props.onClose();
                !['MyDealOfTheDay', '/deal-of-the-day'].includes(props.currentType) && props.clearAll();
                if (status) props.setCount(!props.deleteReminder.count);
              },
              '',
              comment,
            );
          }}
          className="commonButton"
        >
          <IntlMessages id="app.Add" />
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };

  return isMobile() ? (
    <WatchPopupMobile data={data} currentType={currentType} parent={{ btnAction, getBackSelect }} />
  ) : (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <h2 className="popupInnerTitle mr-40">
          <IntlMessages id="app.WatchList" />
        </h2>
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
            noCheckBox={['MyDealOfTheDay', '/deal-of-the-day'].includes(props.currentType)}
          />
        </div>
        <div className="d-flex justify-content-between offerBottomBox flex-wrap mt-20">
          <p className="offerNote">
            <b>
              <IntlMessages id="app.Note" />:
            </b>{' '}
            <IntlMessages id="app.offerNote" />
          </p>
          {getWatchInput()}
        </div>

        {btnAction()}
      </div>
    </div>
  );
};

export default WatchlistPopup;
