const ValidationMessages = {
  // validation messages
  Name: 'Please enter the name.',
  Subject: 'Please enter subject.',
  FirstName: 'Please enter the first name.',
  ownerName: 'Please enter the owner name.',
  LastName: 'Please enter the Last name.',
  NameValid: 'Please enter only alphabets.',
  EmailRequired: 'Please enter the email ID.',
  EmailValid: 'Please enter the valid email ID.',
  PhoneRequire: 'Please enter the mobile number.',
  PhoneValid: 'Please enter the valid mobile number.',
  Address: 'Please enter address.',
  City: 'Please select the city.',
  State: 'Please select the state.',
  Country: 'Please select the country.',
  CompanyName: 'Please enter the company name.',
  pinCode: 'Please enter the zip code.',
  UserName: 'Please enter the username.',
  Designation: 'Please select Designation.',
  Seller: 'Please select seller.',
  SelesPerson: 'Please select sales person.',
  Password: 'Please enter the password.',
  ConfirmPassword: 'Please enter confirm password.',
  SamePasword: 'Confirm password must match with the password.',
  PasswordValid: 'Please enter valid password.',
  PasswordLength: 'Password must be atleast 8 character.',
  Message: 'Please enter message.',
  BusinessType: 'Please select business type.',
  PersonalDoc: 'Please upload personal document.',
  BusinessDoc: 'Please upload business document.',
  PersonalType: 'Please select Personal Document Type',
  BusinessType: 'Please select Business Document Type',
};

export default ValidationMessages;
