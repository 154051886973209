import React, { Component } from 'react';
import { connect } from 'react-redux';
import OpenNotification from '../common/CommonButton/OpenNotification';
import IntlMessages from '../../util/IntlMessages';
import { formatDecimal, isEmpty, parseDecimal } from 'util/utils';

class InputRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: '',
      to: '',
      isChanged: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.commonData.reset && nextProps.commonData.reset.searchReset) {
      if (this.props.commonData.reset !== nextProps.commonData.reset) {
        this.setState({ from: '', to: '' });
      }
    }

    if (nextProps.commonData.modifySearch && nextProps.commonData.modifySearch[this.props.apiKey]) {
      if (this.props.commonData.modifySearch !== nextProps.commonData.modifySearch) {
        const from = nextProps.commonData.modifySearch[this.props.apiKey]['>='];
        const to = nextProps.commonData.modifySearch[this.props.apiKey]['<='];

        if (from < 0 && to < 0) {
          this.setState({ from, to }, () => {
            this.onChange('from', to);
            this.onChange('to', from);
          });
        } else {
          this.setState({ from, to }, () => {
            this.onChange('from', from);
            this.onChange('to', to);
          });
        }
        // this.setState({ from, to }, () => {
        //   this.onChange('from', from);
        //   this.onChange('to', to);
        // });
      }
    }
  }

  componentDidMount() {
    if (this.props.commonData.modifySearch && this.props.commonData.modifySearch[this.props.apiKey]) {
      const from = this.props.commonData.modifySearch[this.props.apiKey]['>='];
      const to = this.props.commonData.modifySearch[this.props.apiKey]['<='];
      // if (from < 0 && to < 0) {
      //   this.setState({ from, to }, () => {
      //     this.onChange('from', to);
      //     this.onChange('to', from);
      //   });
      // } else {
      // this.setState({ from, to }, () => {
      //   this.onChange('from', from);
      //   this.onChange('to', to);
      // });
      // }
    }
  }

  onMinChange = (e) => {
    this.onChange('from', e.target.value);
  };

  onMaxChange = (e) => {
    this.onChange('to', e.target.value);
  };

  onChange = (field, value) => {
    this.setState({
      [field]: value,
      isChanged: true,
    });
  };

  validate = () => {
    const { Min, Max, allowZero = false, allowSame = true } = this.props;
    const { from, to } = this.state;

    if ((from && to) || (parseFloat(from) === 0 && to)) {
      if (parseFloat(from) < Min || parseFloat(to) > Max) {
        this.setState({ from: '', to: '' });
        this.props.selectedRange('', '', this.props.apiKey);
        return OpenNotification({
          type: 'error',
          title: `Must enter between ${Min} to ${Max}.`,
        });
      }
      //   if (allowSame ? parseFloat(from) > parseFloat(to) : parseFloat(from) >= parseFloat(to) && from > 0 && to > 0) {
      //     this.setState((prevState) => ({
      //       from: parseFloat(from) > Max ? '' : prevState.from,
      //       to: '',
      //       isChanged: true,
      //     }));
      //     this.props.selectedRange('', '', this.props.apiKey);
      //     return OpenNotification({
      //       type: 'error',
      //       title: 'To must be greater than from.',
      //     });
      //   }
      //   this.props.selectedRange(from, to, this.props.apiKey);
      // }
      if (
        parseFloat(from) > 0 &&
        parseFloat(to) > 0 &&
        (allowSame ? parseFloat(from) > parseFloat(to) : parseFloat(from) >= parseFloat(to))
      ) {
        this.setState((prevState) => ({
          from: parseFloat(from) > Max ? '' : prevState.from,
          to: '',
          isChanged: true,
        }));
        this.props.selectedRange('', '', this.props.apiKey);
        return OpenNotification({
          type: 'error',
          title: 'To must be greater than from.',
        });
      }
      if (
        parseFloat(from) < 0 &&
        parseFloat(to) < 0 &&
        (allowSame ? parseFloat(from) < parseFloat(to) : parseFloat(from) <= parseFloat(to))
      ) {
        this.setState((prevState) => ({
          from: parseFloat(from) > Max ? '' : prevState.from,
          to: '',
          isChanged: true,
        }));
        this.props.selectedRange('', '', this.props.apiKey);
        return OpenNotification({
          type: 'error',
          title: 'To must be greater than from.',
        });
      }
      if (parseFloat(from) > 0 && parseFloat(to) > 0) {
        this.props.selectedRange(from, to, this.props.apiKey);
      } else {
        this.props.selectedRange(to, from, this.props.apiKey);
      }
    }

    if (parseFloat(to) < Min || parseFloat(to) > Max) {
      this.setState((prevState) => ({
        from: prevState.from,
        to: '',
        isChanged: true,
      }));
      this.props.selectedRange('', '', this.props.apiKey);
      return OpenNotification({
        type: 'error',
        title: `Must enter between ${Min} to ${Max}.`,
      });
    }
    if (!allowZero && parseFloat(from) === 0 && parseFloat(to) === 0) {
      this.setState({ from: '', to: '' });
      this.props.selectedRange('', '', this.props.apiKey);
      return OpenNotification({
        type: 'error',
        title: `Must enter between ${Min} to ${Max}.`,
      });
    }
    if ((!from || !to || from === '' || to === '') && this.state.isChanged) {
      this.props.selectedRange('', '', this.props.apiKey);
    }
  };

  render() {
    const { noNegative, Min } = this.props;
    return (
      <div className="diamondSearchInnerblock">
        <label className="smallLabel">{this.props.title}</label>
        <div className="diamondSearchCaratRange">
          <div className="fromToValue">
            <div className="fromToValueInput">
              <label>{<IntlMessages id="app.from" />}</label>
              <input
                type="number"
                value={this.state.from}
                onChange={(e) => this.onMinChange(e)}
                onBlur={() => this.validate()}
                min={Min}
                onKeyDown={(e) => {
                  if (e.keyCode === 189 || e.keyCode === 109) {
                    // Dash sign prevent
                    noNegative && e.preventDefault();
                  }
                }}
              />
            </div>
            <div className="fromToValueInput">
              <label>{<IntlMessages id="app.to" />}</label>
              <input
                type="number"
                value={this.state.to}
                onChange={(e) => this.onMaxChange(e)}
                onBlur={() => this.validate()}
                min={Min}
                onKeyDown={(e) => {
                  if (e.keyCode === 189 || e.keyCode === 109) {
                    // Dash sign prevent
                    noNegative && e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (props) => {
  return props;
};
export default connect(mapStateToProps)(InputRange);
