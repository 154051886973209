export const FORMAL_MEET_COLUMN = [
  {
    title: 'Sr No.',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: 'Created Date',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'Appointment Date',
    dataIndex: 'cabinSlot',
    key: 'cabinSlot',
  },
  {
    title: 'Meeting Type',
    dataIndex: 'meetingType',
    key: 'meetingType',
  },
  {
    title: 'Notes',
    dataIndex: 'comment',
    key: 'comment',
  },
];
