import React, { useReducer, useRef, useState, useEffect, useCallback, useMemo, memo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import Countdown from './Timer';
import { trackTypeObj, LOCAL_STORAGE_VAR } from '../../constants/Common';
import Table, { LIMIT } from '../DiamondList/TableBack';
import { getGridHeight, emptyData, getState } from '../DiamondList';
import {
  getStoneInfo,
  fetchOffer,
  handleTrackDelete,
  isMobile,
  TITLE,
  getPath,
  LISTINGPAGES,
} from '../DiamondList/DiamondListFunctions';
import MobileViewList from '../DiamondList/MobileViewList';
import { formatNumber } from '../common/DiamondListing/SelectStone';
import cloneDeep from 'lodash/cloneDeep';
import { Tooltip, Divider } from 'antd';
import UtilService from '../../services/util';
import { get } from 'lodash';
import { NOTE_UPSERT } from '../../constants/apiConstant';
import OpenNotification from '../common/CommonButton/OpenNotification';

export const OFFERSTATUS = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected',
  4: 'Expired'
};

const Offer = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const tableRef = useRef();
  const currentType = useRef(location.pathname);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const [state, setState] = useState(() => cloneDeep(getState(props)));
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
  const checked = useSelector((state) => state.diamondData.selectedRows[location.pathname]) || [];
  useEffect(() => {
    if (!isMobile()) {
      props.onSumChange && props.onSumChange(state.sum, currentType.current);
    }
  }, [state.sum, currentType.current]);

  useEffect(() => {
    forceUpdate();
  }, [props.commonData.strip]);

  useEffect(() => {
    const listener = history.listen((route) => {
      if (props.location.pathname !== route.pathname) {
        dispatch({ type: 'RESET_SELECTED_ROWS' });
        setState(cloneDeep(getState(props)));
      }
      if (props.location.search !== route.search) {
        setState(cloneDeep(getState(props)));
      }
    });
    return function cleanup() {
      listener();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', forceUpdate);
    fetch();
    return () => {
      window.removeEventListener('resize', forceUpdate);
    };
  }, [location]);

  useEffect(() => {
    fetch();
  }, [state.page, state.limit]);

  const setResponse = (res) => {
    if (res) {
      let diamondData = res.data || [],
        defaultChecked = [];
      if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
        defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
          ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
          : [];
        if (defaultChecked && defaultChecked.length > 0) {
          diamondData.map((d) => {
            if (defaultChecked.includes(d.id)) {
              d['isDefaultChecked'] = true;
            }
            return d;
          });
        }
        localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
      }
      setState({
        ...state,
        ...res,
        data: diamondData,
        defaultChecked,
        loading: false,
      });
    } else {
      setState({ ...state, loading: false, defaultChecked: [] });
    }
  };

  const fetch = useCallback(() => {
    setState({ ...state, editOffer: null, loading: true, data: [] });
    fetchOffer(state, setResponse);
  }, [state.page, state.limit, state.sum]);

  const clearAllSelectedRows = useCallback(() => {
    if (isMobile()) {
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    } else {
      let tblRows = document.getElementsByTagName('table')[0].rows;
      for (let row of tblRows) {
        row.classList.remove('selectTr');
      }
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, [dispatch]);

  let getLoggedInUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`);

  const handleSaveNotes = useCallback((id, notes, defaultValue) => {
    if (notes !== defaultValue) {
      const obj = NOTE_UPSERT;
      obj.request = {
        diamonds: [{ diamond: id, remarks: notes?.trim() }],
      };
      UtilService.callApi(obj, function (err, data) {
        if (err) {
          OpenNotification({
            type: 'error',
            title: data ? data.message : err && err.data ? err.data.message : '',
          });
          fetch();
        } else if (data && data.code === 'OK') {
          OpenNotification({
            type: 'success',
            title: data?.message || 'Your Request is updated successfully.',
          });
          fetch();
        }
      });
    }
  }, []);

  const fetchcolumn = useMemo(() => {
    let columns = [
      {
        Header: 'No',
        accessor: 'no',
        Cell: ({ row }) => <span>{row.index + 1}</span>,
      },
      {
        Header: 'Notes',
        accessor: 'usernotes',
        id: 'usernotes',
        minWidth: '100px',
        Cell: ({ row, data }) => (
          <span className="tableInput noteAdd">
            <input
              defaultValue={row?.original?.usernotes}
              name={`input${row?.original?.id}`}
              onBlur={(e) => handleSaveNotes(row?.original?.id, e?.target?.value, row?.original?.usernotes)}
            />
          </span>
        ),
      },
      {
        Header: 'Days',
        accessor: 'day',
        Cell: ({ row }) => (
          <span>
            {moment(row.original.offerValidDate) - moment() < 0 ? (
              <span>Offer Expired</span>
            ) : (
              <Countdown
                date={row.original.offerStatus === 1 ? moment(row.original.offerValidDate) : moment()}
                currTime={moment()}
              />
            )}
          </span>
        ),
      },
      {
        Header: 'Stone Info',
        accessor: 'stoneinfo',
        Cell: ({ row }) => <span>{getStoneInfo(row.original).join(' ')}</span>,
      },
      {
        Header: 'Disc(%)/ Price/Ct',
        accessor: 'dispricCt',
        Cell: ({ row }) => {
          return (
            <span>
              {(row.original.back
                ? !row.original.isFcCol
                  ? row.original.ctPr < row.original.rap
                    ? `-${parseFloat(Math.abs(row.original.back)).toFixed(2)}` + ' / '
                    : row.original.back === 0
                    ? Math.abs(row.original.back) + ' / '
                    : `+${parseFloat(Math.abs(row.original.back)).toFixed(2)}` + ' / '
                  : '-' + ' / '
                : '') + parseFloat(row.original.ctPr).toFixed(0)}
            </span>
          );
        },
      },
      // {(row.original.newDiscount
      //   ?(row.original.newDiscount < 0 ?`-${parseFloat(Math.abs(row.original.newDiscount)).toFixed(2)}` :  row.original.newDiscount === 0 ? Math.abs(row.original.newDiscount) : `+${parseFloat(Math.abs(row.original.newDiscount)).toFixed(2)}`) + " / "
      //   : "") + parseFloat(row.original.newPricePerCarat).toFixed(0)}
      {
        Header: 'Quote(%) / Price/Ct',
        accessor: 'quotepricect',
        Cell: ({ row }) => (
          <span>
            {(row.original.newDiscount
              ? !row.original.isFcCol
                ? (row.original.newDiscount < 0
                    ? `-${parseFloat(Math.abs(row.original.newDiscount)).toFixed(2)}` + ' / '
                    : row.original.newDiscount === 0
                    ? Math.abs(row.original.newDiscount) + ' / '
                    : `+${parseFloat(Math.abs(row.original.newDiscount)).toFixed(2)}`) + ' / '
                : '-' + ' / '
              : '') + parseFloat(row.original.newPricePerCarat).toFixed(0)}
          </span>
        ),
      },
      {
        Header: 'Bargain(%)',
        accessor: 'bargain',
        Cell: ({ row }) => (
          <div>
            {!row.original.isFcCol ? (
              !row.original.bargainTrack || !row.original.bargainTrack.length ? (
                <Tooltip title={moment(row.original.createdAt).format('DD/MM/YYYY hh:mm A')}>
                  <span className="offerPrice current">{parseFloat(row.original.newDiscount)}</span>
                </Tooltip>
              ) : (
                row.original.bargainTrack
                  .sort((a, b) => moment(b.updatedAt) - moment(a.updatedAt))
                  .map((b, i) => {
                    return (
                      <>
                        {i === 0 ? (
                          <Tooltip key={i} title={moment(b.createdAt).format('DD/MM/YYYY hh:mm A')}>
                            <span className="offerPrice current">
                              {b.trackDiscount < 0
                                ? `-${Math.abs(b.trackDiscount)}`
                                : b.trackDiscount === 0
                                ? Math.abs(b.trackDiscount)
                                : `+${Math.abs(b.trackDiscount)}`}
                            </span>
                          </Tooltip>
                        ) : getLoggedInUser.id === b.id ? (
                          <Tooltip key={i} title={moment(b.updatedAt).format('DD/MM/YYYY hh:mm A')}>
                            <span className="offerPrice admin">
                              {b.trackDiscount < 0
                                ? `-${Math.abs(b.trackDiscount)}`
                                : b.trackDiscount === 0
                                ? Math.abs(b.trackDiscount)
                                : `+${Math.abs(b.trackDiscount)}`}
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip key={i} title={moment(b.updatedAt).format('DD/MM/YYYY hh:mm A')}>
                            <span className="offerPrice client">
                              {b.trackDiscount < 0
                                ? `-${Math.abs(b.trackDiscount)}`
                                : b.trackDiscount === 0
                                ? Math.abs(b.trackDiscount)
                                : `+${Math.abs(b.trackDiscount)}`}
                            </span>
                          </Tooltip>
                        )}
                        {i !== row.original.bargainTrack.length - 1 && <Divider type="vertical" />}{' '}
                      </>
                    );
                  })
              )
            ) : (
              '-'
            )}
          </div>
        ),
      },

      {
        Header: 'Comment',
        accessor: 'remarks',
        Cell: ({ cell }) => <>{cell.value || '-'}</>,
      },
      {
        Header: 'Active',
        accessor: 'active',
        Cell: ({ row }) => (
          <div className="officeStatus pending">
            <span>{OFFERSTATUS[row.original.offerStatus]}</span>
          </div>
        ),
      },
      {
        Header: 'Action',
        accessor: 'Action',
        Cell: ({ row }) => (
          <div className="actionButton">
            {row.original.offerStatus === 1 && moment(row.original.offerValidDate) - moment() > 0 ? (
              <div className="actionAction" onClick={() => setState({ ...state, editOffer: [row.original] })}>
                <img src={require('../../assets/svg/Account/edit.svg')} alt="" />
              </div>
            ) : null}
            <div
              className="actionAction"
              onClick={() => {
                handleTrackDelete(trackTypeObj.QUOTE, [row.original.id], state.inTrackDiamonds, () => {
                  clearAllSelectedRows();
                  fetch();
                });
              }}
            >
              <img src={require('../../assets/svg/Account/delettable.svg')} alt="" />
            </div>
          </div>
        ),
      },
    ].map((c) => ({ ...c, id: c.accessor }));
    return columns;
  }, [state]);

  const onPaginationChange = useCallback(
    (page, limit) => {
      setState({ ...state, page, limit });
    },
    [state.sum],
  );

  const getTitle = () => {
    return TITLE[getPath()] + ' (' + formatNumber(state.count) + ')';
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        trackType={trackTypeObj.QUOTE}
        {...state}
        {...props}
        currentType={currentType.current}
        rejectOffer={getPath() === LISTINGPAGES.QUOTE}
        delete={get(USER_PERMIT, 'QUOTE.delete', false)}
        nofinalcalc={!get(USER_PERMIT, 'FINAL_CALCULATIONS.view', false)}
        nonocompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        noconfirm={!get(USER_PERMIT, 'CONFIRM_STONE.view', false)}
        nocart={!get(USER_PERMIT, 'CART.view', false)}
        nowatch={!get(USER_PERMIT, 'WATCHLIST.view', false)}
        nooffice={!get(USER_PERMIT, 'OFFICE.view', false)}
        nonote={!get(USER_PERMIT, 'NOTES.view', false)}
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        noquote
        onPaginationChange={onPaginationChange}
        clearAll={clearAllSelectedRows}
        fetch={fetch}
      />
    );
  };

  const classList = ['.HeaderSticky', '.selectStonHeader', '.accountTopBlock', '.accountTabBox', '.botoomStickyBar'];
  const gridHeight = getGridHeight(classList);
  return isMobile() ? (
    <MobileViewList listView={true} {...props} {...state} currentType={currentType} parent={{ getTitle, getAction }} />
  ) : (
    <div className="searchResultListWrapper profilePage">
      <div className="searchInnerResult">
        <div className="searchResultTable profilePageTable" style={{ height: `${checked}` ? `calc(100vh - 275px)` : `calc(100vh - 211px)`, overflow: 'auto' }}>
          <Table
            data={state.data}
            columns={fetchcolumn}
            loading={state.loading}
            ref={tableRef}
            nostatus
            canSort={false}
            currentType={currentType.current}
            defaultChecked={state.defaultChecked}
            FilterOption={false}
          />
          {emptyData(state.loading, state.data.length)}
        </div>
        {getAction()}
      </div>
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(withRouter(Offer));
