import React, { Component } from 'react';
import './uploadImage.less';
import { BASE_URL_ATTACH } from '../../../constants/Common';

const UploadImage = (props) => {
  return (
    <div style={{ marginLeft: '20px' }} className={`uploadeImageBlock ${props.leftAlign && 'leftAlign'}`}>
      <input type="file" onChange={props.onUpload} />
      <div className="uploadeBlock">
        {props.file ? (
          props.file
            .split('/')
            .slice(-1)[0]
            .match(/.(pdf)$/i) ? (
            <img src={require('../../common/UploadImage/pdf.svg')} alt="" />
          ) : (
            <img src={`${BASE_URL_ATTACH}/${props.file}`} alt="" />
          )
        ) : (
          <img src={require('../../../assets/svg/plus-thin.svg')} alt="" />
        )}
      </div>
      <span className="title">{props.title ? props.title : <>Upload ID Proof</>}</span>
    </div>
  );
};

export default UploadImage;
