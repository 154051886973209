import React from 'react';

const CovidStrip = (props) => {
  return props.event && props.event.shortDescription ? (
    <div className="covidStrip">
      <span>{props.event.shortDescription}</span>
      <span className="closeImg" onClick={props.onClick}>
        <img src={require('../../../../assets/svg/Header/close.svg')} alt="" />
      </span>
    </div>
  ) : (
    <></>
  );
};
export default CovidStrip;
