import { notification } from 'antd';
import TermsConditionPopup from 'components/TermsConditionPopup';
import { find, findIndex, isInteger, isNumber, map, sum } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { conditionallyUpdateScrollbar } from 'reactstrap/lib/utils';
import { DIAMOND_BID, SELECT_STONE_TERMS_POP, SELECT_STONE_TERMS_NEW, SELECT_STONE_TERMS_COD, LOCAL_STORAGE_VAR } from '../../../constants/Common';
import { getCurrentTime, getNextBidData } from '../../../services/util';
import IntlMessages from '../../../util/IntlMessages';
import { isNumeric, parseDecimal } from '../../../util/utils';
import { getColumn, getPath, handleBidUpsert, isMobile, LISTINGPAGES } from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import { getBidType } from '../../NewArrivalBidIt';
import OpenNotification from '../CommonButton/OpenNotification';
import CommonModal from '../CommonModal';
import Heading from '../Heading';
import BIDStoneMobile from './BIDStoneMobile';
import { HeadingCalc } from './ConfirmStonePopup';
import { newDiamondPrice } from './FinalCalculations';
import plusIcon from '../../../assets/img/plus.jpg';
import minusIcon from '../../../assets/img/minus.jpg';
import moment from 'moment';
// import { user } from './../../../constants/Common'

const currentType = 'bidStones';

const sumOfNumericString = (ary) => {
  const arr = ary.map(function (elt) {
    return parseFloat(elt);
  });
  return arr.length
    ? arr.reduce(function (a, b) {
      return a + b;
    })
    : 0;
};


class BidPopup extends Component {
  state = {
    columns: [],
    checked: [],
    data: [],
    isModalVisible: false,
    summ:
      getPath() !== LISTINGPAGES.COMPARE
        ? localStorage.getItem('Terms') === 'true'
          ? localStorage.getItem('Termvalue')
            ? localStorage.getItem('Termvalue')
            : ''
          : localStorage.getItem('Terms')
        : '',
    summation: {},
    nextBidData: {},
    showModal: false,
    minCtPr: 0,
    disDiff: this.props?.bid?.sub_types?.downwardInterval,
    disDiffPrevCache: this.props?.bid?.sub_types?.downwardInterval
  };
  setDisDiff = (disDiff) => this.setState({ disDiff: disDiff ? Number(disDiff) : undefined });

  handleDisDiffError = (disDiffValue, upwardInterval, downwardInterval) => {
    if (disDiffValue > downwardInterval) {
      OpenNotification({
        type: 'error',
        title: `Value can not be greater than ${downwardInterval} !`,
      });
    } else if (disDiffValue < upwardInterval * -1) {
      OpenNotification({
        type: 'error',
        title: `Value can not be less than ${upwardInterval * -1} !`,
      });
    }
  }

  handleDisDiffOnEnter = () => {
    const { upwardInterval, downwardInterval } = this.props?.bid?.sub_types;
    if (this.state.disDiff <= downwardInterval && this.state.disDiff >= upwardInterval * -1) {
      const data = [...this.state.data];
      const updatedData = data?.map((row) => {
        const updatedRow = { ...row };
        if (this.props?.bid?.sub_types?.calColumn === 'ctPr') {
          const newBidPricePerCarat = updatedRow.ctPr - (updatedRow.ctPr * this.state.disDiff) / 100;
          updatedRow.bidPricePerCarat = newBidPricePerCarat;
          updatedRow.bidAmount = (newBidPricePerCarat || 0) * updatedRow.crt;
          updatedRow.bidDiscount = parseFloat((1 - newBidPricePerCarat / updatedRow.rap) * -100).toFixed(2);
        } else if (this.props?.bid?.sub_types?.calColumn === 'back') {
          let rap = updatedRow.rap ? updatedRow.rap : updatedRow.ctPr

          updatedRow.bidDiscount = row?.back + (this.state.disDiff * -1)
          updatedRow.bidPricePerCarat = parseFloat(rap + (updatedRow.bidDiscount * rap) / 100).toFixed(2);
          updatedRow.bidAmount = (updatedRow.bidPricePerCarat || 0) * updatedRow.crt;
        }
        return updatedRow;
      });
      const checked = updatedData.filter((el) => find(this.state.checked, { id: el.id }));
      this.setState({ data: [...updatedData], checked, disDiffPrevCache: this.state.disDiff });
    } else {
      this.setState({ disDiff: this.state.disDiffPrevCache });
      this.handleDisDiffError(this.state.disDiff, upwardInterval, downwardInterval);
    }
  };

  handleDisDiffAction = (action) => {
    const { upwardInterval, downwardInterval, diffInterval } = this.props?.bid?.sub_types;
    let value = Number(this.state.disDiff) || 0;
    if (action === 'plus') {
      value = value + diffInterval;
    }
    if (action === 'minus') {
      value = value - diffInterval;
    }
    if (value <= downwardInterval && value >= upwardInterval * -1) {
      this.setState({ disDiff: value ? value?.toFixed(2) : value }, () => this.handleDisDiffOnEnter());
    } else {
      this.handleDisDiffError(value, upwardInterval, downwardInterval)
    }
  };

  checkInterval = (value, oldValue, discount) => {
    value = Number(value);
    oldValue = Number(oldValue);

    const diffInterval = this.state.nextBidData.sub_types.diffInterval;
    const v1 = parseInt(Math.round(value * 100));
    const v2 = parseInt(Math.round(oldValue * 100));
    const diff = Math.abs(v1 - v2);
    const inInterval = diff % (diffInterval * 100) === 0 || diff === 0;
    if (!inInterval) {
      OpenNotification({
        type: 'error',
        title: `You can only change discount in ${Math.abs(diffInterval)}% interval`,
      });
    }
    return inInterval;
  };

  getNewBid = (value, row, blur) => {
    const data = [...this.state.data];
    // console.log(data, 'value', value, row);
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - parseFloat(value) / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    if (getPath() === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidPricePerCarat, back)
        : this.checkInterval(discountValue, oldValue, back);
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
      const max =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
          : upInterval + Math.abs(back);
      const min =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
          : back + downInterval;
      const minValue = data[index].ctPr + data[index].ctPr * (downInterval / 100);
      const minDisc = parseFloat(data[index].rap + ((back + downInterval) * data[index].rap) / 100).toFixed(2);
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          parseFloat(discountValue) >= min &&
          parseFloat(discountValue) <= max &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = data[index].isFcCol
            ? data[index].bidPricePerCarat
            : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minValue * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
        }
      } else {
        if (
          parseFloat(value) >= min &&
          parseFloat(value) <= max &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = data[index].isFcCol
            ? data[index].bidPricePerCarat
            : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title:
                subTypeCode !== 'BIWI'
                  ? 'Your bidding value must be higher than base value.'
                  : `Your bidding amount can not be lower then ${parseFloat(minValue * data[index].crt).toFixed(
                    2,
                  )}. Please, try again.`,
            });
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidPricePerCarat, oldData[index].back)
        : this.checkInterval(discountValue, oldValue, oldData[index].back);
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      const max = upInterval + Math.abs(oldData[index].bidDiscount);
      const min = oldData[index].bidDiscount + downInterval;
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          parseFloat(discountValue) >= oldData[index].bidDiscount &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
        } else {
          data[index].bidDiscount = oldData[index].bidDiscount;
          data[index].bidPricePerCarat = oldData[index].isFcCol
            ? oldData[index].bidPricePerCarat
            : parseFloat(data[index].rap + (data[index].bidDiscount * data[index].rap) / 100).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      } else {
        if (
          parseFloat(value) >= parseFloat(oldData[index].bidPricePerCarat) &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          data[index].bidPricePerCarat = value;
          data[index].bidAmount = (value || 0) * data[index].crt;
          data[index].bidDiscount = parseFloat((1 - value / data[index].rap) * -100).toFixed(2);
        } else {
          data[index].bidPricePerCarat = parseFloat(oldData[index].bidPricePerCarat);
          data[index].bidAmount = data[index].bidPricePerCarat * data[index].crt;
          if (data[index].isFcCol) {
            data[index].bidDiscount = 0
          } else {
            data[index].bidDiscount = parseFloat((1 - data[index].bidPricePerCarat / data[index].rap) * -100).toFixed(2);
          }
          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleNewBidBlur = (...args) => this.getNewBid(...args, true);

  handleDiscountChange = (value, row, blur) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };
    value = Number(value); // converting to number
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
    if (getPath() === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = this.checkInterval(value, oldValue, back);
      const maxPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      const max =
        calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (upInterval / 100) : upInterval + Math.abs(back);
      const min =
        calColumn === 'ctPr' ? data[index].ctPr + data[index].ctPr * (downInterval / 100) : back + downInterval;
      const minDiscount = data[index].ctPr + data[index].ctPr * (downInterval / 100);
      const disc = (parseFloat(minDiscount) * 100) / data[index].rap - 100;
      if (calColumn !== 'ctPr') {
        if (value >= min && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(value >= back)) {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
              });
            } else {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
              });
            }
          }
        }
      } else {
        if (
          parseFloat(maxPricePerCarat) >= min &&
          parseFloat(maxPricePerCarat) <= max &&
          isInIntervalDiff &&
          value >= -100 &&
          value <= 100
        ) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(parseFloat(maxPricePerCarat) >= data[index].ctPr)) {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
              });
            } else {
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minDiscount * data[index].crt).toFixed(
                      2,
                    )}. Please, try again.`,
              });
            }
          }
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = this.checkInterval(value, oldValue, oldData[index].back);
      const max = this.state.nextBidData.sub_types.upwardInterval + Math.abs(oldData[index].bidDiscount);
      const min = oldData[index].bidDiscount + this.state.nextBidData.sub_types.downwardInterval * -1;
      const maxPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
      if (calColumn !== 'ctPr') {
        if (value >= oldData[index].bidDiscount && value <= max && isInIntervalDiff && value >= -100 && value <= 100) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidDiscount = oldValue;
          data[index].bidPricePerCarat = parseFloat(data[index].rap + data[index].rap * (oldValue / 100)).toFixed(2);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          if (isInIntervalDiff) {
            if (!(value >= oldData[index].back)) {
              OpenNotification({
                type: 'error',
                title: 'You can not decrease your previous bid.',
              });
            } else {
              OpenNotification({
                type: 'error',
                title: 'You can not decrease your previous bid.',
              });
            }
          }
        }
      } else {
        if (
          parseFloat(maxPricePerCarat) >= parseFloat(oldData[index].bidPricePerCarat) &&
          isInIntervalDiff &&
          value >= -100 &&
          value <= 100
        ) {
          data[index].bidDiscount = value;
          const bigPricePerCarat = parseFloat(data[index].rap + data[index].rap * (value / 100)).toFixed(2);
          data[index].bidPricePerCarat = bigPricePerCarat;
          data[index].bidAmount = bigPricePerCarat * data[index].crt;
        } else {
          data[index].bidPricePerCarat = parseFloat(oldData[index].bidPricePerCarat);
          data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
          data[index].bidDiscount = parseFloat((1 - data[index].bidPricePerCarat / data[index].rap) * -100).toFixed(2);

          isInIntervalDiff &&
            OpenNotification({
              type: 'error',
              title: `You can not decrease your previous bid.`,
            });
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleDiscountBlur = (...args) => this.handleDiscountChange(...args, true);

  handleValueChange = (value, row, blur) => {
    const data = [...this.state.data];
    const index = findIndex(data, { stoneId: row.stoneId });
    data[index] = { ...data[index] };

    const bidPricePerCarat = parseFloat(value / data[index].crt).toFixed(2);
    const discountValue = data[index].isFcCol
      ? 0
      : parseFloat((1 - bidPricePerCarat / data[index].rap) * -100).toFixed(2);
    const isPositive = data[index].ctPr > data[index].rap;
    const back = isPositive ? Math.abs(data[index].back) : -1 * Math.abs(data[index].back);
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    data[index].back = back;
    const subTypeCode = this.state.nextBidData.sub_types.subTypeCode;
    const upInterval = this.state.nextBidData.sub_types.upwardInterval;
    const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
    const minCtPr = data[index].ctPr + data[index].ctPr * (downInterval / 100);
    const minAmount = parseFloat(minCtPr).toFixed(2) * data[index].crt;

    if (getPath() === LISTINGPAGES.BID) {
      const oldValue = data[index].bidDiscount || back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidAmount, back)
        : this.checkInterval(discountValue, oldValue, back);
      const max =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
          : upInterval + Math.abs(back);
      const min =
        calColumn === 'ctPr' || data[index].isFcCol
          ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
          : back + downInterval;
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          parseFloat(discountValue) >= min &&
          parseFloat(discountValue) <= max &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (discountValue * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = data[index].bidAmount;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(
                      2,
                    )}. Please, try again.`,
              });
          } else {
            data[index].bidDiscount = data[index].back;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(
                      2,
                    )}. Please, try again.`,
              });
          }
        }
      } else {
        if (
          parseFloat(bidPricePerCarat) >= min &&
          parseFloat(bidPricePerCarat) <= max &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (discountValue * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = data[index].bidAmount;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(
                      2,
                    )}. Please, try again.`,
              });
          } else {
            data[index].bidDiscount = data[index].bidDiscount;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title:
                  subTypeCode !== 'BIWI'
                    ? 'Your bidding value must be higher than base value.'
                    : `Your bidding amount can not be lower then ${parseFloat(minAmount).toFixed(
                      2,
                    )}. Please, try again.`,
              });
          }
        }
      }
    } else {
      const oldData = [...this.state.oldData];
      const oldValue = data[index].bidDiscount || data[index].back;
      const isInIntervalDiff = data[index].isFcCol
        ? this.checkInterval(value, data[index].bidAmount, back)
        : this.checkInterval(discountValue, oldValue, oldData[index].back);
      const upInterval = this.state.nextBidData.sub_types.upwardInterval;
      const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
      const max =
        calColumn === 'ctPr'
          ? data[index].ctPr + data[index].ctPr * (upInterval / 100)
          : upInterval + Math.abs(oldData[index].bidDiscount);
      const min =
        calColumn === 'ctPr'
          ? data[index].ctPr + data[index].ctPr * (downInterval / 100)
          : oldData[index].bidDiscount + downInterval;
      const maxPricePerCarat = parseFloat(data[index].rap + (discountValue * data[index].rap) / 100).toFixed(2);
      if (calColumn !== 'ctPr' && !data[index].isFcCol) {
        if (
          parseFloat(discountValue) >= oldData[index].bidDiscount &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (discountValue * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = data[index].bidAmount;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          } else {
            data[index].bidDiscount = oldData[index].bidDiscount;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          }
        }
      } else {
        if (
          parseFloat(value) >= parseFloat(oldData[index].bidPricePerCarat * oldData[index].crt) &&
          isInIntervalDiff &&
          parseFloat(discountValue) >= -100 &&
          parseFloat(discountValue) <= 100
        ) {
          if (data[index].isFcCol) {
            data[index].bidAmount = value;
            data[index].bidPricePerCarat = parseFloat(data[index].bidAmount) / data[index].crt;
            data[index].bidDiscount = 0;
          } else {
            data[index].bidDiscount = discountValue;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (discountValue * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = value;
          }
        } else {
          if (data[index].isFcCol) {
            data[index].bidAmount = data[index].bidPricePerCarat * data[index].crt;
            data[index].bidPricePerCarat = data[index].bidPricePerCarat;
            data[index].bidDiscount = 0;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          } else {
            data[index].bidDiscount = data[index].bidDiscount;
            data[index].bidPricePerCarat = parseFloat(
              data[index].rap + (data[index].bidDiscount * data[index].rap) / 100,
            ).toFixed(2);
            data[index].bidAmount = (data[index].bidPricePerCarat || 0) * data[index].crt;
            isInIntervalDiff &&
              OpenNotification({
                type: 'error',
                title: `You can not decrease your previous bid.`,
              });
          }
        }
      }
    }
    const checked = data.filter((el) => find(this.state.checked, { id: el.id }));
    this.setState({ data, checked });
  };

  handleValueBlur = (...args) => this.handleValueChange(...args, true);

  getNextBidConfig = async () => {
    const nextBidData = await getNextBidData();
    this.setState({ nextBidData });
  };

  handleModalVisibility = (visible) => {
    this.setState({ isModalVisible: visible });
  };

  async componentDidMount() {
    await this.getNextBidConfig();
    const downInterval = this.state.nextBidData.sub_types.downwardInterval * -1;
    const calColumn = this.state.nextBidData.sub_types.calColumn;
    const data = this.props.checked
      .map((x) => {
        return {
          ...x,
          bidPricePerCarat: parseFloat((x.bidPricePerCarat ? x.bidPricePerCarat : x.ctPr) || 0).toFixed(2),
          bidAmount: x.bidAmount ? parseFloat(x.bidAmount).toFixed(2) : parseFloat(x.amt).toFixed(2) || 0,
          bidDiscount: parseFloat(x.bidDiscount ? x.bidDiscount : x.back || 0).toFixed(2),
        };
      })
      .map((x) => newDiamondPrice(x, 'bid'))
      .map((x) => {
        if (getPath() !== LISTINGPAGES.MYBID) {
          const minDis =
            x.ctPr < x.rap
              ? -1 * Math.abs(parseFloat(x.bidDiscount).toFixed(2)) + downInterval
              : Math.abs(parseFloat(x.bidDiscount).toFixed(2)) + downInterval;
          if (calColumn !== 'ctPr' && !x.isFcCol) {
            return {
              ...x,
              bidDiscount: minDis,
              bidPricePerCarat: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2),
              bidAmount: parseFloat(x.rap + x.rap * (minDis / 100)).toFixed(2) * x.crt,
            };
          } else {
            const minCtPr = parseFloat(x.ctPr + x.ctPr * (downInterval / 100)).toFixed(2);
            return {
              ...x,
              bidPricePerCarat: parseFloat(x.ctPr + x.ctPr * (downInterval / 100)).toFixed(2),
              bidAmount: parseFloat(minCtPr).toFixed(2) * x.crt,
              bidDiscount: parseFloat((1 - minCtPr / x.rap) * -100).toFixed(2),
            };
          }
        } else {
          return {
            ...x,
            bidDiscount:
              Number(x.bidDiscount) < 0
                ? -1 * Math.abs(parseFloat(Number(x.bidDiscount)).toFixed(2))
                : Math.abs(parseFloat(Number(x.bidDiscount)).toFixed(2)),
          };
        }
      });
    let disableBidStone = false;
    data.map((item) => {
      const back =
        item.ctPr < item.rap
          ? -1 * Math.abs(parseFloat(Number(item.back)).toFixed(2))
          : Math.abs(parseFloat(Number(item.back)).toFixed(2));
      if (back === item.bidDiscount) {
        disableBidStone = true;
      }
    });
    this.setState({ showModal: disableBidStone });
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      {
        Header: 'Bid Disc(%)',
        accessor: 'bidDiscount',
        id: 'bidDiscount',
        Cell: ({ row, cell }) => {
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          // const updatedValue = React.useMemo(() => {
          //   return row.original.bidDiscount;
          // }, [row.original.bidDiscount]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidDiscount)
              ? parseFloat(row.original.bidDiscount).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.bidDiscount).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            this.setState({ showModal: true });
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            this.setState({ showModal: false });
            const _value = e.target.value;
            isNumeric(_value, true)
              ? this.handleDiscountBlur(_value, row.original)
              : this.handleDiscountBlur(0, row.original);
          }, []);

          // const sign = Number(value) === 0 ||  !value ? '' : Number(value) > 0 ? '-' : '+';
          const sign = Number(value) > 0 ? '+' : '';
          if (row.original.isFcCol) {
            return '-';
          }
          return (
            <div className="tableInput">
              <span className="add-sign">{sign}</span>
              <input
                type="number"
                style={{ paddingLeft: Number(value) > 0 ? 8 : 2 }}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          );
        },
      },
      // {
      //   Header: 'Bid Price/Ct',
      //   accessor: 'bidPricePerCarat',
      //   id: 'bidPricePerCarat',

      //   Cell: ({ row, cell }) => {
      //     console.log('bid Price Pr Caret', cell.value);
      //     const originalValue = React.useMemo(() => {
      //       return Number(parseFloat(cell.value).toFixed(2));
      //     }, [cell.value]);

      //     // const updatedValue = React.useMemo(() => {
      //     //   return row.original.bidPricePerCarat;
      //     // }, [row.original.bidPricePerCarat]);

      //     const [value, setValue] = React.useState(
      //       isNumber(row.original.bidPricePerCarat)
      //         ? parseFloat(row.original.bidPricePerCarat).toFixed(2)
      //         : isNumber(originalValue)
      //         ? parseFloat(originalValue).toFixed(2)
      //         : '0.00',
      //     );

      //     React.useEffect(() => {
      //       setValue(parseFloat(row.original.bidPricePerCarat).toFixed(2));
      //     }, [row.original]);

      //     const handleChange = React.useCallback((e) => {
      //       this.setState({ showModal: true });
      //       e.preventDefault();
      //       const _value = e.target.value;
      //       if (isNumeric(_value)) setValue(_value);
      //     }, []);

      //     const handleBlur = React.useCallback((e) => {
      //       this.setState({ showModal: false });
      //       e.preventDefault();
      //       const _value = e.target.value;
      //       isNumeric(_value, true)
      //         ? this.handleNewBidBlur(_value, row.original)
      //         : this.handleNewBidBlur(0, row.original);
      //     }, []);

      //     return (
      //       <div className="tableInput">
      //         <input value={value} onChange={handleChange} onBlur={handleBlur} />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: 'Bid Value',
      //   accessor: 'bidAmount',
      //   id: 'bidAmount',
      //   Cell: ({ row, cell }) => {
      //     console.log('bid Amount', cell.value);
      //     const originalValue = React.useMemo(() => {
      //       return Number(parseFloat(cell.value).toFixed(2));
      //     }, [cell.value]);

      //     const [value, setValue] = React.useState(
      //       isNumber(row.original.bidAmount)
      //         ? parseFloat(row.original.bidAmount).toFixed(2)
      //         : isNumber(originalValue)
      //         ? parseFloat(originalValue).toFixed(2)
      //         : '0.00',
      //     );

      //     React.useEffect(() => {
      //       setValue(parseFloat(row.original.bidAmount).toFixed(2));
      //     }, [row.original]);

      //     const handleChange = React.useCallback(
      //       (e) => {
      //         e.preventDefault();
      //         this.setState({ showModal: true });
      //         const _value = e.target.value;
      //         if (isNumeric(_value)) setValue(_value);
      //       },
      //       [value],
      //     );

      //     const handleBlur = React.useCallback((e) => {
      //       e.preventDefault();
      //       this.setState({ showModal: false });
      //       const _value = e.target.value;
      //       isNumeric(_value, true)
      //         ? this.handleValueBlur(_value, row.original)
      //         : this.handleValueBlur(0, row.original);
      //     }, []);
      //     return (
      //       <div className="tableInput">
      //         <input value={value} onChange={handleChange} onBlur={handleBlur} />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: 'Bid Price/Ct',
      //   accessor: 'bidPricePerCarat',
      //   id: 'bidPricePerCarat',
      //   Cell: ({ row, cell }) => {
      //     console.log('bid Price Pr Caret', cell.value);
      //     const originalValue = React.useMemo(() => {
      //       return Number(parseFloat(cell.value).toFixed(2));
      //     }, [cell.value]);

      //     const [value, setValue] = React.useState(
      //       isNumber(row.original.bidPricePerCarat)
      //         ? parseFloat(row.original.bidPricePerCarat).toFixed(2)
      //         : isNumber(originalValue)
      //         ? parseFloat(originalValue).toFixed(2)
      //         : '0.00',
      //     );

      //     React.useEffect(() => {
      //       setValue(parseFloat(row.original.bidPricePerCarat).toFixed(2));
      //     }, [row.original]);

      //     const handleChange = React.useCallback((e) => {
      //       this.setState({ showModal: true });
      //       e.preventDefault();
      //       const _value = e.target.value;
      //       if (isNumeric(_value)) {
      //         setValue(_value);
      //         const bidAmount = (parseFloat(_value) * row.original.weight).toFixed(2);
      //         row.original.bidAmount = bidAmount;
      //       }
      //     }, []);

      //     const handleBlur = React.useCallback((e) => {
      //       this.setState({ showModal: false });
      //       e.preventDefault();
      //       const _value = e.target.value;
      //       isNumeric(_value, true)
      //         ? this.handleNewBidBlur(_value, row.original)
      //         : this.handleNewBidBlur(0, row.original);
      //     }, []);

      //     return (
      //       <div className="tableInput">
      //         <input value={value} onChange={handleChange} onBlur={handleBlur} />
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: 'Bid Value',
      //   accessor: 'bidAmount',
      //   id: 'bidAmount',
      //   Cell: ({ row, cell }) => {
      //     console.log('bid Amount', cell.value);
      //     const originalValue = React.useMemo(() => {
      //       return Number(parseFloat(cell.value).toFixed(2));
      //     }, [cell.value]);

      //     const [value, setValue] = React.useState(
      //       isNumber(row.original.bidAmount)
      //         ? parseFloat(row.original.bidAmount).toFixed(2)
      //         : isNumber(originalValue)
      //         ? parseFloat(originalValue).toFixed(2)
      //         : '0.00',
      //     );

      //     React.useEffect(() => {
      //       setValue(parseFloat(row.original.bidAmount).toFixed(2));
      //     }, [row.original]);

      //     const handleChange = React.useCallback((e) => {
      //       e.preventDefault();
      //       this.setState({ showModal: true });
      //       const _value = e.target.value;
      //       if (isNumeric(_value)) setValue(_value);
      //     }, []);

      //     const handleBlur = React.useCallback((e) => {
      //       e.preventDefault();
      //       this.setState({ showModal: false });
      //       const _value = e.target.value;
      //       isNumeric(_value, true)
      //         ? this.handleValueBlur(_value, row.original)
      //         : this.handleValueBlur(0, row.original);
      //     }, []);

      //     return (
      //       <div className="tableInput">
      //         <input value={value} onChange={handleChange} onBlur={handleBlur} />
      //       </div>
      //     );
      //   },
      // },

      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        id: 'bidPricePerCarat',
        Cell: ({ row, cell }) => {
          // console.log('bid Price Pr Caret', cell.value);
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidPricePerCarat)
              ? parseFloat(row.original.bidPricePerCarat).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.bidPricePerCarat).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            this.setState({ showModal: true });
            e.preventDefault();
            const _value = e.target.value;
            if (isNumeric(_value)) {
              setValue(_value);
              const bidAmount = (parseFloat(_value) * row.original.weight).toFixed(2);
              row.original.bidAmount = bidAmount;
            }
          }, []);

          const handleBlur = React.useCallback((e) => {
            this.setState({ showModal: false });
            e.preventDefault();
            const _value = e.target.value;
            isNumeric(_value, true)
              ? this.handleNewBidBlur(_value, row.original)
              : this.handleNewBidBlur(0, row.original);
          }, []);

          const handleSubmit = React.useCallback(
            (e) => {
              e.preventDefault();
              handleBlur(e); // Trigger the handleBlur function manually
              // Perform the submit action
            },
            [handleBlur],
          );

          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
              <button onClick={handleSubmit}>Submit</button>
            </div>
          );
        },
      },
      {
        Header: 'Bid Value',
        accessor: 'bidAmount',
        id: 'bidAmount',
        Cell: ({ row, cell }) => {
          // console.log('bid Amount', cell.value);
          const originalValue = React.useMemo(() => {
            return Number(parseFloat(cell.value).toFixed(2));
          }, [cell.value]);

          const [value, setValue] = React.useState(
            isNumber(row.original.bidAmount)
              ? parseFloat(row.original.bidAmount).toFixed(2)
              : isNumber(originalValue)
                ? parseFloat(originalValue).toFixed(2)
                : '0.00',
          );

          React.useEffect(() => {
            setValue(parseFloat(row.original.bidAmount).toFixed(2));
          }, [row.original]);

          const handleChange = React.useCallback((e) => {
            e.preventDefault();
            this.setState({ showModal: true });
            const _value = e.target.value;
            if (isNumeric(_value)) setValue(_value);
          }, []);

          const handleBlur = React.useCallback((e) => {
            e.preventDefault();
            this.setState({ showModal: false });
            const _value = e.target.value;
            isNumeric(_value, true)
              ? this.handleValueBlur(_value, row.original)
              : this.handleValueBlur(0, row.original);
          }, []);

          return (
            <div className="tableInput">
              <input value={value} onChange={handleChange} onBlur={handleBlur} />
            </div>
          );
        },
      },

      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ].filter((el) => el && el);

    this.setState({ columns, data, oldData: data, checked: data }, () => {
      const newData = [];
      this.state.data.map((d) => {
        this.state.checked.map((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });
      this.getNextBidConfig();
      this.handleCalculation(newData);
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.checkedData.length != this.state.checked.length) {
      this.setState(
        {
          checked: this.state.data.filter((x) => (find(nextProps.checkedData, { id: x.id }) ? true : false)),
        },
        () => {
          const newData = [];
          this.state.data.map((d) => {
            this.state.checked.map((c) => {
              if (d.id === c.id) {
                newData.push(d);
              }
            });
          });
          this.handleCalculation(newData);
        },
      );
    }
  }
  componentDidUpdate(nextProps, nextState) {
    if (nextState.data != this.state.data) {
      const newData = [];
      this.state.data.map((d) => {
        this.state.checked.map((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });
      this.handleCalculation(newData);
    }
  }

  showError = () => {
    this.setState({ showModal: true });
  };
  hideError = () => {
    this.setState({ showModal: false });
  };

  checkCheck = () => {
    if (!this.state.checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to bid.',
      });
      return false;
    } else return true;
  };

  submit = async () => {
    // const termDis = localStorage.getItem('TermsDis');
    // const termObj = JSON.parse(localStorage.getItem('termObj'));
    // termObj.value = termDis;
    // const { id, minAmt, resetDis = '', ...reset } = termObj;
    let showModal = false;
    const time = await getCurrentTime();
    if (new Date(time.iso) > new Date(this.state.nextBidData.sub_types.currentBidEndDate)) {
      return OpenNotification({
        type: 'error',
        title: 'Bid has been completed. You can no longer place a bid.',
      })
    }
    this.state.checked.map((x) => {
      if (x.isFcCol) {
        if (Number(x.bidPricePerCarat) === Number(x.ctPr)) {
          showModal = true;
        }

      } else {
        if (Number(x.bidDiscount) === Number(x.back) || Number(x.bidPricePerCarat) === Number(x.ctPr)) {
          showModal = true;
        }
      }
    });
    if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
      this.state.checked.map((stone) =>
        stone?.isFcCol
          ? OpenNotification({
            type: 'error',
            title: 'Bid Price/Ct and Price/Ct can not be same for bid.',
          })
          : OpenNotification({
            type: 'error',
            title: 'Bid discount and discount can not be same for bid.',
          }),
      );
    }
    // else if (!this.state.summ) {
    //   OpenNotification({
    //     type: 'error',
    //     title: 'Please select term to add bid on diamond(s).',
    //   });
    // }
    else {
      const self = this;
      if (!this.checkCheck()) return;
      const newData = [];
      this.state.data.map((d) => {
        this.state.checked.map((c) => {
          if (d.id === c.id) {
            newData.push(d);
          }
        });
      });

      handleBidUpsert(
        newData,
        this.state.nextBidData.id,
        this.state.nextBidData.sub_types.subTypeCode,
        this.state.nextBidData.sub_types.result,
        (flag) => {
          self.props.onClose();
          self.props.clearAll();
          if (flag && self.props.fetch) self.props.fetch();
        },
      );
    }
  };

  getTerms = () => {
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

    return (
      <div>
        <select
          name="summ"
          value={this.state.summ}
          placeholder="Select Terms"
          onChange={(e) => {
            localStorage.setItem('Termvalue', e.target.value);
            this.setState({ summ: e.target.value }, () => {
              const newData = [];
              this.state.data.map((d) => {
                this.state.checked.map((c) => {
                  if (d.id === c.id) {
                    newData.push(d);
                  }
                });
              });
              this.handleCalculation(newData);
            });
          }}
        >
          {!user?.account?.isCOD ? <option value="" disabled selected hidden>
            Select Terms
          </option> : null
          }  {user?.account?.isCOD
            ? SELECT_STONE_TERMS_COD.map((x) => (<option selected value={x.id}>{x.name}</option>))
            : SELECT_STONE_TERMS_NEW.map((x) => (
              <option value={x.id}>{x.name}</option>
            ))}
        </select>
      </div>
    );
  };

  handleCalculation = (diams) => {
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

    const calc = {};
    diams.map((d) => {
      d.rap_avg = Number.isNaN(d.rap * d.crt) ? 0 : d.rap * d.crt;
      return d;
    });
    const totalRapAvg = sum(map(diams, 'rap_avg'));
    calc.pieces = diams.length;
    const avgPricePerCarat = sumOfNumericString(map(diams, 'bidAmount')) / sum(map(diams, 'crt'));
    calc.totalCarats = sum(map(diams, 'crt'));
    calc.avgDisc = totalRapAvg ? (1 - sum(map(diams, 'amt')) / sum(map(diams, 'rap_avg'))) * -100 : 0;
    // calc.avgDisc = (1 - sum(map(diams, 'amt')) / sum(map(diams, 'rap_avg'))) * -100 * -1;
    calc.AvgDisc = totalRapAvg ? (1 - avgPricePerCarat / (sum(map(diams, 'rap_avg')) / calc.totalCarats)) * -100 : 0;
    calc.finalNetValue = sumOfNumericString(map(diams, 'bidAmount'));
    //calc.finalNetRate = ((100 - calc.finalAvgDisc) * sum(map(diams, 'rap'))) / 100;
    calc.finalNetRate = sumOfNumericString(map(diams, 'bidAmount')) / sum(map(diams, 'crt'));
    calc.netValue = sumOfNumericString(map(diams, 'bidAmount'));
    calc.perCarat = sumOfNumericString(map(diams, 'amt')) / calc.totalCarats;
    calc.BidPerCarat = sumOfNumericString(map(diams, 'bidAmount')) / calc.totalCarats;
    calc.amt = sum(map(diams, 'amt'));
    // SELECT_STONE_TERMS_POP.find(({ id }) => id === term)?.value ?? 0
    calc.addDis = user?.account?.isCOD ? SELECT_STONE_TERMS_COD.find(({ id }) => id === this.state.summ) ?? 0 : SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    calc.finalAvgDisc = totalRapAvg
      ? (1 -
        sumOfNumericString(map(diams, 'bidAmount')) /
        sum(map(diams, 'crt')) /
        (sum(map(diams, 'rap_avg')) / calc.totalCarats)) *
      -100
      : 0;

    if (this.state.summ) {
      const totalRapAvg = sum(map(diams, 'rap_avg'));
      const options = user?.account?.isCOD ? SELECT_STONE_TERMS_COD.find(({ id }) => id === this.state.summ) ?? 0 : SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
      const discCalc = options.minAmt
        ? Number(calc.amt) >= options.minAmt
          ? options.value
          : options.resetDis
        : options.value;
      const termDis = discCalc;

      const avg = totalRapAvg ? (1 - calc.finalNetValue / sum(map(diams, 'rap_avg'))) * -100 : 0;
      const final = calc.finalNetRate + calc.finalNetRate * (parseInt(termDis) / 100);
      const rap = totalRapAvg ? sum(map(diams, 'rap_avg')) / calc.totalCarats : 0;
      //let final =  calc.finalNetValue + (calc.finalNetValue *(parseInt(termDis)));
      const amount = calc.finalNetValue + calc.finalNetValue * (parseInt(termDis) / 100);
      calc.BidPerCarat = sumOfNumericString(map(diams, 'bidAmount')) / calc.totalCarats;
      calc.perCarat = sumOfNumericString(map(diams, 'amt')) / calc.totalCarats;
      // Calculation for term
      calc.addDis = termDis;
      calc.AvgDisc = totalRapAvg ? avg : 0;
      calc.finalNetValue = amount;
      calc.finalNetRate = final;
      calc.finalAvgDisc = totalRapAvg ? (1 - Number(final) / rap) * -100 : 0;
    }
    this.setState({ summation: calc });
  };

  render() {
    const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));

    const dataa = this?.state?.checked
      ?.filter((data) =>
        ['HONGKONG', 'Hong Kong', 'Hongkong', 'hongkong', 'hong kong', 'HONG KONG'].includes(data?.countryNm),
      )
      .map(({ id, stoneId, countryNm, vStnId }) => ({
        id,
        stoneId,
        countryNm,
        vStnId,
      }));

    const ids = dataa?.map((obj) => `${obj?.vStnId}`).join(',');
    const { summation } = this.state;
    localStorage.setItem('disc', summation.addDis);
    const options = user?.account?.isCOD ? SELECT_STONE_TERMS_COD.find(({ id }) => id === this.state.summ) ?? 0 : SELECT_STONE_TERMS_NEW.find(({ id }) => id === this.state.summ) ?? 0;
    localStorage.setItem('termObj', JSON.stringify(options));
    localStorage.setItem('TermsDis', summation.addDis);
    const terms = this.getTerms();
    const VALUES = isMobile()
      ? {
        // 'Pieces:': summation.pieces,
        // 'Crt:': parseFloat(summation.totalCarats || 0).toFixed(2),
        // 'Disc% :': parseFloat(summation.avgDisc || 0).toFixed(2),
        // 'Pr/Ct :': parseFloat(summation.perCarat).toFixed(2),
        // 'Amt. :': parseFloat(summation.amt).toFixed(2),
        'Total Carats': parseFloat(summation.totalCarats || 0).toFixed(2),
        'Bid Avg Discount(%)': parseFloat(summation.AvgDisc || 0).toFixed(2),
        'Avg. Discount(%)': parseFloat(summation.avgDisc || 0).toFixed(2),
        'Bid Per/Carat': parseFloat(summation.BidPerCarat || 0).toFixed(2),
        'Per/Carat': parseFloat(summation.perCarat || 0).toFixed(2),
        'Bid Amount': parseFloat(summation.netValue || 0).toFixed(2),
        // 'Bid Term Discount(%)': parseFloat(summation.addDis || 0).toFixed(2),
        Amount: parseFloat(summation.amt || 0).toFixed(2),
        // 'Final Avg. Discount(%)': parseFloat(summation.finalAvgDisc || 0).toFixed(2),
        // 'Final Price/Carat': parseFloat(summation.finalNetRate || 0).toFixed(2),
        // 'Final Amount': parseFloat(summation.finalNetValue || 0).toFixed(2),
        // TERMS: terms,
      }
      : [
        { title: 'Total Carats', value: parseFloat(summation.totalCarats || 0).toFixed(2) },
        {
          title: 'Bid Avg Discount(%)',
          value: parseFloat(summation.AvgDisc || 0).toFixed(2),
        },
        { title: 'Avg. Discount(%)', value: parseFloat(summation.avgDisc || 0).toFixed(2) },
        { title: 'Bid Per/Carat', value: parseFloat(summation.BidPerCarat || 0).toFixed(2) },
        { title: 'Per/Carat', value: parseFloat(summation.perCarat || 0).toFixed(2) },
        // { title: 'Bid Term Discount(%)', value: parseFloat(summation.addDis || 0).toFixed(2) },
        { title: 'Bid Amount', value: parseFloat(summation.netValue || 0).toFixed(2) },
        { title: 'Amount', value: parseFloat(summation.amt || 0).toFixed(2) },
        // { title: 'Final Avg. Discount(%)', value: parseFloat(summation.finalAvgDisc || 0).toFixed(2) },
        {},
        // { title: 'Final Price/Carat', value: parseFloat(summation.finalNetRate || 0).toFixed(2) },
        {},
        // { title: 'Final Amount', value: parseFloat(summation.finalNetValue || 0).toFixed(2) },
        {},
        // {
        //   title: 'TERMS',
        //   value: terms,
        // },
      ];

    return isMobile() ? (
      <BIDStoneMobile
        {...this.state}
        currentType={currentType}
        onClose={this.props.onClose}
        VALUES={VALUES}
        parent={this}
        disDiffShow={getPath() !== LISTINGPAGES?.MYBID}
      />
    ) : (
      <div>
        <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
          <Heading className="popupInnerTitle mr-20" title={<IntlMessages id="app.bidStone" />} />
          {HeadingCalc(this.state.checked, null, true)}
          {(getPath() !== LISTINGPAGES?.MYBID) && (
            <div className="d-flex align-items-center" style={{ flexDirection: 'column', marginLeft: 'auto' }}>
              <label htmlFor="disDiff" style={{ fontSize: '12px' }}>
                Dis. Diff.
              </label>
              <div>
                <img
                  src={minusIcon}
                  alt="Minus"
                  onClick={() => this.handleDisDiffAction('minus')}
                  style={{ width: '20px', height: '20px', margin: 'auto 10px' }}
                />
                <input
                  type="number"
                  name="disDiff"
                  value={this.state.disDiff}
                  onChange={(e) => this.setDisDiff(e?.target?.value)}
                  style={{ maxWidth: '4rem', textAlign: 'center' }}
                  onKeyDown={(evt) => {
                    evt.which == 13 &&
                      this.setState(
                        { disDiff: this.state.disDiff ? this.state.disDiff?.toFixed(2) : this.state.disDiff },
                        () => this.handleDisDiffOnEnter(),
                      );
                  }}
                  onBlur={() => {
                    this.setState(
                      { disDiff: this.state.disDiff ? this.state.disDiff?.toFixed(2) : this.state.disDiff },
                      () => this.handleDisDiffOnEnter(),
                    );
                  }}
                  max={this.props?.bid?.sub_types?.downwardInterval}
                  min={this.props?.bid?.sub_types?.upwardInterval * -1}
                />
                <img
                  src={plusIcon}
                  alt="Plus"
                  style={{ width: '20px', height: '20px', margin: 'auto 10px' }}
                  onClick={() => this.handleDisDiffAction('plus')}
                />
              </div>
            </div>
          )}
        </div>
        <div className="searchPopupCommonTable">
          <div className="searchResultTable tabInnerTableScroll">
            <Table
              {...this.state}
              nodots
              areAllChecked={true}
              handleCheck={(e) => this.setState({ checked: e })}
              currentType={currentType}
              canSort={false}
              noGrp
              FilterOption={false}
              bidStatus={
                this.state.nextBidData &&
                this.state.nextBidData.sub_types &&
                this.state.nextBidData.sub_types.subTypeCode
              }
            />
          </div>

          <div className="mt-10 finalCalDetail">
            <div className="DiamondDetailPopup">
              {VALUES.map((x) => {
                return (
                  <div className="DiamondDetailPopupItem">
                    <span>{x.title && `${x.title} :`} </span>
                    <span>
                      {x.title === 'Bid Term Discount(%)' || x.title === 'Avg. Discount(%)' || x.title === 'TERMS'
                        ? x.value > 0
                          ? `+${x.value}`
                          : x.value
                        : x.value}
                    </span>
                  </div>
                );
              })}
            </div>
            {
              this.state.checked.filter((x) => x.countryNm === 'USA').length > 0 ?
                (<div style={{ color: "red", fontSize: "17px", fontWeight: 500 }}>
                  Note: Packet No. {this.state.checked.filter((x) => x.countryNm === 'USA')
                    .map((x) => x.vStnId)
                    .join(', ')} Delivery to India is currently unavailable for this stone. However, we can accommodate deliveries to other locations, subject to terms and conditions.</div>) : null
            }
          </div>
          <div className="sideBarPopupButton">
            <a
              id="bid-stone"
              className="commonButton"
              // this.state.showModal
              //   ? `commonButton disable`
              //   : `commonButton`

              // onClick={() => {
              //   this.handleModalVisibility(true);
              //   let showModal = false;
              //   this.state.checked.map((x) => {
              //     if (Number(x.bidDiscount) === Number(x.back)) {
              //       showModal = true;
              //     }
              //   });

              //   if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
              //     this.state.checked.map((stone) =>
              //       stone?.isFcCol
              //         ? OpenNotification({
              //             type: 'error',
              //             title: 'Bid Price/Ct and Price/Ct can not be same for bid.',
              //           })
              //         : OpenNotification({
              //             type: 'error',
              //             title: 'Bid discount and discount can not be same for bid.',
              //           }),
              //     );
              //   }
              //   // else if (!this.state.summ) {
              //   //   OpenNotification({
              //   //     type: 'error',
              //   //     title: 'Please select term to add bid on diamond(s).',
              //   //   });
              //   // }
              //   else {
              //     this.submit();
              //   }
              // }}

              onClick={() => {
                if (ids) {
                  this.handleModalVisibility(true);
                } else {
                  let showModal = false;
                  this.state.checked.map((x) => {
                    if (x.isFcCol == true) {
                      if (Number(x.bidPricePerCarat) === Number(x.ctPr)) {
                        showModal = true;
                      }
                    } else {
                      if (Number(x.bidDiscount) === Number(x.back)) {
                        showModal = true;
                      }
                    }
                  });

                  // if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
                  //   this.state.checked.map(
                  //     (stone) =>
                  //       stone?.isFcCol
                  //         ? OpenNotification({
                  //             type: 'error',
                  //             title: 'Bid Price/Ct and Price/Ct cannot be the same for bid.',
                  //           })
                  //         : OpenNotification({
                  //             type: 'error',
                  //             title: 'Bid discount and discount cannot be the same for bid.',
                  //           }),
                  //           );
                  //           console.log('stone', stone),
                  // } else {
                  //   this.submit();
                  // }

                  if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
                    this.state.checked.map((stone) => {
                      return stone?.isFcCol
                        ? OpenNotification({
                          type: 'error',
                          title: 'Bid Price/Ct and Price/Ct cannot be the same for bid.',
                        })
                        : OpenNotification({
                          type: 'error',
                          title: 'Bid discount and discount cannot be the same for bid.',
                        });
                    });
                  } else {
                    this.submit();
                  }
                }
              }}
            // disabled={this.state.showModal || !this.state.summ}
            >
              <IntlMessages id="app.bidStone" />
            </a>
            <a className="commonButton" onClick={this.props.onClose}>
              <IntlMessages id="app.CancelBid" />
            </a>
          </div>
        </div>

        <CommonModal
          visible={this.state.isModalVisible}
          onCancel={() => this.handleModalVisibility(false)}
          handleCancel={() => this.handleModalVisibility(false)}
          handleOk={() => {
            let showModal = false;
            this.state.checked.map((x) => {
              if (Number(x.bidDiscount) === Number(x.back)) {
                showModal = true;
              }
            });

            if (showModal && this.state.nextBidData.sub_types.subTypeCode !== 'BIWI') {
              this.state.checked.map((stone) =>
                stone?.isFcCol
                  ? OpenNotification({
                    type: 'error',
                    title: 'Bid Price/Ct and Price/Ct can not be same for bid.',
                  })
                  : OpenNotification({
                    type: 'error',
                    title: 'Bid discount and discount can not be same for bid.',
                  }),
              );
            }
            // else if (!this.state.summ) {
            //   OpenNotification({
            //     type: 'error',
            //     title: 'Please select term to add bid on diamond(s).',
            //   });
            // }
            else {
              this.submit();
            }
          }}
          modalSize="xs-size"
          footerShow
          title="Bid Stone"
          submitTitle={<IntlMessages id="app.bidStone" />}
          cancelTitle={<IntlMessages id="app.Cancel" />}
        >
          <div style={{ textAlign: 'center' }}>
            {`Packet No. ${ids} from your bid selection belong to HONGKONG Inventory, So they will be available for
            HONGKONG Delivery only. If Bid win amount of HONGKONG inventory is greater than $15000 it can ship directly
            without any charge apart from INDIA.`}
          </div>
        </CommonModal>
      </div>
    );
  }
}

const mapStateToProps = ({ diamondData }) => {
  return { checkedData: diamondData.selectedRows[currentType] || [] };
};

export default connect(mapStateToProps, null)(BidPopup);