import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import { Drawer } from 'antd';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import SelectStone from '../common/DiamondListing/SelectStone';
import Heading from '../common/Heading';
import BidTermsConditions from './BidTermsConditions';
import Countdown from '../Offer/Timer';
import { getState, isArrayEqual } from '../DiamondList';
import Table from '../DiamondList/TableBack';
import { store } from 'appRedux/store';
import { getColumn, TITLE, fetchDiamondPaginate, LISTINGPAGES, getPath } from '../DiamondList/DiamondListFunctions';
import { DIAMOND_BID, DIAMOND_WEB_STATUS, LOCAL_STORAGE_VAR } from '../../constants/Common';
import IntlMessages from '../../util/IntlMessages';
import NewArrivalBidMobile from './NewArrivalBidMobile';
import { getCurrentTime } from '../../services/util';
import UtilService from '../../services/util';

import { BidService } from 'services/BidService';
import { useCompositeState, useIsMobile, useLoading } from 'util/hooks';
import { isEmpty } from 'util/utils';
import { RowSelectService } from 'services/RowSelectService';

function getBidStartTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(11, 'hours');
  else return moment().startOf('day').add(15, 'hours');
}

function getBidEndTime(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(59, 'minutes');
  else return moment().startOf('day').add(10, 'hours').add(59, 'minutes');
}

export function getBidEndCounter(bidType) {
  if (bidType === DIAMOND_BID.TYPE.OPEN) return moment().startOf('day').add(14, 'hours').add(54, 'minutes');
  else if (moment() < getBidStartTime(DIAMOND_BID.TYPE.OPEN))
    return moment().startOf('day').add(10, 'hours').add(54, 'minutes');
  else return moment().startOf('day').add(1, 'days').add(10, 'hours').add(54, 'minutes');
}

export function getBidType() {
  let bidType = 0;
  if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.OPEN) && moment() <= getBidEndTime(DIAMOND_BID.TYPE.OPEN)) {
    bidType = DIAMOND_BID.TYPE.OPEN;
  } else if (moment() >= getBidStartTime(DIAMOND_BID.TYPE.BLIND) || moment() <= getBidEndTime(DIAMOND_BID.TYPE.BLIND)) {
    bidType = DIAMOND_BID.TYPE.BLIND;
  }
  return bidType;
}

const NewArrivalBidIt = () => {
  const location = useLocation();
  const history = useHistory();
  const [isMobile] = useIsMobile();

  const dispatch = useDispatch();
  const currentType = location.pathname;
  const [loading, startLoading, stopLoading] = useLoading();
  const [state, setState, resetState] = useCompositeState(() => getState());
  const sortRef = React.useRef([]);
  const [terms, showTerm] = React.useState(false);
  const [currTime, setCurrTime] = React.useState('');

  const { data: bidConfig, loading: bidConfigLoading } = useSelector((store) => store?.bidConfig);
  const bidStatus = bidConfig?.sub_types?.allowDiamondFilterWSts;

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const clearSelection = React.useCallback(() => {
    store.dispatch({ type: 'RESET_SELECTED_ROWS' });
    if (document.getElementsByTagName('table')[0]) {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) row.classList.remove('selectTr');
    }
  }, []);

  const checked = useSelector((state) => state.diamondData.selectedRows[location.pathname]) || [];

  React.useEffect(() => {
    const DrawerImage = localStorage.getItem('imageDrawer') ? localStorage.getItem('imageDrawer') === 'true' : true;
    localStorage.setItem('imageDrawer', DrawerImage);
  }, []);

  const setResponse = React.useCallback(
    (res) => {
      const columns = getColumn(state.page);

      if (res) {
        const diamondData = res.data ?? [];
        let defaultChecked = [];
        if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
            ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
            : [];
          if (!isEmpty(defaultChecked)) {
            diamondData.forEach((stone) => {
              if (defaultChecked.includes(stone.id)) stone['isDefaultChecked'] = true;
            });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }
        setState({ ...res, data: diamondData, defaultChecked, columns });
        window.history.pushState(`${window.location.pathname}`, '', `?${res.seachFilterTag.id}`);
      } else {
        setState({ defaultChecked: [] });
      }
    },
    [setState, state.page],
  );

  const fetch = React.useCallback(async () => {
    startLoading();
    resetState();

    if (!isEmpty(bidConfig)) {
      const payload = { page: state?.page, limit: state?.limit, sort: sortRef.current };
      await fetchDiamondPaginate(payload, DIAMOND_WEB_STATUS.BID, setResponse, {
        wSts: bidStatus,
        diamondSearchId: window.location.search.split('?')[1],
      });
    } else {
      RowSelectService.resetSelectedRows(currentType);
    }

    await new Promise((r) => setTimeout(r, 500));
    stopLoading();
  }, [
    bidConfig,
    bidStatus,
    currentType,
    resetState,
    setResponse,
    startLoading,
    state?.limit,
    state?.page,
    stopLoading,
  ]);

  const handleSort = React.useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  const clearAllSelectedRows = React.useCallback(() => {
    if (isMobile) {
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    } else {
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) {
        row.classList.remove('selectTr');
      }
      dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, [dispatch]);

  const onPaginationChange = React.useCallback((page, limit) => setState({ page, limit }), [setState]);

  const setTimers = React.useCallback(async () => {
    const time = await getCurrentTime();
    showTerm(!isEmpty(bidConfig?.sub_types?.ruleFile));
    setCurrTime(time.iso);
    clearSelection();
  }, [bidConfig, clearSelection]);

  React.useEffect(() => {
    setTimers();
  }, [setTimers]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const getTitle = () => {
    return !isEmpty(bidConfig) && !isEmpty(state.data)
      ? bidConfig?.sub_types?.subTypeCode === 'BIWI'
        ? TITLE.BID + ' (' + 'BID IT WIN IT' + ')'
        : TITLE.BID + ' (' + 'Unique Bidding System' + ')' + ' (' + state.count + ')'
      : bidConfig?.sub_types?.subTypeCode === 'BIWI'
        ? 'BID IT WIN IT'
        : 'Unique Bidding System' + ' (0)';
  };

  const getSelection = () => {
    return (
      <SelectStone
        sum={state.sum}
        profilListing={getBidType() ? 'listingSelected diamondListStone bid_it' : ''}
        currentType={currentType}
      />
    );
  };

  const getBidBlock = () => {
    return (
      Boolean(getBidType()) && (
        <div className={'bidEndBlock' + (!isMobile ? ' d-flex align-items-center' : '')}>
          {!isEmpty(bidConfig) && (
            <span className="bidEndLabel">
              <IntlMessages id="app.BidEndIn" /> :
            </span>
          )}
          {!isEmpty(bidConfig) && (
            <Countdown
              currTime={currTime}
              date={moment(bidConfig?.sub_types?.currentBidEndDate)}
              fetch={bidConfig?.sub_types?.currentBidEndDate ? fetch : () => { }}
              bidEndTime={BidService.getBidConfig}
            />
          )}
        </div>
      )
    );
  };

  const getAction = () => {
    return (
      <DiamondListingAction
        {...state}
        bid={bidConfig}
        currTime={currTime}
        currentType={currentType}
        onPaginationChange={onPaginationChange}
        // nopaginate
        clearAll={clearAllSelectedRows}
        fetch={fetch}
        dealOfTheDay={USER_PERMIT?.BID?.view}
        createBidCartStone
        myBidCartStone
        nocart
        nowatch
        myBid
        noreminder
        noquote
        noconfirm={bidConfig?.sub_types?.subTypeCode !== 'BIWI'}
        nonote
        nohold={!USER_PERMIT?.HOLD?.view || getPath() === LISTINGPAGES.BID}
        nofinalcalc
        nonocompare={!USER_PERMIT?.COMPARE?.view}
        noenquiry
        //nooffice={getBidType() === DIAMOND_BID.TYPE.BLIND}
        noshare={
          !USER_PERMIT?.SHARE_VIA_MAIL?.view ||
          !USER_PERMIT?.SHARE_VIA_WHATSAPP?.view ||
          !USER_PERMIT?.SHARE_VIA_SKYPE?.view
        }
        nodownload={!USER_PERMIT?.DOWNLOAD?.view}
        noprint={!USER_PERMIT?.PRINT?.view}
        modify
        onModify={() => {
          const id = window.location.search.split('?')[1];
          id
            ? history.push(`/${LISTINGPAGES.SEARCH_DIAMOND}/${id}`)
            : history.push({ pathname: `/${LISTINGPAGES.SEARCH_DIAMOND}`, state: { isActive: true } });
        }}
      />
    );
  };

  const getDrawer = () => {
    const DrawerImage = localStorage.getItem('imageDrawer');
    return (
      Boolean(DrawerImage === 'true') && (
        <Drawer
          title={false}
          onClose={() => {
            showTerm(false);
            localStorage.setItem('imageDrawer', false);
          }}
          visible={terms}
          wrapClassName="diamondListinSidebar sm-size"
          destroyOnClose
        >
          <BidTermsConditions nextBidData={bidConfig && bidConfig} />
        </Drawer>
      )
    );
  };

  if (isMobile) {
    return (
      <NewArrivalBidMobile
        {...state}
        currentType={currentType}
        parent={{ getTitle, getSelection, getBidBlock, getAction, getDrawer }}
      />
    );
  }

  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
          <Heading title={getTitle()} />

          <div className="d-flex align-items-center">{!isEmpty(state.data) > 0 && getBidBlock()}</div>
          {!isEmpty(bidConfig) ? getSelection() : ''}
        </div>
      </div>
      <div className="searchInnerResult">
        <div
          className="searchResultTable"
          style={{ height: `${checked}` ? `calc(100vh - 217px)` : `calc(100vh - 160px)`, overflow: 'auto' }}
        >
          <Table
            data={state.data}
            loading={loading || bidConfigLoading}
            columns={state.columns}
            handleSort={handleSort}
            currentType={currentType}
            defaultChecked={state.defaultChecked}
            bidStatus={bidConfig?.sub_types?.subTypeCode}
            bidStatusTooltip={bidConfig?.sub_types?.tooltip}
          />
          {!bidConfigLoading && isEmpty(bidConfig) && (
            <div className="empty-data">
              <h3>Currently UBS is Inactive, Kindly visit again. You will be notified when UBS is Active.</h3>
            </div>
          )}
        </div>
        {!isEmpty(bidConfig) && !isEmpty(state.data) && getAction()}
      </div>
      {getDrawer()}
    </div>
  );
};

export default NewArrivalBidIt;
