const initialState = { loading: 0 };

export const LoaderActionTypes = {
  Start: '@loader/start',
  Stop: '@loader/stop',
  Adjust: '@loader/adjust',
  Reset: '@loader/reset',
};

export const LoaderActions = {
  start: (payload) => ({ type: LoaderActionTypes.Start, payload }),
  stop: (payload) => ({ type: LoaderActionTypes.Stop, payload }),
  adjust: (payload) => ({ type: LoaderActionTypes.Adjust, payload }),
  reset: () => ({ type: LoaderActionTypes.Reset }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LoaderActionTypes.Start: {
      return {
        ...state,
        loading: state.loading + 1,
      };
    }

    case LoaderActionTypes.Stop: {
      return {
        ...state,
        loading: state.loading < 1 ? 0 : state.loading - 1,
      };
    }

    case LoaderActionTypes.Adjust: {
      return {
        ...state,
        loading: state.loading + payload.count < 1 ? 0 : state.loading + payload.count,
      };
    }

    case '@@RESET':
    case LoaderActionTypes.Reset: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
