import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { cloneDeep, findIndex, get } from 'lodash';
import DiamondListingAction from '../common/DiamondListing/DiamondListingAction';
import Table from './TableBack';
import { getState, isArrayEqual } from '.';
import { fetchBid, getColumn, LISTINGPAGES, isMobile } from './DiamondListFunctions';
import { getBidType } from '../NewArrivalBidIt';
import Heading from '../common/Heading';
import SelectStone from '../common/DiamondListing/SelectStone';
import { Radio } from 'antd';
import Countdown from '../Offer/Timer';
import IntlMessages from '../../util/IntlMessages';
import { DIAMOND_BID, LOCAL_STORAGE_VAR } from '../../constants/Common';
import { getCurrentTime, getNextBidData } from '../../services/util';
import UtilService from '../../services/util';
import BidListMobile from './BidListMobile';
import NoDataShow from '../common/NoDataShow';

const BIDTAB = {
  LIVE: '1',
  MYBIDCART: '2',
  HISTORY: '3',
};

const currentType = 'MyDealOfTheDay';

const DealOfTheDayList = (props) => {
  const dispatch = useDispatch();
  const tableRef = useRef();

  const [state, setState] = useState(() => cloneDeep(getState()));
  const sortRef = useRef([]);
  const [tab, setTab] = useState(window.location.search === '?cart' ? BIDTAB.MYBIDCART : BIDTAB.LIVE);
  const [showError, setShowError] = useState(false);
  // const [stoneSummary, setStoneSummary] = useState({});
  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const [date] = useState({
    from: moment().startOf('day').add(-6, 'days'),
    to: moment(),
  });
  const [currTime, setCurrTime] = useState('');
  const [nextBidData, setNextBidData] = useState({});

  const selectedRows = useSelector((state) => state.diamondData.selectedRows[currentType]);
  const check = selectedRows;

  const clearAllSelectedRows = useCallback(() => dispatch({ type: 'RESET_SELECTED_ROWS' }), [dispatch]);

  const fetchcolumn = React.useCallback(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    const ctr = Columns.filter((el) => el.id === 'ctPr');
    const amnt = Columns.filter((el) => el.id === 'amt');
    Columns = Columns.filter((el) => el.id !== 'ctPr' && el.id !== 'amt');

    const columns = [
      ...Columns.slice(0, index + 1),
      ctr && ctr[0] ? ctr[0] : null,
      amnt && amnt[0] ? amnt[0] : null,
      {
        Header: 'Bid Disc%',
        accessor: 'bidDiscount',
        Cell: ({ cell, row }) => {
          return row.original.isFcCol
            ? '-'
            : cell.value < 0
            ? `-${parseFloat(Math.abs(cell.value)).toFixed(2)}`
            : `+${parseFloat(Math.abs(cell.value)).toFixed(2)}`;
        },
      },
      {
        Header: 'Bid Price/Ct',
        accessor: 'bidPricePerCarat',
        Cell: ({ cell }) => parseFloat(cell.value).toFixed(2),
      },
      {
        Header: 'Bid Amount',
        accessor: 'bidAmount',
        Cell: ({ cell }) => parseFloat(cell.value).toFixed(2),
      },
      ...Columns.slice(index + 1, Columns.length),
    ]
      .filter((el) => el && el)
      .map((col) => ({ ...col, id: col.accessor }));
    return columns;
  }, []);

  const setResponse = React.useCallback(
    (res) => {
      setShowError(res?.count === 0);
      const columns = fetchcolumn();
      if (res) {
        const diamondData = res.data;
        let defaultChecked = [];
        if (localStorage.getItem(`${LOCAL_STORAGE_VAR}-more`)) {
          defaultChecked = localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
            ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
            : [];
          if (defaultChecked && defaultChecked.length > 0) {
            diamondData &&
              diamondData.map((d) => {
                if (defaultChecked.includes(d.id)) {
                  d['isDefaultChecked'] = true;
                }
                return d;
              });
          }
          localStorage.removeItem(`${LOCAL_STORAGE_VAR}-more`);
        }
        // const calc = calcTotal(diamondData, { term: 0 });
        // const avgDiscount = Number(res?.sum?.avgDiscount);
        // const disDiff = Number(calc?.avgBack) - Number(res?.sum?.avgDiscount);

        setState({
          ...state,
          ...res,
          columns,
          data: diamondData,
          // data: diamondData?.map((item) => {
          //   const bidDiscount = item.back - disDiff;
          //   const bidPricePerCarat = item.rap + (item.rap * bidDiscount) / 100;
          //   const bidAmount = bidPricePerCarat * item.crt;
          //   return { ...item, bidDiscount, bidPricePerCarat, bidAmount, avgDiscount };
          // }),
          defaultChecked,
          loading: false,
        });
      } else {
        setState({ ...state, loading: false, columns, defaultChecked: [] });
      }
    },
    [fetchcolumn, state],
  );

  const fetch = useCallback(() => {
    setState({ ...state, loading: true, data: [] });
    const payload = { ...state, sort: sortRef.current, date };
    if (nextBidData) {
      fetchBid(
        payload,
        nextBidData.id ? nextBidData.id : '',
        nextBidData.sub_types ? nextBidData.sub_types.subTypeCode : '',
        //tab === BIDTAB.LIVE ? [getBidType()] : [DIAMOND_BID.TYPE.OPEN, DIAMOND_BID.TYPE.BLIND],
        tab === BIDTAB.LIVE ? [DIAMOND_BID.STATUS.PENDING] : [DIAMOND_BID.STATUS.WIN, DIAMOND_BID.STATUS.LOSS],
        setResponse,
        tab === BIDTAB.HISTORY,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, nextBidData, tab, setResponse]);

  useEffect(() => {
    async function setTimers() {
      const time = await getCurrentTime();
      const nextBid = await getNextBidData()
        .then()
        .catch((err) => {
          if (err && err.data && err.data.code === 'E_NOT_FOUND') {
            setState((state) => ({ ...state, loading: false, data: [] }));
          }
        });
      setCurrTime(time.iso);
      setNextBidData(nextBid ? nextBid : []);
      nextBid && nextBid.sub_types && fetch();
    }
    setTimers();
    check && tab === BIDTAB.LIVE && clearAllSelectedRows();
  }, [state.page, state.limit, date, tab]);

  const handleSort = useCallback(
    (currentSort) => {
      if (currentSort.length !== sortRef.current.length || !isArrayEqual(currentSort, sortRef.current)) {
        sortRef.current = currentSort;
        fetch();
      }
    },
    [fetch],
  );

  // useEffect(() => {
  //   fetch();
  // }, [date, tab, nextBidData]);

  const getSelection = () => (
    <SelectStone
      profilListing={getBidType() ? 'listingSelected diamondListStone my_bid' : ''}
      sum={state.sum}
      currentType={currentType}
      hideClearAll
      myDealOfTheDay
    />
  );

  const bidEndTime = async () => {
    const nextBid = await getNextBidData()
      .then()
      .catch((err) => {
        if (err && err.data && err.data.code === 'E_NOT_FOUND') {
          setState({ ...state, loading: false, data: [] });
        }
      });
    setNextBidData(nextBid ? nextBid : []);
    getSelection();
  };

  const getTitle = () => {
    return JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length
      ? 'My Deal of the Day' + ' (' + state.count + ')'
      : 'My Deal of the Day' + ' (0)';
  };

  const getAction = () => {
    return tab === BIDTAB.LIVE ? (
      <DiamondListingAction
        {...state}
        {...props}
        nocart
        nonote
        noreminder
        // myBidCartStone
        noconfirm
        noquote
        update={get(USER_PERMIT, 'MY_BID.update', false)}
        nonocompare={!get(USER_PERMIT, 'COMPARE.view', false)}
        nohold
        nofinalcalc
        noenquiry
        noshare={
          !get(USER_PERMIT, 'SHARE_VIA_MAIL.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_WHATSAPP.view', false) ||
          !get(USER_PERMIT, 'SHARE_VIA_SKYPE.view', false)
        }
        nodownload={!get(USER_PERMIT, 'DOWNLOAD.view', false)}
        noprint={!get(USER_PERMIT, 'PRINT.view', false)}
        currentType={currentType}
        nopaginate
        // clearAll={clearAllSelectedRows}
        clearAll={() => {}}
        fetch={fetch}
        //checked={state.data}
      />
    ) : null;
  };

  const getBidBlock = () => {
    return getBidType() ? (
      <div className="bidEndBlock d-flex align-items-center">
        {JSON.stringify(nextBidData) !== '[]' ? (
          <span className="bidEndLabel">
            <IntlMessages id="app.BidEndIn" /> :
          </span>
        ) : (
          ''
        )}
        {JSON.stringify(nextBidData) !== '[]' ? (
          <Countdown
            currTime={currTime}
            date={
              nextBidData &&
              nextBidData.sub_types &&
              nextBidData.sub_types.currentBidEndDate &&
              moment(nextBidData.sub_types.currentBidEndDate)
            }
            bidEndTime={bidEndTime}
          />
        ) : (
          ''
        )}
      </div>
    ) : null;
  };

  const getRadio = () => {
    return (
      <Radio.Group value={tab} onChange={(e) => setTab(e.target.value)}>
        <Radio value={BIDTAB.LIVE}>
          <span>My Deal of the Day</span>
          {/* <IntlMessages id="app.myBid" /> */}
        </Radio>
        {/* <Radio value={BIDTAB.MYBIDCART}>
          <IntlMessages id="app.MyBidCart" />
        </Radio> */}
      </Radio.Group>
    );
  };
  const goBack = () => {
    return (
      <Link className="commonOutline smallButton" to={LISTINGPAGES.DEALTHEOFDAY}>
        <span>Go To Deal of the Day</span>
      </Link>
    );
  };

  if (isMobile()) {
    return (
      <BidListMobile
        {...props}
        {...state}
        checked={check}
        data={state.data}
        currentType={currentType}
        parent={{
          getTitle,
          getSelection,
          getBidBlock: getBidBlock(),
          goBack: goBack(),
          getRadio: getRadio(),
          getAction,
        }}
        areAllChecked={true}
        myBidList={true}
      />
    );
  }
  return (
    <div className="searchResultListWrapper">
      <div className="selectStonHeader">
        <div className="d-flex justify-content-between align-items-center width-100 listingTopBlock pos_rel flex-wrap">
          <Heading title={getTitle()} />
          {JSON.stringify(nextBidData) !== '[]' ? (
            <div className="d-flex">
              <div className="d-flex align-items-center j-content-center mr-10 flex-wrap">
                <div style={{ display: 'block' }}>{getRadio()}</div>
                {/* {getDropDown()} */}
                {goBack()}
              </div>
              {getBidBlock()}
            </div>
          ) : (
            ' '
          )}
          {JSON.stringify(nextBidData) !== '[]' ? getSelection() : ''}
        </div>
      </div>
      <div className="searchInnerResult">
        <div
          className="searchResultTable"
          style={{ height: check?.length ? `calc(100vh - 219px)` : `calc(100vh - 170px)`, overflow: 'auto' }}
        >
          {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? (
            <Table
              noCheckBox
              areAllChecked
              data={state.data}
              columns={state.columns}
              loading={state.loading}
              ref={tableRef}
              areAllChecked
              handleSort={handleSort}
              currentType={currentType}
              defaultChecked={state.defaultChecked}
            />
          ) : (
            ''
          )}
          {showError ? (
            <NoDataShow
              message={'No Bid submission Found, Hurry UP..!!! Submit your bid before bid ends.'}
              noData
              subTypeCode={nextBidData?.sub_types?.subTypeCode === 'BIWI'}
            />
          ) : (
            ''
          )}
          {JSON.stringify(nextBidData) === '[]' ? (
            <NoDataShow message={'No Bid is active now. Please purchase diamond!!'} redirectURL />
          ) : (
            ''
          )}
          {JSON.stringify(nextBidData) !== '[]' && state.data && state.data.length ? getAction() : ''}
        </div>
      </div>
    </div>
  );
};

export default connect((props) => {
  return props;
}, {})(DealOfTheDayList);
