import React from 'react';
import { get } from 'lodash';
import axios from 'axios';

import EmbedPdf from 'components/EmbedPdf';

import { BASE_URL } from 'constants/Common';

const getBlobUrl = async (path) => {
  const response = await axios.get(path, { responseType: 'blob' });
  return window.URL.createObjectURL(response && response.data);
};

const BidTermsConditions = (props) => {
  const [url, setUrl] = React.useState();
  const [loading, setLoading] = React.useState();

  const filePath = React.useMemo(() => {
    return props.nextBidData ? get(props.nextBidData, 'sub_types.ruleFile') : props.centerBanner;
  }, [props.nextBidData, props.centerBanner]);

  const getUrl = React.useCallback(async () => {
    setLoading(true);
    const path = filePath && BASE_URL + filePath;
    const blob = await getBlobUrl(path);
    setUrl((oldUrl) => {
      if (oldUrl) window.URL.revokeObjectURL(oldUrl);
      return blob;
    });
    setLoading(false);
  }, [filePath]);

  React.useEffect(() => {
    getUrl();
  }, [getUrl]);

  if (loading || !url) return null;

  return <EmbedPdf fileUrl={url} fit="pageWidth" noPlugins />;
};

export default React.memo(BidTermsConditions);
