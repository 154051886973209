export class Action {
  constructor(type) {
    this.type = type;
  }

  emit(detail) {
    const event = new CustomEvent(this.type, { detail });
    window.dispatchEvent(event);
  }

  listen(callback) {
    window.addEventListener(callback);
    return () => {
      window.removeEventListener(callback);
    };
  }
}
